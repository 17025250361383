<ng-container *ngIf="project && town && project.type === 'drillings'">
    <div class="page">
        <img class="logo" src="assets/images/logo.svg"/>

        <h1 class="text-center mt-5 mb-5">rapport asfaltboring</h1>

        <table>
            <tr>
                <td width="170">Opdrachtgever:</td>
                <td>{{project.extra?.customer}}</td>
            </tr>
            <tr>

                <td>Werk:</td>
                <td>{{project.name}}</td>
            </tr>
            <tr>
                <td>Boormeester:</td>
                <td>{{project.extra?.driller}}</td>
            </tr>
            <tr>
                <td>Soort boring:</td>
                <td>{{project.extra?.drillingType}}</td>
            </tr>
            <tr>
                <td>Datum uitvoering:</td>
                <td>{{project.extra?.execution_date | date:'EEEE d MMMM yyyy'}}</td>
            </tr>
            <tr>
                <td>Werknummer:</td>
                <td>{{project.extra?.worknumber}}</td>
            </tr>
            <tr>
                <td>Straatnaam:</td>
                <td>{{project.extra?.street}}</td>
            </tr>
            <tr>
                <td>Klantnaam:</td>
                <td>{{project.extra?.customer}}</td>
            </tr>
        </table>
    </div>
    <div *ngIf="project.extra?.description?.length > 8" [innerHTML]="project.extra.description" class="page">

    </div>
</ng-container>
<ng-container *ngIf="project && town && project.type !== 'drillings'">
    <div class="page">
        <img class="logo" src="assets/images/logo.svg"/>

        <h1 class="text-center mt-5 mb-5">Project {{project.name}}</h1>

        <table>
            <tr>
                <td width="170">Opdrachtgever:</td>
                <td>{{town.name}}</td>
            </tr>
            <tr>

                <td>Project:</td>
                <td>{{project.name}}</td>
            </tr>
            <tr>
                <td>Projectleider:</td>
                <td>{{project?.manager_user?.name}}</td>
            </tr>
        </table>
    </div>
    <div *ngIf="project.extra?.description?.length > 8" [innerHTML]="project.extra.description" class="page">

    </div>
</ng-container>
