import {Injectable} from '@angular/core';
import {ConfirmModal} from './confirm-modal';
import {ReplaySubject} from 'rxjs';

class ConfirmModalSettings {

    constructor(public modalConfig: ConfirmModal, public resolve: Function, public reject: Function, public modal: Function = null) {
    }

}

@Injectable()
export class ConfirmModalService {

    private _obsSource = new ReplaySubject<ConfirmModalSettings>(undefined);
    obs$ = this._obsSource.asObservable();

    constructor() {

    }

    public showModal(title: string, content: string, confirmButton = 'Ja', cancelButton = 'Annuleren', callback: Function = null): Promise<{}> {

        let resolveFunc = null;
        let rejectFunc = null;

        const promise = new Promise((resolve, reject) => {
            resolveFunc = resolve;
            rejectFunc = reject;
        });

        const modalConfig = new ConfirmModal(title, content, confirmButton, cancelButton);
        const modalSettings = new ConfirmModalSettings(modalConfig, resolveFunc, rejectFunc, callback);

        this._obsSource.next(modalSettings);

        return promise;
    }

}
