<div class="modal-header">
    <h4 class="modal-title pull-left">Wijzigingshistorie</h4>
    <button mat-dialog-close="" aria-label="Close" class="close pull-right" tabindex="-1" type="button">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div *ngFor="let item of history; let i = index" class="pb-1">
        <ng-container *ngIf="item.user_id !== history[i -1]?.user_id || item.created_at !== history[i -1]?.created_at">
            <b>{{item.user.name}}</b> op {{item.created_at | date:'EE d MMM yyyy HH:mm'}}<br>
        </ng-container>
        {{item.field}} van <i>{{item.old ? item.old : 'Geen'}}</i> naar <b>{{item.new}}</b>
    </div>
</div>
<div class="modal-footer">
    <div class="spacer"></div>
    <button mat-dialog-close color="primary" mat-button type="button">
        Sluiten
    </button>
</div>
