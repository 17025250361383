<div *ngIf="!buttonIcon" class="d-flex">
    <button color="primary" mat-button style="padding: 0">
        <label style="margin: 0; padding: 0 16px;">
            <i *ngIf="!uploading" class="fas fa-upload"></i>
            <i *ngIf="uploading" class="fas fa-spinner fa-spin"></i>
            Uploaden
            <input (change)="uploadFile($event)" hidden multiple type="file">
        </label>
    </button>
</div>
<div *ngIf="buttonIcon" class="d-flex">
    <button color="primary" mat-icon-button style="padding: 0">
        <label class="m-0 curpoint">
            <i *ngIf="uploading" class="fas fa-spinner fa-spin"></i>
            <img *ngIf="!uploading" [src]="icon">
            <input (change)="uploadFile($event)" hidden multiple type="file">
        </label>
    </button>
</div>
