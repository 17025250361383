<div class="content-container">
    <div class="container">
        <div class="row justify-content-md-center">
            <div class="col-12 col-lg-10 col-xl-8">
                <div class="d-flex justify-content-center mb-2 d-sm-none">
                    <div [style.background-image]="contact.image | safeBgUrl" class="user-photo" *ngIf="contact.image">

                    </div>
                </div>

                <div class="d-flex">

                    <div [style.background-image]="contact.image | safeBgUrl" class="user-photo d-none d-sm-flex">
                        <i class="fas fa-user" *ngIf="!contact.image"></i>
                    </div>
                    <div>
                        <h2>
                            {{contact.name}}
                        </h2>
                        <p>
                            <span class="font-weight-bold">Telefoonnummer: </span> <a
                            href="tel:{{contact.phone}}">{{contact.phone}}</a>
                        </p>
                        <p>
                            <span class="font-weight-bold">E-mailadres: </span> <a
                            href="mailto:{{contact.email}}">{{contact.email}}</a>
                        </p>
                        <p>
                            {{contact.description}}
                        </p>
                    </div>
                </div>

                <div *ngIf="Utils.userHasRights('GEMEENTE')" class="d-flex justify-content-end">
                    <button color="primary" mat-raised-button routerLink="/{{Routenames.towns}}/{{townId}}/{{Routenames.projects}}/{{projectId}}/contacts/edit/{{contact.id}}">
                        Contactpersoon aanpassen
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
