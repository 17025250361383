    <div class="container-fluid">
        <div class="row justify-content-sm-center">
            <div class="col-12 col-sm-10 col-md-9 col-lg-7 col-xl-6 pt-2">

                <p class="mrg-btm-15 font-size-13">Voer hieronder uw nieuwe wachtwoord in</p>
                <form class="ng-pristine ng-valid" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <mat-form-field class="w-100">
                            <input [(ngModel)]="data.password" name="password" type="password" id="password"
                                   placeholder="Wachtwoord" matInput>
                        </mat-form-field>
                    </div>
                    <div class="form-group">
                        <mat-form-field class="w-100">
                            <input [(ngModel)]="data.repassword" name="repassword" type="password" id="repassword"
                                   placeholder="Herhaal wachtwoord" matInput>
                        </mat-form-field>
                    </div>
                    <div class="mrg-top-20 message" [ngClass]="{ 'error' : message.error }" *ngIf="message">
                        {{message.message}}
                    </div>
                    <div class="mrg-top-20 text-right">
                        <button mat-raised-button color="primary" [disabled]="buttonDisabled">Wachtwoord opslaan
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
