<form #workareaForm="ngForm">
    <div class="row">
        <div class="col-12 col-md-6">
            <mat-form-field class="w-100">
                <mat-select name="type" (valueChange)="inputChanged($event)" [disabled]="userIsContractor"
                            [(ngModel)]="workarea.extra.damage" #type="ngModel"
                            errorState="type.errors?.required && (type.dirty || workareaForm.submitted)"
                            required placeholder="Oorzaak">
                    <mat-option [value]="undefined" selected disabled>Type</mat-option>
                    <mat-option *ngFor="let type of fieldOptions['damage']"
                                [value]="type">{{type}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 col-md-6">
            <mat-form-field class="w-100">
                <mat-select name="location_damage" (valueChange)="inputChanged($event)"
                            [disabled]="userIsContractor"
                            [(ngModel)]="workarea.extra.location_damage" #location_damage="ngModel"
                            errorState="location_damage.errors?.required && (location_damage.dirty || workareaForm.submitted)"
                            required placeholder="Schade Locatie">
                    <mat-option [value]="undefined" selected disabled>Schade Locatie</mat-option>
                    <mat-option *ngFor="let type of fieldOptions['location_damage']"
                                [value]="type">{{type}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-md-6" *ngIf="Utils.userHasRights('GEMEENTE') || userIsContractor">
            <mat-form-field class="w-100">
                <mat-select name="status" [(ngModel)]="workarea.status_id"
                            (ngModelChange)="statusChange($event)"
                            placeholder="Status">
                    <mat-option *ngFor="let status of statuses" [value]="status.id">
                        {{status.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 col-md-6">
            <!--<mat-form-field class="w-100">
                <mat-select name="district" [(ngModel)]="workarea.extra.district"
                            placeholder="Wijk">
                    <mat-option disabled>Wijk</mat-option>
                    <mat-option *ngFor="let item of fieldOptions['district']" [value]="item">
                        {{item}}
                    </mat-option>
                </mat-select>
            </mat-form-field>-->
            <ng-select [disabled]="userIsContractor"
                       [items]="fieldOptions['district']"
                       [closeOnSelect]="true"
                       [(ngModel)]="workarea.extra.district"
                       [hideSelected]="true"
                       name="district"
                       placeholder="Wijk"></ng-select>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <mat-form-field class="w-100">
                <textarea [(ngModel)]="workarea.comments" name="comments"
                          matInput cdkTextareaAutosize cdkAutosizeMinRows="4"
                          placeholder="Opmerkingen"></textarea>
            </mat-form-field>
        </div>
    </div>
    <mat-divider class="pb-4 pt-2"></mat-divider>
    <div class="row">
        <div class="col-12 col-md-6">
            <mat-form-field class="w-100">
                <mat-select name="roadblock" [(ngModel)]="workarea.extra.roadblock"
                            [disabled]="userIsContractor"
                            placeholder="Afzetting">
                    <mat-option disabled>Afzetting</mat-option>
                    <mat-option *ngFor="let item of fieldOptions['roadblock']" [value]="item">
                        {{item}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 col-md-6">
            <mat-form-field class="w-100">
                <mat-select name="genpriority" [(ngModel)]="workarea.extra.genpriority"
                            [disabled]="userIsContractor"
                            placeholder="Prioriteit">
                    <mat-option disabled>Prioriteit</mat-option>
                    <mat-option *ngFor="let item of fieldOptions['genpriority']" [value]="item">
                        {{item}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-md-6">
            <mat-form-field class="w-100">
                <mat-select name="layertype" [(ngModel)]="workarea.extra.layertype"
                            [disabled]="userIsContractor"
                            placeholder="Soort verharding">
                    <mat-option disabled>Soort verharding</mat-option>
                    <mat-option *ngFor="let item of fieldOptions['layertype']" [value]="item">
                        {{item}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 col-md-6">
            <mat-form-field class="w-100">
                <mat-select name="enclosure" [(ngModel)]="workarea.extra.enclosure"
                            [disabled]="userIsContractor"
                            placeholder="Soort opsluiting">
                    <mat-option disabled>Soort opsluiting</mat-option>
                    <mat-option *ngFor="let item of fieldOptions['enclosure']" [value]="item">
                        {{item}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <mat-divider class="pb-4 pt-2"></mat-divider>
    <div class="row">
        <div class="col-12 col-md-6">
            <mat-form-field class="w-100">
                <input matInput type="number" [(ngModel)]="workarea.extra.surface" name="surface" step="0.01"
                       [disabled]="userIsContractor" placeholder="Oppervlakte (m2)">
            </mat-form-field>
        </div>
        <div class="col-12 col-md-6">
            <mat-form-field class="w-100">
                <input matInput type="number" [(ngModel)]="workarea.extra.length" name="length" step="0.01"
                       [disabled]="userIsContractor" placeholder="Lengte in meters">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-md-6">
            <mat-form-field class="w-100">
                <input matInput type="number" [(ngModel)]="workarea.extra.amount" name="amount" step="0.01"
                       [disabled]="userIsContractor" placeholder="Aantal">
            </mat-form-field>
        </div>
    </div>
</form>