<div class="container pt-3">
    <div class="row justify-content-center">
        <div class="col-md-4 col-lg-3">
            <ng-container *ngIf="contact">
                <b>Foto: </b>
                <label class="m-0 p-0 pr-3">
                    <div [style.background-image]="contact.image | safeBgUrl" class="user-photo"></div>
                    <input (change)="uploadLogo($event)" hidden type="file">
                </label>
            </ng-container>
        </div>
        <div class="col-md-8 col-lg-7">
            <form #contactForm="ngForm">
                <div class="row">
                    <div class="col-12">
                        <mat-form-field class="w-100">
                            <input #name="ngModel" [(ngModel)]="contact.name" errorState="name.errors?.required && (name.dirty || contactForm.submitted)" id="name"
                                   matInput name="name" placeholder="Naam" required
                                   type="text">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <mat-form-field class="w-100">
                            <input #phone="ngModel" [(ngModel)]="contact.phone" errorState="phone.errors?.required && (phone.dirty || contactForm.submitted)" id="phone"
                                   matInput name="phone" placeholder="Telefoonnummer" required
                                   type="tel">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <mat-form-field class="w-100">
                            <input #email="ngModel" [(ngModel)]="contact.email" errorState="email.errors?.required && (email.dirty || contactForm.submitted)" id="email"
                                   matInput name="email" placeholder="E-mailadres" required
                                   type="email">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <mat-form-field class="w-100">
                        <textarea [(ngModel)]="contact.description" id="description" matInput name="description"
                                  placeholder="Beschrijving" type="text"></textarea>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <button color="secondary" mat-button routerLink="/{{Routenames.towns}}/{{townId}}/{{Routenames.projects}}/{{projectId}}/contacts">
                            Annuleren
                        </button>
                        <div class="float-right">
                            <button (click)="delete()" *ngIf="contact.id > 0" class="mr-1" color="warn" mat-button>
                                Verwijderen
                            </button>

                            <button (click)="save(contactForm)" color="primary" mat-raised-button type="submit">
                                Opslaan
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
