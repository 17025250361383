import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TownProjectPhase} from '../../../town-project-phase';
import {TownProjectPhaseService} from '../../../services/townproject/town-project-phase.service';
import {ConfirmDialogService} from '../../../services/confirm-dialog-service/confirm-dialog.service';
import {TownProject} from '../../../town-project';

@Component({
    selector: 'app-town-project-phase-edit-dialog',
    templateUrl: './town-project-phase-edit-dialog.component.html',
    styleUrls: ['./town-project-phase-edit-dialog.component.scss']
})
export class TownProjectPhaseEditDialogComponent implements OnInit {

    form: FormGroup;
    fc: {
        id: FormControl,
        name: FormControl,
        start_date: FormControl,
        end_date: FormControl,
        townproject_id: FormControl
    };

    saving = false;

    constructor(private dialog: MatDialogRef<TownProjectPhaseEditDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public projectPhase: TownProjectPhase,
                private confirmDialog: ConfirmDialogService,
                private townProjectPhasesService: TownProjectPhaseService) {
    }

    ngOnInit(): void {
        this.fc = {
            id: new FormControl(this.projectPhase.id),
            name: new FormControl(this.projectPhase.name, Validators.required),
            start_date: new FormControl(this.projectPhase.start_date),
            end_date: new FormControl(this.projectPhase.end_date),
            townproject_id: new FormControl(this.projectPhase.townproject_id)
        };
        this.form = new FormGroup(this.fc);
    }

    delete() {
        this.confirmDialog.confirm(
            'Verwijderen',
            `Weet je zeker dat je de fase <b>${this.projectPhase.name}</b> met
            <b>${this.projectPhase.town_project_phase_activities.length} activiteiten</b>
            en <b>${this.projectPhase.town_project_phase_replies.length} reacties</b>
            wilt verwijderen?`,
            'Verwijderen',
            'Behouden').then(() => {
            this.townProjectPhasesService.deletePhase(this.projectPhase.id).subscribe(result => {
                Object.assign(this.projectPhase, result.data);
                this.dialog.close(this.projectPhase);
            });
        }, () => {

        });
    }

    setActive() {
        this.saving = true;
        this.townProjectPhasesService.setActive(this.projectPhase.townproject_id, this.projectPhase.id).subscribe(data => {
            const project = new TownProject();
            Object.assign(project, data.data);
            this.dialog.close(project);
            this.saving = false;
        });
    }

    isDone(done:boolean) {
        this.saving = true;
        this.townProjectPhasesService.setDone(this.projectPhase.id, done).subscribe(result => {
            Object.assign(this.projectPhase, result.data);
            this.dialog.close(this.projectPhase);
            this.saving = false;
        }, () => {
            this.saving = false;
        });
    }

    save() {
        this.saving = true;
        this.townProjectPhasesService.savePhase(this.form.value).subscribe(result => {
            Object.assign(this.projectPhase, result.data);
            this.dialog.close(this.projectPhase);
            this.saving = false;
        }, () => {
            this.saving = false;
        });
    }

}
