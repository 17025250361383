import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Chat} from '../chat';
import {ChatMessage} from '../chat-message';
import {Utils} from '../../utils.class';

@Component({
    selector: 'app-chat-message-info-dialog',
    templateUrl: './chat-message-info-dialog.component.html',
    styleUrls: ['./chat-message-info-dialog.component.scss']
})
export class ChatMessageInfoDialogComponent implements OnInit {

    Utils = Utils;

    constructor(public dialogRef: MatDialogRef<ChatMessageInfoDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: {
                    chat: Chat, message: ChatMessage
                }) {
    }

    ngOnInit(): void {
    }

}
