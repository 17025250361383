<div class="header-container">
    <div class="container-fluid">
        <app-back-button class="d-none d-sm-block" backAction="/"
                         backText="Nieuws {{Storage.getTown()['name']}}"></app-back-button>
        <app-back-button class="d-block d-sm-none" backAction="/" backText="Nieuws"></app-back-button>
        <div class="row">
            <div class="col-12">
                <div class="form-group float-right" *ngIf="Utils.userHasRights('GEMEENTE')">
                    <a routerLink="/news/edit/new">
                        <i class="fas fa-plus"></i> Plaats nieuwsbericht
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="content-container">
    <app-pull-to-refresh (onPull)="onPull()" [refresh]="inProgress">
        <div class="news-items">
            <ng-container [hidden]="inProgress" *ngFor="let message of messages">
                <div class="item" (click)="open(message.id)">
                    <div class="row">
                        <div class="col-sm-8 col-lg-9 col-xl-10 col-12 text">
                            {{message.title}}
                            <span *ngIf="message.publish_date == null">(Niet gepubliceerd)</span>
                        </div>
                        <div class="col-sm-4 col-lg-3 col-xl-2 col-12 date">
                            {{message.publish_date || message.updated_at | amLocale:'nl' | amDateFormat: 'D MMMM YYYY \o\\m HH:mm'}}
                        </div>
                    </div>
                    <div class="arrow"></div>
                </div>
            </ng-container>
            <div class="item" (click)="loadMessages()" *ngIf="!loading && showMore">
                <div class="row">
                    <div class="col-12 text-center">
                        <span class="btn btn-primary">Laad meer</span>
                    </div>
                </div>
            </div>
            <div class="item" *ngIf="loading && !inProgress">
                <div class="row">
                    <div class="col-12 text-center">
                        <app-processbar></app-processbar>
                    </div>
                </div>
            </div>
            <div class="item" *ngIf="!loading && !messages.length">
                <div class="row">
                    <div class="col-12 text-center">
                        Er is nog geen nieuws geplaatst
                    </div>
                </div>
            </div>
        </div>
    </app-pull-to-refresh>
</div>
