import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {Town} from '../../town.class';
import {Contact} from '../../contacts/shared/contact';

@Injectable()
export class TownService {

    constructor(private apiService: ApiService) {
    }

    private _towns: Town[] = [];

    public set towns(towns) {
        this._towns = towns;
    }

    public getList(): Promise<Town[]> {
        return new Promise((resolve, reject) => {
            if (this._towns && this._towns.length > 0) {
                resolve(this._towns);
            } else {
                this.apiService.getCall('towns').then((towns: Town[]) => {
                    this._towns = towns;
                    resolve(towns);
                }, err => {
                    reject(err);
                });
            }
        });
    }

    public saveTownIcon(icon) {
        return this.apiService.postCall$('town/icon', {icon});
    }

    public getSingle(townId: number): Promise<Town> {
        return this.apiService.getCall('towns/' + townId);
    }

    public contacts(townprojectId: number): Promise<Array<Contact>> {
        return this.apiService.getCall(`contacts`, {townproject_id: townprojectId});
    }

    public contact(townId: number, contactId: number): Promise<Contact> {
        return this.apiService.getCall(`towns/${townId}/contacts/${contactId}`);
    }

    public storeContact(townId: number, contact: Contact): Promise<Contact> {
        return this.apiService.postCall(`towns/${townId}/contacts`, {contact});
    }

    public deleteContact(townId: number, contactId: number): Promise<Contact> {
        return this.apiService.deleteCall(`towns/${townId}/contacts/${contactId}`);
    }

}
