import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../services/auth/auth.service';
import {Routenames} from '../route-names.enum';

@Component({
    selector: 'app-reset-login-tries',
    templateUrl: './reset-login-tries.component.html',
    styleUrls: ['./reset-login-tries.component.scss']
})
export class ResetLoginTriesComponent implements OnInit, OnDestroy {

    loading = true;
    success = false;
    Routenames = Routenames;

    private subscriptions = new Subscription();

    private token;

    constructor(private router: Router,
                private route: ActivatedRoute,
                private authService: AuthService) {
    }

    ngOnInit() {
        this.subscriptions.add(this.route.params.subscribe(params => {
            this.token = params['token'];
            this.authService.resetLoginTries(this.token).then(() => {
                this.loading = false;
                this.success = true;
            }, () => {
                this.loading = false;
            });
        }));
    }


    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
