<div (mousedown)="outsideClick($event)" *ngIf="!direct" class="container-fluid h-100">
    <div class="row">
        <div class="details-container">
            <div class="folder-view">
                <div class="file-menu">

                </div>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li>
                            <button (click)="up()" [disabled]="!currentPath" color="primary" mat-icon-button>
                                <i class="fas fa-level-up-alt"></i>
                            </button>
                        </li>
                        <li (click)="readDir(crumb.path)"
                            *ngFor="let crumb of breadcrumb"
                            [class.active]="crumb.path === currentPath"
                            [class.curpoint]="crumb.path !== currentPath"
                            aria-current="page"
                            class="breadcrumb-item ">
                            {{crumb.name}}
                            <ng-container *ngIf="!crumb.name">
                                <i class="fas fa-home"></i>
                            </ng-container>
                        </li>
                        <li class="d-flex">

                            <button (click)="addNewItem($event, true)"
                                    color="primary"
                                    mat-button>
                                <i class="fas fa-folder-plus "></i> Map<span
                                class="hidden-sm-down"> toevoegen</span>
                            </button>
                            <button color="primary" mat-button style="padding: 0">
                                <label style="margin: 0; padding: 0 16px;">
                                    <i *ngIf="!uploading" class="fas fa-upload"></i>
                                    <i *ngIf="uploading" class="fas fa-spinner fa-spin"></i>
                                    Uploaden
                                    <input (change)="uploadFile($event)" hidden multiple type="file">
                                </label>
                            </button>
                            <button (click)="camera()" *ngIf="isCordova"
                                    mat-button>
                                <i class="fas fa-camera"></i>
                                Foto
                                <span class="hidden-sm-down"> toevoegen</span>
                            </button>
                            <button (click)="reload()" mat-button>
                                <i [class.fa-spin]="!loaded" class="fas fa-sync-alt"></i>
                            </button>
                        </li>
                    </ol>
                </nav>
                <div (contextmenu)="contextMenuItem($event, contextMenu)"
                     (press)="contextMenuItem($event, contextMenu)" class="folders">
                    <div (click)="select($event, document)"
                         (contextmenu)="select($event, document)"
                         (dblclick)="open()"
                         (press)="select($event, document)"
                         *ngFor="let document of currentDir"
                         [class.selected]="selectedFolders.has(document)"
                         class="item">
                        <div class="content">
                            <i *ngIf="document.type === 'dir'" class="fas fa-folder-open fa-3x"></i>
                            <i *ngIf="document.type === 'file' && !extHasThumb.has(document.extension)"
                               class="far fa-3x {{icons[document.extension] ? icons[document.extension] : 'fa-file'}}">
                            </i>
                            <div *ngIf="document.type === 'file' && extHasThumb.has(document.extension)"
                                 [style.background-image]="(Settings.API_ENDPOINT+'file/thumb?path='+document.path+'&access_token='+Storage.getUserToken())|safeBgUrl"
                                 class="thumb">
                            </div>
                            <div class="title">
                                {{document.filename}}
                            </div>
                        </div>
                    </div>
                    <div #contextMenu
                         [class.show]="showContextMenu && selectedFolders.size > 0"
                         class="context-menu">
                        <div (click)="open()" *ngIf="selectedFolders.size === 1 || multiple"><i class="fas fa-file"></i>
                            Open
                        </div>
                        <div (click)="download()"
                             *ngIf="selectedFolders.size === 1 && selectedFolders.values().next().value.type === 'file'">
                            <i class="fas fa-download"></i>
                            Download
                        </div>
                        <div (click)="rotateImage()"
                             *ngIf="selectedFolders.size === 1 && selectedFolders.values().next().value.type === 'file'">
                            <i class="fa fa-rotate-left"></i>
                            Draaien
                        </div>
                        <div (click)="deleteItem($event)" *ngIf="selectedFolders.size === 1">
                            <i class="fas fa-trash"></i> Verwijderen
                        </div>
                    </div>
                </div>
                <div *ngIf="currentDir?.length === 0" class="empty-folder">
                    <i class="fas fa-upload fa-4x"></i>
                    <div class="title">Deze map is nog leeg!</div>
                    <div>
                        Voeg bestanden toe door ze hierheen te slepen, of maak een map aan met de button in de
                        balk.
                    </div>
                </div>
            </div>
            <div class="actions">
                <button (click)="close()" color="primary" mat-button>Sluiten</button>
                <button (click)="open()"
                        [disabled]="(selectedFolders.size !== 1 && !multiple) || (selectedFolders.size < 1 && multiple)"
                        color="primary"
                        mat-flat-button>
                    Selecteren
                </button>
            </div>
        </div>
    </div>
</div>
