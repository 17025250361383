import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Workarea} from '../../workarea.class';
import {Storage} from '../../storage.class';
import {WorkareaService} from '../../services/workarea/workarea.service';
import {Status} from '../../status.class';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-edit-document-details',
    templateUrl: './edit-document-details.component.html',
    styleUrls: ['./edit-document-details.component.scss']
})
export class EditDocumentDetailsComponent implements OnInit {

    form: FormGroup;

    statuses: Array<Status> = [];
    public saving = false;

    constructor(private confirmDialogService: ConfirmDialogService,
                private workareaService: WorkareaService,
                public dialogRef: MatDialogRef<EditDocumentDetailsComponent>,
                @Inject(MAT_DIALOG_DATA) public workarea: Workarea) {
    }

    ngOnInit() {
        this.getStatusList();
        this.form = new FormGroup({
            street: new FormControl(this.workarea.street, [Validators.required, Validators.maxLength(255)]),
            status_id: new FormControl(this.workarea.status_id, [Validators.required])
        });
    }

    save() {
        this.workarea.street = this.form.get('street').value;
        this.workarea.status_id = this.form.get('status_id').value;
        this.workarea.has_documents = true;
        this.workareaService.saveWorkarea(this.workarea).then((data) => {
            const workarea = data['workarea'] as Workarea;
            if (this.workarea.id) {
                this.workarea.status = this.statuses.find(s => s.id === this.workarea.status_id);
                this.workarea.updated_at = workarea.updated_at;
                this.workareaService.getAll(true).then(() => {
                    this.dialogRef.close();
                });
            } else {
                this.workarea = workarea;
                this.workareaService.addWorkareaToCacheList(this.workarea);
                setTimeout(() => {
                    this.dialogRef.close();
                }, 250);
            }

        }, (error) => {
            this.confirmDialogService.confirm(
                'Fout bij het opslaan',
                `Er is iets fout gegaan bij het opslaan`,
                'Oké', null);
        });
    }

    private getStatusList() {
        this.workareaService.getAvailableStatuses(Storage.getTown()['towntype'] + '_document').then(statuses => {
            this.statuses = statuses;
            if (!this.workarea.status_id) {
                const defStatus = this.statuses.find(s => s.isdefault === 1);
                this.workarea.status = defStatus;
                this.workarea.status_id = defStatus.id;
                this.form.get('status_id').setValue(defStatus.id);
            }
        });
    }

}
