import {Component, OnDestroy, OnInit} from '@angular/core';
import {Article} from '../../classes/article.class';
import {ArticleService} from '../../services/article.service';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';
import {Routenames} from '../../route-names.enum';
import {CodaltComponent} from '../../codalt.component';
import {MatDialog} from '@angular/material/dialog';
import {CordovaService} from '../../cordova.service';
import {ArticleImage} from '../../classes/article-image.class';
import {ImageViewerData} from '../../documents/image-viewer-dialog/image-viewer-data';
import {ImageViewerDialogComponent} from '../../documents/image-viewer-dialog/image-viewer-dialog.component';
import {Settings} from '../../settings.class';
import {ArticleDocument} from '../../classes/article-document';
import {Storage} from '../../storage.class';
import {ArticleLikeType} from '../../classes/article-like';

declare var cordova;

@Component({
    selector: 'app-article-detail',
    templateUrl: './article-detail.component.html',
    styleUrls: ['./article-detail.component.scss']
})
export class ArticleDetailComponent extends CodaltComponent implements OnInit, OnDestroy {

    loading = false;
    article: Article;

    ownLike: ArticleLikeType;

    constructor(private articleService: ArticleService,
                private location: Location,
                private router: Router,
                private confirmDialogService: ConfirmDialogService,
                private matDialog: MatDialog,
                private confirmDialog: ConfirmDialogService,
                private cordovaService: CordovaService,
                private route: ActivatedRoute) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions.add(this.route.params.subscribe((params) => {
            this.getArticle(params['id']);
        }));
    }

    delete() {
        this.confirmDialog.confirm('Verwijderen',
            `Weet je zeker dat je dit artikel wilt verwijderen?`, 'Ja', 'Nee').then(() => {
            this.articleService.delete(this.article.id).subscribe(() => {
                this.router.navigateByUrl(Routenames.articles);
            });
        }, () => {
        });
    }

    openDocument(doc: ArticleDocument) {
        const url = Settings.API_ENDPOINT +
            'file/thumb?secure=article' +
            '&path=/' + this.article.id + '/' + doc.path +
            '&access_token=' + Storage.getUserToken();
        if (typeof cordova !== 'undefined') {
            window['PreviewAnyFile'].previewPath(
                win => console.log("open status", win),
                error => console.error("open failed", error),
                url, {name: 'file.pdf'}
            );
        } else {
            window.open(url);
        }
    }

    openImage(image: ArticleImage) {
        this.matDialog.open(ImageViewerDialogComponent, {
            panelClass: 'image-viewer-dialog',
            data: {
                images: this.article.images,
                secure: 'article',
                viewIndex: this.article.images.indexOf(image)
            } as ImageViewerData
        });

    }

    like(type: ArticleLikeType) {
        this.articleService.like(this.article.id, type).subscribe(like => {
            if (like.data) {
                this.article.articleLikes = [...this.article.articleLikes.filter(l => l.id !== like.data.id), like.data];
            } else {
                this.article.articleLikes = [...this.article.articleLikes.filter(l => l.user_id !== Storage.getUser().id)];
            }
            this.ownLike = this.article.articleLikes.find(a => a.user_id === Storage.getUser().id)?.type;
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private getArticle(slug) {
        this.articleService.get(slug).subscribe((article) => {
            this.article = article.data;
            if (new Date(this.article.publish_date).getTime() > new Date().getTime()) {
                this.cordovaService.setBackbuttonAction(() => {
                    this.router.navigateByUrl(`${Routenames.articles}/toekomst`);
                });
            }
            this.location.replaceState(`${Routenames.articles}/${article.data.slug}`);
            this.ownLike = this.article.articleLikes.find(a => a.user_id === Storage.getUser().id)?.type;
        }, error => {
            if (!this.isCordova) {
                this.confirmDialogService.confirm('Fout', error.message, 'Terug naar overzicht', null).then(() => {
                    this.router.navigateByUrl(Routenames.articles);
                });
            } else {
                this.router.navigateByUrl(Routenames.articles);
            }
        });
    }

    readonly ArticleLikeType = ArticleLikeType;
}
