import {Component, ElementRef, HostListener, NgZone, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, NavigationCancel, NavigationEnd, Router} from '@angular/router';
import {Storage} from './storage.class';
import {User} from './user.class';
import {AuthService} from './services/auth/auth.service';
import {UserService} from './services/user/user.service';
import * as moment from 'moment';
import {CordovaService} from './cordova.service';
import {FirebaseService} from './firebase.service';
import {Settings} from './settings.class';
import {NotificationService} from './notification.service';
import {ConfirmModalService} from './shared/confirm-modal.service';
import {TownService} from './services/town/town.service';
import {Routenames} from './route-names.enum';
import {Title} from '@angular/platform-browser';
import {FormControl} from '@angular/forms';
import {SearchService} from './services/search/search.service';
import {RouteParamnames} from './route-param-names.enum';
import {TownProjectService} from './services/townproject/town-project.service';
import {Utils} from './utils.class';
import {FirebaseX} from '@ionic-native/firebase-x/ngx';
import {Subscription} from 'rxjs';
import {BreakpointObserver} from '@angular/cdk/layout';
import {HttpClient} from '@angular/common/http';

declare var cordova;
declare var IonicDeeplink;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    public copyDate = moment().year();

    public user: User;

    public message: Object;
    activeMenuItem: MenuItem;
    Settings = Settings;
    backAction: (Routenames | string)[] = ['exit'];

    Routenames = Routenames;
    RouteParamnames = RouteParamnames;
    hideHeader = false;
    hideSearch = false;
    showSearchMobile = false;
    bottomMenu: MenuItem[] = [];
    escapeCount = 0;
    townMap = new Map<number, string>();
    projectMap = new Map<number, string>();
    params: {};

    search = '';

    fcSearch = new FormControl();
    loggedIn = false;
    @ViewChild('searchInput')
    private searchInput: ElementRef;
    private subscriptions = new Subscription();

    constructor(private router: Router,
                private ngZone: NgZone,
                private authService: AuthService,
                private userService: UserService,
                private cordovaService: CordovaService,
                private firebaseService: FirebaseService,
                private notificationService: NotificationService,
                private confirmModalService: ConfirmModalService,
                private townService: TownService,
                public searchService: SearchService,
                private townProjectService: TownProjectService,
                private route: ActivatedRoute,
                private firebase: FirebaseX,
                private breakpointObserver: BreakpointObserver,
                private http: HttpClient,
                private title: Title) {

        this.fcSearch.valueChanges.subscribe(searchValue => {
            this.searchService.update(searchValue?.toLowerCase().trim());
        });
    }

    @HostListener('window:keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        if (event.getModifierState && event.getModifierState('Control') && event.key === 'f') {
            if (this.activeMenuItem.search !== false) {
                this.searchInput.nativeElement.focus();
                event.preventDefault();
            }
        }
        if (event.key === 'Escape') {
            if (this.escapeCount === 1) {
                this.escapeCount = 0;
                if (this.backAction[0] !== 'exit') {
                    this.router.navigate(this.backAction);
                }
            } else {
                this.escapeCount++;
                setTimeout(() => {
                    this.escapeCount = 0;
                }, 300);
            }

        }
    }

    ngOnInit() {
        this.http.get('assets/version.json').subscribe(versionInfo => {
            Storage.version = versionInfo as { datetime: string };
        });
        const user = Storage.getUser();
        if (typeof cordova !== 'undefined') {
            this.firebase.hasPermission().then(hasPermission => {
                if (!hasPermission) {
                    this.firebase.grantPermission().then(() => {
                    }, () => {
                    });
                }
            });
        }
        this.loggedIn = !!Storage.getUser();
        if (this.loggedIn) {
            this.checkToken();
            this.setFirebaseSubs();
        }
        this.setMenu();

        this.subscriptions.add(this.authService.onLogin.subscribe(loggedIn => {
            this.loggedIn = loggedIn;
            this.setMenu();
            if (loggedIn) {
                this.setFirebaseSubs();
            }
        }));

        this.watchRouteChange();

        this.subscriptions.add(this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
                setTimeout(() => {
                    this.fcSearch.reset();
                    this.bottomMenu.forEach(item => {
                        item.active = '/' + item.routerLink === event.url;
                        item.active = item.active || !!(item.routeMatch && event.url.match(item.routeMatch));
                        if (item.active) {
                            this.activeMenuItem = item;
                        }
                        if (item.children && item.children.length) {
                            item.children.forEach(childItem => {
                                childItem.active = '/' + childItem.routerLink === event.url;
                                childItem.active = childItem.active || !!(childItem.routeMatch && event.url.match(childItem.routeMatch));
                                if (childItem.active) {
                                    this.activeMenuItem = childItem;
                                }
                            });
                        }
                    });

                    this.hideHeader = this.activeMenuItem?.header === false;
                    this.hideSearch = this.activeMenuItem?.search === false;
                    const deepest = this.route.snapshot.firstChild?.firstChild || this.route.snapshot.firstChild || this.route.snapshot;
                    this.params = deepest.params;
                    if (this.params['via']) {
                        this.backAction = this.params['via'];
                    } else if (this.activeMenuItem?.backAction) {
                        this.backAction = this.activeMenuItem.backAction(deepest.params);
                    } else {
                        this.backAction = ['exit'];
                    }
                    this.cordovaService.setBackbuttonAction(() => {
                        this.router.navigate(this.backAction);
                    });

                    this.title.setTitle((this.activeMenuItem ? this.activeMenuItem.name + ' - ' : '') + 'Dirk');
                });
            }
        }));
        if (user && user.group !== 'CONSUMER') {
            this.townService.getList().then(towns => {
                towns.forEach(p => this.townMap.set(p.id, p.name));
            });
            this.townProjectService.getProjectnames().subscribe(projectNames => {
                projectNames.data.forEach(projectName => this.projectMap.set(projectName.id, projectName.name));
            });
        } else {
            this.townProjectService.getConsumerProjects().subscribe(response => {
                response.data.forEach(consumerProject => {
                    this.townMap.set(consumerProject.town_id, consumerProject.town_name);
                    this.projectMap.set(consumerProject.id, consumerProject.name);

                });
            });
        }
        if (typeof IonicDeeplink !== 'undefined') {
            const routesToMatch = {};
            this.router.config.forEach(route => {
                if (typeof route.path !== 'undefined' && route.path.length > 2) {
                    routesToMatch['/' + route.path] = {
                        target: route.path
                    };
                }
            });
            IonicDeeplink.route(routesToMatch, (match) => {
                console.log(match);
                this.ngZone.run(() => {
                    this.router.navigate([`${match['$link']['path']}`]);

                });
            }, function (nomatch) {
                console.log(nomatch);
            });
        }
    }

    watchRouteChange() {
        this.subscriptions.add(this.router.events.subscribe(val => {
            if (val instanceof NavigationEnd) {
                const user = Storage.getUser();
                const firstPart = val.url.split('/')[1];
                if (user) {
                    this.checkToken();
                    if (!user.active && Settings.unauthorizedAllowedUrls.indexOf(firstPart.split('?')[0]) === -1) {
                        this.router.navigate(['confirm-email'], {queryParamsHandling: 'preserve'});
                    }
                } else {
                    if (Settings.unauthorizedAllowedUrls.indexOf(firstPart) === -1) {
                        this.router.navigate(['login']);
                    }
                }
            }
        }));
    }


    private setFirebaseSubs() {
        if (typeof cordova !== 'undefined') {
            this.firebase.getToken().then(token => {
                this.authService.updateFirebaseToken(token).subscribe();
            });
            this.firebase.onTokenRefresh().subscribe(() => {
                this.firebase.getToken().then(token => this.authService.updateFirebaseToken(token).subscribe(), () => {
                });
            });
            this.firebase.onMessageReceived().subscribe(data => {
                if (data.tap) {
                    this.runNotification(data);
                }
            }, () => {
            });
        }
    }

    private runNotification(data) {
        this.ngZone.run(() => {
            setTimeout(() => {
                this.router.navigateByUrl(data.url);
            });
        });
    }

    private setMenu() {
        const user = Storage.getUser();
        this.bottomMenu = [
            {
                name: 'Klantgebieden',
                icon: 'home',
                visible: () => user && user?.group !== 'CONSUMER',
                active: false,
                search: user && user?.group !== 'CONSUMER',
                badge: 0,
                backAction: () => [Routenames.exit],
                routerLink: this.Routenames.towns,
                children: [
                    {
                        name: 'Kaart',
                        header: false,
                        routeMatch: 'towns\\/([0-9]+)[^\\/]*$',
                        backAction: () => [Routenames.towns]
                    },
                    {
                        name: 'Kaart',
                        header: false,
                        routeMatch: 'towns-screenshot\\/([0-9]+).*$',
                        backAction: () => [Routenames.towns]
                    },
                    {
                        name: 'Workarea',
                        header: false,
                        routeMatch: 'towns\\/([0-9]+)\\/workareas\\/-?([0-9]+)',
                        backAction: params => [Routenames.towns, params[RouteParamnames.townId]]
                    }
                ]
            },

            {
                name: 'Commercie',
                icon: 'map',
                visible: () => user && user?.iscommerce,
                active: false,
                search: false,
                badge: 0,
                backAction: () => [Routenames.exit],
                routerLink: this.Routenames.airtable,
                children: [
                    {
                        name: 'Kaart',
                        header: false,
                        routeMatch: 'airtable\\/([0-9]+)[^\\/]*$',
                        backAction: () => [Routenames.exit]
                    }
                ]
            },
            {
                name: 'Adressen',
                icon: 'map',
                visible: () => user && user?.isaddresses,
                active: false,
                search: false,
                badge: 0,
                backAction: () => [Routenames.exit],
                routerLink: 'adressen',
                children: [
                    {
                        name: 'Kaart',
                        header: false,
                        routeMatch: 'adressen\\/([0-9]+)[^\\/]*$',
                        backAction: () => [Routenames.exit]
                    }
                ]
            },
            {
                name: 'Projecten',
                icon: 'map',
                visible: () => false,
                active: false,
                search: false,
                badge: 0,
                routeMatch: 'projects',
                backAction: () => [Routenames.exit],
                routerLink: this.Routenames.airtable
            },
            {
                name: !user || user?.group === 'CONSUMER' ? 'Tijdlijn' : 'Opdrachten',
                icon: !user || user?.group === 'CONSUMER' ? 'timeline' : 'guide',
                visible: () => user?.group !== 'EMPLOYEE_READ',
                active: true,
                badge: 0,
                search: user?.group !== 'CONSUMER' && this.breakpointObserver.isMatched('(min-width: 768px)'),
                routerLink: !user || user?.group === 'CONSUMER' ? this.Routenames.timeline : this.Routenames.projects,
                routeMatch: 'towns\\/([0-9]+)\\/projects',
                backAction: () => user && user?.group == 'CONSUMER' ? [Routenames.exit] : [Routenames.towns],
                children: [
                    {
                        name: 'Opdracht',
                        search: false,
                        routeMatch: 'towns\\/([0-9]+)\\/projects\\/([0-9]+)[^\\/]*$',
                        backAction: params => [Routenames.towns, params[RouteParamnames.townId], Routenames.projects]
                    },
                    {
                        name: 'Aanmeldingen bewoners',
                        routeMatch: 'towns\\/([0-9]+)\\/projects\\/([0-9]+)/consumers[^\\/]*$',
                        backAction: params => {
                            if (Utils.userIsGroup('CONSUMER')) {
                                return [Routenames.chat];
                            }
                            return [Routenames.towns, params[RouteParamnames.townId], Routenames.projects, params[RouteParamnames.projectId]];
                        }
                    },
                    {
                        name: 'Contactpersonen',
                        routeMatch: 'towns\\/([0-9]+)\\/projects\\/([0-9]+)/contacts[^\\/]*$',
                        backAction: params => {
                            if (Utils.userIsGroup('CONSUMER')) {
                                return [Routenames.chat];
                            }
                            return [Routenames.towns, params[RouteParamnames.townId], Routenames.projects, params[RouteParamnames.projectId]];
                        }
                    },
                    {
                        name: 'Selecteer project',
                        routeMatch: 'towns\\/([0-9]+)\\/projects/contacts$',
                        backAction: params => {
                            return [Routenames.chat];
                        }
                    },
                    {
                        name: 'Tijdlijn',
                        search: false,
                        routeMatch: `towns\\/([0-9]+)\\/projects\\/([0-9]+)/${Routenames.timeline}$`,
                        backAction: params => {
                            if (Utils.userIsGroup('CONSUMER')) {
                                return [Routenames.projects];
                            }
                            return [Routenames.towns, params[RouteParamnames.townId], Routenames.projects];
                        }
                    },
                    {
                        name: 'Tijdlijn',
                        search: false,
                        routeMatch: `towns\\/([0-9]+)\\/projects\\/([0-9]+)/${Routenames.timeline}\\/([0-9]+)*`,
                        backAction: params => {
                            return [Routenames.towns, params[RouteParamnames.townId], Routenames.projects, params[RouteParamnames.projectId], Routenames.timeline];
                        }
                    },
                    {
                        name: 'Contactpersoon',
                        search: false,
                        routeMatch: 'towns\\/([0-9]+)\\/projects\\/([0-9]+)/contacts/([0-9]+)',
                        backAction: params => [Routenames.towns, params[RouteParamnames.townId], Routenames.projects, params[RouteParamnames.projectId], Routenames.contacts]
                    },
                    {
                        name: 'Contactpersoon muteren',
                        search: false,
                        routeMatch: 'towns\\/([0-9]+)\\/projects\\/([0-9]+)/contacts/edit*',
                        backAction: params => [Routenames.towns, params[RouteParamnames.townId], Routenames.projects, params[RouteParamnames.projectId], Routenames.contacts]
                    }
                ]
            },
            {
                name: !user || user?.group === 'CONSUMER' ? 'Home' : 'Booropdrachten',
                icon: 'guide',
                visible: () => false,
                active: true,
                badge: 0,
                search: user?.group !== 'CONSUMER' && this.breakpointObserver.isMatched('(min-width: 768px)'),
                routerLink: this.Routenames.projectsDrilling,
                routeMatch: `towns\\/([0-9]+)\\/${Routenames.projectsDrilling}`,
                backAction: params => [Routenames.towns, params[RouteParamnames.townId], Routenames.projects],
                children: [
                    {
                        name: 'Opdracht',
                        search: false,
                        routeMatch: `towns\\/([0-9]+)\\/${Routenames.projectsDrilling}\\/([0-9]+)[^\\/]*$`,
                        backAction: params => [Routenames.towns, params[RouteParamnames.townId], Routenames.projectsDrilling]
                    }
                ]
            },
            {
                name: 'Tijdlijn',
                icon: 'work',
                visible: () => !['CONSUMER', 'EMPLOYEE_READ'].includes(user?.group) && this.breakpointObserver.isMatched('(min-width: 599px)'),
                active: false,
                search: false,
                badge: 0,
                routerLink: Routenames.timeline,
                routeMatch: Routenames.timeline,
                backAction: () => [Routenames.towns]
            },
            {
                name: !user || user?.group === 'CONSUMER' ? 'Contact' : 'Meldingen',
                icon: 'chat',
                visible: () => !['CONSUMER', 'EMPLOYEE_READ'].includes(user?.group) || user?.townprojects?.length > 0,
                active: false,
                search: false,
                badge: 0,
                routerLink: Routenames.chat,
                routeMatch: Routenames.chat,
                backAction: () => [Routenames.towns]
            }, {
                name: !user || user?.group === 'CONSUMER' ? 'Contact' : 'Melding',
                icon: 'chat',
                visible: () => false,
                active: false,
                search: false,
                badge: 0,
                routeMatch: `${Routenames.chat}\/([0-9]+)*`,
                backAction: () => [Routenames.chat]
            }, {
                name: 'Updates',
                icon: 'updates',
                visible: () => !['CONSUMER', 'EMPLOYEE_READ'].includes(user?.group) || user?.townprojects?.length > 0,
                active: false,
                search: false,
                badge: 0,
                routerLink: Routenames.articles,
                backAction: () => [Routenames.towns],
                routeMatch: Routenames.articles + '/*'
            }, {
                name: 'Updates',
                search: false,
                visible: () => false,
                routeMatch: `${Routenames.articles}\\/([0-9]+)*`,
                backAction: () => {
                    if (Utils.userIsGroup('CONSUMER')) {
                        return [Routenames.articles];
                    }
                    return [Routenames.articles];
                }
            },
            {
                name: 'Profiel',
                icon: 'profile',
                visible: () => true,
                active: false,
                badge: 0,
                routerLink: Routenames.settings,
                routeMatch: Routenames.settings,
                search: false,
                backAction: () => {
                    if (Utils.userIsGroup('CONSUMER')) {
                        return [Routenames.timeline];
                    }
                    return [Routenames.towns];
                }
            },
            {
                name: 'Registreren als bewoner',
                visible: () => false,
                search: false,
                routerLink: Routenames.register,
                backAction: () => [Routenames.login]
            },
            {
                name: 'Commerciele kaart opdrachtgevers D. van der Steen B.V.',
                visible: () => false,
                search: false,
                routerLink: Routenames.airtable,
                backAction: () => [Routenames.projects]
            },
            {
                name: 'Welkom bij Dirk',
                visible: () => false,
                search: false,
                hideMenuContent: true,
                routerLink: Routenames.login,
                backAction: () => [Routenames.exit]
            },
            {
                name: 'Account aanmaken',
                visible: () => false,
                search: false,
                hideMenuContent: true,
                routerLink: Routenames.register,
                routeMatch: Routenames.register + '/?.*',
                backAction: () => [Routenames.login]
            },
            {
                name: 'Account activeren',
                visible: () => false,
                search: false,
                hideMenuContent: true,
                routerLink: Routenames.confirmMail,
                backAction: () => [Routenames.exit]
            },
            {
                name: 'Wachtwoord wijzigen',
                visible: () => false,
                search: false,
                routerLink: Routenames.changePass,
                backAction: () => [Routenames.settings]
            },
            {
                name: 'Wachtwoord vergeten',
                visible: () => false,
                search: false,
                hideMenuContent: true,
                routerLink: Routenames.forgotPass,
                backAction: () => [Routenames.exit]
            },
            {
                name: 'Dirk app installeren',
                visible: () => false,
                search: false,
                hideMenuContent: true,
                routerLink: Routenames.qr,
                backAction: () => [Routenames.exit]
            },
            {
                name: 'Wachtwoord instellen',
                visible: () => false,
                search: false,
                hideMenuContent: true,
                routerLink: Routenames.restorePass,
                routeMatch: Routenames.restorePass,
                backAction: () => [Routenames.exit]
            },
            {
                name: 'Gebruikers beheren',
                visible: () => false,
                search: true,
                routerLink: Routenames.users,
                routeMatch: Routenames.users,
                backAction: () => [Routenames.settings],
                children: [
                    {
                        name: 'Gebruiker aanpassen',
                        visible: () => false,
                        search: false,
                        routeMatch: Routenames.users + '/[0-9]+',
                        backAction: () => [Routenames.users]
                    }
                ]
            }

        ];
    }

    private checkToken() {
        const tokenDate = new Date(Storage.getTokenDate());
        const refreshNotIfBefore = new Date();
        refreshNotIfBefore.setDate(refreshNotIfBefore.getDate() - 3);
        if (tokenDate < refreshNotIfBefore) {
            setTimeout(() => {
                this.authService.refresh().subscribe(() => {

                }, () => {

                });
            }, (2 + (Math.random() * 3)) * 1000);
        }
    }
}


export class MenuItem {
    name!: string;
    icon?: string;
    badge?: number;
    routerLink?: Routenames | string;
    routeMatch?: string;
    hideMenuContent?: boolean;
    backAction?: (params: {}) => (Routenames | string)[];
    active?: boolean;
    search? = true;
    header? = false;
    visible?: () => boolean;
    children?: MenuItem[];
}


