import {EventEmitter, Injectable} from '@angular/core';
import {ApiResponse, ApiService} from '../api/api.service';
import {TownProject} from '../../town-project';
import {map, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {User} from '../../user.class';

@Injectable({
    providedIn: 'root'
})
export class TownProjectService {

    private projectsChanged = new EventEmitter();

    constructor(private apiService: ApiService) {
    }

    getProjectConsumers(projectId: number) {
        return this.apiService.getCall$<User[]>(`townprojects/consumers/${projectId}`);
    }

    getProjectnames() {
        return this.apiService.getCall$<{
            id: number,
            name: string
        }[]>(`townprojects/names`);
    }

    getConsumerProjects() {
        return this.apiService.getCall$<{
            id: number,
            name: string,
            town_id: number,
            town_name: string
        }[]>(`townprojects-for-consumers`);
    }

    follow(townprojectId: number) {
        return this.apiService.postCall$<boolean>(`townprojects/follow`, {townprojectId});
    }

    unfollow(townprojectId: number) {
        return this.apiService.postCall$<boolean>(`townprojects/unfollow`, {townprojectId});
    }

    getProjects(onlyForConsumers = false, townId?: number, projectType?: string[], archive?: boolean, withContacts?: boolean) {
        return new Observable<ApiResponse<TownProject[]>>((observer) => {
            const reloadData = () => {
                this.apiService.getCall$<TownProject[]>(`townprojects`, {
                    onlyForConsumers: onlyForConsumers,
                    townId: townId || townId === 0 ? townId : '',
                    projectType: projectType?.length ? projectType : '',
                    archive: archive ? archive : '',
                    withContacts
                }).subscribe(data => {
                    observer.next(data);
                }, error => {
                    observer.error(error);
                });
            };
            reloadData();
            const projectsChangedSubscription = this.projectsChanged.subscribe(() => {
                reloadData();
            });
            return {
                unsubscribe() {
                    projectsChangedSubscription.unsubscribe();
                }
            };
        });
    }

    getProjectTemplates(type?: string) {
        return this.apiService.getCall$<TownProject[]>('townprojects/templates', {type: (type || '')});
    }

    getProjectsWorkareaCounts() {
        return this.apiService.getCall$('townprojects/counts').pipe(map(c => c.data));
    }

    getProjectsWorkareaCount(townprojectId: number) {
        return this.apiService.getCall$<{
            name: string,
            count: number,
            colorcode: string,
            id: number
        }[]>(`townprojects/count/${townprojectId}`).pipe(map(c => c.data));
    }

    getProject(id: number) {
        return this.apiService.getCall$<TownProject>(`townprojects/${id}`);
    }

    saveProject(project: TownProject) {
        return this.apiService.postCall$(`townprojects`, project)
            .pipe(tap(() => {
                this.projectsChanged.emit();
            }));
    }

    deleteProject(id: number) {
        return this.apiService.deleteCall$(`townprojects/${id}`)
            .pipe(tap(() => {
                this.projectsChanged.emit();
            }));
    }

    public zip(id: number) {
        return this.apiService.getBlobCall(`townprojects/download/${id}`);
    }
}
