import {Component, OnDestroy, OnInit} from '@angular/core';
import {TimelineService} from '../../services/timeline.service';
import {CodaltComponent} from '../../codalt.component';
import {Router} from '@angular/router';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';
import {TownProjectService} from '../../services/townproject/town-project.service';
import {TownProject} from '../../town-project';
import {SearchService} from '../../services/search/search.service';
import {Routenames} from '../../route-names.enum';
import {CordovaService} from '../../cordova.service';

@Component({
    selector: 'app-timeline',
    templateUrl: './timeline.component.html',
    styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent extends CodaltComponent implements OnInit, OnDestroy {

    projects: TownProject[];

    constructor(private timelineService: TimelineService,
                private projectService: TownProjectService,
                private searchService: SearchService,
                private dialog: ConfirmDialogService,
                private cordovaService: CordovaService,
                private router: Router) {
        super();
    }

    ngOnInit(): void {
        this.projectService.getProjects(true).subscribe(data => {
            this.projects = data.data;
            if (this.projects.length === 1) {
                const project = this.projects[0];
                this.router.navigate([Routenames.towns, project.town_id, Routenames.projects, project.id, Routenames.timeline]);
            }
            this.searchService.searchTemplate = null;
            CordovaService.setBackbuttonActionUrl('exit');
        });
    }


}
