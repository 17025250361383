import {MapLocation} from './angular-bing-maps/angular-bing-maps.component';

export class Point {
    private x: number;
    private y: number;

    constructor(x, y) {
        this.x = x;
        this.y = y;
    }
}

export class Region {

    private points = [];
    private length: number;

    constructor(points) {
        this.points = points || [];
        this.length = points.length;
    }

    public area() {
        var area = 0,
            i,
            j,
            point1,
            point2;

        for (i = 0, j = this.length - 1; i < this.length; j = i, i++) {
            point1 = this.points[i];
            point2 = this.points[j];
            area += point1.longitude * point2.latitude;
            area -= point1.latitude * point2.longitude;
        }
        area /= 2;

        return area;
    };

    public centroid() {
        var x = 0,
            y = 0,
            i,
            j,
            f,
            point1,
            point2;

        for (i = 0, j = this.length - 1; i < this.length; j = i, i++) {
            point1 = this.points[i];
            point2 = this.points[j];
            f = point1.longitude * point2.latitude - point2.longitude * point1.latitude;
            x += (point1.longitude + point2.longitude) * f;
            y += (point1.latitude + point2.latitude) * f;
        }

        f = this.area() * 6;

        return new MapLocation({
            longitude: x / f,
            latitude: y / f
        });
    };

    public bestMapView(mapWidth, mapHeight, buffer) {

        var zoomLevel = 0;
        var maxLat = -85;
        var minLat = 85;
        var maxLon = -180;
        var minLon = 180;

        this.points.forEach(point => {

            if (point.latitude > maxLat) {
                maxLat = point.latitude;
            }

            if (point.latitude < minLat) {
                minLat = point.latitude;
            }

            if (point.longitude > maxLon) {
                maxLon = point.longitude;
            }

            if (point.longitude < minLon) {
                minLon = point.longitude;
            }

        });

        var zoom1 = 0;
        var zoom2 = 0;

        //Determine the best zoom level based on the map scale and bounding coordinate information
        if (maxLon != minLon && maxLat != minLat) {

            //best zoom level based on map width
            zoom1 = Math.log(360.0 / 256.0 * (mapWidth - 2 * buffer) / (maxLon - minLon)) / Math.log(2);
            //best zoom level based on map height
            zoom2 = Math.log(180.0 / 256.0 * (mapHeight - 2 * buffer) / (maxLat - minLat)) / Math.log(2);
        }


        return (zoom1 < zoom2) ? zoom1 : zoom2;


    }


    public insideTown(center) {

        var isInside = false;
        var j = 0;
        var x = center.longitude;
        var y = center.latitude;

        var paths = this.points;


        for (var i = 0; i < paths.length; i++) {
            j++;
            if (j == paths.length) {
                j = 0;
            }
            if (((paths[i].latitude < y) && (paths[j].latitude >= y))
                || ((paths[j].latitude < y) && (paths[i].latitude >= y))) {
                if (paths[i].longitude + (y - paths[i].latitude)
                    / (paths[j].latitude - paths[i].latitude)
                    * (paths[j].longitude - paths[i].longitude) < x) {
                    isInside = !isInside
                }
            }
        }

        return isInside;

    }


}

