<ng-container *ngIf="type === 'drillings'">
    <h1>Tabel boorkernen</h1>
    <table class="table table-striped">
        <thead>
        <tr>
            <th class="text-center">Kernnummer</th>
            <th class="text-center">Co&ouml;rdinaten</th>
            <th>Straat</th>
            <th>Rijstrook</th>
            <th>Boorlocatie</th>
            <th class="text-right">Lengte boorkern</th>
            <th>Opmerkingen</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let workarea of filteredWorkareas">
            <td class="text-center">{{workarea.number}}</td>
            <td class="text-center">
                <a href="http://www.google.com/maps/place/{{workarea.lat}},{{workarea.lng}}" target="_blank">
                    {{workarea.lat}}, {{workarea.lng}}
                </a>
            </td>
            <td>{{workarea.street}}</td>
            <td>{{workarea.extra?.lane}}</td>
            <td>{{workarea.extra?.drillLocation}}</td>
            <td class="text-right">{{workarea.extra?.drill_length}} cm</td>
            <td>
                {{workarea.comments?.substr(0, 40)}}
                {{workarea.comments?.length > 40 ? '...' : ''}}
            </td>
        </tr>
        </tbody>
    </table>
</ng-container>
<ng-container *ngIf="type !== 'drillings'">
    <h1>Tabel werkgebieden</h1>
    <table class="table table-striped">
        <thead>
        <tr>
            <th class="text-center">Nummer</th>
            <th class="text-center">Co&ouml;rdinaten</th>
            <th>Straat</th>
            <th>Type</th>
            <th>Schade locatie</th>
            <th>Toplaag</th>
            <th>Afzetting</th>
            <th>Status</th>
            <th>Opmerkingen</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let workarea of filteredWorkareas">
            <td class="text-center">{{workarea.number}}</td>
            <td class="text-center">
                <a href="http://www.google.com/maps/place/{{workarea.lat}},{{workarea.lng}}" target="_blank">
                    {{workarea.lat}}, {{workarea.lng}}
                </a>
            </td>
            <td>{{workarea.street}}</td>
            <td>{{workarea.extra?.damage}}</td>
            <td>{{workarea.extra?.location_damage}}</td>
            <td>{{workarea.extra?.toplayer}}</td>
            <td>{{workarea.extra?.roadblock}}</td>
            <td>{{workarea?.status_name}}</td>
            <td>{{workarea.comments?.substr(0, 40)}}</td>
        </tr>
        </tbody>

    </table>

</ng-container>
