import {ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {ArticleEditComponent} from './articles/article-edit/article-edit.component';
import {WorkareaComponent} from './workarea/workarea.component';

@Injectable()
export class CanDeactivateForm<T extends ArticleEditComponent | WorkareaComponent> implements CanDeactivate<T> {

    constructor() {
    }

    canDeactivate(
        component: T,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (component.openChangesBackActionCheck) {
            return component.openChangesBackActionCheck();
        }
        return true;
    }
}
