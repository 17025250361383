import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {TownProjectPhaseReply} from '../../town-project-phase-reply';

@Injectable({
    providedIn: 'root'
})
export class TownProjectPhaseReplyService {

    constructor(private apiService: ApiService) {
    }

    save(reply: TownProjectPhaseReply) {
        return this.apiService.postCall$<TownProjectPhaseReply>(`townprojects/phases/reply`, reply);
    }

    delete(id: number) {
        return this.apiService.deleteCall$<TownProjectPhaseReply>(`townprojects/phases/reply/${id}`);
    }
}
