<div class="container">
    <div class="row justify-content-md-center">
        <div class="col-12 col-md-10 col-xl-8 pt-2">
            <form #changePasswordForm="ngForm">
                <div class="row">
                    <div class="col-12">
                        <mat-form-field class="w-100">
                            <input #password="ngModel" [(ngModel)]="user.password" autocomplete="new-password"
                                   errorState="password.errors?.minlength && password.dirty" id="password" matInput minlength="6" name="password"
                                   placeholder="Nieuw wachtwoord"
                                   type="password">
                            <mat-error *ngIf="password.errors?.minlength && password.dirty">
                                Uw wachtwoord moet minimaal 7 tekens lang zijn.
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="float-right">
                            <button (click)="save()" [disabled]="user.password?.length < 7" color="primary"
                                    mat-raised-button>
                                Wijzigen
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
