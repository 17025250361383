import {EventEmitter, Injectable} from '@angular/core';
import {CordovaService} from './cordova.service';

@Injectable()
export class FirebaseService {

    public static token: any;
    public newNetification: EventEmitter<any> = new EventEmitter();

    constructor(private cordovaService: CordovaService) {
        this.registerEventEmitters();
    }

    public registerEventEmitters() {
        if (window['FirebasePlugin'] !== undefined) {
            window['FirebasePlugin'].grantPermission();
            window['FirebasePlugin'].onTokenRefresh((token) => {
                // save this server-side and use it to push notifications to this device
                FirebaseService.token = token;
            }, (error) => {
                console.error(error);
            });
            window['FirebasePlugin'].onMessageReceived((notification) => {
                let message = JSON.parse(notification['message']);
                if (notification['tap'] || notification['tap'] === 'background') {
                    message['appWasClosed'] = true;
                } else {
                    message['appWasClosed'] = false;
                }
                console.log(message);
                console.log(notification);
                this.newNetification.emit(message);
            }, (error) => {
                console.error(error);
            });
        } else if (window['FirebasePlugin'] === undefined && typeof window['cordova'] !== 'undefined') {
            alert('This build is corrupted. Plugins are not loaded.');
        }
    }

    public subscribeToTopic(topicName: string) {
        if (window['FirebasePlugin'] !== undefined) {
            if (!FirebaseService.token) {
                window['FirebasePlugin'].onTokenRefresh((token) => {
                    // save this server-side and use it to push notifications to this device
                    FirebaseService.token = token;
                }, (error) => {
                    console.error(error);
                });
            }

            window['FirebasePlugin'].subscribe(topicName);
        }
    }

    public unregisterFromAllTopics() {
        if (window['FirebasePlugin'] !== undefined) {
            window['FirebasePlugin'].unregister();
        }
        setTimeout(() => {
            this.registerEventEmitters();
            this.subscribeToTopic('alldevices');
        }, 3500);
    }


}
