import {EventEmitter, Injectable, NgZone} from '@angular/core';
import {Router} from '@angular/router';

declare var cordova;

@Injectable()
export class CordovaService {

    public static backButtonPressUrl = '/';
    private backFunction: Function;
    private resume = new EventEmitter();
    onResume = this.resume.asObservable();

    constructor(private ngZone: NgZone, private router: Router) {
        this.backFunction = () => {
            navigator['app'].exitApp();
        };
        if (typeof cordova !== 'undefined') {

            document.addEventListener('backbutton', () => {
                this.ngZone.run(() => {
                    this.backFunction();
                });
            }, false);
        }
        document.addEventListener('resume', () => {
            this.resume.emit();
        });
    }

    /**
     * @deprecated
     * @param url
     */
    public static setBackbuttonActionUrl(url) {
        CordovaService.backButtonPressUrl = url;
    }

    public setBackbuttonAction(func: Function) {
        this.backFunction = func;
    }

    public getAppVersion(): Promise<any> {
        return cordova.getAppVersion.getVersionNumber();
    }
}
