import {Storage} from './storage.class';
import {Utils} from './utils.class';
import {Routenames} from './route-names.enum';
import * as FileSaver from 'file-saver';

export class Settings {

    public static readonly EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    public static readonly EXCEL_EXTENSION = '.xlsx';

    public static readonly projectTypes = [
        {
            name: "Boorkernen",
            key: 'drillings'
        }, {
            name: "Project",
            key: 'regular'
        }, {
            name: "Onderhoud",
            key: 'maintenance'
        }
    ];

    public static readonly unauthorizedAllowedUrls = [
        'request-restore-password',
        'restore-password',
        'forgot-password',
        'confirm-email',
        'invite',
        'login',
        'register',
        'reset-login-tries',
        'towns-screenshot',
        'logout',
        Routenames.qr
    ];
    public static readonly BING_MAPS_API_KEY = 'AnmAfGIkRSxoUh0TMvW6z4hLEOkVLc5V4MNzFVg_-eM4HylbpB2UVjx7koWoN3B6';
    public static readonly DEFAULT_FILL_COLOR = 'rgba(103, 198, 221, 0.3)';
    public static readonly ZOOMLEVEL_TRESHOLD_FILLCOLOR = 16;
    public static mapCenter: any = {
        latitude: 52.319413,
        longitude: 4.576759
    };
    public static mapZoom = 1;
    public static filter = {};
    public static backAction = 'exit';
    private static readonly _LOCAL_WS_ENDPOINT = 'ws://localhost:1337';
    private static readonly _PROD_WS_ENDPOINT = 'wss://dirk.dvds.nl/websocket/';
    private static readonly _TEST_WS_ENDPOINT = 'wss://dirktest.codalt.com/websocket/';
    public static readonly WS_ENDPOINT: string =
        Settings.isProduction() ? Settings._PROD_WS_ENDPOINT :
            Settings.isLocalHost() ? Settings._LOCAL_WS_ENDPOINT :
                Settings._TEST_WS_ENDPOINT;

    private static readonly _LOCAL_API_ENDPOINT = 'http://api.dirk.localhost/api/';
    private static readonly _TEST_API_ENDPOINT = 'https://dirktest.codalt.com/api/';
    private static readonly _PROD_API_ENDPOINT = 'https://dirk.dvds.nl/api/';
    public static readonly API_ENDPOINT =
        Settings.isProduction() ? Settings._PROD_API_ENDPOINT :
            Settings.isLocalHost() ? Settings._LOCAL_API_ENDPOINT :
                Settings._TEST_API_ENDPOINT;

    public static _mapSettings: any;

    public static get mapSettings() {
        if (this._mapSettings) {
            return this._mapSettings;
        }
        let storageMapSettings = Storage.getMapSettings();
        if (!storageMapSettings) {
            storageMapSettings = {
                'show_cancelled': false,
                'show_executed_month': false,
                'show_executed_year': false,
                'show_approved': false,
                'show_own': true,
                'largeMapTextShadow': false
            };
            if (Utils.userHasRights('USER') || Utils.userHasRights('CONTRACTOR') || Utils.userHasRights('GEMEENTE') || Utils.userHasRights('READ')) {
                storageMapSettings['show_own'] = false;
            }
            this._mapSettings = storageMapSettings;
            Storage.setMapSettings(this._mapSettings);
        }
        return this._mapSettings = storageMapSettings;
    }

    public static set mapSettings(setSettings) {
        Storage.setMapSettings(this._mapSettings = setSettings);
    }

    public static getMarker(identifier, number, color, shadow = true, has_documents = false, type = 'maintenance') {
        if (type == 'drillings') {
            const textColor = color === 'light' ? 'st1' : 'st0';
            const icon = `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<svg xml:space="preserve" style="enable-background:new 0 0 121.2 36;" viewBox="0 0 121.2 36" height="36" width="120" y="0px" x="0px" id="svg2" version="1.1" sodipodi:docname="bk.svg" inkscape:version="1.1.1 (3bf5ae0d25, 2021-09-20)" xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape" xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg"><defs id="defs18" /><sodipodi:namedview id="namedview16" pagecolor="#ffffff" bordercolor="#666666" borderopacity="1.0" inkscape:pageshadow="2" inkscape:pageopacity="0.0" inkscape:pagecheckerboard="0" showgrid="false" inkscape:zoom="8.8166667" inkscape:cx="60.056711" inkscape:cy="17.977316" inkscape:window-width="2196" inkscape:window-height="1081" inkscape:window-x="800" inkscape:window-y="434" inkscape:window-maximized="0" inkscape:current-layer="svg2" />
<style
   type="text/css"
   id="style2">@import url(&quot;https://fonts.googleapis.com/css?family=Roboto:700&quot;);</style>
<style
   id="style34"
   type="text/css">
    .st0{fill:#0F0064;}
    .st1{fill:#FFFFFF;}
    .st2{font-family:'Roboto';font-weight:700;}
    .st3{font-size:16px;}
</style>
<g id="g40" transform="matrix(0.50168242,0,0,0.50168242,5.8537996,9.714329)">
    <path id="path36" d="M 16,8 C 10.5,8 6,12.5 6,18 6,23.5 10.5,28 16,28 21.5,28 26,23.5 26,18 26,12.5 21.5,8 16,8 Z" class="st0" />
    <path id="path38" d="m 16,10 c 4.4,0 8,3.6 8,8 0,4.4 -3.6,8 -8,8 -4.4,0 -8,-3.6 -8,-8 0,-4.4 3.6,-8 8,-8" class="st1" />
</g>
<g
   transform="translate(-9.7372401,-1.8854442)" id="Symbols">
    <g id="icons_x2F_mark_x5F_blackdrilling">
        <g id="_x39_9">
            <g id="use33">
                <text id="text42" class="${textColor} st2 st3" transform="translate(28,25.8292)">${number}</text>
            </g>
            <g id="use35">
                <text id="text45" class="${textColor} st2 st3" transform="translate(28,25.8292)">${number}</text>
            </g>
        </g>
    </g>
</g>
</svg>
`;
            return icon;
        }
        if (has_documents) {
            return `<svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="star" class="svg-inline--fa fa-star fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg"  height="28"  width="28" viewBox="0 0 576 512">
<path  style="fill:#${color}" fill="currentColor" d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM388.6 312.3l23.7 138.4L288 385.4l-124.3 65.3 23.7-138.4-100.6-98 139-20.2 62.2-126 62.2 126 139 20.2-100.6 98z"></path></svg>`;


        } else {
            const icon = `<svg xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:cc="http://creativecommons.org/ns#" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="svg2" version="1.1" viewBox="0 0 121.17871 36" height="36" width="121.17871">
	<defs id="defs4">
		<style type="text/css">@import url("https://fonts.googleapis.com/css?family=Roboto:700");</style>
		<path id="path-1" d="M 15.367928,31.763464 C 14.409091,30.934103 6,23.505485 6,18.14532 6,12.551588 10.486929,8 16,8 21.513071,8 26,12.551588 26,18.14532 26,23.505485 17.59286,30.934103 16.632072,31.763464 16.451619,31.920825 16.224346,32 16,32 15.775654,32 15.550332,31.92083 15.367928,31.763464 Z" />

		<text style="font-weight:bold;font-size:16px;font-family:Roboto, Sans-Serif, Arial;fill:#0f0064" line-spacing="12" font-weight="bold" font-family="Roboto, Sans-Serif, Arial" font-size="12" id="text-${number}">
			<tspan id="tspan15" y="24" x="28">${number}</tspan>
		</text>
		${shadow ? '<filter id="filter-4" filterUnits="objectBoundingBox" height="1.75" width="1.196" y="-0.375" x="-0.098000005"> <feOffset id="feOffset18" result="shadowOffsetOuter1" in="SourceAlpha" dy="0" dx="0" /><feGaussianBlur id="feGaussianBlur20" result="shadowBlurOuter1" in="shadowOffsetOuter1" stdDeviation="1.5" /><feColorMatrix id="feColorMatrix22" in="shadowBlurOuter1" type="matrix" values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 1 0" /></filter>' : ' '}
	</defs><g transform="translate(0,-2)" style="fill:none;fill-rule:evenodd;stroke:none;stroke-width:1" id="Symbols">
		<g id="icons/mark_black${identifier}">
			<g id="Path${identifier}">
				<use height="100%" width="100%" y="0" x="0" style="fill:#000000;fill-opacity:1;filter:url(#filter-2)" id="use27" xlink:href="#path-1" />
				<use height="100%" width="100%" y="0" x="0" style="fill:#ffffff;fill-rule:evenodd" id="use29" xlink:href="#path-1" />
			</g>
			<path style="fill:#${color}" id="Fill-${identifier}" d="M 8,18.077439 C 8,21.515563 13.292293,27.037903 16,29.5 18.707707,27.037903 24,21.515563 24,18.077439 24,13.623149 20.412021,10 16,10 11.589918,10 8,13.623149 8,18.077439 Z" />
			<g style="fill:#0f0064;fill-opacity:1" id="99">
				<use height="100%" width="100%" y="0" x="0" style="filter:url(#filter-4)" id="use33" xlink:href="#text-${number}" />
				<use height="100%" width="100%" y="0" x="0" id="use35" xlink:href="#text-${number}" />
			</g>
		</g>
	</g>
</svg>`;
            return icon;
        }
    }

    private static isLocalHost() {
        return !window['cordova'] && (location.hostname === 'localhost' || location.hostname === '127.0.0.1' || location.hostname === '::1' || location.hostname === 'api.dirk.localhost');
    }

    private static isProduction() {
        return !!window['cordova'] || location.hostname === 'dirk.dvandersteenbv.nl' || location.hostname === 'dirk.dvds.nl';
    }

    public static isApp() {
        return !!window['cordova'];
    }

    public static saveAsExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], {
            type: Settings.EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + Settings.EXCEL_EXTENSION);
    }

}
