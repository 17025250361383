<div [class.opened]="mobileMenuOpen" class="mobile-menu user-select-none">
    <ul class="list-group">
        <li class="list-group-item">
            Menu <span (click)="mobileMenuOpen = !mobileMenuOpen" class="icon-close_white"></span>
        </li>
        <li (click)="goHome()" class="list-group-item">
            <div class="icon-home"></div>
            Home
        </li>
        <li (click)="searchToggle()" class="list-group-item">
            <div class="icon-search"></div>
            Zoeken
        </li>
        <li [routerLink]="['/settings', {via: 'towns/'+town.id}]" class="list-group-item">
            <div class="icon-settings"></div>
            Instellingen
        </li>
        <li (click)="reload(true);mobileMenuOpen=false" class="list-group-item">
            <div class="icon-sync"></div>
            Kaart herladen
        </li>
        <li (click)="centerCountry();mobileMenuOpen=false" class="list-group-item">
            <div class="icon-netherlands"></div>
            Nederland
        </li>
        <li (click)="mapStyleToggle();mobileMenuOpen=false" class="list-group-item">
            <div [hidden]="mapStyleToggleTrigger%2 == 0" class="icon-map"></div>
            <span [hidden]="mapStyleToggleTrigger%2 == 0">Kaartweergave</span>
            <div [hidden]="mapStyleToggleTrigger%2 != 0" class="icon-satellite"></div>
            <span [hidden]="mapStyleToggleTrigger%2 != 0">Sattelietweergave</span>

        </li>
    </ul>
</div>
<div class="navbar-mobile user-select-none">
    <i (click)="mobileMenuOpen = !mobileMenuOpen" class="fas fa-bars"></i> <span>{{town.name}}</span>
</div>
<div [class.bar-visible]="searchbarVisible"
     [class.filter-visible]="filterbarVisible"
     [class.force-map-width]="forceMapWidthSearch"
     class="searchbar user-select-none">
    <div class="col-12">
        <br>
        <mat-form-field class="w-100">
            <input [(ngModel)]="searchinput" id="searchinput" matInput name="searchinput"
                   placeholder="Zoeken..." type="text">

            <button (click)="searchinput=''" *ngIf="searchinput" aria-label="Clear" mat-button mat-icon-button
                    matSuffix>
                <mat-icon class="icon-close">close</mat-icon>
            </button>
        </mat-form-field>
        <div class="row">
            <div class="list-items">
                <ng-container *ngFor="let result of searchResults">
                    <div (click)="scrollToWorkarea(result.id)" class="item">
                        <div class="row">
                            <div class="col-3 marker">
                                <div
                                    [innerHTML]="Settings.getMarker(result.id, result.number, result.status_colorcode) | safeHtml"
                                    class="marker"></div>
                            </div>
                            <div class="col-9 text txt-ellipsis">
                                {{result.street}} {{result.housenumber}} {{result.place}}
                            </div>
                        </div>
                        <div class="arrow"></div>
                    </div>
                </ng-container>
                <div class="row">
                    <div *ngIf="appliedAnyFilter" class="col-12 text-center">
                        Er zijn filters actief. Deze kunnen invloed hebben op de zoekresultaten.
                    </div>
                    <div *ngIf="!loading && !searchResults.length && searchinput" class="col-12 text-center">
                        Er zijn geen werkvakken gevonden
                    </div>
                    <div class="col-12">
                        <app-processbar *ngIf="loading"></app-processbar>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div [class.bar-visible]="searchbarVisible"
     [class.filter-visible]="filterbarVisible"
     [class.filter-was-visible]="filterbarWasVisible"
     [class.force-map-width-filter]="forceMapWidthFilter"
     [class.force-map-width-search]="forceMapWidthSearch"
     *ngIf="town"
     class="near-searchbar user-select-none">
    <div class="logo-small float-right" routerLink="/"></div>
    <app-angular-bing-maps
        #angularBingMapsComponent
        (map-zoom)="setZoom($event)"
        (mousedown)="mousedownEvent($event)"
        (pushpin-click)="pushpinClick($event)"
        (viewchangeend)="viewchangeendEvent($event)"
        [bing-maps-api-key]="Settings.BING_MAPS_API_KEY"
        [customStyle]="customStyle"
        [forceFollowLocation]="forceFollowLocation"
        [geoData]="currentCoords"
        [map-geo-trigger]="mapGeoTrigger"
        [map-style-toggle-trigger]="mapStyleToggleTrigger"
        [map-zoom-trigger]="mapZoomTrigger"
        [screenshot]="screenshot"
        [polygons]="polygons"
        [pushpins]="pushpins"
        [set-map-center]="Settings.mapCenter"
        [set-map-zoom]="Settings.mapZoom"
        id="map"
    ></app-angular-bing-maps>

    <div *ngIf="!screenshot" class="sidebar left d-none d-md-block user-select-none">
        <div (click)="searchToggle()" class="sidebar-icon search"></div>
        <div (click)="goHome()" class="sidebar-icon home"></div>
        <div
            *ngIf="Utils.userHasRights('USER') || Utils.userHasRights('GEMEENTE') || Utils.userHasRights('SUBADMIN') || town.towntype === 'straatwerk'"
            class="sidebar-icon add-doc" routerLink="/towns/{{town.id}}/projects{{projectId ? ('/'+projectId) : ''}}">
        </div>
        <div (click)="reload(true)" class="sidebar-icon sync"></div>
        <div [routerLink]="['/settings', {via: 'towns/'+town.id}]"
             class="sidebar-icon settings"
             queryParamsHandling="merge"></div>
        <div (click)="createWorkarea()" *ngIf="Utils.userHasRights('CONSUMER')" class="sidebar-icon add"></div>
    </div>

    <div *ngIf="!screenshot" class="sidebar right d-none d-md-block user-select-none">
        <div (click)="filterToggle()" class="sidebar-icon search">
            <div [class.filtered]="(totalWorkareaCount-filteredWorkareaCount)>0" class="dot"></div>
        </div>
        <div (click)="centerCountry()" class="sidebar-icon netherlands"></div>
        <div (click)="centerTown()" class="sidebar-icon town"></div>
        <div (click)="setMapCenterGeo()" class="sidebar-icon sdb-target"></div>
        <div (click)="zoomIn()" class="sidebar-icon zoomin"></div>
        <div (click)="zoomOut()" class="sidebar-icon zoomout"></div>
        <div (click)="mapStyleToggle()" [hidden]="mapStyleToggleTrigger%2 == 0"
             class="sidebar-icon earth map"></div>
        <div (click)="mapStyleToggle()" [hidden]="mapStyleToggleTrigger%2 != 0"
             class="sidebar-icon earth satellite"></div>
    </div>

    <div *ngIf="!screenshot && !movingPushpin" class="sidebar bottom d-bock d-md-none user-select-none">
        <div class="row">
            <div class="col">
                <div class="sidebar-icon home" routerLink="/"></div>
            </div>
            <div class="col">
                <div (click)="centerTown()" class="sidebar-icon town"></div>
            </div>
            <div class="col">
                <div (click)="setMapCenterGeo()" class="sidebar-icon sdb-target"></div>
            </div>
            <div class="col">
                <div (click)="createWorkarea()" class="sidebar-icon add"></div>
            </div>
        </div>
    </div>

    <div *ngIf="!screenshot" class="target"></div>

    <div [style.bottom]="selectedPosition.bottom"
         [style.left]="selectedPosition.left"
         [style.max-height]="selectedPosition.maxHeight"
         [style.right]="selectedPosition.right"
         [style.top]="selectedPosition.top"
         class="selected-workareas user-select-none">
        <a *ngFor="let workarea of selectedWorkareas"
           class="workarea"
           routerLink="/towns/{{town.id}}/workareas/{{workarea.id}}{{workarea.has_documents ? '/documents' : null}}">
            <div class="details">
                <div class="number">
                    {{workarea.has_documents ? workarea.street : workarea.number}}
                    <i class="icon-more-custom pointer" routerLink="/workarea"></i>
                </div>
                <table border="0" cellpadding="0" cellspacing="0">
                    <tr>
                        <td class="txtbold">Soort</td>
                        <td width="10"></td>
                        <td *ngIf="workarea.extra && workarea.extra.vogzang_type">{{workarea.extra.vogzang_type}}
                            <small *ngIf="workarea.extra && workarea.extra.vogzang_service">
                                ({{workarea.extra.vogzang_service}})
                            </small>
                        </td>
                        <td *ngIf="workarea.extra && workarea.extra.damage">
                            {{workarea.extra.damage}}
                        </td>
                        <td *ngIf="workarea.has_documents">
                            Werklocatie
                        </td>
                    </tr>
                    <tr>
                        <td class="txtbold">Status</td>
                        <td width="10"></td>
                        <td>{{workarea.status_name}}</td>
                    </tr>
                </table>
            </div>
            <div *ngIf="!workarea.has_documents" [style.background-image]="workarea.image + '?tinythumb=true' | safeBgUrl"
                 class="image"></div>
        </a>
    </div>
</div>
<div [class.bar-visible]="searchbarVisible"
     [class.filter-visible]="filterbarVisible"
     [class.force-map-width]="forceMapWidthFilter"
     class="filterbar user-select-none">
    <app-workareas-filter #mapFilter [town]="town">
    </app-workareas-filter>

    <div class="col-12">
        <b>Dit filter toont {{filteredWorkareaCount}} van {{totalWorkareaCount}} werkvakken.</b>
        <br><br>
        <i>
            Afgebroken, uitgevoerde en goedgekeurde werkvakken worden alleen getoond als dit is ingesteld in de
            instellingen.
        </i>
        <br><br>
    </div>
</div>
<div class="buttons-move-pushpin" *ngIf="movingPushpin">
    <div class="form-group">
        <button (click)="movePushpin()"
                class="btn btn-success btn-block z-depth-0">
            Nieuwe locatie bevestigen
        </button>
    </div>
</div>
