import {Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Workarea} from '../../workarea.class';
import {Status} from '../../status.class';
import {Utils} from '../../utils.class';

@Component({
    selector: 'app-workarea-drill',
    templateUrl: './workarea-drill.component.html',
    styleUrls: ['./workarea-drill.component.scss']
})
export class WorkareaDrillComponent implements OnInit {

    @ViewChild('workareaForm', {static: true}) workareaForm: FormGroup;
    @Output()
    public formValid = new EventEmitter<FormGroup>();
    @Input()
    public workarea: Workarea;
    @Input()
    statuses: Status[];
    @Input()
    fieldOptions: object;
    public Utils = Utils;

    constructor() {

    }

    @HostListener('change', ['$event'])
    inputChanged(event) {
        this.formValid.emit(this.workareaForm);
    }

    ngOnInit(): void {
        this.formValid.emit(this.workareaForm);
        this.workarea.type = 'drillings';
    }



}
