import {TownProject} from './town-project';

export class TownProjectDrilling extends TownProject {
    extra: {
        reports: string[],
        driller: string,
        drillingType: string,
        foundationType: string,
        description: string,
        worknumber: string,
        street: string,
        customer: string,
        execution_date: Date;
    };
}

export class TownProjectDrillingOptions {
    static drillingType = ['Kwaliteitsboring', 'Constructieboring', 'DLC boring'];
    static foundationType = ['Zand', 'Gebonden', 'Ongebonden', 'Elders'];
}
