import {Component, OnDestroy, OnInit} from '@angular/core';
import {Message} from '../shared/message';
import {ActivatedRoute, Router} from '@angular/router';
import {MessageService} from '../shared/message.service';
import {ConfirmModalService} from '../../shared/confirm-modal.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-news-edit',
    templateUrl: './news-edit.component.html',
    styleUrls: ['./news-edit.component.scss']
})
export class NewsEditComponent implements OnInit, OnDestroy {

    public message = new Message();
    public messageId: number;

    public ispublished = false;

    private subscriptions = new Subscription();

    constructor(private messageService: MessageService,
                private route: ActivatedRoute,
                private router: Router,
                private confirmModalService: ConfirmModalService) {

    }

    ngOnInit() {
        this.subscriptions.add(this.route.params.subscribe(params => {
            if (params['id'] !== 'new') {
                this.messageId = params['id'];
                this.messageService.get(this.messageId).then(message => {
                    this.message = message;
                    this.message.published = this.ispublished = this.message.publish_date != null;
                }, () => {
                });
            }
        }));
    }

    variableIsPresent(variable: any) {
        if (typeof variable === 'undefined') {
            return false;
        }

        return variable.length > 0;
    }

    save() {
        if (this.messageId) {
            this.messageService.update(this.message).then((message: Message) => {
                this.router.navigate([`news`]);
            }, error => {
                this.confirmModalService.showModal(
                    'Nieuwsbericht niet opgeslagen',
                    'Er is iets foutgegaan bij het opslaan van het nieuwsbericht.',
                    'Oke',
                    null
                );
            });
        } else {
            this.messageService.create(this.message).then((message: Message) => {
                this.router.navigate([`news`]);
            }, error => {
                this.confirmModalService.showModal(
                    'Nieuwsbericht niet opgeslagen',
                    'Er is iets foutgegaan bij het opslaan van het nieuwsbericht.',
                    'Oke',
                    null
                );

            });
        }
    }

    delete() {
        this.confirmModalService.showModal(
            'Nieuwsbericht verwijderen',
            'Weet je zeker dat je dit nieuwsbericht wilt verwijderen?',
            'Ja',
            'Nee'
        ).then(() => {
            this.messageService.delete(this.message.id).then(() => {
                this.router.navigate(['news']);
            });
        }, () => {
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

}