import {User} from './user.class';
import {EventEmitter} from '@angular/core';
import {Town} from './town.class';
import {Filter} from './filter';

class StorageInterface {
    public static user: User;
}

export class Storage implements StorageInterface {

    public static townChange: EventEmitter<any> = new EventEmitter();
    public static user: User;
    static version: {datetime: string};

    public static setUserToken(token) {
        localStorage.setItem('access_token', token);
    }

    public static getRegister() {
        const lsr = localStorage.getItem('register');
        return lsr === 'false' ? false : null;
    }

    public static setRegister(register: boolean) {
        localStorage.setItem('register', register ? 'true' : 'false');
    }

    public static setExpireTimeToken(token) {
        localStorage.setItem('token_date', new Date().toISOString());
        localStorage.setItem('expire_access_token', token);
    }

    public static getTokenDate() {
        return localStorage.getItem('token_date');
    }

    public static removeTokenDate() {
        localStorage.setItem('token_date', '');
        localStorage.setItem('expire_access_token', '');
    }

    public static getExpireTimeToken() {
        return localStorage.getItem('expire_access_token');
    }

    public static setUser(user: User) {
        this.user = user;
        localStorage.setItem('register', 'false');
        localStorage.setItem('user', JSON.stringify(user));
    }

    public static getUser(): User {
        return JSON.parse(localStorage.getItem('user'));
    }


    public static setMapSettings(mapSettings: Object) {
        localStorage.setItem('mapSettings', JSON.stringify(mapSettings));
    }

    public static getMapSettings(): Object {
        return JSON.parse(localStorage.getItem('mapSettings'));
    }

    public static setFilter(filter: Object) {
        localStorage.setItem('filter', JSON.stringify(filter));
    }

    public static getFilter(): Filter {
        return JSON.parse(localStorage.getItem('filter'));
    }

    public static logoutUser() {
        delete this.user;
        localStorage.clear();
        localStorage.setItem('register', 'false');
    }

    public static getUserToken() {
        return localStorage.getItem('access_token');
    }

    public static setLastActivity(date) {
        localStorage.setItem('lastActivity', date);
    }

    public static getLastActivity() {
        return localStorage.getItem('lastActivity');
    }

    public static saveSettings(settings: object) {
        localStorage.setItem('settings', JSON.stringify(settings));
    }

    public static getSettings() {
        const settings = JSON.parse(localStorage.getItem('settings'));
        return settings ? settings : {};
    }

    public static getQrRedirectCount() {
        const qrRedirectCountKey = 'qr-redirect-count';
        let qrRedirectCount = +localStorage.getItem(qrRedirectCountKey);
        qrRedirectCount = qrRedirectCount ? qrRedirectCount + 1 : 0;
        localStorage.setItem(qrRedirectCountKey, '' + qrRedirectCount);
        return qrRedirectCount;
    }

    public static setTown(town: Town) {
        if (town) {
            localStorage.setItem('town', JSON.stringify(town));
            Storage.townChange.emit();
        }

    }

    public static getTown(): Town {
        const town = localStorage.getItem('town');
        return town ? JSON.parse(town) : null;
    }

    public static setSearchProjectsIds(ids: number[]) {
        localStorage.setItem('searchProjectsIds', JSON.stringify(ids));
    }

    public static getSearchProjectsIds(): number[] {
        return JSON.parse(localStorage.getItem('searchProjectsIds'));
    }
}
