import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {PinchZoomComponent} from 'ngx-pinch-zoom';
import {Workarea} from '../workarea.class';

@Component({
    selector: 'image-viewer',
    templateUrl: './image-viewer.component.html',
    styleUrls: ['./image-viewer.component.scss']
})
export class ImageViewerComponent implements OnInit, OnChanges {

    @ViewChild('pinchZoom') pinchZoom: PinchZoomComponent;

    @Input()
    hideTime = false;

    @Input()
    public images: Array<any>;

    @Input() workarea: Workarea;

    @Input()
    public objectKey: string;

    @Input()
    public activeImage = -1;

    public showImage = -1;

    @Input()
    public somenumber = -1;

    constructor() {
    }

    ngOnInit() {
        console.log(this.objectKey);
    }

    ngOnChanges(changes: SimpleChanges): void {
        console.log(changes, this.activeImage);
        if (changes['somenumber']) {
            this.showImage = this.activeImage;
        }
    }

    imageClick(event) {
        event.stopPropagation();
    }

    next(event) {
        if (this.pinchZoom.isZoomedIn) {
            this.pinchZoom.toggleZoom();
        }
        event.stopPropagation();
        if (this.showImage + 1 <= this.images.length - 1) {
            this.showImage++;
        } else {
            this.showImage = 0;
        }
    }

    prev(event) {
        if (this.pinchZoom.isZoomedIn) {
            this.pinchZoom.toggleZoom();
        }
        event.stopPropagation();
        if (this.showImage - 1 >= 0) {
            this.showImage--;
        } else {
            this.showImage = this.images.length - 1;
        }
    }

}
