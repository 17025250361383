import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {TownProjectPhase} from '../../town-project-phase';
import {TownProject} from '../../town-project';
import {tap} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class TownProjectPhaseService {

    constructor(private apiService: ApiService) {
    }

    setActive(project_id, townprojectphase_id) {
        return this.apiService.postCall$<TownProject>(`townprojects/phases/active`, {
            project_id, townprojectphase_id
        });
    }

    setDone(townprojectphase_id, done?: boolean) {
        return this.apiService.postCall$<TownProject>(`townprojects/phases/done`, {
            townprojectphase_id,
            done
        });
    }

    savePhase(phase: TownProjectPhase) {
        return this.apiService.postCall$<TownProjectPhase>(`townprojects/phases`, phase);
    }

    deletePhase(id: number) {
        return this.apiService.deleteCall$<TownProjectPhase>(`townprojects/phases/${id}`);
    }

    updatePhaseOrder(phases: TownProjectPhase[], projectId?: number) {
        return this.apiService.postCall$<TownProjectPhase[]>('townprojects/phases/order', {
            phases: phases.map(
                (phase, index) => {
                    return {id: phase.id, order: index};
                }
            ),
            projectId
        });
    }
}
