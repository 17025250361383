import {Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {BreakpointObserver} from '@angular/cdk/layout';
import {Chat} from '../chat';
import {ChatService} from '../chat.service';
import {Routenames} from '../../route-names.enum';
import {Utils} from '../../utils.class';
import {Storage} from '../../storage.class';
import {NewChatDialogComponent} from '../new-chat-dialog/new-chat-dialog.component';
import {Status} from '../../status.class';
import {WorkareaService} from '../../services/workarea/workarea.service';
import {SearchService} from '../../services/search/search.service';
import {TownProjectService} from '../../services/townproject/town-project.service';
import {TownProject} from '../../town-project';

declare var cordova;

@Component({
    selector: 'app-chat-overview',
    templateUrl: './chat-overview.component.html',
    styleUrls: ['./chat-overview.component.scss']
})
export class ChatOverviewComponent implements OnInit, OnDestroy {

    @ViewChild('projectFilterSearchTemplate', {static: true}) projectFilterSearchTemplate;

    @Output()
    openChat = new EventEmitter<Chat>();

    @Input()
    currentChat: Chat;

    subscriptions = new Subscription();
    chats: Chat[] = [];
    isConsumer = Utils.userIsGroup('CONSUMER');
    Routenames = Routenames;
    isCordova = typeof cordova !== 'undefined';
    LocalStorage = Storage;
    Utils = Utils;
    statuses: Status[];
    statusIds: number[];
    search: string;
    searchProjects: number[];
    projects: TownProject[];

    @ViewChild('searchInput')
    private searchInput: ElementRef;

    constructor(private chatService: ChatService,
                private breakpointObserver: BreakpointObserver,
                private searchService: SearchService,
                private router: Router,
                private route: ActivatedRoute,
                private workareaService: WorkareaService,
                private projectService: TownProjectService,
                private dialog: MatDialog) {
    }

    ngOnInit() {
        this.projectService.getProjects(true, null, null, null, true).subscribe(data => {
            this.projects = data.data;
            this.searchService.searchTemplate = this.projectFilterSearchTemplate;
        });

        if (Storage.getUser().group === 'ADMIN' || Storage.getUser().group === 'USER') {
            this.workareaService.getAvailableStatuses('chat').then(statuses => {
                this.statuses = statuses;
                this.statusIds = this.statuses.filter(s => s.isdefault === 1).map(s => s.id);
                this.getChats();
            });
        } else {
            this.getChats();
        }
        this.searchProjects = this.LocalStorage.getSearchProjectsIds();
        if (this.searchProjects) {
            this.changeSearch();
        }
        document.addEventListener('pause', this.chatComponentPause);
        document.addEventListener('resume', this.chatComponentResume);
    }


    private chatComponentPause = () => {
        setTimeout(() => {
            this.subscriptions?.unsubscribe();
            this.subscriptions = new Subscription();
        });
    }
    private chatComponentResume = () => {
        setTimeout(() => {
            this.getChats();
        });
    }

    openNextChat() {
        const openStatus = this.statuses.find(s => s.isdefault === 1);
        this.openChat.emit(this.chats.find(c => c.status_id === openStatus.id));
    }

    changeLoadedStatuses() {
        this.chatService.changeStatuses(this.statusIds);
    }

    changeSearch() {
        this.chatService.searchId(
            this.search ? this.search.trim().replace('#', '') : null,
            this.searchProjects
        );
        this.LocalStorage.setSearchProjectsIds(this.searchProjects);
    }

    @HostListener('window:keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        if (event.getModifierState && event.getModifierState('Control') && event.key === 'f') {
            this.searchInput.nativeElement.focus();
            event.preventDefault();
        }
    }

    newChat() {
        const isMobile = this.breakpointObserver.isMatched('(max-width: 450px)');
        let dialogRef = this.dialog.open(NewChatDialogComponent, {
            maxHeight: '98vh',
            minHeight: isMobile ? '100%' : 'auto',
            height: isMobile ? '100%' : 'auto',
            minWidth: isMobile ? '100%' : '400px',
            width: isMobile ? '100%' : '400px',
            panelClass: 'confirm-dialog'
        });
        const ref = dialogRef.afterClosed().subscribe(chat => {
            this.router.navigate(['/', Routenames.chat, chat.id]);
            ref.unsubscribe();
        });
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
        this.searchService.searchTemplate = null;
    }

    private getChats() {
        this.subscriptions.add(this.chatService.getList(this.statusIds).subscribe(chats => {
            this.chats = chats.sort();
            this.subscriptions.add(this.route.params.subscribe(params => {
                this.openChat.emit(this.chats.find(c => c.id === (+params['id'])));
            }));
        }));
    }

}
