import {Component, ElementRef, HostBinding, Input, NgZone, OnChanges, OnInit} from '@angular/core';
import {Settings} from '../settings.class';
import {DomSanitizer, SafeStyle, SafeUrl} from '@angular/platform-browser';
import {Utils} from '../utils.class';
import {Storage} from '../storage.class';

@Component({
    selector: 'app-codalt-image',
    templateUrl: './codalt-image.component.html',
    styleUrls: ['./codalt-image.component.scss']
})
export class CodaltImageComponent implements OnInit, OnChanges {

    image: SafeStyle;
    safeThumb: SafeUrl;
    imagePreload: SafeUrl;
    imageNoScript: SafeStyle;
    loading = false;
    loaded = false;
    @Input() thumb: string;
    @Input() icon: string;
    @Input() adapt = true;
    @Input() iconBackground: string;
    @Input() offsetPercY = 50;
    @Input() offsetPercX = 50;
    @Input() fullUrl: string;
    Settings = Settings;
    @Input() maintainAspectRatio = false;
    @Input() noOverScale = false;
    @HostBinding('style.aspect-ratio') ratio;
    @HostBinding('style.padding-top.%') padding;
    @HostBinding('style.max-width.px') maxWidth;
    @HostBinding('style.width.%') width;
    @Input() private path: string;
    @Input() private secureType = 'article';

    constructor(private elemRef: ElementRef, private domSanitizer: DomSanitizer, private ngZone: NgZone) {
    }

    ngOnInit() {
        this.setImage();
    }

    setImage() {
        this.loaded = false;
        this.safeThumb = this.thumb ? this.domSanitizer.bypassSecurityTrustUrl(this.thumb) : null;
        this.imagePreload = this.domSanitizer.bypassSecurityTrustUrl(this.getImagePath());
        this.imageNoScript = this.domSanitizer.bypassSecurityTrustStyle('url("' + this.getImagePath() + '")');
    }

    getImagePath() {
        if (this.fullUrl) {
            return this.fullUrl;
        } else if (this.path && this.path.substr(-4) !== 'null') {
            return Settings.API_ENDPOINT +
                'file/thumb?secure=' + this.secureType +
                '&path=/' + this.path +
                '&access_token=' + Storage.getUserToken();
        }
        return null;
    }

    ngOnChanges() {
        this.image = null;
        this.setImage();
    }

    isLoaded() {
        this.image = this.domSanitizer.bypassSecurityTrustStyle('url("' + this.getImagePath() + '")');
        this.loaded = true;
        this.handleImageLoaded();
    }

    private handleImageLoaded() {

        const imageObj = new Image();
        imageObj.src = this.getImagePath();
        if (!imageObj.complete) {
            this.loading = true;
        }
        imageObj.onload = () => {
            if (this.maintainAspectRatio) {
                this.ratio = this.aspectRatio(imageObj.width, imageObj.height);
                if (Utils.isIOS()) {
                    this.padding = Math.floor((100 / +this.ratio.split(' / ')[0]) * +this.ratio.split(' / ')[1]);
                }
                this.width = null;
            } else {
                this.ratio = null;
                this.padding = null;
                this.width = 100;
            }
            if (this.noOverScale) {
                this.maxWidth = imageObj.width;
            }
        };

    }

    private aspectRatio(width, height) {
        const gcd = (a, b) => {
            return b ? gcd(b, a % b) : a;
        };
        const divisor = gcd(width, height);
        return `${width / divisor} / ${height / divisor}`;
    };
}
