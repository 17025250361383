<div class="col-12">
    <h2>Filter</h2>
    <mat-form-field class="w-100">
        <mat-select #type="ngModel"
                    (selectionChange)="inputChanged()"
                    [(ngModel)]="filter.project"
                    name="project"
                    placeholder="Project">
            <mat-option [value]="null" selected>Alle projecten</mat-option>
            <mat-option *ngFor="let project of projects" [value]="project.id">{{project.name}}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field class="w-100">
        <mat-select #type="ngModel"
                    (selectionChange)="inputChanged()"
                    [(ngModel)]="filter.workareaType"
                    name="type"
                    placeholder="Soort werkvak">
            <mat-option selected value="">Werkvakken, locaties en boringen</mat-option>
            <mat-option value="Werkvakken">Werkvakken</mat-option>
            <mat-option value="Werklocaties">Werklocaties</mat-option>
            <mat-option value="Asfaltboringen">Asfaltboringen</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field class="w-100">
        <mat-select #type="ngModel"
                    (selectionChange)="inputChanged()"
                    [(ngModel)]="filter.damage"
                    name="type"
                    placeholder="Type">
            <mat-option [value]="null" selected>Alle types</mat-option>
            <mat-option *ngFor="let type of fieldOptions['damage']"
                        [value]="type">{{type}}</mat-option>
        </mat-select>
    </mat-form-field>
    <div>
        <mat-form-field class="w-100">
            <mat-select #type="ngModel"
                        (selectionChange)="inputChanged()"
                        [(ngModel)]="filter.klinkerfloor"
                        name="type"
                        placeholder="Klinkervak">
                <mat-option [value]="null" selected>Beide</mat-option>
                <mat-option [value]="true">Ja</mat-option>
                <mat-option [value]="false">Nee</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <h6 class="mb-0">Vierkante meters</h6>
    <div class="row">
        <div class="col-6">
            <mat-form-field class="spacer" style="overflow: hidden">
                <input (change)="inputChanged()"
                       [(ngModel)]="filter.min"
                       matInput
                       placeholder="Min (m2)"
                       type="text">
            </mat-form-field>
        </div>
        <div class="col-6">
            <mat-form-field class="spacer" style="overflow: hidden">
                <input (change)="inputChanged()"
                       [(ngModel)]="filter.max"
                       matInput
                       placeholder="Max (m2)"
                       type="text">
            </mat-form-field>
        </div>
    </div>
    <h6 class="mb-1">
        Werkvak aangemaakt
        <small (click)="filter.startDate = filter.endDate = null;inputChanged()" class="underline">Reset</small>
    </h6>
    <div class="row">
        <div class="col-6">
            <mat-form-field (click)="pickerStart.open()" class="w-100">
                <mat-label>Vanaf</mat-label>
                <input (dateChange)="inputChanged()" [(ngModel)]="filter.startDate" [matDatepicker]="pickerStart" matInput>
                <mat-datepicker-toggle [for]="pickerStart" matSuffix></mat-datepicker-toggle>
                <mat-datepicker #pickerStart></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="col-6">
            <mat-form-field (click)="picker.open()" class="w-100">
                <mat-label>Tot</mat-label>
                <input (dateChange)="inputChanged()" [(ngModel)]="filter.endDate" [matDatepicker]="picker" matInput>
                <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </div>
    </div>

    <mat-form-field class="w-100">
        <mat-select (selectionChange)="inputChanged()"
                    [(ngModel)]="filter.toplayer"
                    name="toplayer"
                    placeholder="Toplaag">
            <mat-option [value]="null" selected>Alle materialen</mat-option>
            <mat-option *ngFor="let item of fieldOptions['toplayer']" [value]="item">
                {{item}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field class="w-100">
        <mat-select (selectionChange)="inputChanged()"
                    [(ngModel)]="filter.interlayer"
                    name="interlayer"
                    placeholder="Tussenlaag">
            <mat-option [value]="null" selected>Alle materialen</mat-option>
            <mat-option *ngFor="let item of fieldOptions['interlayer']" [value]="item">
                {{item}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field class="w-100">
        <mat-select (selectionChange)="inputChanged()"
                    [(ngModel)]="filter.bottomlayer"
                    name="bottomlayer"
                    placeholder="Onderlaag">
            <mat-option [value]="null" selected>Alle materialen</mat-option>
            <mat-option *ngFor="let item of fieldOptions['bottomlayer']" [value]="item">
                {{item}}
            </mat-option>
        </mat-select>
    </mat-form-field>


    <mat-form-field class="w-100">
        <mat-select (selectionChange)="inputChanged()"
                    [(ngModel)]="filter.waymark"
                    name="waymark"
                    placeholder="Wegmarkering">
            <mat-option [value]="null" selected>Niet filteren op markeringen</mat-option>
            <mat-option value="all">Met markering</mat-option>
            <mat-option *ngFor="let item of fieldOptions['waymark']" [value]="item">
                {{item}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field class="w-100">
        <mat-select (selectionChange)="inputChanged()"
                    [(ngModel)]="filter.genpriority"
                    name="genpriority"
                    placeholder="Prioriteit">
            <mat-option [value]="null" selected>Alle prioriteiten</mat-option>
            <mat-option *ngFor="let item of fieldOptions['genpriority']" [value]="item">
                {{item}}
            </mat-option>
        </mat-select>
    </mat-form-field>


    <mat-form-field class="w-100">
        <mat-select (selectionChange)="inputChanged()"
                    [(ngModel)]="filter.execution"
                    name="execution"
                    placeholder="Uitvoering">
            <mat-option [value]="null" selected>Alle uitvoeringen</mat-option>
            <mat-option *ngFor="let item of fieldOptions['execution']" [value]="item">
                {{item}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field class="w-100">
        <mat-select (selectionChange)="inputChanged()"
                    [(ngModel)]="filter.wearlayer"
                    name="wearlayer"
                    placeholder="Slijtlaag">
            <mat-option [value]="null" selected>Alle slijtlagen</mat-option>
            <mat-option *ngFor="let item of fieldOptions['wearlayer']" [value]="item">
                {{item}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <button (click)="resetFilter()" class="w-50" color="primary" mat-button>Filter resetten</button>
    <button (click)="applyFilterButton()" class="w-50" color="primary" mat-raised-button>Filter toepassen</button>
</div>
<br><br>
