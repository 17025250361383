<div class="do-center">
    <div class="container py-3 text-center">
        <h1>Welkom bij Dirk</h1>
        <div *ngIf="!Utils.isIOS() && !Utils.isAndroid()">
            <h4>
                Scan deze QR-code met de camera van je telefoon of tablet om de app te installeren
            </h4>
            <qrcode [qrdata]="ownUrl" [width]="256" errorCorrectionLevel="M"></qrcode>
        </div>
        <div class="my-2 row" [class.flex-row-reverse]="Utils.isIOS()">
            <div class="col py-3" [class.light]="Utils.isIOS()">
                <a href="{{playStoreUrl}}" target="_BLANK">
                    <svg-icon src="/assets/svg/google-play-badge.svg"></svg-icon>
                </a>
            </div>

            <div class="col py-3" [class.light]="Utils.isAndroid()">
                <a href="{{appStoreUrl}}" target="_BLANK">
                    <svg-icon src="/assets/svg/app-store.svg"></svg-icon>
                </a>
            </div>
        </div>
    </div>
</div>