import {Component, OnDestroy, OnInit} from '@angular/core';
import {CordovaService} from '../cordova.service';
import {Router} from '@angular/router';
import {Town} from '../town.class';
import {Utils} from '../utils.class';
import {TownService} from '../services/town/town.service';
import {Routenames} from '../route-names.enum';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
    public town: Town;

    public Utils = Utils;

    public showSwitchTown = false;

    constructor(private townService: TownService,
                private router: Router) {
    }

    ngOnInit() {
        if (Utils.userIsGroup('CONSUMER')) {
            this.router.navigate([Routenames.timeline]);
        } else {
            this.router.navigate([Routenames.towns]);
        }
    }

    ngOnDestroy() {
        CordovaService.setBackbuttonActionUrl('/');
    }

}
