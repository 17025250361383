import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'isBase64'
})
export class IsBase64Pipe implements PipeTransform {

    transform<T>(value: string): boolean {

        return value && value.substr(0, 11) === 'data:image/';
    }

}
