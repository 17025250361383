import {Utils} from './utils.class';
import {Storage} from './storage.class';
import {Settings} from './settings.class';
import {Component, OnDestroy} from '@angular/core';
import {Routenames} from './route-names.enum';
import {Subscription} from 'rxjs';

declare const cordova;

@Component({template: ''})
export class CodaltComponent implements OnDestroy {

    Utils = Utils;
    LocalStorage = Storage;
    Settings = Settings;
    isCordova = typeof cordova !== 'undefined';
    Routenames = Routenames;

    subscriptions = new Subscription();

    constructor() {

    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

}
