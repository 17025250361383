import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Storage} from '../../storage.class';

import {Settings} from '../../settings.class';
import {Observable} from 'rxjs';

import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';
import {DocumentsService} from '../../services/documents/documents.service';
import {Document} from '../../document';
import {Workarea} from '../../workarea.class';

@Component({
    selector: 'app-add-chapter',
    templateUrl: './add-document.component.html',
    styleUrls: ['./add-document.component.scss']
})
export class AddDocumentComponent implements OnInit {

    public uploading;
    public dragOver = false;
    public saving = false;
    public file: string;
    public filesize: string;
    public ext: string;
    public isFolder = false;
    public noFilesAllowed = false;
    public parentChapter: Document;
    public workarea: Workarea;

    public form = new FormGroup({
        name: new FormControl('', [Validators.required, Validators.maxLength(255)])
    });

    constructor(private documentService: DocumentsService,
                private confirmDialogService: ConfirmDialogService,
                public dialogRef: MatDialogRef<AddDocumentComponent>,
                @Inject(MAT_DIALOG_DATA) {parentChapter, workarea, files, isFolder}) {
        this.parentChapter = parentChapter;
        this.workarea = workarea;
        if (files) {
            this.uploadFiles(files);
        }

        this.isFolder = isFolder;

    }

    @HostListener('dragover', ['$event'])
    public ondragover(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.dragOver = true;
    }

    @HostListener('dragleave', ['$event'])
    public ondragleave(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        if (evt.srcElement.classList.contains('dialog-container') || evt.srcElement.classList.contains('overlay')) {
            this.dragOver = false;
        }
    }

    @HostListener('drop', ['$event'])
    public ondrop(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.dragOver = false;
        const files = evt.dataTransfer.files;
        if (this.noFilesAllowed) {
            this.confirmDialogService.confirm(
                'Geen hoofdstuk',
                `Je kunt alleen bestanden uploaden onder een hoofdstuk`,
                'Oké', null);
        } else {
            this.uploadFiles(files);
        }
    }

    ngOnInit() {

    }

    save() {
        this.saving = true;
        const newChapter = new Document();
        if (!this.workarea.documents) {
            this.workarea.documents = [];
        }
        let order = Math.max(...this.workarea.documents.map(p => p.order)) + 1;
        if (this.parentChapter) {
            order = Math.max(...this.parentChapter.sub_items.map(p => p.order)) + 1;
        }
        newChapter.order = order;
        newChapter.name = this.form.value['name'];
        newChapter.parent = this.parentChapter ? this.parentChapter.id : null;
        newChapter.file = this.file;
        newChapter.workarea_id = this.workarea.id;
        newChapter.orginal_file = this.file;
        newChapter.ext = this.ext;
        newChapter.filesize = this.filesize;
        if (!this.isFolder && !this.file) {
            this.confirmDialogService.confirm(
                'Geen bestand',
                `Je moet een bestand toevoegen`,
                'Oké', null);
            this.saving = false;
        } else {
            this.documentService.save(newChapter).then(chapter => {
                this.saving = false;
                chapter.sub_items = [];
                this.dialogRef.close(chapter);
            }, error => {
                this.confirmDialogService.confirm(
                    'Error bij opslaan',
                    `Er is iets fout gegaan bij het opslaan`,
                    'Oké', null);
            });
        }
    }

    public uploadFiles(files: File[]) {
        console.log(files);
        const url = `${Settings.API_ENDPOINT}documents/upload${this.parentChapter ? '/' + this.parentChapter.id : ''}`;
        const uploadedChapters = [];
        if (files.length > 0) {
            for (let i = 0; i < files.length; i++) {
                this.uploading = true;
                if (files[i].size < 110664300) { // ~100MB
                    this.makeFileRequest(url, files[i]).subscribe(data => {
                        if (data['success'] && files.length === 1) {
                            this.uploading = false;
                            this.file = data['file'];
                            this.ext = data['ext'];
                            this.filesize = data['filesize'];

                            if (!this.form.value['name']) {
                                this.form.controls['name'].setValue(this.file);
                            }
                        } else if (data['success'] && files.length > 1) {
                            const newChapter = new Document();
                            newChapter.name = data['file'];
                            newChapter.parent = this.parentChapter ? this.parentChapter.id : null;
                            newChapter.file = data['file'];
                            newChapter.orginal_file = data['file'];
                            newChapter.ext = data['ext'];
                            newChapter.filesize = data['filesize'];
                            newChapter.workarea_id = this.workarea.id;
                            this.documentService.save(newChapter).then(chapter => {
                                chapter.sub_items = [];
                                uploadedChapters.push(chapter);
                                if (uploadedChapters.length === files.length) {
                                    this.dialogRef.close(uploadedChapters);
                                }
                            }, error => {
                                this.uploadErrorMessage(`Bestand: ${files[i].name}<br>${error['errormessage']}`);
                            });
                        } else {
                            this.uploading = false;
                            this.uploadErrorMessage(data['error']['upload'][0]);
                        }
                    }, error => {
                        this.uploading = false;
                        this.uploadErrorMessage(`Bestand: ${files[i].name}<br>${error['errormessage'] ? error['errormessage'] : 'Je kunt dit niet uploaden!'}`);
                    });
                } else {
                    if (files.length === 1) {
                        this.uploading = false;
                    }
                    this.confirmDialogService.confirm(
                        'Bestand te groot',
                        `${files[i].name} is te groot, de maximale grootte is 100MB.`,
                        'Oké', null);
                }
            }
        }
    }

    public uploadFile(event) {
        event.stopPropagation();
        event.preventDefault();
        const files = event.srcElement.files;
        this.uploadFiles(files);
    }

    private uploadErrorMessage(message) {
        this.confirmDialogService.confirm(
            'Er is iets foutgegaan bij het uploaden',
            `${message}`,
            'Oké', null);
    }

    private makeFileRequest(url: string, file: File): Observable<number> {
        return Observable.create(observer => {
            const formData: FormData = new FormData(),
                xhr: XMLHttpRequest = new XMLHttpRequest();

            formData.append('upload', file, file.name);

            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        observer.next(JSON.parse(xhr.response));
                        observer.complete();
                    } else {

                        observer.error(xhr.response);
                    }
                }
            };

            xhr.open('POST', url, true);
            xhr.setRequestHeader('Accept', 'application/json');
            xhr.setRequestHeader('Authorization', 'Bearer ' + Storage.getUserToken());
            xhr.send(formData);
        });
    }

}
