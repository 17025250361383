import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {TownProjectPhaseReply} from '../../town-project-phase-reply';
import {FormControl} from '@angular/forms';
import {Town} from '../../town.class';
import {Storage} from '../../storage.class';
import {FileItem} from '../../document-upload/file-item';
import {TownProjectPhaseReplyService} from '../../services/townproject/town-project-phase-reply.service';
import {TownProjectPhase} from '../../town-project-phase';
import {FileService} from '../../services/file.service';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';
import {formatDate} from '@angular/common';
import {User} from '../../user.class';
import {StorageName} from '../../document-upload/storage-name.enum';
import {PdfViewerDialogComponent} from '../../documents/pdf-viewer-dialog/pdf-viewer-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {Settings} from '../../settings.class';
import {ImageViewerDialogComponent} from '../../documents/image-viewer-dialog/image-viewer-dialog.component';
import {TownProjectPhaseActivity} from '../../town-project-phase-activity';
import {BreakpointObserver} from '@angular/cdk/layout';
import {Utils} from '../../utils.class';

declare var cordova;

@Component({
    selector: 'app-town-project-phase-replies',
    templateUrl: './town-project-phase-replies.component.html',
    styleUrls: ['./town-project-phase-replies.component.scss']
})
export class TownProjectPhaseRepliesComponent implements OnInit {

    @Input()
    highlightId: number;

    @Input()
    selectedActivity: TownProjectPhaseActivity;

    @Input()
    phase: TownProjectPhase;
    @Input()
    replies: TownProjectPhaseReply[];
    inputActive = false;
    fcReplyContent = new FormControl();
    town: Town;
    user: User;
    disableDragDrop = typeof cordova !== 'undefined';

    @ViewChild('replyInput', {static: true})
    private replyInput;

    constructor(private replyService: TownProjectPhaseReplyService,
                private fileService: FileService,
                private dialog: MatDialog,
                private breakpointObserver: BreakpointObserver,
                private confirmDialog: ConfirmDialogService) {
    }

    ngOnInit(): void {
        this.town = Storage.getTown();
        this.user = Storage.getUser();
    }

    upload(file: FileItem) {
        const reply = new TownProjectPhaseReply();
        reply.name = file.filename;
        reply.text = file.basename;
        reply.is_document = true;
        reply.townprojectphase_id = this.phase.id;
        reply.townprojectphase_activity_id = this.selectedActivity?.id;
        this.replyService.save(reply).subscribe(data => {
            this.replies.push(data.data);
        });
    }

    enterKey(event: KeyboardEvent) {
        if (event.key === 'Enter' && event.ctrlKey && this.fcReplyContent.valid) {
            this.save();
        }
    }

    moveToActivity(reply) {
        this.replyService.save(reply).subscribe(data => {

        });
    }

    save(event?: any) {
        if (event) {
            event.stopPropagation();
        }
        this.fcReplyContent.disable();
        const reply = new TownProjectPhaseReply();
        reply.name = 'Reactie';
        reply.text = this.fcReplyContent.value;
        reply.townprojectphase_id = this.phase.id;
        reply.townprojectphase_activity_id = this.selectedActivity?.id;
        this.replyService.save(reply).subscribe(data => {
            this.replies.push(data.data);
            this.fcReplyContent.reset();
            this.fcReplyContent.enable();
        });
    }

    delete(reply: TownProjectPhaseReply) {
        this.confirmDialog.confirm(
            'Verwijderen',
            `Weet je zeker dat je jouw reactie van
            ${formatDate(reply.updated_at, 'd MMMM yyyy, H:mm', 'nl')} uur
            wilt verwijderen?`,
            'Verwijderen',
            'Behouden').then(() => {
            this.replyService.delete(reply.id).subscribe(data => {
                const r = this.replies.find(r => r.id === data.data.id);
                if (r.is_document) {
                    this.fileService.delete(`${this.phase.id}/${r.text}`, StorageName.projectreplies).subscribe();
                }
                this.replies.splice(this.replies.indexOf(r), 1);
            });
        }, () => {

        });
    }

    openReply(reply: TownProjectPhaseReply) {
        if (reply.is_document) {
            if (['.jpg', 'jpeg', '.png'].indexOf(reply.text.substr(-4)) !== -1) {
                this.dialog.open(ImageViewerDialogComponent, {
                    panelClass: 'image-viewer-dialog',
                    data: {
                        documentImages: this.replies.filter(f => ['.jpg', 'jpeg', '.png'].indexOf(f.text.substr(-4)) !== -1)
                            .map(d => `${Settings.API_ENDPOINT}file/thumb?path=${d.townprojectphase_id}/${d.text}&current_town_id=${this.town.id}&secure=${StorageName.projectreplies}&access_token=${Storage.getUserToken()}`),
                        viewIndex: this.replies.filter(f => ['.jpg', 'jpeg', '.png'].indexOf(f.text.substr(-4)) !== -1).indexOf(reply)
                    }
                });
            } else if (reply.text.substr(-3) === 'pdf') {
                const url = `${Settings.API_ENDPOINT}file/thumb?path=${reply.townprojectphase_id}/${reply.text}&current_town_id=${this.town.id}&secure=${StorageName.projectreplies}&access_token=${Storage.getUserToken()}`;
                if (typeof cordova !== 'undefined') {
                    window['PreviewAnyFile'].previewPath(
                        win => console.log("open status", win),
                        error => console.error("open failed", error),
                        url, {name: 'file.pdf'}
                    );
                } else {
                    this.dialog.open(PdfViewerDialogComponent, {
                        width: '99%',
                        maxWidth: '99%',
                        maxHeight: '99%',
                        height: '99%',
                        data: {
                            pdf: url,
                            name: reply.name
                        }
                    });
                }
            } else {
                window.open(`${Settings.API_ENDPOINT}file/thumb?path=${reply.townprojectphase_id}/${reply.text}&current_town_id=${this.town.id}&secure=${StorageName.projectreplies}&access_token=${Storage.getUserToken()}`);
            }
        }
    }

    readonly Utils = Utils;
}
