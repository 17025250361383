import {Injectable} from '@angular/core';
import {ApiService} from './api/api.service';
import {Survey} from '../classes/survey.class';
import {SurveyAnswer} from '../classes/surveyanswer.class';

@Injectable()
export class SurveyService {

    constructor(private apiService: ApiService) {
    }

    save(survey: Survey) {
        return this.apiService.postCall$<boolean>('surveys', survey);
    }

    submit(surveyId: number, answers: {}) {
        return this.apiService.postCall$<{ answers: {}, created_at: Date }>('surveys/' + surveyId, answers);
    }

    delete(surveyId: number) {
        return this.apiService.deleteCall$<boolean>('surveys/' + surveyId);
    }

    getanswers(surveyId: number) {
        return this.apiService.getCall$<SurveyAnswer[]>('surveys/answers/' + surveyId);
    }

    deleteAnswers(surveyId: number) {
        return this.apiService.deleteCall$<boolean>('surveys/answers/' + surveyId);
    }
}
