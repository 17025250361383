<div class="modal-header">
    <h4 class="modal-title pull-left">Melding {{chat.id ? 'aanpassen' : 'aanmaken'}}</h4>
    <button aria-label="Close" class="close pull-right" mat-dialog-close="" tabindex="-1" type="button">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div *ngIf="form" [formGroup]="form" class="modal-body">
    <form [formGroup]="form">
        <mat-form-field *ngIf="projects && !chat.id" class="w-100">
            <mat-placeholder>Kies een project</mat-placeholder>
            <mat-select [formControl]="fc.townproject_id">
                <mat-option *ngFor="let project of projects" [value]="project.id">
                    {{project.name}}
                </mat-option>
            </mat-select>
            <mat-error [control]="fc.townproject_id"></mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="chat.id" class="w-100">
            <mat-label>Melding naam</mat-label>
            <input [formControl]="fc.name" matInput placeholder="Naam van de melding" type="text">
            <mat-error [control]="fc.name"></mat-error>
        </mat-form-field>
        <mat-form-field class="w-100">
            <mat-label>Soort melding</mat-label>
            <mat-select [formControl]="fc.type">
                <mat-option *ngFor="let type of types" [value]="type">
                    {{type}}
                </mat-option>
            </mat-select>
            <mat-error [control]="fc.type"></mat-error>
        </mat-form-field>
        <ng-container *ngIf="fc.type?.value && !chat.id">
            <mat-form-field class="w-100 mb-2">
                        <textarea [formControl]="fc.firstMessage"
                                  cdkAutosizeMinRows="4"
                                  cdkTextareaAutosize matInput name="comments"
                                  placeholder="Omschrijf uw {{fc?.type?.value}}"></textarea>
                <mat-error [control]="fc.firstMessage"></mat-error>
            </mat-form-field>
            <div (click)="camera($event)" *ngIf="isCordova" [style.background-image]="fc.image?.value | safeBgUrl"
                 class="image"
                 matRipple>
                <ng-container *ngIf="!fc.image?.value">
                    <i class="fas fa-image fa-6x"></i><br>
                    <p>Voeg aan afbeelding toe aan uw melding</p>
                </ng-container>
            </div>
            <div (click)="uploadInput.click()" *ngIf="!isCordova"
                 [style.background-image]="fc.image?.value | safeBgUrl"
                 class="image"
                 matRipple>
                <input #uploadInput (change)="uploadFile($event)" [multiple]="true" hidden type="file">
                <ng-container *ngIf="!fc.image?.value">
                    <i class="fas fa-image fa-6x"></i>
                    <p>Voeg aan afbeelding toe aan uw melding</p>
                    <p>
                        <mat-error [control]="fc.image" customMessage="Bij een {{fc.type.value}} is een afbeelding verplicht"></mat-error>
                    </p>
                </ng-container>
            </div>
        </ng-container>
    </form>
    <div *ngIf="chat?.id">
        <div *ngFor="let user of chat?.chat_users">
            <div>
                <b>Naam: </b>{{user.user?.name}} {{user.user?.lastname}}
            </div>
            <div>
                <b>Email: </b>{{user.user?.email}}
            </div>
            <div>
                <b>Telefoon: </b>{{user.user?.phone}}
            </div>
            <div>
                <b>Adres: </b><br>
                {{user.user?.street}} {{user.user?.housenumber}}<br>
                {{user.user?.zipcode}} {{user.user?.place}}
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button (click)="startChat()" color="primary" mat-flat-button>
        Melding {{chat.id ? 'aanpassen' : 'aanmaken'}}
    </button>
</div>
