import {Injectable} from '@angular/core';
import {HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {ConfirmDialogService} from './services/confirm-dialog-service/confirm-dialog.service';
import {Storage} from './storage.class';
import packageInfo from '../../package.json';

@Injectable()
export class CodaltInterceptor implements HttpInterceptor {

    public static loadingCount = 0;
    public static appVersion = 0;
    public static appVersionTime = 0;

    constructor(private confirmDialogService: ConfirmDialogService) {
        let multiply = 1;
        let total = 0;
        packageInfo.version.split('.', 3).reverse().forEach((vp) => {
            const part = parseInt(vp) * multiply;
            multiply = multiply * 100;
            total += part;
        });
        CodaltInterceptor.appVersion = total;
        CodaltInterceptor.appVersionTime = packageInfo.versiontime;
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        request = request.clone({
            setHeaders: {
                Authorization: 'Bearer ' + Storage.getUserToken(),
                Accept: 'application/json',
                AppVersion: `${CodaltInterceptor.appVersion}`,
                AppVersionTime: `${CodaltInterceptor.appVersionTime}`
            },
            setParams: {
                current_town_id: Storage.getTown() ? `${Storage.getTown().id}` : ''
            }
        });

        return next.handle(request).pipe(tap((response) => {
            if (response.type === HttpEventType.Sent) {
                setTimeout(() => {
                    CodaltInterceptor.loadingCount++;
                });
            }
            if (response.type === HttpEventType.Response) {
                setTimeout(() => {
                    CodaltInterceptor.loadingCount--;
                });
            }
        }, (error) => {
            if (error.status === 500) {
                let errorMessage = '';
                if (error.error && error.error.message) {
                    errorMessage = error.error.message;
                }
                this.confirmDialogService.confirm('Er is een fout opgetreden', error.message + '<br><br>' + errorMessage, 'Sluiten', null);
            }
            setTimeout(() => {
                CodaltInterceptor.loadingCount--;
            });
        }));
    }
}



