import {Pipe, PipeTransform} from '@angular/core';
import {Utils} from '../utils.class';

@Pipe({
    name: 'shortNumber',
    pure: true
})
export class ShortNumberPipe implements PipeTransform {

    transform(transformNumber: number, withDecimals = false) {
        return Utils.shortNumber(transformNumber, withDecimals);
    }
}
