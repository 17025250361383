<div class="items h-100" [class.empty]="timelineItems?.length === 0">
    <div *ngIf="timelineItems.length === 0 && !loading" class="not-found">
        <div class="text-center">
            Er staat nog niets ingepland voor dit project
        </div>
    </div>
    <virtual-scroller #virtualScroll [items]="timelineItems" class="item-list" *ngIf="timelineItems.length > 0">
        <div *ngFor="let item of virtualScroll.viewPortItems" class="item"
             routerLink="/{{townId ? 'towns/'+townId+'/projects/'+projectId+'/' : ''}}{{Routenames.timeline}}/{{item.slug}}">
            <app-codalt-image [path]="item.id + '/' + item.image"
                              [thumb]="item.image_thumb"
                              [adapt]="item.adapt_image"
                              secureType="timeline"
                              class="detail__image">
            </app-codalt-image>
            <div [class.important]="item.alert" class="detail__block">
                <h3 [class.unread]="!item.read" class="item__title">
                    {{item.publish_date | date:'EEEE d MMMM yyyy'}} {{(item.publish_date | date:'HH:mm') === '00:00' ? '' : (item.publish_date | date:'HH:mm')}}
                </h3>
                <div class="details">
                    <div class="sub-title">
                        {{item.title}}
                    </div>
                    <div>
                        {{item?.town_project?.name}}
                    </div>
                </div>
                <div class="details pt-2">
                    <div>
                        {{item.intro}}
                    </div>
                </div>
            </div>
        </div>
        <div class="item-spacer"></div>
    </virtual-scroller>
    <button *ngIf="Utils.userHasRights('USER')" class="add-floating-button" color="primary" mat-fab
            routerLink="/{{townId ? 'towns/'+townId+'/projects/'+projectId+'/' : ''}}{{Routenames.timeline}}/nieuw/bewerken">
        <mat-icon class="fas fa-plus"></mat-icon>
    </button>
</div>
<div [class.active]="childActive" class="router-outlet">
    <router-outlet (activate)="childActive=true"
                   (deactivate)="childClosed($event); childActive=false">
    </router-outlet>
</div>
<ng-template #projectFilterSearchTemplate>
    <ng-select *ngIf="!this.projectId"
               [(ngModel)]="searchProjects"
               (change)="changeSearch()"
               [closeOnSelect]="false"
               [hideSelected]="true"
               [items]="projects"
               [multiple]="true"
               bindLabel="name"
               bindValue="id"
               name="towns"
               placeholder="Filter Projecten"></ng-select>
</ng-template>
