<div class="items h-100" [class.empty]="articles?.length === 0">
    <div *ngIf="articles.length === 0 && !loading" class="not-found">
        <div class="text-center">
            Er zijn nog geen updates geplaatst
        </div>
    </div>
    <virtual-scroller #virtualScroll (vsEnd)="scroll($event)" [items]="articles" class="item-list" *ngIf="articles?.length > 0">
        <div *ngFor="let article of virtualScroll.viewPortItems" class="item"
             routerLink="/{{Routenames.articles}}/{{article.slug}}">
            <app-codalt-image [path]="article.id + '/' + article.image"
                              [thumb]="article.image_thumb"
                              [adapt]="article.adapt_image"
                              class="detail__image">
            </app-codalt-image>
            <div [class.important]="article.alert" class="detail__block">
                <h3 [class.unread]="!article.read" class="item__title">{{article.title}}</h3>
                <div class="details">
                    <time>
                        {{article.publish_date | date:'EE d MMM yyyy HH:mm'}}
                    </time>
                    <div>
                        {{article?.town_project?.name}}
                    </div>
                </div>
            </div>
        </div>
        <div class="item-spacer"></div>
    </virtual-scroller>
    <button *ngIf="Utils.userHasRights('USER')" class="add-floating-button" color="primary" mat-fab
            routerLink="/{{Routenames.articles}}/nieuw/bewerken">
        <mat-icon class="fas fa-plus"></mat-icon>
    </button>
    <button *ngIf="Utils.userHasRights('USER')" class="delete-button"
            color="primary"
            mat-fab
            matTooltip="{{future ? 'Nieuws uit het verleden' : 'Toekomstig nieuws'}}"
            matTooltipPosition="before"
            routerLink="/{{Routenames.articles}}/{{future ? '' : 'toekomst'}}">
        <mat-icon [class.fa-clock]="!future" [class.fa-history]="future" class="fas"></mat-icon>
    </button>
</div>
<div class="router-outlet" [class.active]="childActive">
    <router-outlet (activate)="childActive=true"
                   (deactivate)="childClosed($event); childActive=false">
    </router-outlet>
</div>

<ng-template #projectFilterSearchTemplate>
    <ng-select
        [(ngModel)]="searchProjects"
        (change)="changeSearch()"
        [closeOnSelect]="false"
        [hideSelected]="true"
        [items]="projects"
        [multiple]="true"
        bindLabel="name"
        bindValue="id"
        name="towns"
        placeholder="Filter Projecten"></ng-select>
</ng-template>
