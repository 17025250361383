import {EventEmitter, Injectable} from '@angular/core';
import {webSocket, WebSocketSubject} from 'rxjs/webSocket';

import {Storage} from '../storage.class';
import {Settings} from '../settings.class';
import {catchError, finalize, map, mergeMap, retryWhen} from 'rxjs/operators';
import {AuthService} from '../services/auth/auth.service';
import {Observable, of, throwError, timer} from 'rxjs';

interface WebsocketMessage<T> {
    type: string;
    data: T;
    success: boolean;
    reinit: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class WebsocketService {

    websocketOpen = new EventEmitter<boolean>();
    private websocket;
    private retryTimeoutCounter = 0;

    constructor(private authService: AuthService) {

    }

    disconnect() {
        this.websocket?.unsubscribe();
        this.websocket = null;
    }

    public getWebsocket<T>(type: string, resetWebsocket = false) {
        if (resetWebsocket) {
            this.websocket = null;
        }
        return this.getFullWebsocket().pipe(map((data: WebsocketMessage<T>) => {
                if (typeof data.data !== 'undefined' && (data.data as any as string) === 'Unauthorized.') {
                    this.authService.logout();
                } else if (data.type === type || !data.success) {
                    return data;
                }
            }), retryWhen(genericRetryStrategy({
                scalingDuration: 500,
                excludedStatusCodes: [500],
                maxRetryAttempts: 20
            })),
            catchError(error => of(error)));

    }

    public doRequest(type: string, data?: any, method?: string) {
        if (data && !method) {
            method = 'post';
        }
        this.getFullWebsocket().next({type, data, method, access_token: Storage.getUserToken()});
    }

    private getRetryTime() {
        console.log(this.retryTimeoutCounter);
        if (this.retryTimeoutCounter > 10) {
            return 10000;
        }
        if (this.retryTimeoutCounter > 4) {
            return 3000;
        }
        return 1000;
    }

    private getFullWebsocket(): WebSocketSubject<object> {
        if (!this.websocket) {
            this.websocket = webSocket({
                url: `${Settings.WS_ENDPOINT}?access_token=${Storage.getUserToken()}`,
                openObserver: {
                    next: () => {
                        this.websocketOpen.emit(true);
                    }
                },
                closeObserver: {
                    next: () => {
                        this.websocketOpen.emit(false);
                    }
                }
            });
        }
        return this.websocket;
    }
}

export const genericRetryStrategy = ({
                                         maxRetryAttempts = 3,
                                         scalingDuration = 1000,
                                         excludedStatusCodes = []
                                     }: {
    maxRetryAttempts?: number,
    scalingDuration?: number,
    excludedStatusCodes?: number[]
} = {}) => (attempts: Observable<any>) => {
    return attempts.pipe(
        mergeMap((error, i) => {
            const retryAttempt = i + 1;
            // if maximum number of retries have been met
            // or response is a status code we don't wish to retry, throw error
            if (
                retryAttempt > maxRetryAttempts ||
                excludedStatusCodes.find(e => e === error.status)
            ) {
                console.error('Websocket maximum reconnect tries reached');
                return throwError(error);
            }
            console.warn(
                `Websocket reconnect attempt ${retryAttempt}: retrying in ${retryAttempt *
                scalingDuration}ms`
            );
            return timer(retryAttempt * scalingDuration);
        }),
        finalize(() => console.log('End Retry Websocket'))
    );
};
