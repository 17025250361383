import {EventEmitter, Injectable, Output, TemplateRef} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SearchService {

    searchTemplate: TemplateRef<any>;

    @Output() change: EventEmitter<string> = new EventEmitter();

    constructor() {
    }

    update(val: string) {
        this.change.emit(val);
    }
}
