import {EventEmitter, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Storage} from '../storage.class';
import {map} from 'rxjs/operators';
import {Chat} from './chat';
import {ApiService} from '../services/api/api.service';
import {User} from '../user.class';
import {ChatUser} from './chat-user';
import {ChatMessage} from './chat-message';
import {WebsocketService} from './websocket.service';

@Injectable({
    providedIn: 'root'
})
export class ChatService {

    private items: Chat[] = [];
    private isRequested = false;
    private itemChanged = new EventEmitter<number[]>();
    private retryTimeoutPointer;
    private retryTimeoutCounter = 0;
    private retryTimeoutMaxCounter = 20;

    private init = false;
    private statusIds: number[];
    private projectIds: number[];
    private search: string;

    constructor(private websocket: WebsocketService, private apiService: ApiService) {
        if (!!Storage.getUserToken()) {
            this.websocketInit();
        }
        this.websocket.websocketOpen.subscribe(isOpen => {
            if (isOpen) {
                this.websocket.doRequest('chats/overview');
            } else {
                this.retryWebsocketInit();
            }
        });
    }

    public searchId(searchId?: string, projectIds?: number[]) {
        this.search = searchId;
        this.projectIds = projectIds;
        this.websocket.doRequest('chats', {
            searchId,
            projectIds
        }, 'get');
    }

    public changeStatuses(statusIds) {
        this.statusIds = statusIds;
        this.websocket.doRequest('chats', {
            statusIds: this.statusIds
        }, 'get');
    }

    public getList(statusIds): Observable<Chat[]> {
        this.statusIds = statusIds;
        if (!this.init) {
            this.websocketInit();
        }
        return new Observable<Chat[]>((observer) => {
            const itemChangedSubscription = this.itemChanged.subscribe(() => {

                if (this.search || this.statusIds || this.projectIds) {
                    let items = this.items;
                    if(this.search?.length) {
                        items = items.filter(i => i.id === (+this.search));
                    }
                    if(this.statusIds?.length) {
                        items = items.filter(i => this.statusIds.indexOf(i.status_id) !== -1);
                    }
                    if(this.projectIds?.length) {
                        items = items.filter(i => this.projectIds.indexOf(i.townproject_id) !== -1);
                    }
                    observer.next(items);
                } else {
                    observer.next(this.items);
                }
            });

            if (this.isRequested) {
                if (this.items?.length) {
                    if (this.statusIds) {
                        observer.next(this.items.filter(i => this.statusIds.indexOf(i.status_id) !== -1));
                    } else {
                        observer.next(this.items);
                    }

                } else {
                    this.websocket.doRequest('chats', {
                        statusIds: this.statusIds
                    }, 'get');
                }
            } else {
                this.websocket.doRequest('chats', {
                    statusIds: this.statusIds
                }, 'get');
                this.isRequested = true;
            }

            return {
                unsubscribe(): void {
                    itemChangedSubscription.unsubscribe();
                }
            };
        });
    }

    public getChat(chatId): Observable<Chat> {
        return new Observable<Chat>((observer) => {
            this.getList(this.statusIds).subscribe((chats: Chat[]) => {
                observer.next(chats.find(i => i.id === chatId));
            });
        });
    }

    public saveChat(chat: Chat) {
        return this.apiService.postCall$<Chat>('chats', chat);
    }

    public leaveChat(chat: Chat) {
        const chatUser = chat.chat_users.find(u => u.user_id === Storage.user.id);
        return this.apiService.deleteCall$('chats', {id: chatUser.id}).pipe(map(() => {
            this.items.splice(this.items.indexOf(chat), 1);
            this.itemChanged.emit(this.items.map(i => +i.id));
        }));
    }

    public lastRead(messageId, chatId) {
        return this.apiService.postCall$('chats/message/read', {messageId, chatId}).pipe(map(() => {
            this.itemChanged.emit();
        }));
    }

    public muteChat(chat: Chat) {
        const chatUser = chat.chat_users.find(u => u.user_id === Storage.user.id);
        return this.apiService.getCall$('chats/mute', {id: chatUser.id});
    }

    public makeUserAdmin(user: ChatUser) {
        return this.apiService.postCall$<ChatUser>('chats/admin', user);
    }

    public getUsers() {
        return this.apiService.getCall$<User[]>('chats/users');
    }

    public newMessage(message: ChatMessage) {
        this.websocket.doRequest('chatMessage', message);
    }

    private retryWebsocketInit() {
        this.init = false;
        if (this.retryTimeoutPointer) {
            clearTimeout(this.retryTimeoutPointer);
        }
        this.retryTimeoutPointer = setTimeout(() => {
            this.websocketInit();
        }, 1000);
    }

    private websocketInit(resetWebsocket = false) {
        this.init = true;
        this.websocket.getWebsocket<Chat[]>('chats', resetWebsocket).subscribe((items) => {
            if (items?.data?.length) {
                this.websocketHandling(items.data);
                this.retryTimeoutCounter = 0;
            } else if (!!items?.reinit) {
                if (this.retryTimeoutCounter < this.retryTimeoutMaxCounter) {
                    this.retryTimeoutPointer = setTimeout(() => {
                        this.websocketInit(true);
                    }, this.getRetryTime());
                }
            }
        }, () => {
            if (this.retryTimeoutPointer) {
                clearTimeout(this.retryTimeoutPointer);
            }
            if (this.retryTimeoutCounter < this.retryTimeoutMaxCounter) {
                this.retryTimeoutPointer = setTimeout(() => {
                    this.websocketInit();
                }, this.getRetryTime());
            }
        }, () => {

        });
    }

    private getRetryTime() {
        this.retryTimeoutCounter++;
        if (this.retryTimeoutCounter > 10) {
            return 30000;
        }
        if (this.retryTimeoutCounter > 4) {
            return 1500;
        }
        return 500;
    }

    private websocketHandling(items: Chat[]) {
        items.forEach(item => {
            const currentItem = this.items.find(p => p.id === +item.id);
            if (item['deleted']) {
                if (currentItem) {
                    this.items.splice(this.items.indexOf(currentItem), 1);
                }
            } else if (currentItem) {
                Object.assign(currentItem, item);
            } else {
                this.items.push(item);
            }
        });
        this.items.sort((a, b) => {
            if ((a?.lastMessage?.updated_at || a.updated_at) > (b?.lastMessage?.updated_at || b.updated_at)) {
                return -1;
            }
            if ((a?.lastMessage?.updated_at || a.updated_at) < (b?.lastMessage?.updated_at || b.updated_at)) {
                return 1;
            }
            return 0;
        }).sort((b, a) => {
            if (a?.status?.id > b?.status?.id) {
                return -1;
            }
            if (a?.status?.id < b?.status?.id) {
                return 1;
            }
            return 0;
        });
        this.itemChanged.emit(items.map(i => +i.id));
    }
}
