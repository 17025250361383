import {User} from '../user.class';

export class ChatMessage {
    id?: number;
    message: string;
    user_id?: number;
    chat_id: number;
    user?: User;
    updated_at?: Date;
    deleted_at?: Date;
    lng?: number;
    lat?: number;
}
