import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {Town} from '../town.class';
import {Workarea} from '../workarea.class';
import {Filter} from '../filter';
import {WorkareaService} from '../services/workarea/workarea.service';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-town-table',
    templateUrl: './town-table.component.html',
    styleUrls: ['./town-table.component.scss']
})
export class TownTableComponent implements OnInit, OnDestroy {

    childActive = false;
    town: Town;
    filter = new Filter();
    workareas: Workarea[];
    filteredWorkareas: Workarea[];

    type: string;

    private subscriptions = new Subscription();

    constructor(private workareaService: WorkareaService,
                private activatedRoute: ActivatedRoute) {
    }

    ngOnInit() {
        this.reload();
        document.body.classList.add('pt-0');
    }

    public reload(reload = false) {
        Object.assign(this.filter, this.activatedRoute.snapshot.queryParams);

        this.workareaService.getAll(reload).then(workareas => {
            this.workareas = workareas;
            this.filterWorkareas(this.filter);
        });
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    private filterWorkareas(filter: any) {
        if (this.workareas) {
            this.filteredWorkareas = filter.apply(this.workareas);
            if (this.filteredWorkareas[0]) {
                this.type = this.filteredWorkareas[0].type;
            }
            setTimeout(() => {
                document.body.appendChild(document.createElement("readyforpuppeteer"));
            });
        }

    }
}
