import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {AddressArea} from '../../classes/addressarea.class';

@Injectable()
export class AddressService {

    constructor(private apiService: ApiService) {
    }

    saveAddressArea(area: AddressArea) {
        return this.apiService.postCall$<AddressArea>(`address-area`, area);
    }

    getAddressAreas() {
        return this.apiService.getCall$<AddressArea[]>(`address-area`);
    }

    deleteAddressArea(id: number) {
        return this.apiService.deleteCall$(`address-area/${id}`);
    }

    getBuildings(points: any[]) {
        return this.apiService.postCall$<{
            identificatie: string;
            status: string;
            gebruiksdoelen: string[];
            locatie: {
                lat: number;
                lng: number;
            }
        }[]
        >(`address-area/buildings`, {points});
    }

    getAddresses(numbers: any[]) {
        return this.apiService.postCall$<{ id: string; line_one: string; line_two: string; }[]
        >(`address-area/addresses`, {numbers});
    }
}
