import {Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Workarea} from '../../workarea.class';
import {Utils} from '../../utils.class';
import {Status} from '../../status.class';

@Component({
    selector: 'app-workarea-vogelzang',
    templateUrl: './workarea-vogelzang.component.html',
    styleUrls: ['./workarea-vogelzang.component.scss']
})
export class WorkareaVogelzangComponent implements OnInit {

    @ViewChild('workareaForm', {static: true}) workareaForm: FormGroup;

    @HostListener('change', ['$event'])
    inputChanged(event) {
        this.formValid.emit(this.workareaForm);
    }

    @Output()
    public formValid = new EventEmitter<FormGroup>();

    @Input()
    public workarea: Workarea;

    @Input()
    statuses: Status[];

    @Input()
    fieldOptions: object;

    public Utils = Utils;

    priorities = ['Hoog - opgelost binnen een dag', 'Midden - opgelost binnen een week', 'Laag - zelf in te plannen'];

    vogzang_services = ['Ophalen vuilcontainer', 'Tillen boodschappen', 'gebruik E-car'];
    vogzang_types = ['Service', 'Compliment', 'Klacht', 'Zorg'];

    constructor() {
    }

    ngOnInit() {
        this.formValid.emit(this.workareaForm);
        if (!this.workarea.status_id) {
            this.workarea.status_id = 1;
            this.workarea.status = this.statuses[0];
        }
    }

    public getMinTime(): Date {
        let min = new Date(this.workarea.extra.servicedate);
        min.setHours(8);
        min.setMinutes(0);
        return min;
    }

    public getMaxTime(): Date {
        let max = new Date(this.workarea.extra.servicedate);
        max.setHours(15);
        max.setMinutes(30);
        return max;
    }

    variableIsPresent(variable: any) {
        if (typeof variable === 'undefined' || variable == null) {
            return false;
        }
        return variable.length > 0;
    }

    statusChange() {
        this.statuses.forEach(status => {
            if (status.id == this.workarea.status_id) {
                this.workarea.status = status;
            }
        });
    }

}
