import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    NgZone,
    OnChanges,
    Output,
    SimpleChanges
} from '@angular/core';

@Component({
    selector: 'app-pull-to-refresh',
    templateUrl: './pull-to-refresh.component.html',
    styleUrls: ['./pull-to-refresh.component.scss']
})
export class PullToRefreshComponent implements AfterViewInit, OnChanges {

    private element: any;
    public startAfterRelease = false;
    forcescrolling = false;

    @Input('refreshInProgressText') refreshInProgressText = 'Refreshing..';
    @Input('refresh') inProgress = false;
    @Output() onPull = new EventEmitter<any>();

    constructor(el: ElementRef, private zone: NgZone) {
        this.element = el.nativeElement;
    }

    ngAfterViewInit() {
        this.element.scrollTop = 75;
    }

    forceResetScrolling() {

        this.zone.run(() => {
            setTimeout(() => {
                this.element.scrollTop = 75;
                this.forcescrolling = true;

            }, 50);
            setTimeout(() => {
                this.element.scrollTop = 75;
                this.forcescrolling = false;
            }, 200);

            setTimeout(() => {
                this.element.scrollTop = 75;
                this.forcescrolling = true;
            }, 500);
            setTimeout(() => {
                this.element.scrollTop = 75;
                this.forcescrolling = false;
            }, 650);
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        // When inProgress is set to false (reload is finished)
        if (!changes.inProgress.currentValue) {
            this.forceResetScrolling();
        }
    }

    private get scrollTop() {
        return this.element.scrollTop || 0;
    }

    // While move, check how for we moved to the top
    // Less then 15 pixels to the to means reload
    // This displays "release to reload" on screen
    // The reload action will be executed on touchEnd
    @HostListener('touchmove', ['$event'])
    onTouchMove($event) {
        if (this.scrollTop <= 15) {
            this.startAfterRelease = true;
        } else {
            this.startAfterRelease = false;
        }
    }

    // When touch ends, check whter we have to reset the height
    // or we have te emit an onPull (refresh) request
    @HostListener('touchend', ['$event'])
    onTouchEnd($event) {
        if (this.startAfterRelease) {
            this.onPull.emit();
            this.startAfterRelease = false;
        } else {
            this.forceResetScrolling();
        }
    }

}
