import {Component, OnDestroy, OnInit} from '@angular/core';
import {Contact} from '../shared/contact';
import {TownService} from '../../services/town/town.service';
import {ActivatedRoute} from '@angular/router';
import {Storage} from '../../storage.class';
import {Utils} from '../../utils.class';
import {ConfirmModalService} from '../../shared/confirm-modal.service';
import {Subscription} from 'rxjs';
import {Routenames} from '../../route-names.enum';

@Component({
    selector: 'app-contact-detail',
    templateUrl: './contact-detail.component.html',
    styleUrls: ['./contact-detail.component.scss']
})
export class ContactDetailComponent implements OnInit, OnDestroy {

    public Utils = Utils;

    contact = new Contact();
    contactId: number;
    townId: number;
    projectId: number;
    Routenames = Routenames;

    private subscriptions = new Subscription();

    constructor(private townService: TownService, private route: ActivatedRoute,
                private confirmModal: ConfirmModalService) {
    }

    ngOnInit() {
        this.subscriptions.add(this.route.params.subscribe(params => {
            this.townId = +params['townId'];
            this.projectId = +params['projectId'];
            this.contactId = +params['id'];
        }));
        this.townService.contact(this.townId, this.contactId).then(contact => {
            this.contact = contact;
        }, (error) => {
            this.confirmModal.showModal('Kon contactpersoon niet ophalen', error);
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }


}
