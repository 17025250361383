import {Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Workarea} from '../../workarea.class';
import {FormGroup} from '@angular/forms';
import {Storage} from '../../storage.class';
import {TownProject} from '../../town-project';
import {TownProjectService} from '../../services/townproject/town-project.service';

@Component({
    selector: 'app-workarea-naw',
    templateUrl: './workarea-naw.component.html',
    styleUrls: ['./workarea-naw.component.scss']
})
export class WorkareaNawComponent implements OnInit {

    @ViewChild('workareaForm', {static: true}) workareaForm: FormGroup;
    @Output()
    formValid = new EventEmitter<FormGroup>();
    @Input()
    workarea: Workarea;
    userIsContractor = false;
    @Input()
    projects: TownProject[];

    constructor(private projectService: TownProjectService) {
    }

    @HostListener('change', ['$event'])
    inputChanged(event) {
        this.formValid.emit(this.workareaForm);
    }

    ngOnInit() {
        if (Storage.getUser().group === 'CONTRACTOR') {
            this.userIsContractor = true;
        }
        this.formValid.emit(this.workareaForm);
    }

    projectChange(event) {
        this.projects.forEach(townproject => {
            if (townproject.id == this.workarea.townproject_id) {
                this.workarea.townProject = townproject;
            }
        });
    }

}
