import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {Document} from '../../document';
import {Workarea} from '../../workarea.class';

@Injectable({
    providedIn: 'root'
})
export class DocumentsService {

    constructor(private apiService: ApiService) {
    }

    public download(chapterId): Promise<any> {
        return this.apiService.getCall(`documents/download/${chapterId}`);
    }

    public getDocumentsForWorkarea(workareaId): Promise<Workarea> {
        return this.apiService.getCall(`documents/${workareaId}`);
    }

    public save(chapter: Document): Promise<Document> {
        return this.apiService.postCall('documents/addChapter', chapter);
    }

    public saveCameraImage(chapter: Document): Promise<Document> {
        return this.apiService.postCall('documents/addCameraImage', chapter);
    }

    public rename(chapter: Document): Promise<Document> {
        return this.apiService.postCall('documents/renameChapter', chapter);
    }

    public changeOrder(chapters: Document[]): Promise<boolean> {
        return this.apiService.postCall('documents/changeOrder', chapters);
    }

    public delete(chapterId: number[]): Promise<Object> {
        return this.apiService.deleteCall(`documents/${chapterId}`);
    }
}
