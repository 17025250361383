import {Component, HostListener, Inject, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {SwiperConfigInterface, SwiperDirective} from 'ngx-swiper-wrapper';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Settings} from '../../settings.class';
import {Storage} from '../../storage.class';
import {ImageViewerData} from './image-viewer-data';
import {CodaltComponent} from '../../codalt.component';
import {PinchZoomComponent} from 'ngx-pinch-zoom';

declare var cordova;

@Component({
    selector: 'app-image-viewer-dialog',
    templateUrl: './image-viewer-dialog.component.html',
    styleUrls: ['./image-viewer-dialog.component.scss']
})
export class ImageViewerDialogComponent extends CodaltComponent implements OnInit {

    @ViewChildren('pinchZoom') pinchZooms: QueryList<PinchZoomComponent>;

    @ViewChild(SwiperDirective, {static: true}) directiveRef?: SwiperDirective;
    secure = '';

    Settings = Settings;
    Storage = Storage;

    showNavigation = typeof cordova === 'undefined';

    public config: SwiperConfigInterface = {
        a11y: {enabled: true},
        direction: 'horizontal',
        slidesPerView: 'auto',
        navigation: false,
        preloadImages: true,
        //allowTouchMove: typeof cordova !== 'undefined',
        keyboard: true,
        noSwipingClass: 'pz-dragging'
    };

    constructor(
        public dialogRef: MatDialogRef<ImageViewerDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public images: ImageViewerData) {
        super();
        if (images.secure) {
            this.secure = `secure=${images.secure}&`;
        }
    }

    ngOnInit() {
        this.directiveRef.indexChange.subscribe(() => {
            this.pinchZooms.forEach(pz => {
                if (pz.isZoomedIn) {
                    pz.toggleZoom();
                }
            });
        });
    }

    @HostListener('click', ['$event'])
    public click(event) {
        if (event.target.localName !== 'img'
            && event.srcElement.className.indexOf('swiper-button') === -1
            && event.srcElement.className.indexOf('pz-zoom-button') === -1) {
            this.dialogRef.close();
        }
    }
}

