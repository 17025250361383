<div class="container-fluid" *ngIf="!registered">
    <div class="row">
        <div class="col-12 col-lg-8 offset-lg-2 pt-2">
            <p>Vul onderstaand formulier in om uw account voor de Dirk-app aan te maken.</p>
            <form #registerForm="ngForm" (submit)="save(registerForm)">
                <div class="row">
                    <div class="col-12 col-md-7">
                        <mat-form-field class="w-100">
                            <input type="text" [(ngModel)]="user.name" name="name" id="name"
                                   placeholder="Voornaam" required #name="ngModel" matInput
                                   errorState="name.errors?.required && (name.dirty || registerForm.submitted)">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-5">
                        <mat-form-field class="w-100">
                            <input type="text" [(ngModel)]="user.lastname" id="lastname"
                                   name="lastname" matInput
                                   placeholder="Achternaam" required #lastname="ngModel"
                                   errorState="lastname.errors?.required && (lastname.dirty || registerForm.submitted)">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-5">
                        <mat-form-field class="w-100">
                            <input type="email" [(ngModel)]="user.email" id="email" name="email"
                                   placeholder="E-mailadres" required #email="ngModel" matInput
                                   errorState="(email.errors?.required ||email.hasError('email') || emailInUse)  && (email.dirty || registerForm.submitted)">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-7">
                        <mat-form-field class="w-100">
                            <input type="text" [(ngModel)]="user.phone" name="phone" placeholder="Telefoonnummer"
                                   id="phone" #phone="ngModel" matInput
                                   errorState="phone.errors?.required && (phone.dirty || registerForm.submitted)">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row flex-row-reverse">
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <button type="submit" [disabled]="submitDisabled" class="w-100"
                                    mat-raised-button color="primary">{{submitText}}
                            </button>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <button [routerLink]="['/login']" class="w-100" tabindex="-1"
                                    mat-stroked-button color="warn">Annuleren
                            </button>
                        </div>
                    </div>

                </div>

            </form>

        </div>
    </div>
</div>

<div class="container text-center pt-5 h-100" *ngIf="registered">
    <img src="assets/images/logo.svg" class="mb-5" alt="Logo">
    <h3>U heeft uw e-mailadres nog niet bevestigd</h3>
    <p>
        wij hebben een bevestigingslink verstuurd naar <span class="font-weight-bold">{{user.email}}</span><br><br>
        klik op de link in deze e-mail om uw account te activeren
    </p>

    <button mat-raised-button color="primary" routerLink="/login">&laquo; Naar het inlogscherm</button>
</div>
