import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {WorkareaService} from '../../services/workarea/workarea.service';
import {Workarea} from '../../workarea.class';
import {WorkareaHistory} from '../../workarea-history';
import {Status} from '../../status.class';
import {TownProject} from '../../town-project';

@Component({
    selector: 'app-workarea-history-dialog',
    templateUrl: './workarea-history-dialog.component.html',
    styleUrls: ['./workarea-history-dialog.component.scss']
})
export class WorkareaHistoryDialogComponent implements OnInit {

    workarea: Workarea;
    history: WorkareaHistory[];
    fieldTranslations: {};
    statuses: Status[];
    projects: TownProject[];

    booleans = ['extra.klinkerfloor', 'extra.reinforcementgrid'];

    constructor(private workareaService: WorkareaService,
                private dialog: MatDialogRef<WorkareaHistoryDialogComponent>,
                @Inject(MAT_DIALOG_DATA) data: {
                    workarea: Workarea,
                    fieldTranslations: {},
                    statuses: Status[],
                    projects: TownProject[]
                }) {
        this.workarea = data.workarea;
        this.fieldTranslations = data.fieldTranslations;
        this.statuses = data.statuses;
        this.projects = data.projects;
    }

    ngOnInit(): void {
        this.workareaService.getHistory(this.workarea.id).subscribe(history => {
            this.history = history.data;
            this.history.forEach(item => {
                if (this.booleans.indexOf(item.field) !== -1) {
                    item.old = item.old === '1' ? 'Ja' : 'Nee';
                    item.new = item.new === '1' ? 'Ja' : 'Nee';
                }
                if (item.field === 'status_id') {
                    item.old = this.statuses.find(s => s.id === +item.old)?.name;
                    item.new = this.statuses.find(s => s.id === +item.new)?.name;
                }
                if (item.field === 'townproject_id') {
                    item.old = this.projects.find(s => s.id === +item.old)?.name;
                    item.new = this.projects.find(s => s.id === +item.new)?.name;
                }
                if (item.field.substring(0, 5) === 'extra') {
                    item.field = this.fieldTranslations[item.field.substring(6)];
                } else {
                    item.field = this.fieldTranslations[item.field];
                }
            });
        });
    }

}
