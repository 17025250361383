import {Component, OnInit} from '@angular/core';
import {CodaltComponent} from '../codalt.component';
import {TownProjectService} from '../services/townproject/town-project.service';
import {ActivatedRoute} from '@angular/router';
import {User} from '../user.class';
import {Sort} from '@angular/material/sort';
import {Utils} from '../utils.class';

@Component({
    selector: 'app-town-project-consumers',
    templateUrl: './town-project-consumers.component.html',
    styleUrls: ['./town-project-consumers.component.scss']
})
export class TownProjectConsumersComponent extends CodaltComponent implements OnInit {

    displayedColumns = [
        'name',
        'lastname',
        'phone',
        'email',
        'updated_at'
    ];

    currentSort: Sort = {
        active: 'name',
        direction: 'desc'
    };

    townId: number;
    projectId: number;
    consumers: User[];

    constructor(private townProjectService: TownProjectService,
                private route: ActivatedRoute) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions.add(this.route.params.subscribe((params: { projectId, townId }) => {
            this.townId = +params.townId;
            this.projectId = +params.projectId;
            this.townProjectService.getProjectConsumers(this.projectId).subscribe(consumer => {
                this.consumers = consumer.data;
                setTimeout(() => {
                    this.sortConsumers(this.currentSort);
                });
            });
        }));
    }

    sortConsumers(sort?: Sort) {
        if (sort) {
            this.currentSort = sort;
        } else {
            sort = this.currentSort;
        }
        const data = this.consumers.slice();
        if (!sort.active || sort.direction === '') {
            this.consumers = data;
            return;
        }

        this.consumers = data.sort((a, b) => {
            const isAsc = sort.direction === 'asc';
            return Utils.compare(a[sort.active], b[sort.active], isAsc);
        });

    }
}
