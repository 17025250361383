import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TimelineService} from '../../services/timeline.service';
import {Timeline} from '../../classes/timeline.class';
import {CodaltComponent} from '../../codalt.component';
import {VirtualScrollerComponent} from 'ngx-virtual-scroller';
import {ActivatedRoute} from '@angular/router';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';
import {Storage} from '../../storage.class';
import {TownProjectService} from '../../services/townproject/town-project.service';
import {TownProject} from '../../town-project';
import {SearchService} from '../../services/search/search.service';

@Component({
    selector: 'app-timeline-town',
    templateUrl: './timeline-town.component.html',
    styleUrls: ['./timeline-town.component.scss']
})
export class TimelineTownComponent extends CodaltComponent implements OnInit, OnDestroy {

    @ViewChild("virtualScroll") virtualScroll: VirtualScrollerComponent;

    childActive: boolean;

    timelineItems: Timeline[] = [];
    loading = true;
    projectId;
    townId;

    @ViewChild('projectFilterSearchTemplate', {static: true}) projectFilterSearchTemplate;
    searchProjects: number[] = [];
    projects: TownProject[];

    constructor(private timelineService: TimelineService,
                private projectService: TownProjectService,
                private searchService: SearchService,
                private dialog: ConfirmDialogService,
                private route: ActivatedRoute) {
        super();
    }

    ngOnInit(): void {
        if (Storage.getUser().group === 'ADMIN') {
            this.projectService.getProjects(true).subscribe(data => {
                this.projects = data.data;
                this.searchService.searchTemplate = this.projectFilterSearchTemplate;
            });
        }
        this.projectId = this.route.snapshot.params?.projectId;
        if (!this.projectId) {
            this.searchProjects = this.LocalStorage.getSearchProjectsIds();
        }
        this.townId = this.route.snapshot.params?.townId;
        this.getTimelines();
    }

    childClosed(event) {
        if (event?.reserve) {
            this.getTimelines();
        } else if (event?.form) {
            const timeline = this.timelineItems.find(a => a.id === event?.timeline?.id);
            if (timeline) {
                Object.assign(timeline, event?.timeline);
            } else {
                this.getTimelines();
            }
        }
    }

    changeSearch() {
        this.getTimelines();
        this.LocalStorage.setSearchProjectsIds(this.searchProjects);
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }


    private getTimelines() {
        this.loading = true;
        if (!this.searchProjects?.length && this.projectId) {
            this.searchProjects = [+this.projectId];
        }
        this.timelineService.getList(this.searchProjects).subscribe(timelines => {
            this.timelineItems = timelines.data;
            const scrollToItem = this.timelineItems.filter(t => new Date(t.publish_date) <= new Date());
            if (scrollToItem?.length > 0) {
                setTimeout(() => {
                    this.virtualScroll.scrollToIndex(this.timelineItems.indexOf(scrollToItem[scrollToItem.length - 1]));
                });
            }
            this.loading = false;
        }, () => {
            this.loading = false;
        });
    }
}
