<div class="row">
    <div class="col-12 d-flex justify-content-between">
        <a routerLink="{{backAction}}"
           [queryParams]="queryParams"
           queryParamsHandling="merge" *ngIf="backAction != 'exit'">
            <div class="text" [innerHTML]="backText | safeHtml">
            </div>
            <div class="icon"></div>
        </a>
        <a *ngIf="backAction == 'exit'">
            <div class="text" [innerHTML]="backText | safeHtml">
            </div>
        </a>
        <div class="logo-small" routerLink="/"></div>
    </div>
</div>
