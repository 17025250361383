import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ConfirmModalService} from './confirm-modal.service';
import {Subscription} from 'rxjs';
import {ConfirmModal} from './confirm-modal';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-confirm-modal',
    template: `
        <ng-template #confirmPopupTemplate>
            <div class="modal-header">
                <h4 class="modal-title pull-left">{{config.title}}</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="handleClick(false)" *ngIf="config.confirmButton != null">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" [innerHTML]="config.content">
            </div>
            <div class="modal-footer">
                <button type="button" mat-button color="primary" (click)="handleClick(false)" *ngIf="config.cancelButton != null">{{config.cancelButton}}
                </button>
                <button type="button" mat-raised-button color="primary" (click)="handleClick(true)" *ngIf="config.confirmButton != null">{{config.confirmButton}}
                </button>
            </div>
        </ng-template>`
})
export class ConfirmModalComponent implements OnDestroy, OnInit {

    @ViewChild('confirmPopupTemplate', {static: true}) private templateRef: TemplateRef<any>;

    modalRefConfirmPopup: BsModalRef;
    config: ConfirmModal;
    subscription: Subscription;

    private resolve: Function;
    private reject: Function;

    constructor(private confirmModalService: ConfirmModalService, private modalService: BsModalService) {
    }

    ngOnInit() {

        this.subscription = this.confirmModalService.obs$
            .subscribe(config => {
                this.modalRefConfirmPopup = this.modalService.show(this.templateRef, {class: 'confirmPopup'});
                if (config.modal) {
                    config.modal(this.modalRefConfirmPopup);
                }
                this.config = config.modalConfig;
                this.resolve = config.resolve;
                this.reject = config.reject;
            });
    }

    public handleClick(value: boolean) {
        if (value) {
            this.resolve();
        } else {
            this.reject();
        }

        this.modalRefConfirmPopup.hide();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

}
