import {User} from './user.class';
import {Workarea} from './workarea.class';

export class WorkareaImage {
    public id: number;
    public user: User;
    public workarea: Workarea;
    public status: Object;
    public updated_at: Date;
    public image_url: string;

    public workarea_id: number;
    public user_id: number;
    public status_id: number;
}
