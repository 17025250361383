<div class="container">
    <div class="row justify-content-md-center">
        <div class="col-12 col-md-10 col-xl-8 pt-2">
            <div class="mb-3">
                <h3>Mijn account</h3>
                <div class="row">
                    <div class="col-12" *ngIf="user">
                        Je bent ingelogd als {{user.name}}. Je e-mailadres is {{user.email}}.
                    </div>
                    <div class="col-12 mt-2">
                        <button class="mr-2" color="primary" mat-stroked-button routerLink="/user/change-password">
                            Wachtwoord wijzigen
                        </button>
                        <button (click)="logout()" color="warn" mat-raised-button>Uitloggen</button>
                    </div>
                </div>
            </div>
            <div class="mb-3" *ngIf="!Utils.userIsGroup('CONSUMER')">
                <h3>Notificaties</h3>
                <div class="row">
                    <div class="col-12" *ngIf="user">
                        <mat-slide-toggle (change)="projectEmailNotifChanged($event)"
                                          [(ngModel)]="user.project_notifs"
                                          color="primary">
                            Dagelijkse e-mailupdate over mijn projecten
                        </mat-slide-toggle>
                    </div>
                </div>
            </div>
            <div *ngIf="Utils.userIsGroup('CONSUMER')" class="mb-3">
                <h3>Mijn projecten</h3>
                <div class="row">
                    <div class="col-12">
                        <p>Je volgt de volgende projecten van D. van der Steen B.V.:</p>
                        <table class="table w-100 mb-3">
                            <tbody>
                            <tr *ngFor="let townproject of user?.townprojects">
                                <td class="townproject-cell">
                                    {{townproject.name}}
                                </td>
                                <td class="text-right">
                                    <button (click)="removeTownProject(townproject)" color="warn" mat-stroked-button>
                                        Uitschrijven<span class="d-none d-md-inline"> voor project</span>
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2"></td>
                            </tr>
                            </tbody>
                        </table>

                        <button (click)="addTownProject()" class="mb-3" color="primary" mat-stroked-button>
                            Project toevoegen
                        </button>
                    </div>
                </div>
            </div>
            <div *ngIf="Utils.userHasRights('ADMIN')" class="mb-3">
                <h3>Admin</h3>
                <div class="row">
                    <div class="col-12">
                        <button color="primary" mat-stroked-button routerLink="/users">Gebruikersbeheer</button>
                    </div>
                </div>
            </div>
            <div *ngIf="Utils.userHasRights('GEMEENTE') && townTypeIsStraatwerk && !Utils.userHasRights('USER')"
                 class="mb-3">
                <h3>Exporteren</h3>
                <div class="row">
                    <div class="col-12">
                        <div class=" d-flex flex-wrap">
                            <div class="mr-1">
                                <button (click)="exportExcel()" class="mt-1" color="primary" mat-stroked-button>
                                    Exporteer kaart naar excel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="!Utils.userIsGroup('CONSUMER')" class="mb-3">
                <h3>Voorkeuren kaart</h3>

                <div *ngFor="let mapsetting of staticmapsettings">

                    <mat-checkbox (change)="mapSettingsChanged()" [(ngModel)]="StaticSettings.mapSettings[mapsetting.key]"
                                  [value]="true"
                                  color="primary">
                        {{mapsetting.description}}
                    </mat-checkbox>

                </div>
                <br>

                <mat-checkbox (change)="mapSettingsChanged()" [(ngModel)]="StaticSettings._mapSettings['largeMapTextShadow']"
                              [value]="false"
                              color="warn">
                    Schaduw achter vaknummers ook tonen bij veel werkvakken
                </mat-checkbox>
                <br>
                <small>Het tonen van schaduw maakt de kaart beter leesbaar maar zorgt ervoor dat de kaart
                    langzamer laadt
                </small>

            </div>
        </div>
        <div class="col-12 col-md-10 col-xl-8">
            <div class="moredetails">
                <h3>App details</h3>
                <p>Huidige versie: {{version.version}}</p>
                <p>Versie datum: {{version.datetime | amLocale:'nl' | amDateFormat: 'D MMMM YYYY \o\\m HH:mm'}}</p>

                <a class="codalt" href="https://codalt.com?utm_source=dvds_dirk" target="_BLANK">
                    <div>Deze app is ontwikkeld door</div>
                    <img alt="Codalt" src="assets/images/codalt.svg"/>
                </a>
            </div>
        </div>
    </div>
</div>
