import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Settings} from '../settings.class';
import {AngularBingMapsComponent, MapLocation, Polygon, Pushpin} from '../angular-bing-maps/angular-bing-maps.component';
import {Region} from '../region.class';
import {Utils} from '../utils.class';
import {Observable, Subscription} from 'rxjs';
import * as XLSX from 'xlsx';
import {formatDate} from '@angular/common';
import {ConfirmDialogService} from '../services/confirm-dialog-service/confirm-dialog.service';
import {AddressService} from '../services/address/address.service';
import {AddressArea} from 'app/classes/addressarea.class';
import {debounceTime, map, switchMap, tap} from 'rxjs/operators';
import {LocationService} from '../workarea/shared/location.service';
import {FormControl} from '@angular/forms';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';

@Component({
    selector: 'app-addresses-map',
    templateUrl: './addresses-map.component.html',
    styleUrls: ['./addresses-map.component.scss']
})
export class AddressesMapComponent implements OnInit, OnDestroy, AfterViewInit {

    static limitBuildings = 2500;
    static warningBuildings = 100;
    Utils = Utils;
    Settings = Settings;

    pushpins: Pushpin[] = [];

    mapGeoTrigger = 0;
    mapZoomTrigger = 0;
    mapStyleToggleTrigger = 0;
    zoomChange = 0;
    currentCoords = {'coords': {'latitude': 0.000, 'longitude': 0.000}};
    loading = false;
    areas: AddressArea[] = [];
    fcSearch = new FormControl('');
    @ViewChild(AngularBingMapsComponent) mapComponent: AngularBingMapsComponent;
    addressSearch: Observable<{ name: string; lat: number; lng: number; }[]> = null;

    private subscriptions = new Subscription();
    private buildings$: Subscription;

    constructor(private confirmDialogService: ConfirmDialogService,
                private locationService: LocationService,
                private addressService: AddressService) {
    }

    showAddress(value: any) {
        return value ? value['name'] : '';
    }

    goToAddress(val: MatAutocompleteSelectedEvent) {
        const coords = val.option.value;
        Settings.mapCenter = new MapLocation({latitude: coords['lat'], longitude: coords['lng']});
        Settings.mapZoom = 16 + Math.random();
    }


    ngOnInit() {
        this.subscriptions.add(this.addressService.getAddressAreas().subscribe(areas => {
            this.areas = areas.data;
        }));

        this.addressSearch = this.fcSearch.valueChanges.pipe(debounceTime(300), switchMap(value => {
            return this.locationService.getAddresses(value).pipe(map(result => {
                const bingResults = result['resourceSets'][0]['resources'];
                const results = [];
                bingResults.forEach(rs => {
                    results.push({
                        name: rs['name'],
                        lat: rs['point']['coordinates'][0],
                        lng: rs['point']['coordinates'][1]
                    });
                });
                return results;
            }));
        }));

        this.centerCountry();
    }

    ngAfterViewInit() {
        this.subscriptions.add(this.mapComponent.somePointMoveEvent.pipe(tap(e => {
            this.areas.find(a => a.drawing).loading = true;
        })).pipe(debounceTime(1500)).subscribe(() => {
            this.loadBuildings();
        }));

        this.subscriptions.add(this.mapComponent.pushpinDbClickEvent.subscribe(() => {
            this.loadBuildings();
        }));

        this.subscriptions.add(this.mapComponent.mapRightClickEvent.subscribe(() => {
            this.loadBuildings();
        }));

    }

    centerCountry() {
        const countryPoints = [
            {latitude: 53.49785, longitude: 4.682922},
            {latitude: 53.396432, longitude: 7.393799},
            {latitude: 50.75731, longitude: 6.011581},
            {latitude: 51.238706, longitude: 3.269119}
        ];
        const region = new Region(countryPoints);
        Settings.mapZoom = region.bestMapView(window.innerWidth, window.innerHeight, 100);
        Settings.mapCenter = region.centroid();
    }


    setZoom(event) {
        setTimeout(() => {
            Settings.mapZoom = event + this.zoomChange;
        });
    }

    loadAdresses(area: AddressArea) {
        const downloadAddresses = (numbers: { identificatie: string; gebruiksdoelen: string[]; }[]) => {
            area.loading = true;
            this.subscriptions.add(this.addressService.getAddresses(numbers.map(n => n.identificatie)).subscribe(adressenResponse => {
                const sheetArray = [];
                adressenResponse.data.forEach(adres => {
                    const answerRow = {};
                    answerRow['Regel 1'] = adres.line_one;
                    answerRow['Regel 2'] = adres.line_two;
                    area.buildings.find(b => +b.identificatie === +adres.id)?.gebruiksdoelen.forEach((gebruiksdoel, i) => {
                        answerRow['Gebruiksdoel ' + (i + 1)] = gebruiksdoel;
                    });

                    sheetArray.push(answerRow);
                });

                const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(sheetArray);
                worksheet['!cols'] = [
                    {width: 50},
                    {width: 80},
                    {width: 30},
                    {width: 30},
                    {width: 30}
                ];

                const workbook: XLSX.WorkBook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
                const excelBuffer: any = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
                const typeTitle = `${area.name}`.replace(/\s/g, '-').replace(/[^a-z0-9\-]/gi, '');
                const filename = `Adressen_${typeTitle}_${formatDate(new Date(), 'yyyy-MMM-d_H-mm', 'nl')}`;
                Settings.saveAsExcelFile(excelBuffer, filename.replace('.', ''));
                area.loading = false;

            }, () => {
                area.loading = true;
            }));
        };

        const region = new Region(area.exteriorRing);
        const numbers = [];
        area.buildings.forEach(building => {
            if (region.insideTown({latitude: building.locatie.lat, longitude: building.locatie.lng})) {
                numbers.push({identificatie: building.identificatie, gebruiksdoelen: building.gebruiksdoelen});
            }
        });
        if (numbers.length > AddressesMapComponent.limitBuildings) {
            this.confirmDialogService.confirm('Te veel gebouwen geselecteerd',
                `Het aantal gebouwen waarvoor adressen gedownload kunnen worden is gelimiteerd op ${AddressesMapComponent.limitBuildings}. Je probeert nu voor ${numbers.length} gebouwen de adressen te downloaden. Verklein het gebied.`,
                'Sluiten',
                null).then(() => {

            });
        } else if (numbers.length > AddressesMapComponent.warningBuildings) {
            this.confirmDialogService.confirm('Adressen downloaden',
                `Je staat op het punt om voor ${numbers.length} gebouwen de adressen te downloaden. Dit kan ${1 + Math.round(numbers.length / 60)} minuten duren. Weet je zeker dat je door wilt gaan?`,
                'Adressen downloaden',
                'Annuleren').then(() => {
                downloadAddresses(numbers);
            })
        } else {
            downloadAddresses(numbers);
        }

    }

    editArea(area: AddressArea) {
        this.areas.filter(a => a.drawing === true).forEach(drawingArea => {
            drawingArea.drawing = false;
        });

        area.drawing = true;
        this.drawArea(area);
        if (area.exteriorRing.length > 0) {
            const areaRegion = new Region(area.exteriorRing);
            Settings.mapZoom = areaRegion.bestMapView(window.innerWidth, window.innerHeight, 200);
            this.mapComponent.mapCenter = areaRegion.centroid();
            this.mapComponent.setView();
        }

    }

    addArea() {
        const addressArea = new AddressArea();
        addressArea.strokeColor = '#000000';
        addressArea.exteriorRing = [];
        addressArea.drawing = true;
        this.areas.unshift(addressArea);
    }

    drawArea(area: AddressArea) {
        area.drawing = true;
        this.drawPolygon(area);
        this.drawBuildings(area);
    }

    removeArea(area: AddressArea) {
        this.confirmDialogService.confirm(
            'Adresgebied verwijderen',
            `Wil je het adresgebied ${area.name || ''} verwijderen?`,
            'ja', 'nee').then(() => {
            area.drawing = false;
            if (area.id) {
                this.subscriptions.add(this.addressService.deleteAddressArea(area.id).subscribe(() => {
                    this.areas.splice(this.areas.indexOf(area), 1);
                    this.drawPolygon(area);
                }));
            } else {
                this.areas.splice(this.areas.indexOf(area), 1);
                this.drawPolygon(area);
            }
        }, () => {
        });

    }

    setPoint(e?: { lat: number; lng: number; }) {
        const area = this.areas.find(a => a.drawing === true);
        if (!area) {
            return;
        }
        if (!area.name) {
            this.subscriptions.add(this.locationService.getLocations(e.lat, e.lng).subscribe(response => {
                area.name = response['resourceSets'][0]['resources'][0].address.locality;
            }));
        }

        area.exteriorRing.push({
            latitude: e.lat,
            longitude: e.lng
        });
        this.drawPolygon(area);
    }

    saveArea(area: AddressArea) {
        this.drawPolygon(area);
        this.subscriptions.add(this.addressService.saveAddressArea(area).subscribe(areaResponse => {
            area.updated_at = areaResponse.data.updated_at;
        }));
    }

    private drawPolygon(area) {
        area.snappedPoints = area.snappedPoints ?? [];
        this.mapComponent.updateManualPolygons([new Polygon({
            exteriorRing: area.exteriorRing,
            drawing: area.drawing,
            data: null
        })]);
    }

    loadBuildings() {
        const area = this.areas.find(a => a.drawing);
        if (area.exteriorRing.length > 3) {
            area.loading = true;
            const latitudes = area.exteriorRing.map(c => c.latitude);
            const longitudes = area.exteriorRing.map(c => c.longitude);
            const corners = [
                {lat: Math.min(...latitudes), lng: Math.min(...longitudes)},
                {lat: Math.max(...latitudes), lng: Math.max(...longitudes)}
            ];

            this.buildings$?.unsubscribe();

            this.buildings$ = this.addressService.getBuildings(corners).subscribe(buildingsResponse => {
                const region = new Region(area.exteriorRing);
                area.buildings = [];
                buildingsResponse.data.forEach(building => {
                    if (region.insideTown({
                        latitude: building.locatie.lat,
                        longitude: building.locatie.lng
                    })) {
                        area.buildings.push(building);
                    }
                });
                this.saveArea(area);
                this.drawBuildings(area);
            });
        }
    }

    drawBuildings(area: AddressArea) {
        const pushpins: Pushpin[] = [];
        area.buildings.forEach(building => {
            pushpins.push(new Pushpin({
                longitude: building.locatie.lng,
                latitude: building.locatie.lat,
                data: building.identificatie,
                icon: Settings.getMarker(
                    123,
                    ``,
                    '#F00',
                    true,
                    false,
                    'maintenance')
            }));
        });
        area.loading = false;
        this.pushpins = pushpins;
    }

    ngOnDestroy(): void {
        this.buildings$?.unsubscribe();
        this.subscriptions.unsubscribe();
    }

}
