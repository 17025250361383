<div class="town-details">
    <div class="content">
        <div class="d-flex align-items-start">
            <mat-form-field appearance="fill" class="mr-2 flex-grow-1">
                <mat-label>Zoeken op kaart</mat-label>
                <input matInput name="search"
                       [formControl]="fcSearch"
                       [matAutocomplete]="auto"
                       type="text">
                <mat-autocomplete #auto="matAutocomplete"
                                  (optionSelected)="goToAddress($event)"
                                  [displayWith]="showAddress"
                                  autoActiveFirstOption
                                  class="address-list">
                    <mat-option *ngFor="let item of addressSearch | async" [value]="item">
                        {{item.name}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>

            <div>
                <button mat-raised-button color="primary" (click)="addArea()">Gebied toevoegen</button>
            </div>
        </div>
        <div *ngFor="let area of areas; let i = index" class="area">
            <div class="area-controls">
                <h1 class="h5 condensed curpoint" (click)="editArea(area)">
                    <i class="fas fa-house-circle-check mr-3"></i> {{area.name}}
                </h1>
                <small *ngIf="area.buildings">{{area.buildings?.length}} gebouwen</small>
                <div (click)="removeArea(area)" *ngIf="area.drawing" class="fab small mr-1"><i class="fas fa-trash"></i></div>
            </div>

            <div *ngIf="area.drawing" class="details">
                <div class="d-flex justify-content-around mb-2">
                    <button mat-stroked-button color="primary" (click)="loadAdresses(area)" [disabled]="area.loading">
                        <i class="fa fa-file-excel mr-2" *ngIf="!area.loading"></i>
                        <i class="fa fa-spinner fa-spin mr-2" *ngIf="area.loading"></i>
                        Adressen downloaden
                    </button>
                </div>
                <div class="row">
                    <mat-form-field appearance="fill" class="col-12">
                        <mat-label>Naam</mat-label>
                        <input [(ngModel)]="area.name" (change)="saveArea(area)" matInput name="name" placeholder="Naam van het gebied" type="text">
                    </mat-form-field>
                </div>


                <div class="my-3 font-italic">
                    Rechtermuisknop: punt toevoegen<br>
                    Gebied aanpassen: punt verslepen<br>
                    Dubbel klikken op punt: punt verwijderen
                </div>
            </div>
        </div>
    </div>
</div>
<div class="near-searchbar user-select-none town-details-visible">
    <div class="logo-small float-right" routerLink="/"></div>
    <app-angular-bing-maps
        (map-zoom)="setZoom($event)"
        (mapRightClickEvent)="setPoint($event)"
        [bing-maps-api-key]="Settings.BING_MAPS_API_KEY"
        [showCurrentLocation]="false"
        [alwaysShowFill]="true"
        [geoData]="currentCoords"
        [map-geo-trigger]="mapGeoTrigger"
        [map-style-toggle-trigger]="mapStyleToggleTrigger"
        [map-zoom-trigger]="mapZoomTrigger"
        [pushpins]="pushpins"
        [set-map-center]="Settings.mapCenter"
        [set-map-zoom]="Settings.mapZoom"
        id="map"
    ></app-angular-bing-maps>
</div>
