import {Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Workarea} from '../../workarea.class';
import {Status} from '../../status.class';
import {Utils} from '../../utils.class';

@Component({
    selector: 'app-workarea-general',
    templateUrl: './workarea-general.component.html',
    styleUrls: ['./workarea-general.component.scss']
})
export class WorkareaGeneralComponent implements OnInit {

    @ViewChild('workareaForm', {static: true}) workareaForm: FormGroup;
    @Output()
    public formValid = new EventEmitter<FormGroup>();
    @Input()
    public workarea: Workarea;
    @Input()
    statuses: Status[];
    @Input()
    fieldOptions: object;
    public Utils = Utils;

    constructor() {
    }

    @HostListener('change', ['$event'])
    inputChanged(event) {
        this.formValid.emit(this.workareaForm);
    }

    ngOnInit() {
        this.formValid.emit(this.workareaForm);
        if (!this.workarea.status_id) {
            this.workarea.status_id = 101;
            this.workarea.status = this.statuses[0];
        }
    }

    statusChange(event) {
        this.statuses.forEach(status => {
            if (status.id == this.workarea.status_id) {
                this.workarea.status = status;
            }
        });
    }

    addArea() {
        if (!this.workarea.areas) {
            this.workarea.areas = [];
        }
        this.workarea.areas.push({
            width: 1,
            length: 1
        });
    }

    removeArea(area) {
        this.workarea.areas.splice(this.workarea.areas.indexOf(area), 1);
    }

    areasTotal(areas) {
        let totalSize = 0;
        if (areas) {
            areas.forEach(area => {
                area.length = area.length > 0 ? area.length : 1;
                area.width = area.width > 0 ? area.width : 1;
                totalSize += area.width * area.length;
            });
        }
        return Math.round(totalSize * 100) / 100;
    }

    changeKlinkerfloor(value: boolean) {
        if (value && (!this.workarea.extra.bottomlayer || this.workarea.extra.bottomlayer === 'Niet van toepassing')) {
            this.workarea.extra.bottomlayer = 'AC-22 Base';
        }
        if (!value && this.workarea.extra.bottomlayer === 'AC-22 Base') {
            this.workarea.extra.bottomlayer = 'Niet van toepassing';
        }
    }

    toplayerChange(event) {
        if (this.fieldOptions['toplayer'].indexOf(this.workarea.extra.toplayer) === -1) {
            this.workarea.extra.toplayer = '';
        }

    }

}
