<div (mousedown)="outsideClick($event)" *ngIf="workarea" class="container-fluid h-100">
    <div class="row">
        <div class="scroll-container">
            <div class="details-container">
                <div class="detail-title">
                    <a routerLink="/{{Routenames.towns}}/{{workarea.town_id}}">
                        <div class="icon"></div>
                        <div class="text"><b>{{workarea.number}}</b> Werkgebied</div>
                    </a>
                    <div class="toolbar">
                        <div (click)="editDocumentDetails()" class="text curpoint">
                            &nbsp;{{workarea.street}} - {{workarea.status?.name}} <i class="fas fa-pencil-alt"></i>
                        </div>
                    </div>
                    <div class="logo-small" routerLink="/"></div>

                </div>
                <div class="detail-title">
                    <div>
                        <div (click)="editDocumentDetails()" class="text curpoint">
                            &nbsp;{{workarea.street}} - {{workarea.status?.name}} <i class="fas fa-pencil-alt"></i>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-9">
                        <app-processbar *ngIf="!loaded && !workarea.id"></app-processbar>
                    </div>
                </div>
                <div class="folder-view">
                    <div class="file-menu">

                    </div>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li>
                                <button (click)="up()" [disabled]="!parentItem" color="primary" mat-icon-button>
                                    <i class="fas fa-level-up-alt"></i>
                                </button>
                            </li>
                            <li (click)="open(null, true)"
                                [class.active]="!parentItem"
                                [class.curpoint]="parentItem"
                                aria-current="page"
                                class="breadcrumb-item">
                                Home
                            </li>
                            <li (click)="open(crumb)"
                                *ngFor="let crumb of breadcrumb"
                                [class.active]="crumb === parentItem"
                                [class.curpoint]="crumb !== parentItem"
                                aria-current="page"
                                class="breadcrumb-item ">
                                {{crumb.name}}
                            </li>
                            <li class="d-flex">
                                <button (click)="reload()" mat-button>
                                    <i [class.fa-spin]="!loaded" class="fas fa-sync-alt"></i> Herladen
                                </button>
                                <button (click)="addNewItem($event, parentItem, true)"
                                        *ngIf="Utils.userHasRights('SUBADMIN')"
                                        color="primary"
                                        mat-button>
                                    <i class="fas fa-folder-plus "></i> Map<span
                                    class="hidden-sm-down"> toevoegen</span>
                                </button>
                                <button (click)="addNewItem($event, parentItem)"
                                        *ngIf="Utils.userHasRights('SUBADMIN')"
                                        color="primary"
                                        mat-button>
                                    <i class="fas fa-upload"></i> Bestand<span class="hidden-sm-down"> uploaden</span>
                                </button>
                                <button (click)="camera()" *ngIf="isCordova && Utils.userHasRights('SUBADMIN')"
                                        mat-button>
                                    <i class="fas fa-camera"></i>
                                    Foto
                                    <span class="hidden-sm-down"> toevoegen</span>
                                </button>
                            </li>
                        </ol>
                    </nav>
                    <div (contextmenu)="contextMenuItem($event, contextMenu)"
                         (press)="contextMenuItem($event, contextMenu)" class="folders">
                        <div (click)="select($event, document)"
                             (contextmenu)="select($event, document)"
                             (dblclick)="open()"
                             (press)="select($event, document)"
                             *ngFor="let document of viewingFolder"
                             [class.selected]="selectedFolders.has(document)"
                             class="item">
                            <div class="content">
                                <i *ngIf="!document.file"
                                   class="fas fa-folder-open fa-3x">
                                </i>
                                <i *ngIf="document.file && !extHasThumb.has(document.ext)"
                                   class="far fa-3x {{icons[document.ext] ? icons[document.ext] : 'fa-file'}}">
                                </i>
                                <div *ngIf="document.file && extHasThumb.has(document.ext)"
                                     [style.background-image]="(Settings.API_ENDPOINT+'documents/thumb/'+document.id+'?access_token='+Storage.getUserToken())|safeBgUrl"
                                     class="thumb">
                                </div>
                                <div class="title">
                                    {{document.name}}
                                </div>
                            </div>
                        </div>
                        <div #contextMenu
                             [class.show]="showContextMenu && selectedFolders.size > 0"
                             class="context-menu">
                            <div (click)="open()" *ngIf="selectedFolders.size === 1"><i class="fas fa-file"></i> Open
                            </div>
                            <div (click)="download()"
                                 *ngIf="selectedFolders.size === 1 && selectedFolders.values().next().value.file"><i
                                class="fas fa-download"></i>
                                Download
                            </div>
                            <div (click)="renameItem($event)"
                                 *ngIf="selectedFolders.size === 1 && Utils.userHasRights('SUBADMIN')">
                                <i class="fas fa-pencil-alt"></i>
                                Hernoemen
                            </div>
                            <div (click)="deleteItem($event)"
                                 *ngIf="Utils.userHasRights('SUBADMIN')">
                                <i class="fas fa-trash"></i> Verwijderen
                            </div>
                        </div>
                    </div>
                    <div *ngIf="viewingFolder?.length === 0" class="empty-folder">
                        <i class="fas fa-upload fa-4x"></i>
                        <div *ngIf="parentItem" class="title">Deze map is nog leeg!</div>
                        <div *ngIf="!parentItem" class="title">Dit werkgebied is nog leeg!</div>
                        <div>
                            Voeg documenten toe door ze hierheen te slepen, of maak een map aan met de button in de
                            balk.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
