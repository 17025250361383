import {Pipe, PipeTransform} from '@angular/core';
import {Status} from '../status.class';

@Pipe({
    name: 'statusDisabled'
})
export class StatusDisabledPipe implements PipeTransform {

    transform<T>(status: Status, waymark: string, status_id: number): boolean {
        // When this status is currently selected, status is never disabled
        if (status.id === status_id) {
            return false;
        }

        // Wacht op markering, gemarkeerd
        if (status.id === 130 || status.id === 131) {
            if (!waymark || waymark === 'Niet van toepassing') {
                return true;
            }
        }
        // Afgegoten
        if (status.id === 121) {
            if (waymark === 'Verf' || waymark === 'Thermoplast') {
                return true;
            }
        }
    }

}
