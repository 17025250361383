<div class="chats">
    <div class="overview">
        <app-chat-overview #chatOverviewComponent
                           (openChat)="openChat($event)"
                           [currentChat]="currentChat">
        </app-chat-overview>
    </div>
    <div class="messaging">
        <div *ngIf="!currentChat" class="placeholder">Selecteer een melding</div>
        <app-chat-messaging (openNextChat)="chatOverviewComponent.openNextChat()"
                            *ngIf="currentChat"
                            [chat]="currentChat">
        </app-chat-messaging>
    </div>
</div>
