import {Component, OnInit} from '@angular/core';
import {Chat} from '../chat';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-chat',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {

    currentChat: Chat;
    subscriptions: Subscription;

    constructor() {

    }

    ngOnInit(): void {

    }

    openChat(chat) {
        this.currentChat = chat;
    }
}
