<ng-container *ngIf="town?.towntype !== 'straatwerk'">
    <div *ngIf="town && projects" class="container">
        <div class="header mt-2">
            <div class="row">
                <div class="col-auto">
                    <h2>Overzicht {{viewOnyDrilling ? 'Booropdrachten' : '(deel)opdrachten' }}</h2>
                    <h1>{{town.name}}</h1>
                </div>
                <div class="col justify-content-start align-items-start d-flex">
                    <a (click)="projectModal()" class="button mx-0" *ngIf="Utils.userHasRights('USER')">Opdracht toevoegen</a>
                    <a class="button mx-0 ml-2" routerLink="/towns/{{townId}}">Kaart openen</a>
                    <a class="button mx-0 ml-2" *ngIf="!viewOnyDrilling && Utils.userHasRights('USER')"
                       routerLink="/towns/{{townId}}/{{Routenames.projectsDrilling}}">Booropdrachten</a>
                    <a class="button mx-0 ml-2" *ngIf="viewOnyDrilling"
                       routerLink="/towns/{{townId}}/{{Routenames.projects}}">Projecten</a>
                </div>
                <div class="col-auto">
                    <app-town-logo [town]="town"></app-town-logo>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="town && projects" class="container projects-container">
        <div class="projects">
            <p *ngIf="projects.length === 0" class="text-center w-100">
                Er zijn nog geen (deel)opdrachten voor {{town.name}}.
                <a (click)="projectModal()" class="button mt-3">Opdracht toevoegen</a>
            </p>
            <ng-container *ngFor="let project of filteredProjects">
                <div class="project">
                    <div class="name">
                        <span>{{project.name}}</span>
                        <i *ngIf="Utils.userHasRights('USER')" (click)="projectModal(project)" class="fa fa-edit curpoint"></i>
                    </div>
                    <div *ngIf="project.budget" class="budget">
                        &euro; {{project.budget | number: '0.0-2'}}
                    </div>
                    <div class="divider"></div>
                    <div class="manager">
                        {{project.manager_user?.name}}
                    </div>
                    <div *ngIf="project?.town_project_phase?.name" class="current-phase">
                        Huidige fase: {{project?.town_project_phase?.name}}
                    </div>
                    <div *ngIf="viewOnyDrilling" class="current-phase">
                        Uitvoeringsdatum: {{project?.extra?.execution_date | date : 'd MMMM yyyy'}}
                    </div>
                    <div class="divider"></div>
                    <table *ngIf="projectsCounts && projectsCounts[project.id]" class="table-fixed">
                        <tr *ngIf="projectsCounts[project.id]['new']">
                            <td>
                                <div [innerHTML]="projectsCounts[project.id]['new'] | marker : 'C80000'">

                                </div>

                            </td>
                            <td>Geschouwd</td>
                        </tr>
                        <tr *ngIf="projectsCounts[project.id]['calculated']">
                            <td>
                                <div [innerHTML]="projectsCounts[project.id]['calculated'] | marker : '0000C8'"></div>
                            </td>
                            <td>Berekend</td>
                        </tr>
                        <tr *ngIf="projectsCounts[project.id]['progress']">
                            <td>
                                <div [innerHTML]="projectsCounts[project.id]['progress'] | marker :  'FFB300'"></div>
                            </td>
                            <td>In uitvoering</td>
                        </tr>
                        <tr *ngIf="projectsCounts[project.id]['executed']">
                            <td>
                                <div [innerHTML]="projectsCounts[project.id]['executed'] | marker : '00C800'"></div>
                            </td>
                            <td>Geasfalteerd</td>
                        </tr>
                        <tr *ngIf="projectsCounts[project.id]['approved']">
                            <td>
                                <div [innerHTML]="projectsCounts[project.id]['approved'] | marker :  'B000C8'"></div>
                            </td>
                            <td>Goedgekeurd</td>
                        </tr>
                    </table>

                    <a *ngIf="townId" [queryParams]="{project: project.id}" class="tomap" routerLink="/towns/{{project.town_id}}">
                        Opdrachtdelen op kaart bekijken &raquo;
                    </a>

                    <a class="button" routerLink="/towns/{{project.town_id || 'template'}}/{{viewOnyDrilling ? Routenames.projectsDrilling : Routenames.projects}}/{{project.id}}">
                        Bekijk opdracht
                    </a>
                </div>
            </ng-container>
        </div>
    </div>
    <ng-template #projectFilterSearchTemplate>
        <mat-form-field>
            <mat-label>Filter</mat-label>
            <mat-select multiple [(ngModel)]="projectFilter" (selectionChange)="loadProjects()">
                <ng-container *ngFor="let type of Settings.projectTypes">
                    <mat-option *ngIf="type.key !== 'drillings' || Utils.userHasRights('USER')" [value]="type.key" [hidden]="viewOnyDrilling">
                        {{type.name}}
                    </mat-option>
                </ng-container>
                <mat-option value="archive">
                    Gearchiveerd
                </mat-option>
            </mat-select>
        </mat-form-field>
    </ng-template>
</ng-container>
<ng-container *ngIf="town?.towntype === 'straatwerk'">
    <div *ngIf="town && projects" class="container">
        <div class="header mt-2">
            <div class="row">
                <div class="col-auto">
                    <h2>Overzicht </h2>
                    <h1>{{town.name}}</h1>
                </div>
                <div class="col justify-content-start align-items-start d-flex">
                    <a class="button mx-0 ml-2" routerLink="/towns/{{townId}}">Kaart openen</a>

                    <button (click)="exportExcel()" class="button mx-0 ml-2" [disabled]="generatingExcel">
                        Excel
                        <i *ngIf="generatingExcel" class="fas fa-circle-notch fa-spin"></i>
                    </button>
                </div>
                <div class="col-auto">
                    <app-town-logo [town]="town"></app-town-logo>
                </div>
            </div>
        </div>
    </div>
</ng-container>
