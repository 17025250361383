import {Injectable} from '@angular/core';
import {CordovaService} from './cordova.service';
import {FirebaseService} from './firebase.service';

declare var cordova;

@Injectable()
export class NotificationService {

    private permissions: boolean;

    constructor(private cordovaService: CordovaService, private firebaseService: FirebaseService) {
        if (typeof cordova !== 'undefined' && typeof cordova['plugins'] !== 'undefined') {
            cordova.plugins.notification.local.hasPermission((granted) => {
                this.permissions = granted;
            });

            cordova.plugins.notification.local.on('click', (data) => {
                let notif = {
                    title: data['title'],
                    text: data['text'],
                    url: data['data']['url'],
                    appWasClosed: true
                };
                this.firebaseService.newNetification.emit(notif)
            }, this);
        }
    }

    public newNotification(notif) {
        console.log('create local notif method');
        cordova.plugins.notification.local.schedule({
            data: {url: notif['url']},
            title: notif['title'],
            text: notif['text'],
            sound: true,
            foreground: true
        });
    }

}
