import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserService} from '../services/user/user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Storage} from '../storage.class';
import {User} from '../user.class';
import {AuthService} from '../services/auth/auth.service';
import {ConfirmModalService} from '../shared/confirm-modal.service';

@Component({
    selector: 'app-confirm-email',
    templateUrl: './confirm-email.component.html',
    styleUrls: ['./confirm-email.component.scss']
})
export class ConfirmEmailComponent implements OnInit, OnDestroy {

    user = new User();
    interval: any;

    constructor(private userService: UserService,
                private router: Router,
                private route: ActivatedRoute,
                private authService: AuthService,
                private confirmModal: ConfirmModalService) {
    }

    ngOnInit() {

        const queryParams = this.route.snapshot.queryParams;
        if (queryParams?.hash) {
            this.userService.confirmRegistration(queryParams?.hash).subscribe();
        }
        this.user = Storage.getUser();

        if (this.user.active) {
            this.router.navigate(['/']);
            return;
        }
    }

    ngOnDestroy() {
        clearInterval(this.interval);
    }

    logout() {
        this.authService.logout().subscribe();
        this.router.navigate(['login']);
    }

}
