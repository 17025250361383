import {Town} from './town.class';
import {TownProject} from './town-project';

export class User {

    public id: number = null;
    public name = '';
    public lastname = '';
    public email = '';
    public phone = '';
    public access_token: string;
    public password: string;
    public isadmin = false;
    public invite: boolean;
    public street = '';
    public place = '';
    public housenumber = '';
    public zipcode = '';
    public extra: {
        photo: string
    };

    public active;

    public created_at: Date;

    public updated_at: Date;

    public last_login: Date;

    public group: string;

    public towns: Array<Town>;

    public townprojectIds: number[];

    public townprojects: Array<TownProject>;

    project_notifs: boolean;

    iscommerce: boolean;

    isaddresses: boolean;

    constructor() {
    }

}
