import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from '../services/auth/auth.service';
import {Storage} from '../storage.class';
import {ActivatedRoute, Router} from '@angular/router';
import {FirebaseService} from '../firebase.service';
import {CordovaService} from '../cordova.service';
import {TownService} from '../services/town/town.service';
import {TownProjectService} from '../services/townproject/town-project.service';
import {Routenames} from '../route-names.enum';
import {CodaltComponent} from '../codalt.component';
import {ConfirmDialogService} from '../services/confirm-dialog-service/confirm-dialog.service';
import {Utils} from '../utils.class';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent extends CodaltComponent implements OnInit, OnDestroy {

    user = {email: '', password: ''};

    message = null;
    usertype = null;
    register = Storage.getRegister();
    townproject = null;
    townprojects: { id: number; name: string; town_name: string; }[];
    Routenames = Routenames;

    constructor(private authService: AuthService,
                private router: Router,
                private route: ActivatedRoute,
                private confirmDialog: ConfirmDialogService,
                private firebaseService: FirebaseService,
                private townService: TownService,
                private townProjectService: TownProjectService) {
        super();
    }

    ngOnInit() {
        CordovaService.setBackbuttonActionUrl('exit');

        this.townProjectService.getConsumerProjects().subscribe(response => {
            this.townprojects = response.data;
        });
        this.subscriptions.add(this.route.params.subscribe(params => {
            if (params['token']) {
                this.authService.logout();
                this.authService.refresh(params['token']).subscribe(() => {
                    this.router.navigate([Routenames.projects], {replaceUrl: true});
                }, error => {
                    if (Storage.getUserToken()) {
                        this.authService.refresh().subscribe(() => {
                            this.router.navigate([Routenames.projects], {replaceUrl: true});
                        }, error => {
                            this.invalidToken();
                        });
                    } else {
                        this.invalidToken();
                    }
                });
            }
        }));
    }

    emailChange() {
        this.user.email = this.user.email.trim();
    }

    invalidToken() {
        this.confirmDialog.confirm(
            'Ongeldige link',
            'De gebruikte links is niet (meer) geldig.',
            'Nieuwe link versturen',
            'Uitloggen'
        ).then(() => {
            const parsedJwt = Utils.parseJwt(this.route.snapshot.params['token']);
            this.authService.loginEmailWithId(parsedJwt['sub']).subscribe(() => {
                this.router.navigateByUrl('/');
                this.confirmDialog.confirm(
                    'E-mail verstuurd',
                    'Er is een nieuwe link verstuurd naar uw e-mailadres',
                    'Sluiten',
                    null
                ).then(() => {
                }, () => {
                    this.router.navigateByUrl('/logout');
                });
            });
        }, () => {

        });
    }

    ngOnDestroy() {
        CordovaService.setBackbuttonActionUrl('/');
    }

    login(): void {
        this.authService.login(this.user['email'], this.user.password).subscribe((response) => {

            if (response?.user?.group === 'CONSUMER') {
                this.router.navigate([Routenames.projects]);
            } else if (response.user.towns?.length < 1) {
                alert('ERROR: Gebruiker heeft geen gemeenten!');
            } else if (response.user.towns?.length > 1) {
                this.router.navigate(['/towns']);
                this.townService.towns = response['user'].towns;
            } else {
                Storage.setTown(response['user'].towns[0]);
                this.townService.towns = response['user'].towns;
                this.router.navigate(['/']);
            }
        }, errorResponse => {
            this.message = 'E-mailadres of wachtwoord onjuist';
            if (errorResponse.message) {
                this.message = errorResponse?.message;
            }

        });
    }

    changeRegister() {
        this.usertype = null;
        this.townproject = null;
        Storage.setRegister(this.register);
    }


}
