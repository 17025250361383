import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {TownProjectPhaseActivity} from '../../town-project-phase-activity';
import {User} from '../../user.class';

@Injectable({
    providedIn: 'root'
})
export class TownProjectPhaseActivityService {

    constructor(private apiService: ApiService) {
    }

    assignees(townId: number) {
        return this.apiService.getCall$<User[]>(`townprojects/phases/activity/assignees/${townId}`);
    }

    save(activity: TownProjectPhaseActivity) {
        return this.apiService.postCall$(`townprojects/phases/activity`, activity);
    }

    delete(id: number) {
        return this.apiService.deleteCall$(`townprojects/phases/activity/${id}`);
    }

    saveOrder(activities: TownProjectPhaseActivity[]) {
        const order = activities.map(
            (a, index) => {
                const order = index + 1;
                a.order = order;
                return {id: a.id, order};
            }
        );
        return this.apiService.postCall$('townprojects/phases/activity/order', {order: order});
    }
}
