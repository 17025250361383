<form #workareaForm="ngForm">
    <div class="row">
        <div class="col-12 col-md-4">
            <mat-form-field class="w-100">
                <mat-select #drillLocation="ngModel" (valueChange)="inputChanged($event)"
                            [(ngModel)]="workarea.extra.drillLocation"
                            errorState="drillLocation.errors?.required && (drillLocation.dirty || workareaForm.submitted)"
                            name="drillLocation"
                            placeholder="Boorlocatie" required>
                    <mat-option [value]="undefined" disabled selected>Boorlocatie</mat-option>
                    <mat-option *ngFor="let type of fieldOptions['drillLocation']"
                                [value]="type">{{type}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 col-md-4">
            <mat-form-field class="w-100">
                <input [(ngModel)]="workarea.extra.lane" [matAutocomplete]="auto" matInput name="lane"
                       placeholder="Rijstrook"
                       type="text">
                <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let option of fieldOptions['lanes']" [value]="option">
                        {{option}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
        <div class="col-12 col-md-4">
            <mat-form-field class="w-100">
                <input [(ngModel)]="workarea.extra.drill_length" matInput name="drill_length"
                       placeholder="Lengte boorkern"
                       step="0.01" type="number">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <mat-form-field class="w-100">
                <textarea [(ngModel)]="workarea.comments"
                          cdkAutosizeMinRows="4"
                          matInput
                          name="comments" placeholder="Opmerkingen">
                </textarea>
            </mat-form-field>
        </div>
    </div>
</form>
