import {Component, OnInit} from '@angular/core';
import {User} from '../user.class';
import {FormGroup} from '@angular/forms';
import {UserService} from '../services/user/user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfirmModalService} from '../shared/confirm-modal.service';
import {AuthService} from '../services/auth/auth.service';
import {Routenames} from '../route-names.enum';


@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

    user: User;

    emailInUse = false;

    registered = false;
    submitDisabled = false;
    submitText = 'Registreren';

    fieldTranslations = {
        'name': 'Naam',
        'lastname': 'Achternaam',
        'street': 'Straat',
        'housenumber': 'Huisnummer',
        'zipcode': 'Postcode',
        'place': 'Plaats',
        'email': 'E-mailadres',
        'phone': 'Telefoonnummer'
    };

    constructor(private userService: UserService,
                private router: Router,
                private confirmModal: ConfirmModalService,
                private authService: AuthService,
                private route: ActivatedRoute) {
        this.user = new User();

    }

    variableIsPresent(variable: any) {
        if (typeof variable === 'undefined' || variable == null) {
            return false;
        }
        return variable.length > 0;
    }

    ngOnInit() {
        const projectId = +this.route.snapshot.params['projectId'];
        if (projectId) {
            this.user.townprojectIds = [projectId];
        } else {
            this.router.navigate([Routenames.login]);

        }
    }

    save(form: FormGroup) {

        if (!form.valid) {

            const controls = form.controls;
            let errorMessage = '<b>Verbeter de volgende velden:</b><br>';

            for (const name in controls) {
                if (controls[name].invalid) {
                    errorMessage += `${this.fieldTranslations[name] || name} is verplicht<br>`;
                }
            }

            this.confirmModal.showModal('Controleer je invoer', errorMessage, 'Oke', null);

            return;
        }

        this.submitDisabled = true;
        this.submitText += '...';

        this.userService.registerUser(this.user).then(response => {
            this.emailInUse = false;
            this.submitDisabled = false;
            this.submitText = 'Registreren';
            this.registered = true;
        }, error => {
            this.submitDisabled = false;
            this.submitText = 'Registreren';

            if (error['message']['status'] == 409) {
                this.emailInUse = true;
                this.confirmModal.showModal('Email al in gebruik', 'Dit email adres is al in geruik. ' +
                    'Gebruik wachtwoord vergeten om uw wachtwoord op te vragen.', 'Oke', null);
            }
        });
    }

}
