import {Injectable} from '@angular/core';
import {ApiService} from '../../services/api/api.service';
import {Message} from './message';

@Injectable()
export class MessageService {

    constructor(private apiService: ApiService) {
    }

    public create(message: Message): Promise<Message> {
        return this.apiService.postCall('message', message);
    }

    public update(message: Message): Promise<Message> {
        return this.apiService.patchCall('message', message);
    }

    public delete(messageId: number): Promise<boolean> {
        return this.apiService.deleteCall('message/' + messageId);
    }

    public get(messageId: number): Promise<Message> {
        return this.apiService.getCall('message/' + messageId);
    }

    public getList(skipFirst: number, numberOfItemsEachTime: number): Promise<Array<Message>> {
        return this.apiService.getCall(`messages/${skipFirst}/${numberOfItemsEachTime}`);
    }
}
