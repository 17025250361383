import {Directive, ElementRef, forwardRef, HostListener, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

const DECIMAL_INPUT_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DecimalInputDirective),
    multi: true
};

const KeyCodes = {
    Comma: 44,
    Dash: 45,
    Dot: 46
};

@Directive({
    selector: '[appDecimalInput]',
    providers: [DECIMAL_INPUT_VALUE_ACCESSOR]
})
export class DecimalInputDirective implements ControlValueAccessor, OnInit {
    private decimals: number;
    private allowNegativeValues: boolean;
    // 48 - 57 = 0-9 / 44 = , / 46 = . / 45 = -
    private numberKeyCodes = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57];
    private allowedKeyCodes: number[];
    private onChangeCallback: (_: any) => void;
    private onTouchedCallback: () => void;

    constructor(private elementRef: ElementRef) {
        this.onChangeCallback = () => void 0;
        this.onTouchedCallback = () => void 0;
    }

    @Input() set decimalInput(decimals: string | number) {
        this.decimals = typeof decimals === 'string' ? +decimals : decimals;
    }

    @Input() get allowNegative() {
        return this.allowNegativeValues;
    }

    set allowNegative(value: boolean) {
        this.allowNegativeValues = value;
        if (!this.allowedKeyCodes) {
            return;
        }

        const hasKeyCode = this.allowedKeyCodes.some(key => key === KeyCodes.Dash);
        if (value && !hasKeyCode) {
            this.allowedKeyCodes.push(KeyCodes.Dash);
        } else if (hasKeyCode) {
            this.allowedKeyCodes.splice(this.allowedKeyCodes.indexOf(KeyCodes.Dash), 1);
        }
    }

    ngOnInit() {
        // only allow ',' and '.' if decimals amount is gt 0
        if (this.decimals) {
            this.allowedKeyCodes = [...this.numberKeyCodes, KeyCodes.Dot, KeyCodes.Comma];
        } else {
            this.allowedKeyCodes = [...this.numberKeyCodes];
        }
        if (this.allowNegative) {
            this.allowedKeyCodes.push(KeyCodes.Dash);
        }
    }

    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }

    setDisabledState(isDisabled: boolean) {
        this.elementRef.nativeElement.disabled = isDisabled;
    }

    writeValue(value) {
        if (value === undefined || value === null) {
            value = '';
        }
        if (this.toNumber(value) === this.toNumber(this.elementRef.nativeElement.value)) {
            return;
        }
        this.setValue(this.format(value.toString()));
    }

    @HostListener('keypress', ['$event'])
    onChangeKeypress(event: KeyboardEvent) {
        const keyCode = event.keyCode;
        if (this.allowedKeyCodes.indexOf(keyCode) === -1) {
            event.preventDefault();
            return;
        }
        // only allow one occurence of '-' character and only at the beginning of the sequence
        if (keyCode === KeyCodes.Dash &&
            (this.elementRef.nativeElement.value.indexOf('-') > -1 || this.elementRef.nativeElement.value.length > 0)) {
            event.preventDefault();
            return;
        }
    }

    @HostListener('input', ['$event'])
    onInputChange(event: KeyboardEvent) {
        let value = event.target['value'];
        value = this.format(value);

        this.setValue(value);
        this.onChangeCallback(this.toNumber(value));
    }

    @HostListener('blur')
    onBlur() {
        // if the only existing character is the '-' character,
        // then clear input
        const value = this.elementRef.nativeElement.value;
        if (this.allowNegative && value.length === 1 && value[0] === '-') {
            this.elementRef.nativeElement.value = '';
        }

        this.setValue(this.format(this.elementRef.nativeElement.value), true);
        this.onTouchedCallback();
    }

    private toNumber(value) {
        if (typeof value !== 'string') {
            return value;
        }
        if (value === '') {
            return null;
        }
        return parseFloat(value.replace(',', '.')) || 0;
    }

    private setValue(value: string, onBlur = false) {
        const previousSelectionStart = this.elementRef.nativeElement.selectionStart;

        this.elementRef.nativeElement.value = value;

        if (!onBlur && this.elementRef.nativeElement.selectionEnd !== previousSelectionStart) {
            this.elementRef.nativeElement.selectionEnd = previousSelectionStart;
        }
    }

    private format(value: string) {
        value = value.replace(' ', '');
        const [number, decimals = ''] = this.splitValue(value);
        const allowedDecimals = this.decimals;

        if (allowedDecimals) {

            if (decimals) {
                return `${number},${decimals}`;
            }
            return value;

        } else {
            return `${value}`;
        }
    }

    private splitValue(value: string) {
        if (value.indexOf('.') !== -1) {
            return value.split('.');
        }
        return value.split(',');
    }

}
