<image-viewer *ngIf="workarea"
              [activeImage]="activeImage"
              [images]="workarea.images"
              [somenumber]="somenumber"
              [workarea]="workarea"
              [hideTime]="workarea.type === 'drillings'"
              objectKey='image_url'>
</image-viewer>
<div *ngIf="workarea" class="container-fluid" [class.readonly]="Utils.userIsGroup('READ')">
    <div class="row">
        <div class="images-container">
            <h4>Afbeeldingen</h4>

            <div *ngIf="loaded" class="scroll-container">
                <figure (click)="activeImage = i; somenumber=somenumber+1;"
                        *ngFor="let image of workarea.images; let i = index" class="pointer">
                    <div [style.background-image]="image.image_url + '?thumb=true'|safeBgUrl" class="image"></div>
                    <figcaption>
                        <span class="font-weight-bold">{{image?.status?.name}}</span><br>
                        <ng-container *ngIf="workarea.type !== 'drillings'">
                            {{image.updated_at | amLocale:'nl' | amDateFormat: 'D MMMM YYYY \o\\m HH:mm'}}<br>
                        </ng-container>
                        <ng-container *ngIf="workarea.type === 'drillings'">
                            {{image.updated_at | amLocale:'nl' | amDateFormat: 'D MMMM YYYY'}}<br>
                        </ng-container>
                        {{image?.user?.name}}
                    </figcaption>
                </figure>
                <br><br><br><br><br><br>
            </div>
            <button (click)="uploadInput.click()" *ngIf="!Utils.userIsGroup('READ') && !isCordova" color="primary" mat-raised-button>
                <input #uploadInput (change)="uploadFile($event)" hidden multiple type="file">
                <mat-icon class="icon-camera mr-1"></mat-icon>
                Foto
                <span class="hidden-sm-down"> toevoegen</span>
            </button>
            <button (click)="takePhoto()" *ngIf="!Utils.userIsGroup('READ') && isCordova" color="primary" mat-raised-button>
                <mat-icon class="icon-camera mr-1"></mat-icon>
                Foto
                <span class="hidden-sm-down"> toevoegen</span>
            </button>
        </div>
        <div class="scroll-container">
            <div class="details-container">
                <div class="row">
                    <div class="col-12">
                        <div class="detail-title">
                            <app-back-button backAction="/towns/{{workarea.town_id}}"
                                             [queryParams]="{movePushpin: null, newLat: null, newLng: null}"
                                             backText="<b>{{workarea.number}}</b> Gegevens"></app-back-button>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-9">
                                <app-processbar *ngIf="!loaded"></app-processbar>
                            </div>
                        </div>
                        <div class="row justify-content-end mt-1">
                            <button mat-button (click)="openNavi()" class="col-6 col-md-auto mb-2">
                                <i class="fas fa-map-marked"></i> Navigeer
                            </button>
                            <button mat-button (click)="movePushpin()" class="col-6 col-md-auto mb-2" *ngIf="!Utils.userIsGroup('READ')">
                                <i class="fas fa-location-arrow"></i> Verplaatsen
                            </button>
                            <button mat-button (click)="history()" *ngIf="Storage.getUser().group === 'ADMIN'" class="col-6 col-md-auto mb-2">
                                <i class="fas fa-history"></i> Wijzigingen
                            </button>
                            <button mat-button (click)="pdf()" *ngIf="Storage.getUser().group === 'ADMIN'" class="col-6 col-md-auto mb-2">
                                <i *ngIf="generatingReport" class="fas fa-circle-notch fa-spin"></i>
                                <i *ngIf="!generatingReport" class="fas fa-file-pdf"></i> PDF
                            </button>
                        </div>
                        <form #workareaForm="ngForm" *ngIf="loaded && fieldOptions && workarea">
                            <app-workarea-naw (formValid)="isFromValid($event, 'naw')"
                                              [projects]="projects"
                                              [workarea]="workarea"></app-workarea-naw>
                            <app-workarea-vogelzang
                                (formValid)="isFromValid($event, 'vogelzang')"
                                *ngIf="townType == 'vogzang'"
                                [fieldOptions]="fieldOptions"
                                [statuses]="statuses"
                                [workarea]="workarea">
                            </app-workarea-vogelzang>
                            <app-workarea-houthaven
                                (formValid)="isFromValid($event, 'houthaven')"
                                *ngIf="townType == 'houthaven'"
                                [fieldOptions]="fieldOptions"
                                [statuses]="statuses"
                                [workarea]="workarea">
                            </app-workarea-houthaven>
                            <app-workarea-general
                                (formValid)="isFromValid($event, 'general')"
                                *ngIf="townType == 'general'"
                                [fieldOptions]="fieldOptions"
                                [statuses]="statuses"
                                [workarea]="workarea">
                            </app-workarea-general>
                            <app-workarea-blvc
                                (formValid)="isFromValid($event, 'blvc')"
                                *ngIf="townType == 'blvc'"
                                [fieldOptions]="fieldOptions"
                                [statuses]="statuses"
                                [workarea]="workarea">
                            </app-workarea-blvc>
                            <app-workarea-straatwerk
                                (formValid)="isFromValid($event, 'straatwerk')"
                                *ngIf="townType == 'straatwerk'"
                                [fieldOptions]="fieldOptions"
                                [statuses]="statuses"
                                [workarea]="workarea">
                            </app-workarea-straatwerk>
                            <app-workarea-drill
                                (formValid)="isFromValid($event, 'drillings')"
                                *ngIf="townType == 'drillings'"
                                [fieldOptions]="fieldOptions"
                                [workarea]="workarea"
                            ></app-workarea-drill>
                            <div class="row mb-5 pb-5">
                                <div [class.col-md-4]="workarea.type === 'drillings'" [class.col-md-6]="workarea.type !== 'drillings'"
                                     class="col-12">
                                    <div class="form-group">
                                        <div [routerLink]="['/towns',workarea.town_id]" [queryParams]="{movePushpin: null, newLat: null, newLng: null}"
                                             class="btn btn-outline-danger btn-block z-depth-0"
                                             queryParamsHandling="merge">Terug naar kaart
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="workarea.type === 'drillings' && !Utils.userIsGroup('READ')" [class.col-md-4]="workarea.type === 'drillings'"
                                     [class.col-md-6]="workarea.type !== 'drillings'" class="col-12">
                                    <div class="form-group">
                                        <div (click)="delete()"
                                             class="btn btn-danger btn-block z-depth-0"
                                             queryParamsHandling="merge">Verwijderen
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="!Utils.userIsGroup('READ')" [class.col-md-4]="workarea.type === 'drillings'"
                                     [class.col-md-6]="workarea.type !== 'drillings'"
                                     class="col-12">
                                    <div class="form-group">
                                        <button (click)="save()"
                                                class="btn btn-success btn-block z-depth-0">Opslaan
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #locationsModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">
            Kies een adres<br>
            <i>U kunt dit adres later aanpassen</i>
        </h4>
        <button (click)="modalReflocationsModal.hide()" aria-label="Close" class="close pull-right" type="button">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <ul class="list-group">
            <li (click)="setLocation(adr.address)" *ngFor="let adr of addresses" class="list-group-item">
                {{adr.address.addressLine}}<br> {{adr.address.postalCode}} {{adr.address.adminDistrict2}}
            </li>
        </ul>
    </div>
</ng-template>
