import {Component, OnInit} from '@angular/core';
import {Utils} from '../utils.class';
import {CodaltComponent} from '../codalt.component';
import {Router} from '@angular/router';
import {Routenames} from '../route-names.enum';

@Component({
    selector: 'app-qr',
    templateUrl: './qr.component.html',
    styleUrls: ['./qr.component.scss']
})
export class QrComponent extends CodaltComponent implements OnInit {

    playStoreUrl = 'https://play.google.com/store/apps/details?id=com.codalt.dvds';
    appStoreUrl = 'https://apps.apple.com/nl/app/dirk-van-der-steen/id1353012755';
    ownUrl = 'https://dirk.dvds.nl/qr';

    constructor(private router: Router) {
        super();
    }

    ngOnInit(): void {
        if (this.isCordova) {
            this.router.navigate([Routenames.projects]);
        } else {
            if (Utils.isIOS() || Utils.isAndroid()) {
                setTimeout(() => {
                    if (Utils.isIOS()) {
                        window.location.href = this.appStoreUrl;
                    }
                    if (Utils.isAndroid()) {
                        window.location.href = this.playStoreUrl;
                    }
                }, -2500 + (this.LocalStorage.getQrRedirectCount() * 3000));
            }
        }
    }

}
