<div class="container-fluid">
    <div class="row justify-content-sm-center">
        <div class="col-12 col-sm-10 col-md-9 col-lg-7 col-xl-6 pt-2">

            <ng-container *ngIf="!loading">
                <div class="text-center">
                    <ng-container *ngIf="success">
                        <h2>
                            De inlogpogingen zijn gereset
                        </h2>
                        <button class="mt-4" color="primary" mat-raised-button routerLink="/{{Routenames.login}}">
                            Klik hier om in te loggen
                        </button>
                    </ng-container>
                    <h2 *ngIf="!success">
                        Incorrecte reset token
                    </h2>
                </div>
            </ng-container>
            <ng-container *ngIf="loading">
                <app-processbar></app-processbar>
            </ng-container>
        </div>
    </div>
</div>
