import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Storage} from '../storage.class';
import {TownService} from '../services/town/town.service';
import {Subscription} from 'rxjs';
import {Town} from '../town.class';
import {AuthService} from '../services/auth/auth.service';

@Component({
    selector: 'app-town-detail',
    templateUrl: './town-detail.component.html',
    styleUrls: ['./town-detail.component.scss']
})
export class TownDetailComponent implements OnInit, OnDestroy {


    childActive = false;
    town: Town;
    screenshot = false;
    child: any;
    childIsWorkarea = false;
    hideMap = false;
    private subscriptions = new Subscription();

    constructor(private router: Router,
                private cdref: ChangeDetectorRef,
                private route: ActivatedRoute,
                private authService: AuthService,
                private townService: TownService) {
    }

    childActivate() {
        this.childActive = true;
        this.cdref.detectChanges();
    }

    childDeactivate() {
        this.childActive = false;
        this.cdref.detectChanges();
    }

    childChanged(childComponent: Component) {
        this.childIsWorkarea = !!childComponent['isWorkareaComponent'];
        this.cdref.detectChanges();
    }

    ngOnInit() {
        this.screenshot = this.route.snapshot.url.splice(0, 1)[0].path == 'towns-screenshot';
        if (this.screenshot) {
            this.hideMap = ['list', 'table', 'report'].indexOf(this.route.snapshot?.firstChild?.url[0]?.path) !== -1;
            document.body.classList.add('screenshot');
        }
        this.subscriptions.add(this.route.params.subscribe(params => {
            const townId = +params['townId'];
            if (params['token']) {
                Storage.setUserToken(params['token']);
                this.authService.getUserDetails().subscribe(user => {
                    Storage.setUser(user.data);
                    this.loadTown(townId);
                });
            } else {
                this.loadTown(townId);
            }
        }));
    }

    loadTown(id) {
        this.townService.getSingle(id).then(town => {
            this.town = town;
            Storage.setTown(town);
        });
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
}
