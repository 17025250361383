import {Component, Input, OnInit} from '@angular/core';
import {Town} from '../town.class';
import {TownService} from '../services/town/town.service';
import {ConfirmModalService} from '../shared/confirm-modal.service';
import {CodaltComponent} from '../codalt.component';

@Component({
    selector: 'app-town-logo',
    templateUrl: './town-logo.component.html',
    styleUrls: ['./town-logo.component.scss']
})
export class TownLogoComponent extends CodaltComponent implements OnInit {

    @Input()
    town: Town;

    constructor(private townService: TownService,
                private confirmModalService: ConfirmModalService) {
        super();
    }

    ngOnInit(): void {
    }

    uploadLogo(event) {
        event.stopPropagation();
        event.preventDefault();
        const file = event.srcElement.files[0];
        if (file.type === 'image/svg+xml') {
            const reader = new FileReader();
            reader.readAsText(file, "UTF-8");
            reader.onload = (evt) => {
                this.town.icon = `data:image/svg+xml;base64,${window.btoa(evt.target.result + '')}`;
                this.townService.saveTownIcon(this.town.icon).subscribe();
            };
            reader.onerror = (evt) => {
            };
        } else if (['image/jpeg', 'image/png'].indexOf(file.type) !== -1) {
            const img = new Image();
            img.src = window.URL.createObjectURL(file);
            img.onload = () => {
                const factor = 200 / Math.max(img.naturalWidth, img.naturalHeight);
                const width = factor < 1 ? img.naturalWidth * factor : img.naturalWidth;
                const height = factor < 1 ? img.naturalHeight * factor : img.naturalHeight;
                const elem = document.createElement('canvas');
                elem.width = width;
                elem.height = height;
                const ctx = elem.getContext('2d');
                ctx.drawImage(img, 0, 0, width, height);
                if (file.type === 'image/png') {
                    this.town.icon = ctx.canvas.toDataURL('image/png');
                } else {
                    this.town.icon = ctx.canvas.toDataURL('image/jpeg', 0.7);
                }
                this.townService.saveTownIcon(this.town.icon).subscribe();
            };
        } else {
            this.confirmModalService.showModal(
                'Verkeerd bestand',
                'Dit bestandsformaat wordt niet ondersteund',
                'Oké'
            ).then(() => {

            }, () => {

            });
        }
    }

}
