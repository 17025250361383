import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {User} from '../../user.class';
import {UserService} from '../../services/user/user.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-user-detail',
    templateUrl: './user-detail.component.html',
    styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent implements OnInit, OnDestroy {

    backTo: string;
    userId: number;
    user: User;

    private subscriptions = new Subscription();

    constructor(private route: ActivatedRoute, private userService: UserService) {
    }

    ngOnInit() {

        this.subscriptions.add(this.route.params.subscribe(params => {
            this.userId = +params['id'];
            this.backTo = params['backTo'];
        }));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
