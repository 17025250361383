import {Component, OnInit} from '@angular/core';
import {AuthService} from '../services/auth/auth.service';
import {Message} from '../message.class';
import {ConfirmModalService} from '../shared/confirm-modal.service';
import {FormGroup} from '@angular/forms';

@Component({
    selector: 'app-request-restore-password',
    templateUrl: './request-restore-password.component.html',
    styleUrls: ['./request-restore-password.component.scss']
})
export class RequestRestorePasswordComponent implements OnInit {

    user = {email: ''};
    buttonDisabled = false;
    message: Message;
    buttonText = 'Aanvragen';
    hideControls = false;

    constructor(private authService: AuthService,
                private confirmModal: ConfirmModalService) {
    }

    ngOnInit() {
        this.authService.logout().subscribe();
    }

    onSubmit(form: FormGroup) {

        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const emailValid = re.test(String(this.user.email).toLocaleLowerCase());

        if (!form.valid || !emailValid) {
            this.confirmModal.showModal('Oeps...',
                'Dit is geen geldig e-mailadres', 'Oke', null).then(() => {
            });
            return;
        }

        this.buttonDisabled = true;
        this.buttonText = 'Email aan het versturen...';
        this.authService.requestRestorePassword(this.user.email).subscribe((response) => {
            this.emailIsSend();
        }, errorResponse => {

            this.buttonText = 'Aanvragen';
            this.message = new Message(errorResponse?.message, true);
            this.buttonDisabled = false;

        });
    }

    private emailIsSend() {
        this.message = new Message('Controleer uw ' + this.user.email +
            ' email voor instructies om uw wachtwoord te resetten. Als u niets binnen krijgt ' +
            'binnen 15 minuten, controleer de spam map van uw inbox');
        this.buttonText = 'Email verzonden!';
        this.hideControls = true;
    }
}
