<div class="header-container">
    <div class="container-fluid">
        <app-back-button backAction="/news" backText="Maak een nieuw nieuwsbericht"></app-back-button>
    </div>
</div>
<div class="content-container">
    <div class="container">
        <div class="row justify-content-md-center">
            <div class="col-12 col-md-10 col-xl-8 pt-2">
                <form>
                    <div class="row">
                        <div class="col-12">
                            <mat-form-field class="w-100">
                                <input matInput type="text" [(ngModel)]="message.title" name="title" id="title"
                                       placeholder="Titel">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <mat-form-field class="w-100">
                                <mat-label>Bericht</mat-label>
                                <textarea [(ngModel)]="message.text" matInput cdkTextareaAutosize cdkAutosizeMinRows="2"
                                          name="text"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <mat-checkbox [(ngModel)]="message.published" name="published" [value]="true">
                                    Publiceer
                                </mat-checkbox>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="float-right">
                                <button mat-button color="warn" (click)="delete()" *ngIf="message.id > 0" class="mr-1">
                                    <span class="d-none d-md-block">Nieuwsbericht verwijderen</span>
                                    <span class="d-block d-md-none">Verwijderen</span>
                                </button>
                                <button mat-raised-button color="primary" (click)="save()">
                                    Opslaan
                                </button>
                            </div>
                            <button mat-raised-button color="warn" tabindex="0" routerLink="/news">
                                Annuleren
                            </button>

                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>