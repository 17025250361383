<div class="header-container">
    <div class="container-fluid">
        <app-back-button backAction="/news" backText="{{message?.title}}"></app-back-button>
    </div>
</div>
<div class="content-container" *ngIf="message.text">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="form-group float-right">
                    <a routerLink="/news/edit/{{message.id}}" *ngIf="Utils.userHasRights('GEMEENTE')">
                        <i class="fas fa-pencil"></i> Nieuwsbericht aanpassen
                    </a>
                </div>
            </div>
        </div>
        <div class="row justify-content-md-center">
            <div class="col-12 col-lg-10 col-xl-8">
                <div class="row">
                    <div class="col-12">
                        <p>
                            Datum: {{message.publish_date || message.updated_at |  amLocale:'nl' | amDateFormat: 'D MMMM YYYY \o\\m HH:mm'}}
                        </p>
                        <p [innerHTML]="message.text | nl2br">
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
