import {Component, OnDestroy, OnInit} from '@angular/core';
import {Timeline} from '../../classes/timeline.class';
import {TimelineService} from '../../services/timeline.service';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';
import {Routenames} from '../../route-names.enum';
import {CodaltComponent} from '../../codalt.component';
import {MatDialog} from '@angular/material/dialog';
import {CordovaService} from '../../cordova.service';
import {TimelineImage} from '../../classes/timeline-image.class';
import {ImageViewerData} from '../../documents/image-viewer-dialog/image-viewer-data';
import {ImageViewerDialogComponent} from '../../documents/image-viewer-dialog/image-viewer-dialog.component';
import {Settings} from '../../settings.class';
import {Storage} from '../../storage.class';
import {TimelineDocument} from '../../classes/timeline-document';

declare var cordova;

@Component({
    selector: 'app-timeline-detail',
    templateUrl: './timeline-detail.component.html',
    styleUrls: ['./timeline-detail.component.scss']
})
export class TimelineDetailComponent extends CodaltComponent implements OnInit, OnDestroy {

    loading = false;
    timeline: Timeline;
    projectId;
    townId;

    constructor(private timelineService: TimelineService,
                private location: Location,
                private router: Router,
                private confirmDialogService: ConfirmDialogService,
                private matDialog: MatDialog,
                private confirmDialog: ConfirmDialogService,
                private cordovaService: CordovaService,
                private route: ActivatedRoute) {
        super();
    }

    ngOnInit(): void {
        this.projectId = this.route.snapshot.params?.projectId;
        this.townId = this.route.snapshot.params?.townId;
        this.subscriptions.add(this.route.params.subscribe((params) => {
            this.getTimeline(params['id']);
        }));
    }

    delete() {
        this.confirmDialog.confirm('Verwijderen',
            `Weet je zeker dat je dit artikel wilt verwijderen?`, 'Ja', 'Nee').then(() => {
            this.timelineService.delete(this.timeline.id).subscribe(() => {
                this.router.navigateByUrl(`towns/${this.timeline?.town_project?.town_id}/projects/${this.timeline?.townproject_id}/${Routenames.timeline}`);
            });
        }, () => {
        });
    }

    openDocument(doc: TimelineDocument) {
        const url = Settings.API_ENDPOINT +
            'file/thumb?secure=timeline' +
            '&path=/' + this.timeline.id + '/' + doc.path +
            '&access_token=' + Storage.getUserToken();
        if (typeof cordova !== 'undefined') {
            window['PreviewAnyFile'].previewPath(
                win => console.log("open status", win),
                error => console.error("open failed", error),
                url, {name: 'file.pdf'}
            );
        } else {
            window.open(url);
        }
    }

    openImage(image: TimelineImage) {
        this.matDialog.open(ImageViewerDialogComponent, {
            panelClass: 'image-viewer-dialog',
            data: {
                images: this.timeline.images,
                secure: 'timeline',
                viewIndex: this.timeline.images.indexOf(image)
            } as ImageViewerData
        });

    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private getTimeline(slug) {
        this.timelineService.get(slug).subscribe((timeline) => {
            this.timeline = timeline.data;
            if (new Date(this.timeline.publish_date).getTime() > new Date().getTime()) {
                this.cordovaService.setBackbuttonAction(() => {
                    this.router.navigateByUrl(`towns/${this.timeline?.town_project?.town_id}/projects/${this.timeline?.townproject_id}/${Routenames.timeline}/toekomst`);
                });
            }
            this.location.replaceState(`towns/${this.timeline?.town_project?.town_id}/projects/${this.timeline?.townproject_id}/${Routenames.timeline}/${timeline.data.slug}`);
        }, error => {
            if (!this.isCordova) {
                this.confirmDialogService.confirm('Fout', error.message, 'Terug naar overzicht', null).then(() => {
                    if (this.townId) {
                        this.router.navigateByUrl(`towns/${this.townId}/projects/${this.projectId}/${Routenames.timeline}`);
                    } else {
                        this.router.navigateByUrl(`/${Routenames.timeline}`);
                    }
                });
            } else {
                if (this.townId) {
                    this.router.navigateByUrl(`towns/${this.townId}/projects/${this.projectId}/${Routenames.timeline}`);
                } else {
                    this.router.navigateByUrl(`/${Routenames.timeline}`);
                }
            }
        });
    }
}
