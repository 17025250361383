<div class="pdf-view-container">
    <div class="dialog-close">
        <div class="title">
            {{name}}
        </div>
        <div (click)="close()" class="fab small active" id="btn-cancel">
            <i class="fas fa-times"></i>
        </div>
    </div>
    <iframe id="PDFviewer" style="width: 100%; height: 100%; text-align: center;"></iframe>
</div>
