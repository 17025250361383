<div class="dialog-container">
    <h1 class="condensed dialog-title">
        Werkgebied gegevens
    </h1>
    <div class="dialog-content">
        <form [formGroup]="form" class="w-100">

                    <mat-form-field class="w-100">
                        <input formControlName="street" matInput
                               name="street" placeholder="Naam" required
                               type="text">
                    </mat-form-field>

                    <mat-form-field class="w-100">
                        <mat-select formControlName="status_id"
                                    name="status_id"
                                    placeholder="Status">
                            <mat-option *ngFor="let status of statuses" [value]="status.id">
                                {{status.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

        </form>

    </div>
    <div class="d-flex flex-wrap">
        <button [mat-dialog-close]="false" mat-button>Annuleren</button>
        <div class="spacer"></div>
        <button (click)="save()" [disabled]="saving || !form.valid" cdkFocusInitial color="primary" mat-raised-button>
            Opslaan <i *ngIf="saving" class="fas fa-circle-notch fa-spin"></i>
        </button>
    </div>
</div>
