import {Component, OnInit} from '@angular/core';
import {User} from '../../user.class';
import {Storage} from '../../storage.class';
import {UserService} from '../../services/user/user.service';
import {ConfirmModalService} from '../../shared/confirm-modal.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

    public user: User;

    constructor(private userService: UserService,
                private confirmModalService: ConfirmModalService,
                private router: Router) {
    }

    ngOnInit() {
        this.user = Storage.getUser();
    }

    save() {
        this.userService.changeUser(this.user).then((user: User) => {
            Storage.setUser(user);
            this.confirmModalService.showModal(
                'Wachtwoord',
                'Uw wachtwoord is gewijzigd!',
                'Oké', null).then(() => {
                this.router.navigate(['settings']);
            });
        }, errorResponse => {
            this.confirmModalService.showModal(
                'Fout',
                'Er is een fout opgetreden, uw wachtwoord is niet gewijzigt',
                'Oké', null);
        });
    }
}
