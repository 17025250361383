import {Injectable} from '@angular/core';
import {User} from '../../user.class';
import {ApiService} from '../api/api.service';

@Injectable()
export class UserService {

    constructor(private apiService: ApiService) {
    }

    public registerUser(user: User): Promise<User> {
        return this.apiService.postCall('user/register', user);
    }

    public changeUser(user: User): Promise<User> {
        return this.apiService.patchCall('user', user);
    }

    public getUser(userId: number): Promise<User> {

        return this.apiService.getCall('user', {userId: userId});
    }

    public getUsers(): Promise<Array<User>> {
        return this.apiService.getCall('users');
    }

    public getUserNames(): Promise<Object> {
        return this.apiService.getCall('usernames');
    }

    public deleteUser(userId: number): Promise<Object> {
        return this.apiService.deleteCall('user', {userId});
    }

    public getIp(): Promise<string> {
        return this.apiService.getCall('user/ip');
    }

    public saveSettings(settings: object): Promise<Object> {
        return this.apiService.postCall('user/settings', {settings: settings});
    }

    public accountActive(email: string) {
        return this.apiService.getCall$(`email-confirmed`, {email});
    }

    confirmRegistration(hash: string) {
        return this.apiService.getCall$('confirm-email', {hash});
    }

}
