import {FormControl} from '@angular/forms';

export function requiredConditional(fc: FormControl, value: any) {
    return (control: FormControl) => {
        if ((!control.value || (Array.isArray(control.value) && control.value.length < 1)) && fc.value === value) {
            return {required: true};
        }
        return null;
    };
}
