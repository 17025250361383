<form #workareaForm="ngForm">
    <div class="row">
        <div class="col-6">
            <mat-form-field class="w-100">
                <mat-select name="type" (valueChange)="inputChanged($event)"
                            [(ngModel)]="workarea.extra.damage" #type="ngModel"
                            required placeholder="Type">
                    <mat-option [value]="undefined" selected disabled>Type</mat-option>
                    <mat-option *ngFor="let type of fieldOptions['types']"
                                [value]="type">{{type}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-6">
            <mat-form-field class="w-100">
                <mat-select name="location_damage" (valueChange)="inputChanged($event)"
                            [(ngModel)]="workarea.extra.location_damage" #location_damage="ngModel"
                            required placeholder="Schade Locatie">
                    <mat-option [value]="undefined" selected disabled>Schade Locatie</mat-option>
                    <mat-option *ngFor="let type of fieldOptions['location_damage']"
                                [value]="type">{{type}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <mat-form-field class="w-100">
                <textarea [(ngModel)]="workarea.comments" name="comments"
                          matInput cdkTextareaAutosize cdkAutosizeMinRows="4"
                          placeholder="Opmerkingen"></textarea>
            </mat-form-field>
        </div>
    </div>
    <div class="row" *ngIf="Utils.userHasRights('GEMEENTE')">
        <div class="col-6">
            <mat-form-field class="w-100">
                <mat-select name="status" [(ngModel)]="workarea.status_id"
                            (ngModelChange)="statusChange($event)"
                            placeholder="Satus" required>
                    <mat-option *ngFor="let status of statuses" [value]="status.id">
                        {{status.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-6">
            <mat-form-field class="w-100">
                <mat-select name="priority" [(ngModel)]="workarea.extra.priority"
                            placeholder="Prioriteit">
                    <mat-option *ngFor="let priority of fieldOptions['priorities']" [value]="priority">
                        {{priority}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <mat-form-field class="w-100">
                <mat-select name="leader" [(ngModel)]="workarea.extra.leader"
                            placeholder="Verantwoordelijke projectleider">
                    <mat-option *ngFor="let leader of fieldOptions['leaders']" [value]="leader">
                        {{leader}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-6">
            <mat-form-field class="w-100">
                <mat-select name="action_holder" [(ngModel)]="workarea.extra.action_holder"
                            placeholder="Actiehouder">
                    <mat-option *ngFor="let action_holder of fieldOptions['action_holders']" [value]="action_holder">
                        {{action_holder}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <mat-form-field class="w-100">
                <input matInput type="text" [(ngModel)]="workarea.extra.vonumber" name="vonumber"
                       placeholder="Verrekenen op">
            </mat-form-field>
        </div>
    </div>

</form>