import {User} from './user.class';
import {TownProjectPhase} from './town-project-phase';

export class TownProjectPhaseActivity {
    id: number;
    townprojectphase_id: number;
    townProjectPhase: TownProjectPhase;
    user_id: number;
    user: User;
    name: string;
    deadline: Date;
    executed: Date;
    assignee: string;
    order: number;
    deleted_at?: Date;
}
