import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Utils} from '../../utils.class';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TownProject} from '../../town-project';
import {TownProjectService} from '../../services/townproject/town-project.service';

@Component({
    selector: 'app-town-project-description-dialog',
    templateUrl: './town-project-description-dialog.component.html',
    styleUrls: ['./town-project-description-dialog.component.scss']
})
export class TownProjectDescriptionDialogComponent implements OnInit {

    project: TownProject;

    form: FormGroup;

    constructor(private townProjectService: TownProjectService,
                private dialog: MatDialogRef<TownProjectDescriptionDialogComponent>,
                @Inject(MAT_DIALOG_DATA) data: {
                    project?: TownProject
                }) {
        this.project = data.project;
    }

    ngOnInit(): void {
        this.form = new FormGroup({
            description: new FormControl(this.project.description, Validators.required)
        });
    }

    save() {
        Utils.triggerValidationP(this.form).then(() => {
            Object.assign(this.project, this.form.value);
            delete this.project.users;
            this.townProjectService.saveProject(this.project).subscribe((data) => {
                this.dialog.close(data.data);
            });
        }, () => {
        });
    }
}
