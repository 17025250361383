<div class="modal-header">
    <h4 class="modal-title pull-left">Project beschrijving</h4>
    <button mat-dialog-close="" aria-label="Close" class="close pull-right" tabindex="-1" type="button">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div *ngIf="form" [formGroup]="form" class="modal-body">
    <simple-tiny [content]="form.get('description')"
                 elementId="description"
                 ngDefaultControl
                 propertyName="value"
    ></simple-tiny>
    <mat-error *ngIf="form.get('content')?.invalid">Content is een verplicht veld</mat-error>
</div>
<div class="modal-footer">
    <button mat-dialog-close color="primary" mat-button type="button">
        Sluiten
    </button>
    <div class="spacer"></div>
    <button (click)="save()" color="primary" mat-raised-button type="button">
        Opslaan
    </button>
</div>
