<div class="dialog-container">
    <h4 class="condensed dialog-title">
        Welk project wilt u volgen?
    </h4>
    <div class="dialog-content mt-3">
        <div *ngIf="!townprojects?.length" class="pb-4">
            <i>Je volgt al onze projecten al</i>
        </div>
        <mat-radio-group [(ngModel)]="townproject" class="mb-3 projectsradio">
            <div *ngFor="let project of townprojects" class="my-1 w-100">
                <mat-radio-button [value]="project">
                    {{project.name}}<br>
                    <small>{{project.town_name}}</small>
                </mat-radio-button>
            </div>
        </mat-radio-group>
        <button mat-stroked-button color="primary" (click)="dialogRef.close()">
            Annuleren
        </button>
        <button mat-raised-button color="primary" [disabled]="!townproject" class="float-right" (click)="addTownproject()">
            Toevoegen
        </button>
    </div>
</div>
