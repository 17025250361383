import {TownProject} from './town-project';
import {TownProjectPhaseReply} from './town-project-phase-reply';
import {TownProjectPhaseActivity} from './town-project-phase-activity';

export class TownProjectPhase {
    id: number;
    townproject_id: number;
    townProject: TownProject;
    name: string;
    start_date?: Date;
    end_date?: Date;
    done: boolean;
    order: number;
    town_project_phase_replies: TownProjectPhaseReply[];
    town_project_phase_activities: TownProjectPhaseActivity[];
    deleted_at?: Date;
}
