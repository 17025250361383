<div class="message-line">
    <div class="chat-info">
        <div class="info">
            <div class="title">{{chat?.name}} #{{chat?.id}}</div>
            <div class="users overflow-ellipsis">
                {{chat?.created_at | date:'EEEE d MMM yyyy H:mm'}} -
                <ng-container *ngFor="let user of chat.chat_users;  let last = last;">
                    {{user?.user?.name}}{{last ? '' : ', '}}
                </ng-container>
            </div>
        </div>
        <div class="actions">
            <ng-container *ngIf="selectedItems.size > 0">
                <button (click)="copyMessage($event)"
                        *ngIf="enableCopy"
                        mat-icon-button
                        matTooltip="Kopieër berichten">
                    <mat-icon class="fas fa-copy"></mat-icon>
                </button>
                <button (click)="deleteMessages($event)"
                        *ngIf="enableDelete"
                        mat-icon-button
                        matTooltip="Verwijder berichten">
                    <mat-icon class="fas fa-trash"></mat-icon>
                </button>
            </ng-container>
            <button (click)="openNavi()"
                    *ngIf="chatAdmin && chat?.lat > 0"
                    mat-icon-button
                    matTooltip="GPS-locatie tijdens aanmaken melding">
                <mat-icon class="fas fa-map"></mat-icon>
            </button>
            <button (click)="editChat()"
                    *ngIf="chatAdmin"
                    mat-icon-button
                    matTooltip="Melding aanpassen">
                <mat-icon class="fas fa-edit"></mat-icon>
            </button>
            <button routerLink="/{{Routenames.chat}}" class="close"
                    mat-icon-button>
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
    <div class="messages" *ngIf="datasource">
        <ng-container *uiScroll="let message of datasource; let i = index">
            <div (mousedown)="select($event, message)"
                 (press)="selectStart(message)"
                 (touchstart)="select($event,message)"
                 [class.deleted]="message.deleted_at"
                 [class.own]="LocalStorage.getUser().id === message.user_id"
                 [class.selected]="selectedItems && selectedItems.has(message.id)"
                 [class.last]="(i +2) === chatMessageService.totalCount && chatAdmin"
                 [class.unread]="lastRead < message.id" class="message">
                <div>
                    <div class="info">
                        <div *ngIf="message.user_id !== LocalStorage.getUser().id" class="name">
                            {{(chat.chat_users | byId : message.user_id : 'user_id')?.user?.name || 'D. van der Steen'}}
                        </div>
                        <div *ngIf="message.user_id === LocalStorage.getUser().id" class="name">
                            {{LocalStorage.getUser()?.group === 'ADMIN' ? 'D. van der Steen' : 'Jij'}}
                        </div>
                        <div></div>
                        <div class="date">
                            <ng-container *ngIf="!Utils.isToday(message.updated_at)">
                                <span class="d-none d-sm-block">
                                    {{message.updated_at | date:'d MMM yyyy H:mm'}}
                                </span>
                                <span class="d-block d-sm-none">
                                      {{message.updated_at | date:'d MMM H:mm'}}
                                </span>
                            </ng-container>
                            <ng-container *ngIf="Utils.isToday(message.updated_at)">
                                {{message.updated_at  | date:'H:mm'}}
                            </ng-container>
                        </div>
                    </div>
                    <div *ngIf="!(message.message | isBase64)" [innerHTML]="message.message" class="text">

                    </div>
                    <div (click)="openImage(message.message)"
                         *ngIf="(message.message | isBase64)"
                         [style.background-image]="message.message | safeBgUrl" class="image">

                    </div>
                </div>
            </div>
        </ng-container>
        <div *ngIf="chatAdmin" class="status-buttons">
            <p>
                Wijzig de status van deze melding naar:
            </p>
            <div>
                <button (click)="statusChange(status)"
                        *ngFor="let status of statuses | filter : chat.status_id : 'id' : true"
                        [style.background-color]="'#'+status.colorcode"
                        class="mr-2 ml-2"
                        color="primary"
                        mat-raised-button>
                    {{status.name}}
                </button>
            </div>
        </div>
    </div>
    <div class="d-flex composer">
        <div *ngIf="chat.status.releasenumber && !chatAdmin" class="flex-fill d-flex flex-column align-items-center">
            <i>Deze melding is afgehandeld door DvdSteen. Bent u nog niet tevreden? Dan kunt u deze melding heropenen.</i>
            <button (click)="reopen()" color="primary" mat-button>
                Melding heropenen
            </button>
        </div>
        <ng-container *ngIf="!chat.status.releasenumber">
            <form [formGroup]="form" class="flex-fill mr-2">
                <mat-form-field appearance="fill" class="w-100 mt-3">
                            <textarea #autosize="cdkTextareaAutosize"
                                      (keyup)="sendMessage($event)"
                                      cdkAutosizeMaxRows="5"
                                      cdkAutosizeMinRows="1"
                                      cdkTextareaAutosize
                                      formControlName="message"
                                      matInput
                                      placeholder="Typ een bericht"></textarea>
                    <mat-error [control]="form.get('message')"></mat-error>
                    <button *ngIf="!isCordova" class="curpoint" mat-icon-button matsuffix>
                        <div (click)="uploadInput.click()">
                            <mat-icon class="fas fa-image" color="primary"></mat-icon>
                            <input #uploadInput (change)="uploadFile($event)" [multiple]="true" hidden type="file">
                        </div>
                    </button>
                    <button (click)="camera($event)" *ngIf="isCordova" color="primary" mat-icon-button matsuffix>
                        <mat-icon class="fas fa-image" color="primary"></mat-icon>
                    </button>
                </mat-form-field>
            </form>
            <div class="d-flex align-items-center justify-content-center">
                <button (click)="sendMessage()" [disabled]="sending || form.invalid" color="primary" mat-mini-fab>
                    <svg-icon src="assets/images/icons/send.svg"></svg-icon>
                    <mat-icon *ngIf="sending" class="fas fa-circle-notch fa-spin"></mat-icon>
                </button>
            </div>
        </ng-container>
    </div>
</div>
