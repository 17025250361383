export class Town {

    public id: number = null;
    public area: any;
    public towntype: string;
    public code: string;
    public name: string;
    public nameshort: string;
    public CalamityPhoneNumber: string;
    public area_forced: boolean;
    public consumerTown: boolean;

    public icon: string;


    public created_at: Date;
    public updated_at: Date;
    public deleted_at: Date;


    constructor() {
    }

}
