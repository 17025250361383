<ng-container *ngIf="!icon">
    <button (click)="openModal()" *ngIf="!directUpload" [hidden]="hide" mat-raised-button type="button">
        {{multiple ? 'Bestanden selecteren' : 'Bestand selecteren'}}
    </button>
    <button *ngIf="directUpload" class="curpoint" mat-raised-button>
        <label style="margin: 0; padding: 0;">
            <ng-container>{{multiple ? 'Bestanden selecteren' : 'Bestand selecteren'}}</ng-container>
            <input #uploadInput (change)="uploadFile($event)" [multiple]="multiple" hidden type="file">
        </label>
    </button>
    <button (click)="openModal(null, true)" *ngIf="directCamera && !isIos" class="ml-2" mat-raised-button type="button">
        Camera
    </button>
</ng-container>
<ng-container *ngIf="icon">
    <button (click)="openModal()" *ngIf="!directUpload" [hidden]="hide" mat-icon-button type="button">
        <mat-icon class="{{icon}}"></mat-icon>
    </button>
    <div class="d-flex" *ngIf="directUpload && !directCamera">
        <button class="curpoint" mat-icon-button>
            <div (click)="uploadInput.click()" class="mr-1 mt-1">
                <mat-icon class="{{icon}}"></mat-icon>
                <input #uploadInput (change)="uploadFile($event)" [multiple]="multiple" hidden type="file">
            </div>
        </button>
        <button (click)="callback.emit(null)" *ngIf="remove" mat-icon-button type="button">
            <mat-icon class="fas fa-trash"></mat-icon>
        </button>
    </div>
    <ng-container *ngIf="directUpload && directCamera">
        <button (click)="chooseSource()" [hidden]="hide" mat-icon-button type="button">
            <mat-icon class="{{icon}}"></mat-icon>
        </button>
        <input #uploadInput id="uploadInput" (change)="uploadFile($event)" [multiple]="multiple" hidden type="file">
    </ng-container>
</ng-container>
