import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';

@Injectable()
export class AirtableService {

    constructor(private apiService: ApiService) {
    }

    getTable() {
        return this.apiService.getCall$<Airtable[]>(`airtable`);
    }

    getTableOmzet() {
        return this.apiService.getCall$<AirtableOmzet[]>(`airtable/omzet`);
    }


    getGemeenten() {
        return this.apiService.getCall$<{
            features: {
                properties: {
                    'GM_CODE': string,
                    'GM_NAAM': string,
                    'H2O': string,
                    'AANT_INW': number,
                    'AANTAL_HH': number,
                    'OPP_TOT': number,
                    'OPP_LAND': number,
                    'OPP_WATER': number,
                    'JRSTATCODE': string,
                    'JAAR': number,
                },
                geometry: {
                    coordinates: [number, number][][]
                }
            }[]
        }>(`airtable/gemeenten`);
    }

    getProvincies() {
        return this.apiService.getCall$<{
            features: {
                properties: {
                    statcode: string
                },
                geometry: {
                    coordinates: [number, number][][]
                }
            }[]
        }>(`airtable/provincies`);
    }

}

export interface Airtable {
    'id': string,
    'createdTime': Date,
    'fields': {
        'Naam': string,
        'Provincie': string,
        'KM weglengte': number,
        'Afstand vanaf Utrecht': number,
        'Status': string,
        'Onderhoudscontracten': string[],
        'Code': string,
        'Type': string,
        'Notes t\/m zomer 2022': string[],
        'Last Modified': string,
        'Resterende Looptijd Einde Contractduur': number,
        'Laatst aangepast variabele Status': string
    }
};

export interface AirtableOmzet {
    'id': string,
    'createdTime': Date,
    'fields': {
        'Aanbestedingswijze': string,
        'KM weglengte': number,
        'Code': string,
        'Type': string,
        'Naam': string,
        'Omzet 2019': number,
        'Omzet 2020': number,
        'Omzet 2021': number,
        'Omzet 2022': number,
        'Totale Omzet': number,
    }
};
