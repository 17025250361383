import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmDialogComponent} from './confirm-dialog/confirm-dialog.component';
import {CordovaService} from '../../cordova.service';

@Injectable({
    providedIn: 'root'
})
export class ConfirmDialogService {

    private prevBackFunction: Function;

    constructor(public dialog: MatDialog,
                private cordovaService: CordovaService) {
    }

    confirm(title, text, confirm = 'Oké', cancel = 'Annuleren'): Promise<any> {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            panelClass: 'confirm-dialog',
            disableClose: true,
            data: {
                title: title,
                text: text,
                confirm: confirm,
                cancel: cancel
            }
        });
        // this.prevBackFunction = this.cordovaService.getCurrentBackFunction();
        /*this.cordovaService.setBackbuttonAction(() => {
            dialogRef.close();
        });*/

        return new Promise<void>((resolve, reject) => {
            const subs = dialogRef.afterClosed().subscribe(result => {
                if (this.prevBackFunction) {
                    //this.cordovaService.setBackbuttonAction(this.prevBackFunction);
                }
                if (result) {
                    resolve();
                } else {
                    reject();
                }
                subs.unsubscribe();
            });
        });
    }
}
