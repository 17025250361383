<div class="controls">
    <div class="filter">
        <mat-form-field appearance="fill" class="search">
            <input #searchInput
                   (keyup)="changeSearch()"
                   [(ngModel)]="search"
                   autocomplete="off"
                   matInput
                   placeholder="#"
                   type="text">
        </mat-form-field>
        <mat-form-field *ngIf="statuses" appearance="fill" class="status">
            <mat-select (selectionChange)="changeLoadedStatuses()"
                        [(ngModel)]="statusIds"
                        [multiple]="true"
                        name="status"
                        placeholder="Statusen">
                <mat-option *ngFor="let status of statuses" [value]="status.id">
                    {{status.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <button (click)="newChat()" color="primary" mat-icon-button>
        <img src="/assets/images/icons/townprojects/todo.svg">
    </button>
</div>
<div class="chats">
    <div *ngIf="!isConsumer && chats?.length === 0" class="text-center my-5">
        Er zijn geen meldingen gevonden
    </div>
    <div *ngIf="isConsumer && chats?.length === 0" class="text-center my-5 col">
        Je hebt nog geen contact met ons opgenomen.
        <br><br>
        Je kunt hier contact met ons opnemen als je een vraag, compliment of klacht hebt.
        Of als je meer informatie wilt over een project.
        <button (click)="newChat()" class="mt-3" mat-stroked-button>Direct contact opnemen</button>
        <br><br>

        <div class="pt-2" *ngIf="projects">
            <ng-container *ngIf="projects?.length === 1">
                <ng-container *ngIf="projects[0].contacts?.length > 0">
                    Wil je liever telefonisch contact? <br>
                </ng-container>
                <ng-container *ngIf="projects[0].contacts?.length === 1">
                    Neem contact op met<br>
                    {{projects[0].contacts[0]?.name}}<br>
                    <a class="mt-2" mat-stroked-button href="tel:{{projects[0].contacts[0]?.phone}}">
                        Tel: {{projects[0].contacts[0]?.phone}}
                    </a>
                </ng-container>
                <ng-container *ngIf="projects[0].contacts?.length > 1">
                    Neem contact op met een van onze medewerkers<br>
                    <button class="mt-2" mat-raised-button color="primary" routerLink="/{{Routenames.towns}}/{{projects[0].town_id}}/projects/{{projects[0].id}}/contacts">
                        Neem contact op
                    </button>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="projects?.length > 1">
                Wil je liever telefonisch contact? <br>
                Neem contact op met een van onze medewerkers<br>
                <button class="mt-2" mat-raised-button color="primary" routerLink="/{{Routenames.towns}}/{{projects[0].town_id}}/projects/contacts">
                    Neem contact op
                </button>
            </ng-container>
        </div>
    </div>
    <div [routerLink]="['/', Routenames.chat, chat.id]"
         *ngFor="let chat of chats"
         [class.active]="currentChat === chat"
         class="chat"
         matRipple>
        <div [class.unread]="chat.lastMessage && chat?.lastMessage?.id !== (chat.chat_users | byId:LocalStorage.getUser()?.id:'user_id')?.last_read">
            <div class="title">
                <ng-container *ngIf="!Utils.isToday(chat?.lastMessage?.updated_at)">
                    <time class="time d-none d-sm-block">
                        {{chat?.lastMessage?.updated_at | date:'d MMM yyyy H:mm'}}
                    </time>
                    <time class="time d-block d-sm-none">
                        {{chat?.lastMessage?.updated_at | date:'d MMM H:mm'}}
                    </time>
                </ng-container>
                <ng-container *ngIf="Utils.isToday(chat?.lastMessage?.updated_at)">
                    <time class="time">{{chat?.lastMessage?.updated_at | date:'H:mm'}}</time>
                </ng-container>
                {{chat.creator?.name}}
            </div>
            <div class="item">
                <div class="message overflow-ellipsis">
                    <i *ngIf="!chat.lastMessage">Nog geen berichten in deze chat</i>
                    <span *ngIf="chat.lastMessage">{{chat.lastMessage?.user?.name}}: </span>
                    <ng-container *ngIf="!(chat.lastMessage?.message | isBase64)">{{chat.lastMessage?.message}}</ng-container>
                    <i *ngIf="(chat.lastMessage?.message | isBase64)" class="fas fa-image"></i>
                </div>
                <div class="project-info">
                    <b>{{chat.name | chatTypePipe}} #{{chat.id}}</b>&nbsp;-&nbsp;
                    <div class="name overflow-ellipsis">
                        {{chat.town_project?.name}}
                    </div>
                    <div class="status">
                        <span [style.color]="'#'+chat.status?.colorcode">{{chat.status?.name}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="isConsumer && chats?.length > 0" class="text-center col">
        <button (click)="newChat()" class="mt-3" mat-stroked-button>Nieuwe melding maken</button>
    </div>
</div>
<ng-template #projectFilterSearchTemplate>
    <ng-select
        [(ngModel)]="searchProjects"
        (change)="changeSearch()"
        [closeOnSelect]="false"
        [hideSelected]="true"
        [items]="projects"
        [multiple]="true"
        bindLabel="name"
        bindValue="id"
        name="towns"
        placeholder="Filter Projecten"></ng-select>
</ng-template>
