export class FileItem {
    type: string;
    path: string;
    timestamp: number;
    size: number;
    dirname: string;
    basename: string;
    extension: string;
    filename: string;
}
