<div *ngIf="!hideHeader" class="mainheader user-select-none">
    <div class="container">
        <div class="row flex-nowrap">
            <div class="col align-items-center d-flex">
                <a *ngIf="backAction[0] != 'exit'" [routerLink]="backAction" class="back-action" queryParamsHandling="merge">
                    <div class="icon"></div>
                    <div class="text" [class.mobile-search]="showSearchMobile && !hideSearch">
                        <div [innerHTML]="activeMenuItem?.name | safeHtml">
                        </div>
                        <div *ngIf="params" class="town-and-project">
                            {{townMap.get(+params[RouteParamnames.townId])}}
                            <ng-container *ngIf="projectMap.get(+params[RouteParamnames.projectId])">-</ng-container>
                            {{projectMap.get(+params[RouteParamnames.projectId])}}
                        </div>
                    </div>
                </a>

                <a *ngIf="backAction[0] == 'exit'" class="back-action">
                    <div class="text" [class.mobile-search]="showSearchMobile && !hideSearch">
                        <div [innerHTML]="activeMenuItem?.name | safeHtml" *ngIf="activeMenuItem?.name">
                        </div>
                        <div *ngIf="params" class="town-and-project">
                            {{townMap.get(+params[RouteParamnames.townId])}}
                            <ng-container *ngIf="projectMap.get(+params[RouteParamnames.projectId])">-</ng-container>
                            {{projectMap.get(+params[RouteParamnames.projectId])}}
                        </div>
                    </div>
                </a>
                <div *ngIf="!hideSearch" class="flex-fill d-flex d-md-none justify-content-end">
                    <div class="search-button" (click)="showSearchMobile = !showSearchMobile">
                        <div class="search-icon"></div>
                    </div>
                </div>
            </div>
            <div *ngIf="searchService.searchTemplate" class="col col-sm-auto d-flex align-items-center justify-content-start text-right">
                <ng-container [ngTemplateOutlet]="searchService.searchTemplate"></ng-container>
            </div>
            <div *ngIf="!hideSearch" [class.d-none]="!showSearchMobile" class="d-md-flex col col-sm-auto align-items-center justify-content-start text-right search-input">
                <mat-form-field class="w-100">
                    <input #searchInput
                           [formControl]="fcSearch"
                           autocomplete="off"
                           matInput
                           placeholder="Zoeken... (Ctrl + F)"
                           type="text">
                </mat-form-field>
            </div>
            <div class="col col-auto d-none d-md-flex align-items-center" [class.d-flex]="hideSearch && !searchService.searchTemplate">
                <div class="logo-small" routerLink="/"></div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!hideHeader" class="mainbalk">
    <div class="container">
        <div class="row">
            <div class="spacer"></div>
        </div>
    </div>
</div>

<div class="router-outlet">
    <router-outlet></router-outlet>
</div>
<app-confirm-modal></app-confirm-modal>
<nav *ngIf="!hideHeader" class="nav">
    <div class="container px-1">
        <ul class="list">
            <ng-container *ngIf="!activeMenuItem?.hideMenuContent">
                <ng-container *ngFor="let bm of bottomMenu">
                    <li *ngIf="bm.visible()"
                        [class.active]="bm.active" [routerLink]="bm.routerLink" class="item">
                        <div class="link">
                            <svg-icon src="assets/svg/{{bm.icon}}.svg"></svg-icon>
                            <span class="text">{{bm.name}}</span>
                            <div *ngIf="bm.badge" class="badge">{{bm.badge}}</div>
                        </div>
                    </li>
                </ng-container>
            </ng-container>
        </ul>
    </div>
</nav>
