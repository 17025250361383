<div class="modal-header">
    <h4 class="modal-title pull-left">Activiteit </h4>
    <button aria-label="Close" class="close pull-right" mat-dialog-close="" tabindex="-1" type="button">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div *ngIf="form" [formGroup]="form" class="modal-body">
    <mat-form-field class="w-100">
        <mat-placeholder>Naam</mat-placeholder>
        <input [formControl]="fc.name" matInput type="text">
        <mat-error [control]="fc.name"></mat-error>
    </mat-form-field>
    <mat-form-field *ngIf="assignees" class="w-100">
        <mat-placeholder>Verantwoordelijke</mat-placeholder>
        <mat-select (selectionChange)="assigneeChange($event)" [value]="assignee">
            <mat-option *ngFor="let assignee of assignees" [value]="assignee">
                {{assignee.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field (click)="deadline.open()" class="w-100">
        <mat-label>Deadline</mat-label>
        <input [formControl]="fc.deadline" [matDatepicker]="deadline" matInput>
        <mat-datepicker-toggle [for]="deadline" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #deadline></mat-datepicker>
        <mat-error [control]="fc.deadline"></mat-error>
    </mat-form-field>
</div>
<div class="modal-footer">
    <button (click)="delete()" *ngIf="phaseActivity.id" color="warn" mat-button>
        Verwijderen
    </button>
    <div class="flex-fill"></div>
    <button color="primary" mat-button mat-dialog-close="" type="button">
        Sluiten
    </button>
    <button (click)="save()" [disabled]="form.invalid || saving" color="primary" mat-raised-button type="button">
        Opslaan <i *ngIf="saving" class="fas fa-circle-notch fa-spin"></i>
    </button>
</div>
