import {ChatUser} from './chat-user';
import {ChatMessage} from './chat-message';
import {User} from '../user.class';
import {TownProject} from '../town-project';
import {Status} from '../status.class';

export class Chat {
    id?: number;
    name: string;
    chat_users?: ChatUser[] = [];
    chatMessages?: ChatMessage[];
    creator?: User;
    lastMessage?: ChatMessage;
    lastReadMessageIndex?: number;
    updated_at?: Date;
    created_at?: Date;
    type: string;
    lng?: number;
    lat?: number;
    townproject_id: number;
    town_project?: TownProject;
    status?: Status;
    status_id?: number;
}
