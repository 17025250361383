export class Message {

    public error = false;
    public message = '';

    constructor(message: string, error = false) {
        this.message = message;
        this.error = error;
    }
}
