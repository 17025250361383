import {User} from './user.class';
import {TownProjectPhase} from './town-project-phase';

export class TownProjectPhaseReply {
    id: number;
    townprojectphase_id: number;
    townProjectPhase: TownProjectPhase;
    townprojectphase_activity_id: number;
    user_id: number;
    user: User;
    is_document: boolean;
    name: string;
    text: string;
    updated_at: Date;
}
