<section *ngIf="article" class="detail">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-lg-9">
                <app-codalt-image [path]="article.id + '/' + article.image"
                                  [adapt]="article.adapt_image"
                                  [thumb]="article.image_thumb"
                                  class="detail__image">
                </app-codalt-image>
                <div class="detail__block pb-0 pb-4">
                    <div class="row">
                        <div class="col-24 col-md order-1 order-md-0 mt-md-0 mt-3">
                            <h1 class="detail__title">
                                {{article.title}}
                            </h1>
                            <div class="detail__details">
                                <div class="detail__author">
                                    {{article?.town_project?.name}} - {{article.user.name}}
                                </div>
                                <time class="detail__date">
                                    {{article.publish_date | date:'EEEE d MMMM yyyy HH:mm'}}
                                </time>
                            </div>
                        </div>
                        <div class="col-24 col-md-auto order-0 order-md-1 d-flex justify-content-end">
                            <div>
                                <div class="likes d-flex">
                                    <div class="like" [class.active]="ownLike === ArticleLikeType.leuk" (click)="like(ArticleLikeType.leuk)" matTooltip="{{ArticleLikeType.leuk}}">
                                        <i class="fa-solid fa-thumbs-up"></i>&nbsp;&nbsp;{{(article.articleLikes | where : 'type' : ArticleLikeType.leuk)?.length}}
                                    </div>
                                    <div class="like" [class.active]="ownLike === ArticleLikeType.nieuwsgierig" (click)="like(ArticleLikeType.nieuwsgierig)" matTooltip="{{ArticleLikeType.nieuwsgierig}}">
                                        <i class="fa-regular fa-face-smile-halo"></i>&nbsp;&nbsp;{{(article.articleLikes | where : 'type' : ArticleLikeType.nieuwsgierig)?.length}}
                                    </div>
                                    <div class="like" [class.active]="ownLike === ArticleLikeType.geweldig" (click)="like(ArticleLikeType.geweldig)" matTooltip="{{ArticleLikeType.geweldig}}">
                                        <i class="fa-solid fa-heart"></i>&nbsp;&nbsp;{{(article.articleLikes | where : 'type' : ArticleLikeType.geweldig)?.length}}
                                    </div>
                                    <div class="like" [class.active]="ownLike === ArticleLikeType.niet_leuk" (click)="like(ArticleLikeType.niet_leuk)" matTooltip="{{ArticleLikeType.niet_leuk}}">
                                        <i class="fa-solid fa-thumbs-down"></i>&nbsp;&nbsp;{{(article.articleLikes | where : 'type' : ArticleLikeType.niet_leuk)?.length}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="content">
                        <p [innerHTML]="article.content"></p>
                    </div>

                    <div class="item-images">
                        <app-codalt-image (click)="openImage(image)"
                                          *ngFor="let image of article.images"
                                          [adapt]="image.adapt_image"
                                          [path]="article.id + '/' + image.path"
                                          [thumb]="image.thumb">
                        </app-codalt-image>
                    </div>
                    <div class="item-documents" *ngIf="article.documents?.length">
                        <b>Bijlage(n): </b>
                        <table>
                            <tr (click)="openDocument(document)" *ngFor="let document of article.documents">
                                <td>
                                    <i class="fas fa-file-pdf-o"></i>
                                </td>
                                <td>
                                    {{document.path}}
                                </td>
                            </tr>
                        </table>
                    </div>
                    <app-survey-answer [typeObj]="article"></app-survey-answer>
                </div>
            </div>
            <div *ngIf="article.readBy" class="col-12 col-lg-3">
                <div class="detail__block readby">
                    <h3 class="mb-2">Gelezen ({{article.readBy.length || '0'}})</h3>
                    <div *ngFor="let user of article.readBy">
                        {{user.name}}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="Utils.userHasRights('USER')" class="floating-buttons">
        <button (click)="delete()" color="primary" mat-fab>
            <mat-icon class="fas fa-trash"></mat-icon>
        </button>
        <button color="primary" mat-fab routerLink="/{{Routenames.articles}}/{{article.slug}}/vragenlijst-antwoorden"
                matTooltip="Poll / vragenlijst voor dit artikel">
            <mat-icon class="fas fa-question"></mat-icon>
        </button>
        <button color="primary" mat-fab routerLink="/{{Routenames.articles}}/{{article.slug}}/kopie">
            <mat-icon class="fas fa-copy"></mat-icon>
        </button>
        <button color="primary" mat-fab routerLink="/{{Routenames.articles}}/{{article.slug}}/bewerken">
            <mat-icon class="fas fa-pencil-alt"></mat-icon>
        </button>
    </div>
</section>
