<div class="image-viewer-container">
    <div (click)="dialogRef.close()" class="close-viewer">
        <i class="fas fa-times"></i>
    </div>
    <div class="swiper-button-prev" *ngIf="showNavigation" (click)="directiveRef.prevSlide();$event.stopPropagation()" tabindex="-1"><i class="fas fa-chevron-left"></i> </div>
    <div class="swiper-button-next" *ngIf="showNavigation" (click)="directiveRef.nextSlide();$event.stopPropagation()" tabindex="-1"><i class="fas fa-chevron-right"></i> </div>
    <div [(index)]="images.viewIndex" [swiper]="config" class="swiper-container" >

        <div class="swiper-wrapper" *ngIf="images.documentImages?.length > 0">
            <div *ngFor="let img of images.documentImages" class="image swiper-slide">
                <div class="image-box">
                    <pinch-zoom [draggable]="true" overflow="visible" *ngIf="img.id" #pinchZoom [limit-zoom]="3">
                        <img src="{{Settings.API_ENDPOINT}}documents/download/{{img.id}}?access_token={{Storage.getUserToken()}}" class="img" alt="image"/>
                    </pinch-zoom>
                    <pinch-zoom [draggable]="true" overflow="visible" *ngIf="!img.id" #pinchZoom [limit-zoom]="3">
                        <img [src]="img" class="img" alt="image"/>
                    </pinch-zoom>
                </div>
                <div *ngIf="img.id" class="text">
                    {{img.name}}<br>
                    <ng-container *ngIf="img.updated_at">
                        {{img.updated_at | date : 'd MMM yyyy, H:mm'}}
                    </ng-container>
                    <ng-container *ngIf="!img.updated_at">
                        Zojuist gemaakt
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="swiper-wrapper" *ngIf="images.images?.length > 0">
            <div *ngFor="let img of images.images" class="image swiper-slide">
                <div class="image-box">
                    <pinch-zoom [draggable]="true" overflow="visible" *ngIf="img.path" #pinchZoom [limit-zoom]="3">
                        <img class="img" alt="image"
                            [src]="(Settings.API_ENDPOINT+'file/thumb?'+secure+'path=/'+(img.timeline_id || img.article_id)+'/'+img.path+'&access_token='+LocalStorage.getUserToken())">
                    </pinch-zoom>
                    <pinch-zoom [draggable]="true" overflow="visible" *ngIf="!img.path" #pinchZoom [limit-zoom]="3">
                        <img class="img" alt="image" [src]="img">
                    </pinch-zoom>
                </div>
                <div class="text">
                </div>
            </div>
        </div>


    </div>
</div>
