import {Component, OnInit} from '@angular/core';
import {TownProjectService} from '../../services/townproject/town-project.service';
import {MatDialogRef} from '@angular/material/dialog';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';
import {TownProject} from '../../town-project';
import {CodaltComponent} from '../../codalt.component';
import {Storage} from '../../storage.class';

@Component({
    selector: 'app-add-townproject-modal',
    templateUrl: './add-townproject-modal.component.html',
    styleUrls: ['./add-townproject-modal.component.scss']
})
export class AddTownprojectModalComponent extends CodaltComponent implements OnInit {

    townproject: TownProject;

    townprojects: {
        id: number,
        name: string,
        town_id: number,
        town_name: string
    }[];

    constructor(public dialogRef: MatDialogRef<AddTownprojectModalComponent>,
                private confirmDialogService: ConfirmDialogService,
                private townProjectService: TownProjectService) {
        super();
    }

    ngOnInit() {
        this.townProjectService.getConsumerProjects().subscribe(res => {
            const projectIds = Storage.getUser().townprojects.map(p => p.id);
            this.townprojects = res.data.filter(p => projectIds.indexOf(p.id) === -1);

        });
    }

    addTownproject() {
        this.townProjectService.follow(this.townproject.id).subscribe(result => {
            if (result.success) {
                this.dialogRef.close();
            } else {
                this.failed();
            }
        }, (err) => {
            console.log(err);
            this.failed();
        });
    }

    failed() {
        this.confirmDialogService.confirm(
            'Project toevoegen mislukt',
            'Het is niet gelukt om dit project toe te voegen.',
            'Sluiten',
            null).then(() => {
            this.dialogRef.close();
        });
    }
}
