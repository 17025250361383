import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'selectedOptions'
})
export class SelectedOptionsPipe implements PipeTransform {

    transform(value: {}): string[] {
        const arr = [];
        for (let key of Object.keys(value)) {
            if (!!value[key]) {
                arr.push(key);
            }
        }
        return arr;
    }

}
