export class Contact {
    id: number;
    name: string;
    description: string;
    phone: string;
    email: string;
    town_id: number;
    townproject_id: number;
    image: string;
}
