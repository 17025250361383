<form #workareaForm="ngForm">
    <div class="row">
        <div class="col-6">
            <mat-form-field class="w-100">
                <mat-select name="damage" (valueChange)="inputChanged($event)"
                            (ngModelChange)="workarea.extra.type = undefined"
                            [(ngModel)]="workarea.extra.damage" #type="ngModel"
                            errorState="type.errors?.required && (type.dirty || workareaForm.submitted)"
                            required placeholder="Type">
                    <mat-option [value]="undefined" selected disabled>Type</mat-option>
                    <mat-option *ngFor="let type of fieldOptions['damage']"
                                [value]="type">{{type}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div *ngIf="Utils.userHasRights('GEMEENTE')"
             class="col-6">
            <mat-form-field class="w-100">
                <mat-select name="status" [(ngModel)]="workarea.status_id"
                            (ngModelChange)="statusChange()"
                            placeholder="Satus">
                    <mat-option [ngValue]="undefined" disabled>Status</mat-option>
                    <mat-option *ngFor="let status of statuses" [value]="status.id">
                        {{status.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <mat-form-field class="w-100">
            <textarea [(ngModel)]="workarea.comments" name="comments"
                      matInput cdkTextareaAutosize cdkAutosizeMinRows="4"
                      placeholder="Opmerkingen"></textarea>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-12 pb-3" *ngIf="workarea.feedback">
            <b>Reactie D. van der Steen:</b><br>
            {{workarea.feedback}}
        </div>
        <div class="col-12 pb-3" *ngIf="!workarea.feedback && !workarea.deleted_at">
            Er is nog geen reactie op deze melding.
        </div>
    </div>
    <div class="row" *ngIf="Utils.userHasRights('SUBADMIN')">
        <div class="col-12">
            <mat-form-field class="w-100">
            <textarea [(ngModel)]="workarea.feedback" name="feedback"
                      matInput cdkTextareaAutosize cdkAutosizeMinRows="4"
                      placeholder="Terugkoppeling"></textarea>
            </mat-form-field>
        </div>
    </div>
    <ng-container *ngIf="Utils.userHasRights('SUBADMIN')">
        <mat-divider class="pt-2"></mat-divider>
        <div class="row pb-1">
            <div class="col-12">
                <small>Aangemaakt door</small>
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <mat-form-field class="w-100" *ngIf="workarea['user']">
                    <input matInput type="text" [(ngModel)]="workarea.user.name"
                           name="user_name" disabled
                           placeholder="Naam">
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-form-field class="w-100" *ngIf="workarea['user'] && workarea['user']['email']">
                    <input matInput type="text" [(ngModel)]="workarea.user.email"
                           name="user_email" disabled
                           placeholder="Email">
                </mat-form-field>
            </div>
            <div class="col-3">
                <mat-form-field class="w-100" *ngIf="workarea['user'] && workarea['user']['phone']">
                    <input matInput type="text" [(ngModel)]="workarea.user.phone"
                           name="user_phone" disabled
                           placeholder="Telefoon">
                </mat-form-field>
            </div>
        </div>
    </ng-container>
</form>