import {Injectable} from '@angular/core';
import {ApiService} from './api/api.service';
import {FileItem} from '../document-upload/file-item';
import {StorageName} from '../document-upload/storage-name.enum';

@Injectable()
export class FileService {

    constructor(private apiService: ApiService) {
    }

    read(dir?: string) {
        return this.apiService.getCall$<FileItem[]>(`file/read`, {dir});
    }

    createFolder(name: string, path?: string) {
        return this.apiService.postCall$(`file/create-folder`, {name, path});
    }

    delete(path: string | FileItem, storage?: StorageName) {
        if (path instanceof FileItem) {
            return this.apiService.deleteCall$('file', {path: path.path, secure: storage});
        } else {
            return this.apiService.deleteCall$('file', {path, secure: storage});
        }
    }

    rotateImage(path?: string, root?: boolean, secure?: string) {
        if (path.substr(0, 7) === '/media/') {
            path = path.substr(6);
        }
        if (root) {
            return this.apiService.getCall$<FileItem>(`file/rotate-image`, {path, root, secure});
        } else {
            return this.apiService.getCall$<FileItem>(`file/rotate-image`, {path, secure});
        }
    }
}
