<svg-icon *ngIf="!thumb" class="svg" src="assets/images/logo-small.svg"></svg-icon>
<div *ngIf="icon" [style.background-color]="iconBackground" class="svg">
    <i class="{{icon}}"></i>
</div>

<img *ngIf=safeThumb [src]="safeThumb" class="thumb"/>
<div [class.loaded]="loaded" [class.loading]="loading" [style.background-image]="image" class="image" [class.adapt]="adapt"></div>

<img (load)="isLoaded()" [src]="imagePreload" class="preload" loading="lazy"/>
<noscript>
    <div [style.background-image]="imageNoScript" class="image"></div>
</noscript>
