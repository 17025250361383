import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Contact} from '../shared/contact';
import {TownService} from '../../services/town/town.service';
import {Storage} from '../../storage.class';
import {FormGroup} from '@angular/forms';
import {ConfirmModalService} from '../../shared/confirm-modal.service';
import {Subscription} from 'rxjs';
import {Routenames} from '../../route-names.enum';

@Component({
    selector: 'app-contact-edit',
    templateUrl: './contact-edit.component.html',
    styleUrls: ['./contact-edit.component.scss']
})
export class ContactEditComponent implements OnInit, OnDestroy {

    contact = new Contact();
    contactId: number;
    townId: number;
    projectId: number;
    Routenames = Routenames;

    private subscriptions = new Subscription();

    constructor(
        private route: ActivatedRoute,
        private townService: TownService,
        private router: Router,
        private confirmModalService: ConfirmModalService) {
    }

    ngOnInit() {
        this.subscriptions.add(this.route.params.subscribe(params => {
            this.townId = +params['townId'];
            this.projectId = +params['projectId'];
            if (params['id'] != "new") {
                this.contactId = +params['id'];
                this.townService.contact(this.townId, this.contactId).then(contact => {
                    this.contact = contact;
                });
            } else {
                this.contact = new Contact();
                this.contact.townproject_id = this.projectId;
                this.contact.town_id = this.townId;
            }
        }));
    }

    variableIsPresent(variable: any) {
        if (typeof variable === 'undefined') {
            return false;
        }

        return variable.length > 0;
    }

    save(form: FormGroup) {
        if (!form.valid) {
            return;
        }
        this.townService.storeContact(this.townId, this.contact).then(() => {
            this.router.navigate([Routenames.towns, this.townId, Routenames.projects, this.projectId, Routenames.contacts]);
        }, (error) => {
            this.confirmModalService.showModal('Fout tijdens opslaan contactpersoon', error);
        });
    }

    delete() {
        this.confirmModalService.showModal(
            'Contactpersoon verwijderen',
            'Weet je zeker dat je deze contactpersoon wilt verwijderen?',
            'Ja',
            'Nee'
        ).then(() => {
            this.townService.deleteContact(Storage.getTown()['id'], this.contact.id).then(() => {
                this.router.navigate(['contacts']);
            });
        }, () => {
            this.confirmModalService.showModal('Fout bij verwijderen contactpersoon',
                'Kon contactpersoon niet verwijderen.');
        });
    }

    uploadLogo(event) {
        event.stopPropagation();
        event.preventDefault();
        const file = event.srcElement.files[0];
        if (file.type === 'image/svg+xml') {
            const reader = new FileReader();
            reader.readAsText(file, "UTF-8");
            reader.onload = (evt) => {
                this.contact.image = `data:image/svg+xml;base64,${window.btoa(evt.target.result + '')}`;
                this.townService.storeContact(this.townId, this.contact);
            };
            reader.onerror = (evt) => {
            };
        } else if (['image/jpeg', 'image/png'].indexOf(file.type) !== -1) {
            const img = new Image();
            img.src = window.URL.createObjectURL(file);
            img.onload = () => {
                const factor = 500 / Math.max(img.naturalWidth, img.naturalHeight);
                const width = factor < 1 ? img.naturalWidth * factor : img.naturalWidth;
                const height = factor < 1 ? img.naturalHeight * factor : img.naturalHeight;
                const elem = document.createElement('canvas');
                elem.width = width;
                elem.height = height;
                const ctx = elem.getContext('2d');
                ctx.drawImage(img, 0, 0, width, height);
                if (file.type === 'image/png') {
                    this.contact.image = ctx.canvas.toDataURL('image/png');
                } else {
                    this.contact.image = ctx.canvas.toDataURL('image/jpeg', 0.85);
                }
                this.townService.storeContact(this.townId, this.contact);
            };
        } else {
            this.confirmModalService.showModal(
                'Verkeerd bestand',
                'Dit bestandsformaat wordt niet ondersteund',
                'Oké'
            ).then(() => {

            }, () => {

            });
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }


}
