import {Injectable} from '@angular/core';
import {ApiService} from '../../services/api/api.service';
import {AuthLog} from './auth-log.model';

@Injectable()
export class AuthLogService {

    constructor(private apiService: ApiService) {
    }

    public getAuthLog(): Promise<Array<AuthLog>> {
        return this.apiService.getCall('/auth-log');
    }


}
