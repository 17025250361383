import {Component, OnInit} from '@angular/core';
import {TownService} from '../services/town/town.service';
import {ActivatedRoute} from '@angular/router';
import {Town} from '../town.class';
import {TownProjectService} from '../services/townproject/town-project.service';
import {TownProjectDrilling} from '../town-project-drilling';

@Component({
    selector: 'app-town-report',
    templateUrl: './town-report.component.html',
    styleUrls: ['./town-report.component.scss']
})
export class TownReportComponent implements OnInit {

    town: Town;
    project: TownProjectDrilling;
    date = new Date();

    constructor(private townService: TownService,
                private townProjectService: TownProjectService,
                private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        const parentParams = this.route.parent.snapshot.params;
        this.townService.getSingle(+parentParams['id']).then(data => {
            this.town = data;
        });
        const queryParams = this.route.snapshot.queryParams;
        this.townProjectService.getProject(+queryParams['project']).subscribe(data => {
            this.project = data.data as any as TownProjectDrilling;
            setTimeout(() => {
                document.body.appendChild(document.createElement("readyforpuppeteer"));
            });
        });
    }

}
