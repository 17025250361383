import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ArticleService} from '../services/article.service';
import {Article} from '../classes/article.class';
import {CodaltComponent} from '../codalt.component';
import {IPageInfo} from 'ngx-virtual-scroller';
import {ActivatedRoute} from '@angular/router';
import {ConfirmDialogService} from '../services/confirm-dialog-service/confirm-dialog.service';
import {TownProject} from '../town-project';
import {TownProjectService} from '../services/townproject/town-project.service';
import {SearchService} from '../services/search/search.service';
import {Storage} from '../storage.class';

@Component({
    selector: 'app-articles',
    templateUrl: './articles.component.html',
    styleUrls: ['./articles.component.scss']
})
export class ArticlesComponent extends CodaltComponent implements OnInit, OnDestroy {

    @ViewChild('projectFilterSearchTemplate', {static: true}) projectFilterSearchTemplate;

    childActive: boolean;

    articles: Article[] = [];
    loading = true;
    future = false;

    searchProjects: number[] = [];
    projects: TownProject[];

    @ViewChild('searchInput')
    private searchInput: ElementRef;

    private limit = 9;
    private skip = 0;
    private allLoaded = false;

    constructor(private articleService: ArticleService,
                private projectService: TownProjectService,
                private searchService: SearchService,
                private dialog: ConfirmDialogService,
                private route: ActivatedRoute) {
        super();
    }

    ngOnInit(): void {
        if (Storage.getUser().group === 'ADMIN') {
            this.projectService.getProjects(true).subscribe(data => {
                this.projects = data.data;
                this.searchService.searchTemplate = this.projectFilterSearchTemplate;
            });
        }
        this.skip = 0;
        this.searchProjects = this.LocalStorage.getSearchProjectsIds();
        if (this.searchProjects) {
            this.changeSearch();
        } else {
            this.getArticles();
        }
    }

    changeSearch() {
        this.skip = 0;
        this.getArticles();
        this.LocalStorage.setSearchProjectsIds(this.searchProjects);
    }

    childClosed(event) {
        if (event?.reserve) {
            this.skip = 0;
            this.getArticles();
        } else if (event?.form) {
            console.log(event);
            const article = this.articles.find(a => a.id === event?.article?.id);
            if (article) {
                Object.assign(article, event?.article);
            } else {
                this.skip = 0;
                this.getArticles();
            }
        }
    }

    ngOnDestroy() {
        this.searchService.searchTemplate = null;
        this.subscriptions.unsubscribe();
    }

    scroll(article: IPageInfo) {
        if (!this.loading && !this.allLoaded) {
            if (article.endIndex > (this.articles.length) - 3) {
                this.skip += this.limit;
                this.getArticles();
            }
        }
    }

    private getArticles() {
        this.loading = true;
        this.future = typeof this.route.snapshot.url[0] !== 'undefined' && this.route.snapshot.url[0].path === 'toekomst';
        this.articleService.getList(this.limit, this.skip, this.future, this.searchProjects).subscribe(articles => {
            if (this.skip === 0) {
                this.articles = [];
            }
            this.allLoaded = articles.data.length < this.limit;
            this.articles = this.articles.concat(articles.data);
            this.loading = false;
        }, () => {
            this.loading = false;
        });
    }
}
