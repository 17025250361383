import {Component, OnDestroy, OnInit} from '@angular/core';
import {Message} from '../shared/message';
import {MessageService} from '../shared/message.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Utils} from '../../utils.class';
import {ConfirmModalService} from '../../shared/confirm-modal.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-news-detail',
    templateUrl: './news-detail.component.html',
    styleUrls: ['./news-detail.component.scss']
})
export class NewsDetailComponent implements OnInit, OnDestroy {
    public Utils = Utils;

    public message = new Message();
    public messageId: number;

    private subscriptions = new Subscription();

    constructor(private messageService: MessageService,
                private route: ActivatedRoute,
                private router: Router,
                private confirmModalService: ConfirmModalService) {

        this.subscriptions.add(this.route.params.subscribe(params => {
            this.messageId = params['id'];
            this.messageService.get(this.messageId).then(message => {
                    this.message = message;
                },
                () => {
                    this.confirmModalService.showModal(
                        'Nieuwsbericht niet gevonden',
                        'We konden het nieuwsbericht niet laden.',
                        'Oke',
                        null
                    ).then(() => {
                        this.router.navigate(['/news']);
                    });
                });
        }));
    }

    ngOnInit() {

    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

}
