<mat-nav-list>
    <a (click)="openLink('remove')" *ngIf="options.remove" mat-list-item>
        <mat-icon class="fas fa-trash" mat-list-icon></mat-icon>
        <span mat-line>Verwijder foto</span>
    </a>
    <a (click)="openLink('rotate')" *ngIf="options.rotate" mat-list-item>
        <mat-icon class="fas fa-rotate-left" mat-list-icon></mat-icon>
        <span mat-line>Foto draaien</span>
    </a>
    <a (click)="openLink('gallery')" mat-list-item>
        <mat-icon class="fas fa-image" mat-list-icon></mat-icon>
        <span mat-line>Galerij</span>
    </a>
    <a (click)="openLink('camera')" mat-list-item>
        <mat-icon class="fas fa-camera" mat-list-icon></mat-icon>
        <span mat-line>Camera</span>
    </a>
</mat-nav-list>
