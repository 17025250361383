import {Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {Settings} from '../settings.class';
import {GpsService} from '../gps.service';
import {ConfirmModalService} from '../shared/confirm-modal.service';
import {CordovaService} from '../cordova.service';
import {Subscription} from 'rxjs';
import {Utils} from '../utils.class';


export class Pushpin {

    latitude: number;
    longitude: number;
    icon: string;
    anchorX: number = 13;
    anchorY: number = 30;
    data: any;

    public constructor(init?: Partial<Pushpin>) {
        Object.assign(this, init);
    }
}

export class MapLocation {

    public latitude: number;
    public longitude: number;

    public constructor(init?: Partial<MapLocation>) {
        Object.assign(this, init);
    };
}


export class Polygon {

    public exteriorRing = [];
    drawing?: boolean;
    public fillColor: string = Settings.DEFAULT_FILL_COLOR;
    public strokeColor: string = '#67c6dd';
    public strokeThickness: number = 2;
    data: {};

    public constructor(init?: Partial<Polygon>) {
        Object.assign(this, init);
    };
}


declare var $MicrosoftMaps8: any;
declare var Microsoft: any;
declare var document: any;

@Component({
    selector: 'app-angular-bing-maps',
    templateUrl: './angular-bing-maps.component.html',
    styleUrls: ['./angular-bing-maps.component.scss']
})
export class AngularBingMapsComponent implements OnInit, OnChanges, OnDestroy {


    @Input() polygons: Polygon[];
    @Input() pushpins: Pushpin[];
    @Input('bing-maps-api-key') bingMapsApiKey: string;
    @Input('set-map-center') mapCenter: MapLocation;
    @Input('set-map-zoom') mapZoom: number;
    @Input('map-style-toggle-trigger') mapStyleToggleTrigger: number;
    @Input('map-center-trigger') mapCenterTrigger: any;
    @Input('map-geo-trigger') mapGeoTrigger: any;
    @Input('map-zoom-trigger') mapZoomTrigger: any;
    @Input() geoData: any;
    @Output('map-center') getCenterEvent = new EventEmitter();
    @Output('somePointMoveEvent') somePointMoveEvent = new EventEmitter();
    @Output('map-zoom') getZoomEvent = new EventEmitter();
    @Output('pushpin-click') pushpinClickEvent = new EventEmitter();
    @Output('pushpin-dbl-click') pushpinDbClickEvent = new EventEmitter();
    @Output('polygon-click') polygonClickEvent? = new EventEmitter();
    @Output('viewchangeend') viewchangeendEvent = new EventEmitter();
    @Output('mapRightClickEvent') mapRightClickEvent? = new EventEmitter();
    @Output('mousedown') mousedownEvent = new EventEmitter();
    private _msBingHandlers = [];
    manualPolygons: Polygon[];
    map: any;
    private layer: any;
    private geoLayer: any;
    private timer;
    private _pushpins: Pushpin[];
    private gpsWatchId: any;
    private followLocation = false;
    @Input() private forceFollowLocation = false;
    @Input() private customStyle = {};
    private geoDataAvailable = false;
    private geoDataLocationPin: any;
    @Input() showCurrentLocation = true;
    @Input() private screenshot = false;
    @Input() private alwaysShowFill = false;
    private loadTimeout;
    private puppeteerSet = false;
    private subscriptions = new Subscription();

    private msPolygons = [];

    private drawPolygon = new Polygon();

    constructor(
        private gpsService: GpsService,
        private elRef: ElementRef,
        private confirmModalService: ConfirmModalService,
        private cordovaService: CordovaService) {
        /*let tries = 0;
        this.timer = setInterval(_ => {
            tries++;
            if (typeof Microsoft != 'undefined') {
                this.loadMap();
                clearInterval(this.timer);
            }
            if (tries > 50) {
                clearInterval(this.timer);
                alert("Kaart kon niet worden geladen, controleer je internetverbinding");
            }
        }, 250);*/
    }

    ngOnInit() {
        this.subscriptions.add(this.cordovaService.onResume.subscribe(() => {
            this.setupGeo();
        }));

        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = `https://www.bing.com/mapspreview/sdkrelease/mapcontrol?branch=experimental?key=AnmAfGIkRSxoUh0TMvW6z4hLEOkVLc5V4MNzFVg_-eM4HylbpB2UVjx7koWoN3B6&c=nl`;
        script.onload = () => {
            let tries = 0;
            this.timer = setInterval(_ => {
                tries++;
                if (typeof Microsoft != 'undefined') {
                    this.loadMap();
                    clearInterval(this.timer);
                }
                if (tries > 50) {
                    clearInterval(this.timer);
                    alert('Kaart kon niet worden geladen, controleer je internetverbinding');
                }
            }, 250);
        };
        this.elRef.nativeElement.appendChild(script);


        /*
            Since Bing Maps fires view change events as soon as the map loads, we have to wait until after the
            initial viewchange event has completed before we bind to $scope.center. Otherwise the user's
            $scope.center will always be set to {0, 0} when the map loads
        var initialViewChangeHandler = Microsoft.Maps.Events.addHandler(this.map, 'viewchangeend', function() {
            Microsoft.Maps.Events.removeHandler(initialViewChangeHandler);
            //Once initial view change has ended, bind the user's specified handler to view change
            var centerBindEvent = angularBingMaps.getCenterBindEvent();
            Microsoft.Maps.Events.addHandler(this.map, centerBindEvent, function(event) {
                this.center = this.map.getCenter();
            });
        });
*/

        this.followLocation = this.forceFollowLocation;

    }

    ngOnChanges(changes: any) {
        if (changes['pushpins']) {
            if (this.map) {
                if (this.screenshot) {
                    this.setView();
                }
                this.drawPushpins();
            }
        }

        if (changes['mapCenterTrigger']) {
            if (this.map)
                this.getCenterEvent.next(this.map.getCenter());
        }
        if (changes['mapZoom'] && this.map) {
            this.setView((changes['mapCenter'] === undefined));
        }

        if (changes['mapGeoTrigger'] && this.map) {
            this.mapCenter = this.geoData.coords;
            Settings.mapCenter = this.geoData.coords;
            this.followLocation = true;
            this.setView();
        }

        if (changes['mapZoomTrigger'] && this.map) {
            this.getZoomEvent.next(this.map.getZoom());
        }

        if (changes['mapStyleToggleTrigger'] && this.map) {
            if (this.mapStyleToggleTrigger % 2 == 0)
                this.map.setView({animate: true, mapTypeId: 'r'});
            else
                this.map.setView({animate: true, mapTypeId: 'a'});
        }
    }

    setView(zoomOnly = false) {
        if (this.mapCenter === null) {
            const locations = this.pushpins.map(p => new Microsoft.Maps.Location(p.latitude, p.longitude));
            const rect = Microsoft.Maps.LocationRect.fromLocations(locations);
            this.map.setView({
                bounds: rect,
                padding: 5,
                mapTypeId: this.mapStyleToggleTrigger % 2 == 0 ? 'r' : 'a'
            });
        } else if (zoomOnly) {
            this.map.setView({
                zoom: this.mapZoom,
                mapTypeId: this.mapStyleToggleTrigger % 2 == 0 ? 'r' : 'a'
            });
        } else if (!isNaN(this.mapCenter.latitude)) {
            this.map.setView({
                center: new Microsoft.Maps.Location(this.mapCenter.latitude, this.mapCenter.longitude),
                zoom: this.mapZoom,
                mapTypeId: this.mapStyleToggleTrigger % 2 == 0 ? 'r' : 'a'
            });
        }
    }

    loadMap() {

        this.map = new Microsoft.Maps.Map(this.elRef.nativeElement.querySelector('#map'), {
            credentials: Settings.BING_MAPS_API_KEY,
            customMapStyle: this.customStyle
        });
        this._msBingHandlers.push(Microsoft.Maps.Events.addHandler(this.map, 'rightclick', (event) => {
            this.mapRightClickEvent.next({
                lat: event.location.latitude,
                lng: event.location.longitude
            });
        }));

        if (!this.puppeteerSet) {
            this.loadTimeout = setInterval(() => {
                // Prevent puppeteer ready when pushpins have not loaded yet
                if (this.pushpins?.length > 0
                    && document.querySelector('#map canvas#labelCanvasId')) {
                    setTimeout(() => {
                        this.puppeteerSet = true;
                        document.body.appendChild(document.createElement('readyforpuppeteer'));
                    }, 2500);
                    clearInterval(this.loadTimeout);
                }
            }, 250);
        }

        this.drawPushpins();

        let h = Microsoft.Maps.Events.addHandler(this.map, 'viewchangeend', (event) => {
            let mapCenter = this.map.getCenter();
            let latDiff = (mapCenter['latitude'] - this.geoData.coords['latitude']);
            let longDiff = (mapCenter['longitude'] - this.geoData.coords['longitude']);
            if (this.forceFollowLocation) {
                this.mapCenter = this.geoData.coords;
                this.setView();
            } else if (latDiff > 0.00001 || longDiff > 0.00001) {
                this.followLocation = false;
                console.log('Disabled followLocation because of enough user interaction');
            }
            this.viewchangeendEvent.next({
                event: event,
                zoomLevel: this.map.getZoom(),
                center: this.map.getCenter()
            });

            if (!this.alwaysShowFill) {
                if (this.map.getZoom() > Settings.ZOOMLEVEL_TRESHOLD_FILLCOLOR || this.screenshot) {
                    this.msPolygons.forEach(msPolygon => {
                        msPolygon.setOptions({
                            fillColor: 'rgba(0, 0, 0, 0)'
                        });
                    });
                } else {
                    this.msPolygons.forEach(msPolygon => {
                        msPolygon.setOptions({
                            fillColor: Settings.DEFAULT_FILL_COLOR
                        });
                    });
                }
            }
        });
        this._msBingHandlers.push(h);
        let hh = Microsoft.Maps.Events.addHandler(this.map, 'mousedown', (event) => {
            this.mousedownEvent.next(event);
        });
        this._msBingHandlers.push(hh);

        this.setView();

        const options = {
            showDashboard: false,
            enableHighDpi: true,
            enableClickableLogo: false,
            enableSearchLogo: false,
            showCopyright: false,
            tileBuffer: 40
        } as any;

        if (this.customStyle) {
            options.customMapStyle = this.customStyle;
        }

        this.map.setOptions(options);

        this.setupGeo();

        this.drawPolygons();

    }

    setupGeo() {
        if (this.screenshot) {
            return;
        }
        if (typeof navigator.geolocation !== 'undefined') {
            if (this.gpsWatchId) {
                navigator.geolocation.clearWatch(this.gpsWatchId);
            }

            this.gpsWatchId = navigator.geolocation.watchPosition((geoData) => {

                    this.geoData = geoData;
                    this.geoDataAvailable = true;
                    this.setCurrentLocation();
                }, () => {
                    if (Utils.isIOS() || Utils.isAndroid()) {
                        this.confirmModalService.showModal(
                            'GPS locatie niet beschikbaar',
                            'Je hebt ons geen toegang gegeven voor je GPS locatie. ' +
                            'Dit is nodig om de exacte locatie van het probleem vast te stellen.',
                            'Opnieuw proberen', 'Geen GPS gebruiken').then(() => {
                            this.setupGeo();
                        }, () => {
                        });
                    }
                },
                {
                    enableHighAccuracy: true,
                    maximumAge: 3000
                });
        } else {
            setTimeout(() => {
                this.setupGeo();
            }, 3000);
        }

    }

    setCurrentLocation() {
        if (this.showCurrentLocation) {
            if (!this.geoDataLocationPin) {
                this.geoLayer = new Microsoft.Maps.Layer();
                this.geoDataLocationPin = new Microsoft.Maps.Pushpin(
                    new Microsoft.Maps.Location(this.geoData.coords.latitude, this.geoData.coords.longitude),
                    {
                        icon: '<?xml version="1.0" encoding="UTF-8"?><svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><!-- Generator: Sketch 48.2 (47327) - http://www.bohemiancoding.com/sketch --><title>icons/current</title><desc>Created with Sketch.</desc><defs><path d="M9.99799378,20 C4.48690942,20 0.00200621928,15.5148718 0,10.0035111 C0.00200621928,4.48813763 4.48690942,0.00100315995 10,0 C15.5110844,0 19.9979938,4.48613131 20,10.0005016 C20,15.5138687 15.5130906,20 9.99799378,20" id="path-1"></path><filter x="-30.0%" y="-30.0%" width="160.0%" height="160.0%" filterUnits="objectBoundingBox" id="filter-2"><feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset><feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur><feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.4 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix></filter></defs><g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="icons/current"><g id="Group-3" transform="translate(6.000000, 6.000000)"><g id="Path"><use fill="black" fill-opacity="1" filter="url(#filter-2)" xlink:href="#path-1"></use><use fill="#FFFFFF" fill-rule="evenodd" xlink:href="#path-1"></use></g><path d="M10,2.00631991 C5.59333935,2.00732307 2.0082255,5.59462306 2.00621928,10.0035111 C2.0082255,14.4083864 5.59133313,17.9936801 9.99799378,17.9936801 C14.4066606,17.9936801 17.9937807,14.4083864 17.9937807,10.0015047 C17.9917745,5.59261674 14.4066606,2.00631991 10,2.00631991" id="Path" fill="#67C6DD"></path></g></g></g></svg>',
                        anchor: new Microsoft.Maps.Point(16, 16)
                    }
                );
                this.geoLayer.add(this.geoDataLocationPin);
                this.map.layers.insert(this.geoLayer);
            } else {
                this.geoDataLocationPin.setLocation(new Microsoft.Maps.Location(this.geoData.coords.latitude, this.geoData.coords.longitude));
            }
        }

        if (this.followLocation) {
            this.mapCenter = this.geoData.coords;
            Settings.mapCenter = this.geoData.coords;
            this.setView();
        }

    }


    drawPushpins() {
        if (this.pushpins) {
            if (this.layer)
                this.map.layers.remove(this.layer);
            this.layer = new Microsoft.Maps.Layer();

            this.pushpins.forEach(pushpin => {
                let msPushpin = new Microsoft.Maps.Pushpin(
                    new Microsoft.Maps.Location(pushpin.latitude, pushpin.longitude),
                    {
                        icon: pushpin.icon,
                        anchor: new Microsoft.Maps.Point(pushpin.anchorX, pushpin.anchorY)
                    }
                );

                let hndlr = Microsoft.Maps.Events.addHandler(msPushpin, 'click', (event => {
                    this.pushpinClickEvent.next({
                        pushpin: pushpin,
                        event: event
                    });
                }));
                this._msBingHandlers.push(hndlr);

                this.layer.add(msPushpin);
            });
            this.map.layers.insert(this.layer);
        }
    }


    updateManualPolygons(polygons: Polygon[]) {
        this.manualPolygons = polygons;
        if (this.map) {
            this.drawPolygons();
        }
    }

    drawPolygons() {
        if (this.polygons || this.manualPolygons) {
            this.map.entities.clear();
            [...(this.polygons ?? []), ...(this.manualPolygons ?? [])].forEach(polygon => {
                let msPolygon = null;
                const msExteriorRing = [];
                polygon.exteriorRing.forEach(((location, index) => {
                    if (!isNaN(location['latitude'])) {
                        msExteriorRing.push(new Microsoft.Maps.Location(
                            location['latitude'],
                            location['longitude']
                        ));
                    } else {
                        alert('Grenzen van werkgebied konden niet geladen worden');
                    }
                    if (polygon.drawing) {
                        const pushpin = new Microsoft.Maps.Pushpin(
                            new Microsoft.Maps.Location(location['latitude'], location['longitude']),
                            {
                                text: 'P' + (index + 1),
                                color: '#0f0064',
                                draggable: true
                            });
                        this.map.entities.push(pushpin);
                        Microsoft.Maps.Events.addHandler(pushpin, 'drag', (e) => {
                            location['latitude'] = e.target.geometry.y;
                            location['longitude'] = e.target.geometry.x;
                            if (msPolygon) {
                                const upMsExteriorRing = [];
                                polygon.exteriorRing.forEach((lct, i) => {
                                    upMsExteriorRing.push(new Microsoft.Maps.Location(
                                        lct['latitude'],
                                        lct['longitude']
                                    ));
                                });
                                msPolygon.setLocations(upMsExteriorRing);
                            }
                            this.somePointMoveEvent.emit(e);
                        });
                        Microsoft.Maps.Events.addHandler(pushpin, 'dblclick', (e) => {
                            polygon.exteriorRing.splice(polygon.exteriorRing.indexOf(location), 1);
                            if (msPolygon) {
                                const upMsExteriorRing = [];
                                polygon.exteriorRing.forEach((lct, i) => {
                                    upMsExteriorRing.push(new Microsoft.Maps.Location(
                                        lct['latitude'],
                                        lct['longitude']
                                    ));
                                });
                                msPolygon.setLocations(upMsExteriorRing);
                                this.map.entities.removeAt(this.map.entities.indexOf(pushpin));
                                this.pushpinDbClickEvent.emit(e);
                            }
                        });
                    }
                }));

                const isProvincie = !!polygon.data && polygon.data['fields']?.Type === 'Provincie';
                if (isProvincie) {
                    msPolygon = new Microsoft.Maps.Polyline(msExteriorRing, {
                        strokeColor: polygon.strokeColor,
                        strokeThickness: polygon.strokeThickness
                    });
                    this.map.entities.push(msPolygon);
                    this.msPolygons.push(msPolygon);

                    const hndlr = Microsoft.Maps.Events.addHandler(msPolygon, 'click', (event => {
                        this.polygonClickEvent?.next({
                            polygon: polygon,
                            event: event
                        });
                    }));
                    this._msBingHandlers.push(hndlr);
                } else {
                    msPolygon = new Microsoft.Maps.Polygon(msExteriorRing, {
                        fillColor: this.alwaysShowFill ? polygon.fillColor : (this.map.getZoom() > Settings.ZOOMLEVEL_TRESHOLD_FILLCOLOR ? 'rgba(0, 0, 0, 0)' : polygon.fillColor),
                        strokeColor: polygon.strokeColor,
                        strokeThickness: polygon.strokeThickness
                    });
                    this.map.entities.push(msPolygon);
                    this.msPolygons.push(msPolygon);

                    const hndlr = Microsoft.Maps.Events.addHandler(msPolygon, 'click', (event => {
                        this.polygonClickEvent?.next({
                            polygon: polygon,
                            event: event
                        });
                    }));
                    this._msBingHandlers.push(hndlr);
                }
            });
        }
    }

    ngOnDestroy(): void {

        if (typeof navigator.geolocation !== 'undefined' && this.gpsWatchId) {
            navigator.geolocation.clearWatch(this.gpsWatchId);
        }
        if (this.timer) {
            clearInterval(this.timer);
        }
        if (this.map) {
            this.map.dispose();
        }
        this.subscriptions.unsubscribe();
    }


}
