<div class="container  h-100">

    <div class="row justify-content-center">
        <div class="col-lg-10">
            <table (matSortChange)="sortConsumers($event)"
                   *ngIf="consumers?.length > 0"
                   [dataSource]="consumers"
                   [matSortActive]="currentSort.active"
                   [matSortDirection]="currentSort.direction"
                   class="mat-elevation-z8 w-100 mb-4"
                   mat-table
                   matSort>
                <ng-container matColumnDef="name">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header>Voornaam</th>
                    <td *matCellDef="let project" mat-cell> {{project.name}}</td>
                </ng-container>
                <ng-container matColumnDef="lastname">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header>Achternaam</th>
                    <td *matCellDef="let project" mat-cell> {{project.lastname}}</td>
                </ng-container>
                <ng-container matColumnDef="phone">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header>Telefoonnummer</th>
                    <td *matCellDef="let project" mat-cell>
                        <a href="tel:{{project.phone}}">{{project.phone}}</a>
                    </td>
                </ng-container>
                <ng-container matColumnDef="email">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header>E-mail</th>
                    <td *matCellDef="let project" mat-cell>
                        <a href="tel:{{project.email}}">{{project.email}}</a>
                    </td>
                </ng-container>
                <ng-container matColumnDef="updated_at">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header>aangemeld op</th>
                    <td *matCellDef="let project" mat-cell>
                        <a href="tel:{{project.email}}">{{project.updated_at | date: 'EEE d MMM yyyy HH:mm'}}</a>
                    </td>
                </ng-container>

                <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
                <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
            </table>
        </div>
    </div>
</div>
