<div class="modal-header">
    <h4 class="modal-title pull-left">{{onlyDrilling ? 'Boor' : '(Deel)'}}opdracht {{project.id ? 'aanpassen' : 'toevoegen'}}</h4>
    <button (click)="close()" aria-label="Close" class="close pull-right" tabindex="-1" type="button">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div *ngIf="form" [formGroup]="form" class="modal-body">
    <mat-form-field *ngIf="!project.id && !this.onlyDrilling" class="w-100">
        <mat-placeholder>Soort project</mat-placeholder>
        <mat-select [formControl]="fc.type">
            <mat-option *ngFor="let status of types" [value]="status.key">
                {{status.name}}
            </mat-option>
        </mat-select>
        <mat-error [control]="fc.type"></mat-error>
    </mat-form-field>
    <ng-container *ngIf="fc.type.value">
        <ng-container *ngIf="fc.type.value !== 'drillings' && !project.id">
            <div *ngIf="!projectTemplates">
                <small>Project templates laden</small>
                <mat-progress-bar color="primary" mode="indeterminate"></mat-progress-bar>
            </div>
            <mat-form-field *ngIf="projectTemplates" class="w-100">
                <mat-placeholder>Project template</mat-placeholder>
                <mat-select [formControl]="fc.template">
                    <mat-option [value]="null">
                        Geen template
                    </mat-option>
                    <mat-option *ngFor="let template of projectTemplates" [value]="template.id">
                        {{template.name}}
                    </mat-option>
                </mat-select>
                <mat-error [control]="fc.type"></mat-error>
            </mat-form-field>
        </ng-container>
        <mat-form-field class="w-100">
            <mat-placeholder>Naam</mat-placeholder>
            <input formControlName="name" matInput type="text">
            <mat-error [control]="fc.name"></mat-error>
        </mat-form-field>
        <mat-form-field class="w-100">
            <mat-placeholder>Budget</mat-placeholder>
            <input appDecimalInput formControlName="budget" matInput type="text">
            <mat-error [control]="fc.budget"></mat-error>
        </mat-form-field>
        <div>
            <mat-checkbox *ngIf="fc.type.value !== 'drillings'" class="mt-1 mb-1" color="primary" formControlName="custom_numbers">
                Eigen nummerreeks
            </mat-checkbox>
        </div>
        <div>
            <mat-checkbox *ngIf="fc.type.value !== 'drillings'" class="mt-1 mb-1" color="primary" formControlName="consumers">
                Openstellen voor bewoners
            </mat-checkbox>
        </div>
        <div>
            <mat-checkbox *ngIf="fc.consumers.value" class="mt-1 mb-1" color="primary" formControlName="consumers_site">
                Zichtbaar op website
            </mat-checkbox>
        </div>
        <div>
            <mat-checkbox *ngIf="fc.type.value !== 'drillings'" class="mt-1 mb-1" color="primary" formControlName="archive">
                Archiveren
            </mat-checkbox>
        </div>
        <div>
            <mat-checkbox *ngIf="fc.type.value !== 'drillings'" class="mt-1 mb-1" color="primary" formControlName="email">
                Email updates versturen
            </mat-checkbox>
        </div>
        <div>
            <mat-checkbox *ngIf="fc.type.value !== 'drillings'" class="mt-1 mb-3" color="primary" formControlName="restrict">
                Toegang beperken
            </mat-checkbox>
        </div>
        <div *ngIf="fc.restrict.value">
            <ng-select
                [formControl]="fc.users"
                [items]="townManagers"
                [multiple]="true"
                bindLabel="name"
                bindValue="id"
                placeholder="Klantgebied gebruikers"></ng-select>
        </div>
        <mat-form-field class="w-100">
            <mat-placeholder>Projectleider</mat-placeholder>
            <mat-select formControlName="manager">
                <mat-option *ngFor="let user of managers" [value]="user.id">
                    {{user.name}}
                </mat-option>
            </mat-select>
            <mat-error [control]="fc.manager"></mat-error>
        </mat-form-field>
        <mat-form-field class="w-100" *ngIf="fc.type.value !== 'drillings'">
            <mat-placeholder>Projectleider gemeente</mat-placeholder>
            <mat-select formControlName="town_manager">
                <mat-option *ngFor="let user of townManagers" [value]="user.id">
                    {{user.name}}
                </mat-option>
            </mat-select>
            <mat-error [control]="fc.manager"></mat-error>
        </mat-form-field>
        <mat-checkbox *ngIf="fc.type.value === 'maintenance'" class="mt-5 mb-1" color="primary" formControlName="appendAllCurrentParts">
            Alle bestaande delen toevoegen
        </mat-checkbox>
    </ng-container>
</div>
<div class="modal-footer">
    <button (click)="delete()" color="warn" mat-button>Verwijderen</button>
    <div class="spacer"></div>
    <button (click)="close()" color="primary" mat-button type="button">
        Sluiten
    </button>
    <button (click)="save()" color="primary" mat-raised-button type="button">
        Opslaan
    </button>
</div>
