import {Component, DoCheck, Input, OnDestroy, OnInit} from '@angular/core';
import {User} from '../../../user.class';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../../services/user/user.service';
import {isUndefined} from 'util';
import {Storage} from '../../../storage.class';
import {Message} from '../../../message.class';
import {ConfirmModalService} from '../../../shared/confirm-modal.service';
import {Subscription} from 'rxjs';
import {TownService} from '../../../services/town/town.service';
import {Town} from '../../../town.class';

@Component({
    selector: 'user-detail-edit',
    templateUrl: './user-detail-edit.component.html',
    styleUrls: ['./user-detail-edit.component.scss']
})
export class UserDetailEditComponent implements OnInit, OnDestroy, DoCheck {

    public unsavedChanges = false;
    public initJson: string;
    saveButtonDisabled = false;
    saveButtonText = 'Opslaan';
    message: Message = new Message('');

    user: User;
    loggedInUser: User;

    @Input()
    userId: number;

    @Input()
    backTo = '/users';

    public towns: Town[] = [];

    private routeParamsSubscription: Subscription;

    constructor(private route: ActivatedRoute, private router: Router,
                private confirmModalService: ConfirmModalService,
                private userService: UserService, private townService: TownService) {

    }

    ngOnInit() {
        this.loggedInUser = Storage.getUser();
        if (this.userId) {
            this.userService.getUser(this.userId).then((user: User) => {
                if (!isUndefined(user.id)) {
                    this.user = user;
                    this.user['townIds'] = this.user.towns.map(t => t.id);
                    this.initJson = JSON.stringify(this.user);
                }
                this.townService.getList().then(towns => {
                    this.towns = towns;
                });
            }, () => {
                this.user = new User();
                this.townService.getList().then(towns => {
                    this.towns = towns;
                    this.user['townIds'] = [];
                    this.initJson = JSON.stringify(this.user);
                });
            });
        } else {
            this.townService.getList().then(towns => {
                this.user = new User();
                this.towns = towns;
                this.user['townIds'] = [];
                this.user.group = 'CONSUMER';
                this.initJson = JSON.stringify(this.user);
            });
        }
    }

    ngOnDestroy() {
        if (this.routeParamsSubscription) {
            this.routeParamsSubscription.unsubscribe();
        }
    }

    ngDoCheck(): void {
        if (!this.unsavedChanges && this.initJson && this.initJson != JSON.stringify(this.user)) {
            this.unsavedChanges = true;
            this.saveButtonText = 'Wijzigingen opslaan';
        }
    }

    onSubmit() {
        this.saveButtonDisabled = true;

        if (!this.user.id) {
            this.createUser();
        } else {
            this.saveUser();
        }
    }

    createUser() {
        this.saveUser();
    }

    saveUser() {
        if (this.loggedInUser.id !== this.user.id) {
            this.user.invite = true;
        }
        this.saveButtonText = 'Gebruiker opslaan...';
        this.saveButtonDisabled = true;
        this.userService.changeUser(this.user).then((user: User) => {
            this.saveButtonText = 'Opslaan';
            this.saveButtonDisabled = false;
            this.message = new Message('');
            this.showIsSaved();
            if (this.loggedInUser.id === user.id) {
                Storage.setUser(user);
            }
            this.router.navigateByUrl(this.backTo);
        }, errorResponse => {
            this.message = new Message(errorResponse['data']['errormessage'], true);
            this.saveButtonText = 'Opslaan';
            this.saveButtonDisabled = false;
        });
    }

    showIsSaved() {
        this.saveButtonText = 'Wijzigingen opgeslagen';
        setTimeout(() => {
            this.saveButtonText = 'Wijzigingen opgeslagen';
        }, 8000);
    }

    deleteUser() {

        this.confirmModalService.showModal('Gebruiker verwijderen',
            'Weet je zeker dat je deze gebruiker wilt verwijderen?').then(() => {

            this.userService.deleteUser(this.userId).then((response: object) => {
                this.router.navigate([`/users`]);
            }, errorResponse => {

            });

        }, () => {
        });
    }

    uploadLogo(event) {
        event.stopPropagation();
        event.preventDefault();
        if (!this.user.extra) {
            this.user.extra = {photo: ''};
        }
        const file = event.srcElement.files[0];
        if (file.type === 'image/svg+xml') {
            const reader = new FileReader();
            reader.readAsText(file, "UTF-8");
            reader.onload = (evt) => {
                this.user.extra.photo = `data:image/svg+xml;base64,${window.btoa(evt.target.result + '')}`;
                this.userService.changeUser(this.user);
            };
            reader.onerror = (evt) => {
            };
        } else if (['image/jpeg', 'image/png'].indexOf(file.type) !== -1) {
            const img = new Image();
            img.src = window.URL.createObjectURL(file);
            img.onload = () => {
                const factor = 500 / Math.max(img.naturalWidth, img.naturalHeight);
                const width = factor < 1 ? img.naturalWidth * factor : img.naturalWidth;
                const height = factor < 1 ? img.naturalHeight * factor : img.naturalHeight;
                const elem = document.createElement('canvas');
                elem.width = width;
                elem.height = height;
                const ctx = elem.getContext('2d');
                ctx.drawImage(img, 0, 0, width, height);
                if (file.type === 'image/png') {
                    this.user.extra.photo = ctx.canvas.toDataURL('image/png');
                } else {
                    this.user.extra.photo = ctx.canvas.toDataURL('image/jpeg', 0.85);
                }
                this.userService.changeUser(this.user);
            };
        } else {
            this.confirmModalService.showModal(
                'Verkeerd bestand',
                'Dit bestandsformaat wordt niet ondersteund',
                'Oké'
            ).then(() => {

            }, () => {

            });
        }
    }
}
