import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TownProjectPhase} from '../../town-project-phase';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {MatDialog} from '@angular/material/dialog';
import {TownProjectPhaseEditDialogComponent} from './town-project-phase-edit-dialog/town-project-phase-edit-dialog.component';
import {TownProject} from '../../town-project';
import {CodaltComponent} from '../../codalt.component';
import {TownProjectPhaseService} from '../../services/townproject/town-project-phase.service';
import {BreakpointObserver} from '@angular/cdk/layout';

declare var cordova;

@Component({
    selector: 'app-town-project-phase-list',
    templateUrl: './town-project-phase-list.component.html',
    styleUrls: ['./town-project-phase-list.component.scss']
})
export class TownProjectPhaseListComponent extends CodaltComponent implements OnInit {

    @Output()
    phaseSelected = new EventEmitter<TownProjectPhase>();

    @Input()
    project: TownProject;

    @Input()
    projectPhases: TownProjectPhase[];

    @Input()
    currentProjectPhase: TownProjectPhase;

    @Input()
    selectedPhase: TownProjectPhase;

    disableDragDrop = typeof cordova !== 'undefined';

    constructor(private dialog: MatDialog,
                private breakpointObserver: BreakpointObserver,
                private townProjectPhaseService: TownProjectPhaseService) {
        super();
    }

    ngOnInit(): void {
        if (this.currentProjectPhase && !this.selectedPhase) {
            this.selectPhase(this.projectPhases.find(p => p.id === this.currentProjectPhase.id));
        }
        if (!this.selectedPhase && this.projectPhases.length > 0) {
            this.selectPhase(this.projectPhases[0]);
        }
    }

    selectPhase(projectPhase?: TownProjectPhase) {
        this.selectedPhase = projectPhase;
        this.phaseSelected.emit(projectPhase);
    }

    drop(event: CdkDragDrop<string[]>, items: any[]) {
        moveItemInArray(items, event.previousIndex, event.currentIndex);
        this.townProjectPhaseService.updatePhaseOrder(this.projectPhases, this.project.id).subscribe();
    }

    editPhase(projectPhase?: TownProjectPhase) {
        if (!projectPhase) {
            projectPhase = new TownProjectPhase();
            projectPhase.town_project_phase_activities = [];
            projectPhase.town_project_phase_replies = [];
            projectPhase.townproject_id = this.project.id;
            if (this.projectPhases) {
                const prevFase = this.projectPhases[this.projectPhases.length - 1];
                if (prevFase?.start_date) {
                    const date = new Date(prevFase.start_date);
                    date.setDate(date.getDate() + 1);
                    projectPhase.start_date = date;
                }
            }
        }
        this.dialog.open(TownProjectPhaseEditDialogComponent, {
            panelClass: 'confirm-dialog',
            disableClose: true,
            data: projectPhase
        }).afterClosed().subscribe((phase: TownProjectPhase | TownProject) => {
            if (phase instanceof TownProject) {
                Object.assign(this.project, phase);
                this.project.town_project_phase = this.projectPhases.find(p => p.id === this.project.townprojectphase_id);
            } else {
                if (!phase.deleted_at) {
                    if (!this.projectPhases.find(p => p.id === phase.id) && phase) {
                        this.projectPhases.push(phase);
                    }
                } else {
                    this.projectPhases.splice(this.projectPhases.indexOf(phase), 1);
                }
            }
        });
    }
}
