import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TownProject} from '../town-project';
import {Town} from '../town.class';
import {UserService} from '../services/user/user.service';
import {User} from '../user.class';
import {Utils} from '../utils.class';
import {TownProjectService} from '../services/townproject/town-project.service';
import {ConfirmDialogService} from '../services/confirm-dialog-service/confirm-dialog.service';
import {map} from 'rxjs/operators';
import {TownProjectPhaseService} from '../services/townproject/town-project-phase.service';
import {CodaltComponent} from '../codalt.component';
import {Settings} from '../settings.class';


@Component({
    selector: 'app-town-project-edit-dialog',
    templateUrl: './town-project-edit-dialog.component.html',
    styleUrls: ['./town-project-edit-dialog.component.scss']
})
export class TownProjectEditDialogComponent extends CodaltComponent implements OnInit {

    projectTemplates: TownProject[];

    town: Town;
    project: TownProject;
    users: User[];
    managers: User[];
    townManagers: User[];

    types = Settings.projectTypes;

    form: FormGroup;
    fc: {
        name,
        budget,
        type: FormControl,
        consumers_site: FormControl,
        custom_numbers,
        consumers: FormControl,
        manager: FormControl,
        town_manager: FormControl,
        appendAllCurrentParts,
        template: FormControl,
        archive: FormControl,
        email: FormControl,
        restrict: FormControl,
        users: FormControl
    };

    onlyDrilling?: boolean;

    constructor(
        private userService: UserService,
        private townProjectService: TownProjectService,
        private townProjectPhasesService: TownProjectPhaseService,
        private confirmDialog: ConfirmDialogService,
        private dialog: MatDialogRef<TownProjectEditDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data: {
            town: Town,
            project?: TownProject,
            onlyDrilling?: boolean
        }) {
        super();
        if (data.onlyDrilling) {
            this.onlyDrilling = data.onlyDrilling;
            this.types = this.types.filter(t => t.key === 'drillings');
        }
        this.town = data.town;
        this.project = data.project || new TownProject();
        if (this.onlyDrilling) {
            this.project.type = 'drillings';
        }
        this.fc = {
            name: new FormControl(this.project.name || null, Validators.required),
            budget: new FormControl(this.project.budget || null),
            type: new FormControl(this.project.type || null, Validators.required),
            custom_numbers: new FormControl(this.project.custom_numbers || null),
            consumers: new FormControl(this.project.consumers || null),
            consumers_site: new FormControl(this.project.consumers_site || null),
            manager: new FormControl(this.project.manager || null, Validators.required),
            town_manager: new FormControl(this.project.town_manager || null),
            appendAllCurrentParts: new FormControl(false),
            template: new FormControl(),
            archive: new FormControl(this.project.archive || false),
            email: new FormControl(this.project.email || false),
            restrict: new FormControl(this.project.restrict || false),
            users: new FormControl(this.project.users?.map(u => u.id) || false)
        };
        this.form = new FormGroup(this.fc);
        this.subscriptions.add(this.fc.type.valueChanges.subscribe(value => {
            this.projectTemplates = null;
            this.townProjectService.getProjectTemplates(value).pipe(map(p => p.data)).subscribe(templates => {
                this.projectTemplates = templates;
            });
        }));
        this.subscriptions.add(this.fc.template.valueChanges.subscribe(value => {
            const template = this.projectTemplates?.find(p => p.id === value);
            if (template) {
                this.fc.custom_numbers.setValue(template.custom_numbers);
                this.fc.manager.setValue(template.manager);
                this.fc.name.setValue(template.name);
            }
        }));
    }

    ngOnInit(): void {
        this.userService.getUsers().then(users => {
            this.users = users;
            this.managers = this.users.filter(p => p.group === 'ADMIN');
            this.townManagers = this.users.filter(p => ['GEMEENTE', 'SUBADMIN'].indexOf(p.group) !== -1 && !!p.towns.find(t => t.id === this.town.id));
        });
    }

    close() {
        this.dialog.close();
    }

    save() {
        Utils.triggerValidationP(this.form).then(() => {
            if (this.form.valid) {
                if (this.fc.type.value === 'drillings') {
                    this.fc.custom_numbers.setValue(true);
                }
                Object.assign(this.project, this.form.value);
                this.townProjectService.saveProject(this.project).subscribe((data) => {
                    this.dialog.close(data.data);
                });
            }
        }, () => {
        });
    }

    delete() {
        this.confirmDialog.confirm(
            'Project verwijderen',
            `Weet je zeker dat je het project ${this.project.name} wilt verwijderen?`,
            'Verwijderen',
            'Behouden').then(() => {
            this.townProjectService.deleteProject(this.project.id).subscribe(() => {
                this.dialog.close();
            });
        }, () => {

        });
    }
}
