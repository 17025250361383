<div class="container">
    <div class="contacts" *ngIf="projectId">
        <ng-container *ngFor="let contact of contacts">
            <div class="item" routerLink="/{{Routenames.towns}}/{{townId}}/{{Routenames.projects}}/{{projectId}}/contacts/{{contact.id}}">
                <div [style.background-image]="contact.image | safeBgUrl" class="user-photo">
                    <i class="fas fa-user" *ngIf="!contact.image"></i>
                </div>
                <div class="info">
                    <div>
                        {{contact.name}}
                    </div>
                    <div>
                        <span>
                            tel: {{contact.phone}}
                        </span>
                        <span class="d-none d-sm-inline">
                            |
                        </span>
                        <span class="d-inline d-sm-none">
                            <br>
                        </span>
                        <span>
                            email: {{contact.email}}
                        </span>
                    </div>
                </div>
                <div class="arrow"></div>
            </div>
        </ng-container>
        <div *ngIf="loading" class="item">
            <div class="row">
                <div class="col-12 text-center">
                    <app-processbar></app-processbar>
                </div>
            </div>
        </div>
        <div *ngIf="!loading && !contacts.length" class="item">
            <div class="row cursor-default">
                <div class="col-12 text-center">
                    Er zijn nog geen contactpersonen beschikbaar
                </div>
            </div>
        </div>
        <div *ngIf="Utils.userHasRights('GEMEENTE')" class="item"
             routerLink="/{{Routenames.towns}}/{{townId}}/{{Routenames.projects}}/{{projectId}}/{{Routenames.contacts}}/edit/new">
            <div class="row">
                <div class="col-12 text-center">
                    <i class="fas fa-plus"></i> Contactpersoon toevoegen
                </div>
            </div>
        </div>
    </div>
    <p class="pt-3 mb-1" *ngIf="!projectId">
        <b>Voor welk project neem je contact op?</b>
    </p>
    <div class="contacts" *ngIf="!projectId">
        <ng-container *ngFor="let project of projects">
            <div class="item" routerLink="/{{Routenames.towns}}/{{project.town_id}}/{{Routenames.projects}}/{{project.id}}/contacts">
                <div class="info">
                    <div>
                        {{project.name}}
                    </div>
                </div>
                <div class="arrow"></div>
            </div>
        </ng-container>
        <div *ngIf="loading" class="item">
            <div class="row">
                <div class="col-12 text-center">
                    <app-processbar></app-processbar>
                </div>
            </div>
        </div>
        <div *ngIf="!loading && !projects.length" class="item">
            <div class="row cursor-default">
                <div class="col-12 text-center">
                    Er zijn nog geen projecten beschikbaar
                </div>
            </div>
        </div>
        <div *ngIf="Utils.userHasRights('GEMEENTE')" class="item"
             routerLink="/{{Routenames.towns}}/{{townId}}/{{Routenames.projects}}/{{projectId}}/{{Routenames.contacts}}/edit/new">
            <div class="row">
                <div class="col-12 text-center">
                    <i class="fas fa-plus"></i> Contactpersoon toevoegen
                </div>
            </div>
        </div>
    </div>
</div>
