import {Component, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Settings} from '../settings.class';
import {Polygon, Pushpin} from '../angular-bing-maps/angular-bing-maps.component';
import {TownService} from '../services/town/town.service';
import {Router} from '@angular/router';
import {Region} from '../region.class';
import {Utils} from '../utils.class';
import {Workarea} from '../workarea.class';
import {Town} from '../town.class';
import {WorkareasFilterComponent} from '../workareas-filter/workareas-filter.component';
import {combineLatest, Subscription} from 'rxjs';
import {WorkareaService} from '../services/workarea/workarea.service';
import {Airtable, AirtableService} from '../services/airtable/airtable.service';

@Component({
    selector: 'app-airtable-map',
    templateUrl: './airtable-map.component.html',
    styleUrls: ['./airtable-map.component.scss']
})
export class AirtableMapComponent implements OnInit, OnDestroy {

    public town = new Town();

    public polygons: Polygon[] = [];

    public pushpins: Pushpin[] = [];
    public Settings = Settings;
    public selectedWorkareas: Workarea[] = [];
    public mapGeoTrigger = 0;
    public mapZoomTrigger = 0;
    public mapStyleToggleTrigger = 0;
    public selectedPosition: any = {};
    public zoomChange = 0;
    public searchbarVisible = false;
    public filterbarVisible = false;
    public filterbarWasVisible = false;
    public mobileMenuOpen = false;
    public forceMapWidthFilter = false;
    public forceMapWidthSearch = false;
    public Utils = Utils;
    public currentCoords = {'coords': {'latitude': 0.000, 'longitude': 0.000}};
    public loading = false;
    public workareas: Workarea[];
    public filteredWorkareas: Workarea[];
    public appliedAnyFilter = false;
    screenshot = false;
    public customStyle = {
        elements: {
            mapElement: {
                labelVisible: false
            },
            area: {
                visible: false
            },
            transportation: {
                visible: false
            },
            water: {
                fillColor: '#b0cffa'
            },
            point: {
                visible: false
            }
        }
    };
    projectId;
    selectedCustomer: Airtable;
    private townRegion: Region;
    @ViewChild('mapFilter', {static: true}) private filterRef: WorkareasFilterComponent;
    private scrollToWorkareaId: number;
    private subscriptions = new Subscription();

    constructor(private workareaService: WorkareaService,
                private townService: TownService,
                private airtableService: AirtableService,
                private router: Router,
                private ngZone: NgZone) {


    }

    private _searchinput = '';

    public get searchinput() {
        return this._searchinput;
    }

    ngOnInit() {
        const airtable$ = this.airtableService.getTable();
        const airtableOmzet$ = this.airtableService.getTableOmzet();
        const allGemeenten$ = this.airtableService.getGemeenten();
        const allProvincies$ = this.airtableService.getProvincies();
        combineLatest(airtable$, airtableOmzet$, allGemeenten$, allProvincies$).subscribe(([airtable, omzet, gemeenten, provincies]) => {
            const polygons = [];
            airtable.data = airtable.data.sort((a, b) => {
                const z = {
                    'Klant': 9,
                    'Lopende Aanbesteding': 8,
                    'Niet-potentieel / on-hold': 5,
                    'Potentieel': 7
                }
                return (a.fields.Type?.localeCompare(b.fields.Type) * 1000) + (((z[a.fields.Status] ?? 1) - (z[b.fields.Status] ?? 1)));
            });
            console.log(airtable.data.map(z => z.fields.Status));

            airtable.data.forEach(town => {
                console.log(town.fields.Type);
                if (true) {
                    let dtown: {
                        geometry: {
                            coordinates: [number, number][][]
                        }
                    }[];
                    const isProvincie = town.fields.Type === 'Provincie';
                    if (town.fields.Type === 'Gemeente') {
                        dtown = gemeenten.data.features.filter(f => f.properties.GM_CODE === town.fields.Code && f.properties.H2O === 'NEE');
                    }
                    if (isProvincie) {
                        dtown = provincies.data.features.filter(f => f.properties.statcode === town.fields.Code);
                        console.log(town.fields.Type, dtown);
                    }

                    if (dtown) {
                        if (dtown.length > 0) {
                            const getColor = (status: string, opacity: number) => {
                                if (status === 'Opdrachtgever') {
                                    return `rgba(40, 40, 140, ${opacity})`;
                                }
                                if (status === 'Lopende Aanbesteding') {
                                    return `rgba(255, 255, 0, ${opacity})`;
                                }
                                if (status === 'Niet-potentieel/on-hold') {
                                    return `rgba(150, 150, 150, ${opacity})`;
                                }
                                if (status === 'Inventariseren/analyseren 2024') {
                                    return `rgba(146, 197, 222, ${opacity})`;
                                }
                                if (status === 'Potentieel') {
                                    return `rgba(67, 156, 199, ${opacity})`;
                                }
                                console.log('Onbekende status: ', status);
                                return `rgba(220, 239, 250, ${opacity})`;
                            }
                            dtown.forEach(dt => {
                                dt.geometry.coordinates.forEach(z => {
                                    z.forEach(c => {
                                        polygons.push({
                                            exteriorRing: c.map(z => {
                                                return {longitude: z[0], latitude: z[1]}
                                            }),
                                            fillColor: getColor(town.fields.Status, 0.5),
                                            strokeColor: getColor(town.fields.Status, 1),
                                            strokeThickness: isProvincie ? 1.5 : .5,
                                            data: town
                                        });
                                    });
                                });
                            });
                        } else {
                            console.log('Could not find ', town.fields.Naam, town.fields.Code);
                        }
                    }
                }
            });
            this.polygons = polygons;

            if (false) {
                const displayProperty = 'Omzet 2022';
                let min = 999999999999;
                let max = 0;
                omzet.data.forEach(town => {
                    if (town.fields[displayProperty] > max) {
                        max = town.fields[displayProperty];
                    }
                    if (town.fields[displayProperty] < min && town.fields[displayProperty] > 0) {
                        min = town.fields[displayProperty];
                    }
                });
                console.log(min, max);
                omzet.data.forEach(town => {
                    //console.log(town, town.fields['Omzet 2019'], town.fields['Totale Omzet'], town.fields.Naam);
                    let dtown: {
                        geometry: {
                            coordinates: [number, number][][]
                        }
                    }[];
                    const isProvincie = town.fields.Type === 'Provincie';
                    if (town.fields.Type === 'Gemeente') {
                        dtown = gemeenten.data.features.filter(f => f.properties.GM_CODE === town.fields.Code && f.properties.H2O === 'NEE');
                    }
                    if (isProvincie) {
                        dtown = provincies.data.features.filter(f => f.properties.statcode === town.fields.Code);
                        //console.log(town.fields.Type, dtown);
                    }

                    if (dtown) {
                        if (dtown.length > 0 && town.fields[displayProperty] > 0) {
                            const getColor = (status: number, opacity: number) => {
                                const px = 100 / (max - min);
                                const blue = 20 + Math.min(80, ((status - min) * px));
                                console.log(town.fields.Naam, '\t', blue, town.fields[displayProperty]);
                                return isProvincie ? `rgba(103, 198, 221, ${blue / 100})` : `rgba(15, 0, 100, ${blue / 100})`;
                            }
                            dtown.forEach(dt => {
                                dt.geometry.coordinates.forEach(z => {
                                    z.forEach(c => {
                                        polygons.push({
                                            exteriorRing: c.map(z => {
                                                return {longitude: z[0], latitude: z[1]}
                                            }),
                                            fillColor: getColor(town.fields[displayProperty], 0.5),
                                            strokeColor: getColor(town.fields[displayProperty], 1),
                                            strokeThickness: isProvincie ? 1.5 : .5,
                                            data: town
                                        });
                                    });
                                });
                            });
                        } else {
                            //   console.log('Could not find ', town.fields.Naam, town.fields.Code);
                        }
                    }


                });
            }


        });

        this.centerCountry();

    }

    public viewchangeendEvent(data) {

        this.selectedWorkareas = [];
    }


    public centerCountry() {
        const countryPoints = [
            {
                'latitude': 53.49785,
                'longitude': 4.682922
            }, {
                'latitude': 53.396432,
                'longitude': 7.393799
            }, {
                'latitude': 50.75731,
                'longitude': 6.011581
            }, {
                'latitude': 51.238706,
                'longitude': 3.269119
            }
        ];
        const region = new Region(countryPoints);
        Settings.mapZoom = region.bestMapView(window.innerWidth, window.innerHeight, 20);
        Settings.mapCenter = region.centroid();
    }


    public setZoom(event) {
        setTimeout(() => {
            Settings.mapZoom = event + this.zoomChange;
        });
    }

    public polygonClick(event) {
        this.ngZone.run(() => {
            this.selectedCustomer = event.polygon.data;
        });
    }

    goHome() {
        if (this.town.consumerTown) {
            this.router.navigate([``]);
        } else {
            this.router.navigate([`towns`]);
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }


}
