import {User} from '../user.class';


export class ArticleLike {
    id: number;
    article_id: number;
    user_id: number;
    created_at: Date;
    user: User;
    type: ArticleLikeType;
}

export enum ArticleLikeType {
    leuk = 'leuk', geweldig = 'geweldig', nieuwsgierig = 'nieuwsgierig', bezorgd = 'bezorgd', niet_leuk = 'niet leuk'
}
