import {Injectable} from '@angular/core';
import {ApiService} from './api/api.service';
import {Timeline} from '../classes/timeline.class';
import {TimelineImage} from '../classes/timeline-image.class';
import {TimelineDocument} from '../classes/timeline-document';

@Injectable()
export class TimelineService {

    constructor(private apiService: ApiService) {
    }

    getList(projectIds?: number | number[]) {
        if (!Array.isArray(projectIds)) {
            projectIds = [projectIds];
        }
        return this.apiService.getCall$<Timeline[]>(`timeline`, {projectIds});
    }

    get(slug: string, copy?: boolean) {
        const params = copy ? {copy} : {};
        return this.apiService.getCall$<Timeline>(`timeline/${slug}`, params);
    }

    save(timeline: Timeline) {
        return this.apiService.postCall$<Timeline>('timeline', timeline);
    }

    delete(id: number) {
        return this.apiService.deleteCall$(`timeline/${id}`);
    }

    reserve() {
        return this.apiService.postCall$<Timeline>('timeline/reserve');
    }

    addImage(id, path) {
        return this.apiService.postCall$<TimelineImage>('timeline/add-image', {id, path});
    }

    sortImages(timeline: Timeline) {
        return this.apiService.postCall$<TimelineImage>('timeline/sort-images', {
            id: timeline.id,
            images: timeline.images.map(p => {
                return {
                    id: p.id,
                    adapt_image: p.adapt_image
                };
            })
        });
    }

    rmImage(id) {
        return this.apiService.deleteCall$(`timeline/rm-image/${id}`);
    }


    addDocument(id, path) {
        return this.apiService.postCall$<TimelineDocument>('timeline/add-document', {id, path});
    }

    sortDocuments(timeline: Timeline) {
        return this.apiService.postCall$<TimelineDocument>('timeline/sort-documents', {
            id: timeline.id,
            documents: timeline.documents.map(p => p.id)
        });
    }

    rmDocument(id) {
        return this.apiService.deleteCall$(`timeline/rm-document/${id}`);
    }
}
