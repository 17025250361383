<div class="user-select-none">
    <div class="container">
        <div class="d-flex flex-wrap justify-content-center">
            <div *ngFor="let town of searchedTowns" class="col-sm-6 col-lg-4 col-12  p-2">
                <button (click)="openTown(town)" class="w-100 town-button" color="primary" mat-stroked-button>
                    {{town.name}}
                </button>
            </div>
        </div>
        <div #tooltip="matTooltip"
             matTooltip='Druk op enter om dit gebied te openen'
             matTooltipPosition="below">
        </div>
    </div>
</div>
