import {Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Workarea} from '../../workarea.class';
import {Status} from '../../status.class';
import {Utils} from '../../utils.class';
import {Storage} from '../../storage.class';

@Component({
    selector: 'app-workarea-straatwerk',
    templateUrl: './workarea-straatwerk.component.html',
    styleUrls: ['./workarea-straatwerk.component.scss']
})
export class WorkareaStraatwerkComponent implements OnInit {

    @ViewChild('workareaForm', {static: true}) workareaForm: FormGroup;

    @HostListener('change', ['$event'])
    inputChanged(event) {
        this.formValid.emit(this.workareaForm);
    }

    @Output()
    public formValid = new EventEmitter<FormGroup>();

    @Input()
    public workarea: Workarea;

    @Input()
    statuses: Status[];

    @Input()
    fieldOptions: object;

    public Utils = Utils;

    priorities = ['Hoog - opgelost binnen een dag', 'Midden - opgelost binnen een week', 'Laag - zelf in te plannen'];

    public userIsContractor = false;

    constructor() {
    }

    ngOnInit() {
        if (Storage.getUser().group === 'CONTRACTOR') {
            this.userIsContractor = true;
        }
        this.formValid.emit(this.workareaForm);
        if (!this.workarea.status_id) {
            this.workarea.status_id = 129;
            this.workarea.status = this.statuses[0];
        }
        if (typeof this.workarea.extra.roadblock === 'undefined' || !this.workarea.extra.roadblock) {
            this.workarea.extra.roadblock = 'n.v.t.';
        }
        if (typeof this.workarea.extra.damage === 'undefined' || !this.workarea.extra.damage) {
            this.workarea.extra.damage = 'Verzakking';
        }
    }

    statusChange(event) {
        this.statuses.forEach(status => {
            if (status.id == this.workarea.status_id) {
                this.workarea.status = status;
            }
        });
    }

}
