import {Component, OnDestroy, OnInit} from '@angular/core';
import {CodaltComponent} from '../../codalt.component';
import {ArticleService} from '../../services/article.service';
import {Article} from '../../classes/article.class';
import {ActivatedRoute, Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DateNLFormatAdapter} from '../../date-adapters/date-nlformat-adapter';
import {DateAdapter} from '@angular/material/core';
import {formatDate, Location} from '@angular/common';
import {Routenames} from '../../route-names.enum';
import {Utils} from '../../utils.class';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';
import {validTime} from '../../validators/valid-time.validator';
import {handleTimeValueChange} from '../../utils/handle-time-value-change';
import {CdkDragDrop} from '@angular/cdk/drag-drop';
import {ArticleImage} from '../../classes/article-image.class';
import {TownProjectService} from '../../services/townproject/town-project.service';
import {TownProject} from '../../town-project';
import {ArticleDocument} from '../../classes/article-document';

@Component({
    selector: 'app-article-edit',
    templateUrl: './article-edit.component.html',
    styleUrls: ['./article-edit.component.scss'],
    providers: [
        {provide: DateAdapter, useClass: DateNLFormatAdapter}
    ]
})
export class ArticleEditComponent extends CodaltComponent implements OnInit, OnDestroy {

    reserve = false;
    saving = false;
    form: FormGroup;
    article: Article;
    projects: TownProject[];

    constructor(private articleService: ArticleService,
                private projectService: TownProjectService,
                private location: Location,
                private router: Router,
                private dialog: ConfirmDialogService,
                private confirmDialog: ConfirmDialogService,
                private route: ActivatedRoute) {
        super();
    }

    reorderImages(event: CdkDragDrop<ArticleImage[]>) {
        const movedImage = this.article.images[event.previousIndex];
        this.article.images.splice(event.previousIndex, 1);
        this.article.images.splice(event.currentIndex, 0, movedImage);
        this.articleService.sortImages(this.article).subscribe(() => {
        });
    }

    imageAdaptChanged() {
        this.articleService.sortImages(this.article).subscribe(() => {
        });
    }

    ngOnInit(): void {
        this.subscriptions.add(this.route.params.subscribe(params => {
            this.getArticle(params['id']);
        }));
        this.projectService.getProjects(true).subscribe(projects => {
            this.projects = projects.data;
        });
    }

    public openChangesBackActionCheck(): Promise<boolean> {
        return new Promise((resolve) => {
            if (this.form.dirty) {
                this.dialog.confirm(
                    'Niet opgeslagen wijzigingen',
                    `Wilt u de niet opgeslagen wijzigingen opslaan of verwerpen?`,
                    'Wijzigingen opslaan',
                    'Wijzigingen verwerpen').then(() => {
                    this.save();
                    resolve(false);
                }, () => {
                    resolve(true);
                });
            } else {
                resolve(true);
            }
        });
    }

    save() {
        Utils.triggerValidation(this.form);
        if (this.form.valid) {
            this.saving = true;
            this.articleService.save(this.form.value).subscribe(article => {
                Object.assign(this.article, article.data);
                this.form.markAsPristine();
                this.saving = false;
                if (new Date(this.form.value['publish_date']).getTime() > new Date().getTime()) {
                    this.router.navigateByUrl(`${Routenames.articles}/toekomst`);
                } else {
                    this.router.navigateByUrl(Routenames.articles);
                }

            }, () => {
                this.saving = false;
            });
        }
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    uploadedFiles(files) {
        if (!this.article.images) {
            this.article.images = [];
        }
        files.file.forEach(file => {
            this.articleService.addImage(this.article.id, file.filename).subscribe(img => {
                const loading = this.article.images.find(p => !p.id);
                if (loading) {
                    img.data.thumb = loading.thumb;
                    img.data.adapt_image = true;
                    Object.assign(loading, img.data);
                } else {
                    this.article.images.push(img.data);
                }
            });
        });
    }

    uploadFiles(files?: File[]) {
        Array.from(files).forEach(file => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                if (!this.article.images) {
                    this.article.images = [];
                }
                this.article.images.push({
                    thumb: reader.result.toString()
                } as ArticleImage);
            };
        });
    }

    deleteImage(image: ArticleImage) {
        this.articleService.rmImage(image.id).subscribe(() => {
            const index = this.article.images.indexOf(image);
            this.article.images.splice(index, 1);
        });
    }

    uploadedMainImage(file) {
        this.form.get('image').setValue(file ? file.file.filename : null);
    }

    uploadMainImage(files) {
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = () => {
            this.article.image_thumb = reader.result.toString();
        };
        this.form.get('image').setValue(null);
    }

    reorderDocuments(event: CdkDragDrop<ArticleDocument[]>) {
        const movedImage = this.article.documents[event.previousIndex];
        this.article.documents.splice(event.previousIndex, 1);
        this.article.documents.splice(event.currentIndex, 0, movedImage);
        this.articleService.sortDocuments(this.article).subscribe(() => {
        });
    }

    deleteDocument(document: ArticleDocument) {
        this.articleService.rmDocument(document.id).subscribe(() => {
            const index = this.article.documents.indexOf(document);
            this.article.documents.splice(index, 1);
        });
    }

    uploadedDocuments(files) {
        if (!this.article.documents) {
            this.article.documents = [];
        }
        files.file.forEach(file => {
            this.articleService.addDocument(this.article.id, file.filename).subscribe(img => {
                const loading = this.article.documents.find(p => !p.id);
                if (loading) {
                    Object.assign(loading, img.data);
                } else {
                    this.article.documents.push(img.data);
                }
            });
        });
    }

    private getArticle(slug) {
        this.reserve = slug === 'nieuw';
        const article$ = this.reserve ? this.articleService.reserve() : this.articleService.get(slug, this.route.snapshot.url[1].path === 'kopie');
        article$.subscribe(article => {
            this.article = article.data;
            this.generateForm(this.article);
        }, error => {
            this.dialog.confirm('Fout', error.message, 'Terug naar overzicht', null).then(() => {
                this.router.navigateByUrl(Routenames.articles);
            });
        });
    }

    private generateForm(article: Article) {
        const images = article.images ? article.images.map(img => img.id) : [];
        if (!article.publish_date) {
            article.publish_date = new Date();
            if (article.publish_date.getMinutes() > 44) {
                article.publish_date.setHours(article.publish_date.getHours() + 2);
            } else {
                article.publish_date.setHours(article.publish_date.getHours() + 1);
            }
            article.publish_date.setMinutes(0);
        }
        const fcPublishTime = new FormControl(
            article.publish_date ? formatDate(article.publish_date, 'HH:mm', 'nl') : null,
            {validators: [validTime(), Validators.required], updateOn: 'blur'}
        );
        const fcPublishDate = new FormControl(article.publish_date ? article.publish_date : new Date());
        this.form = new FormGroup({
            id: new FormControl(article.id),
            title: new FormControl(article.title, Validators.required),
            alert: new FormControl(article.alert || false),
            adapt_image: new FormControl(article.adapt_image ?? true),
            content: new FormControl(article.content, Validators.required),
            publish_date: fcPublishDate,
            publish_time: fcPublishTime,
            images: new FormControl(images),
            image: new FormControl(article.image),
            townproject_id: new FormControl(article.townproject_id, Validators.required)
        });
        this.subscriptions.add(fcPublishTime.valueChanges.subscribe(
            value => handleTimeValueChange(value, fcPublishTime)
        ));
        this.subscriptions.add(this.form.valueChanges.subscribe(() => {
            if (fcPublishTime.value && fcPublishDate.value) {
                const time = fcPublishTime.value.split(':');
                if (time.length === 2) {
                    const date = new Date(fcPublishDate.value);
                    date.setHours(time[0]);
                    date.setMinutes(time[1]);
                    if (date.toString() !== (new Date(fcPublishDate.value)).toString()) {
                        fcPublishDate.setValue(date);
                    }
                }
            }
        }));
    }
}
