import {Injectable} from '@angular/core';
import {CordovaService} from './cordova.service';

@Injectable()
export class CameraService {

    constructor(private cordovaService: CordovaService) {
    }

    getPicture() {
        return new Promise((resolve, reject) => {
            navigator['camera'].getPicture((data) => {
                resolve(data);
            }, (data) => {
                reject(data);
            }, {
                quality: 50,
                targetWidth: 1600,
                targetHeight: 1600,
                destinationType: 0
            });
        });
    }

}
