export class Document {
    public id: number;

    public name: string;

    public order: number;

    public parent: any;

    public file: string;
    public orginal_file: string;
    public ext: string;
    public filesize: string;

    public created_at: Date;

    public updated_at: Date;

    public deleted_at: any;

    public sub_items: Document[] = [];

    public workarea_id: number;

}
