import {Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Workarea} from '../../workarea.class';
import {Status} from '../../status.class';
import {Utils} from '../../utils.class';

@Component({
    selector: 'app-workarea-houthaven',
    templateUrl: './workarea-houthaven.component.html',
    styleUrls: ['./workarea-houthaven.component.scss']
})
export class WorkareaHouthavenComponent implements OnInit {

    @ViewChild('workareaForm', {static: true}) workareaForm: FormGroup;

    @HostListener('change', ['$event'])
    inputChanged(event) {
        this.formValid.emit(this.workareaForm);
    }

    @Output()
    public formValid = new EventEmitter<FormGroup>();

    @Input()
    public workarea: Workarea;

    @Input()
    statuses: Status[];

    @Input()
    fieldOptions: object;

    public Utils = Utils;

    constructor() {
    }

    ngOnInit() {
        this.formValid.emit(this.workareaForm);
        this.workarea.status_id = 114;
        this.workarea.status = this.statuses[0];
    }

    statusChange(event) {
        this.statuses.forEach(status => {
            if (status.id == this.workarea.status_id) {
                this.workarea.status = status;
            }
        });
    }

}
