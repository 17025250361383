<form #workareaForm="ngForm">
    <div class="row">
        <div *ngIf="projects?.length >0" class="col-12 col-md-4">
            <mat-form-field class="w-100">
                <mat-select (valueChange)="inputChanged($event)"
                            [(ngModel)]="workarea.townproject_id"
                            [disabled]="userIsContractor"
                            name="townproject_id"
                            placeholder="Project">
                    <mat-option [value]="undefined" disabled selected>Project</mat-option>
                    <mat-option *ngFor="let project of projects"
                                [value]="project.id">{{project.name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 {{projects?.length >0 ? 'col-md-5' : 'col-md-9'}}">
            <mat-form-field class="w-100">
                <input #street="ngModel" [(ngModel)]="workarea.street" [disabled]="userIsContractor"
                       errorState="street.errors?.required && (street.dirty || workareaForm.submitted)"
                       id="streetn" matInput
                       name="street" placeholder="Straatnaam" required
                       type="text">
            </mat-form-field>
        </div>
        <div class="col-12 col-md-3">
            <mat-form-field class="w-100">
                <input #housenumber="ngModel" [(ngModel)]="workarea.housenumber" [disabled]="userIsContractor"
                       errorState="housenumber.errors?.required && (housenumber.dirty || workareaForm.submitted)"
                       id="hnumber" matInput
                       name="housenumber" placeholder="Huisnummer" required
                       type="text">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-md-3">
            <mat-form-field class="w-100">
                <input [(ngModel)]="workarea.zipcode" [disabled]="userIsContractor" id="zipcode" matInput
                       name="zipcode" placeholder="Postcode"
                       type="text">
            </mat-form-field>
        </div>
        <div class="col-12 col-md-6">
            <mat-form-field class="w-100">
                <input #place="ngModel" [(ngModel)]="workarea.place" [disabled]="userIsContractor" errorState="place.errors?.required && (place.dirty || workareaForm.submitted)"
                       id="town" matInput
                       name="place" placeholder="Plaatsnaam" required
                       type="text">
            </mat-form-field>
        </div>
        <div class="col-12 col-md-3">
            <mat-form-field class="w-100">
                <input [(ngModel)]="workarea.road_number" [disabled]="userIsContractor" id="road_number" matInput
                       name="road_number" placeholder="Wegnummer"
                       type="text">
            </mat-form-field>
        </div>
    </div>
</form>
