import {Injectable} from '@angular/core';
import {ApiService} from '../../services/api/api.service';

@Injectable()
export class UploadService {

    constructor(private apiService: ApiService) {
    }

    public getUploads(): Promise<object> {

        return this.apiService.getCall('/file/upload/files');
    }

}
