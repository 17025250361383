<div class="container">
    <div class="row justify-content-center">
        <div class="col-12 col-xl-9">
            <div class="d-flex pb-2 pt-2 justify-content-end align-items-center">
                <mat-checkbox (change)="searching()" [(ngModel)]="showConsumers" class="mr-2">
                    Toon bewoners
                </mat-checkbox>
                <button (click)="newUser()" color="primary" mat-raised-button>
                    Toevoegen
                </button>
            </div>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-12 col-xl-10">
            <table class="table table-striped table-hover usersTable">
                <thead>
                <tr>
                    <th>Naam</th>
                    <th class="email">Email</th>
                    <th>Groep</th>
                    <th>Gemeente(n)</th>

                    <th></th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td *ngIf="!users" colspan="5">
                        <app-processbar></app-processbar>
                    </td>
                </tr>
                <tr *ngFor="let user of searchedUsers" class="curpoint">
                    <td (click)="showUser(user.id)">
                        {{user.name}}
                    </td>
                    <td (click)="showUser(user.id)" class="email">
                        {{user.email}}
                    </td>
                    <td (click)="showUser(user.id)">
                        {{userGroupMap.get(user.group)}}
                    </td>
                    <td (click)="showUser(user.id)">
                        <ng-container *ngIf="user.group !== 'ADMIN'">
                            <ng-container *ngFor="let town of user.towns | slice : 0 :3; let last = last">
                                {{town.code}}{{last ? '' : ', '}}
                            </ng-container>
                            <small *ngIf="user.towns?.length > 3">
                                (en {{user.towns.length - 3}} meer)
                            </small>
                        </ng-container>
                    </td>
                    <td class="text-right">
                        <button (click)="revokeAccess(user)" color="warn" mat-icon-button>
                            <mat-icon class="fas fa-trash"></mat-icon>
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
