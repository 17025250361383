import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../services/api/api.service';
import {Settings} from '../settings.class';
import {Storage} from '../storage.class';
import {CordovaService} from '../cordova.service';
import {User} from '../user.class';
import {Utils} from '../utils.class';
import {ExportService} from '../services/export/export.service';
import {ConfirmModalService} from '../shared/confirm-modal.service';
import {AuthService} from '../services/auth/auth.service';
import {HttpClient} from '@angular/common/http';
import {WorkareaService} from '../services/workarea/workarea.service';
import {Subscription} from 'rxjs';
import {AddTownprojectModalComponent} from './add-townproject-modal/add-townproject-modal.component';
import {MatDialog} from '@angular/material/dialog';
import {TownProject} from '../town-project';
import {TownProjectService} from '../services/townproject/town-project.service';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {UserService} from '../services/user/user.service';

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit, OnDestroy {

    public settings: any = {statusses: []};
    public Utils = Utils;

    public StaticSettings = Settings;

    public cordovaVersion = 'web versie';
    public version: any = {commit: 'unknown', datetime: 'unknown', version: 'unknown'};

    public Storage = Storage;

    public user: User;

    public markers = {};

    public townTypeIsStraatwerk = false;

    public staticmapsettings = [
        {
            key: 'show_cancelled',
            description: 'Toon afgebroken opdrachten'
        },
        {
            key: 'show_executed_month',
            description: 'Toon uitgevoerde opdrachten ouder dan 1 maand'
        },
        {
            key: 'show_executed_year',
            description: 'Toon uitgevoerde opdrachten ouder dan 1 jaar'
        },
        {
            key: 'show_approved',
            description: 'Toon goedgekeurde opdrachten'
        },
        {
            key: 'show_own',
            description: 'Toon enkel eigen opdrachten'
        }
    ];

    private subscriptions = new Subscription();

    constructor(private apiService: ApiService, private http: HttpClient,
                private router: Router,
                private route: ActivatedRoute,
                private cordovaService: CordovaService,
                private exportService: ExportService,
                private confirmModal: ConfirmModalService,
                private workareaService: WorkareaService,
                private townProjectService: TownProjectService,
                private userService: UserService,
                private matDialog: MatDialog,
                private authService: AuthService) {
        if (Object.keys(Settings.mapSettings).length === 0) {
            this.staticmapsettings.forEach(setting => {
                if (!Settings.mapSettings[setting.key]) {
                    Settings.mapSettings[setting.key] = true;
                }
            });
        }
    }

    removeTownProject(townproject: TownProject) {
        this.townProjectService.unfollow(townproject.id).subscribe(() => {
            this.authService.getUserDetails().subscribe(() => {
                this.user = Storage.getUser();
            });
        }, () => {
            this.confirmModal.showModal('Uitschrijven mislukt',
                'Het is niet gelukt om je uit te schrijven voor ' + townproject.name);
        });
    }

    addTownProject() {
        this.subscriptions.add(this.matDialog.open(AddTownprojectModalComponent, {
            panelClass: 'add-townproject-dialog',
            maxWidth: '100vw',
            maxHeight: '100vh',
            data: {
                user: User
            }
        }).afterClosed().subscribe(() => {
            this.authService.getUserDetails().subscribe(() => {
                this.user = Storage.getUser();

            });
        }));
    }

    exportConsumer() {
        let modal: any;

        this.confirmModal.showModal('Exporteren naar excel',
            `We zijn het excel-bestand naar uw e-mail aan het versturen...`,
            null, null, m => {
                modal = m;
            });

        this.exportService.exportExcelConsumers(Storage.getTown()['id']).then(() => {

            modal.hide();
            this.confirmModal.showModal('Exporteren naar excel',
                `We hebben het Excel-bestand naar uw e-mailadres ${Storage.getUser()['email']} verzonden.
                Heeft u het bericht over 15 minuten nog niet ontvangen? Controleer dan uw spam map.`,
                'Oke', null);

        }, error => {
            const err = error['error']['error']['errormessage'];
            this.confirmModal.showModal('Oeps...', `Er is iets fout gegaan op de server ${err}`, 'Oke', null).then(() => {
            });
        });
    }

    exportExcel() {

        let modal: any;

        this.confirmModal.showModal('Exporteren naar excel',
            `We zijn het excel-bestand naar uw e-mail aan het versturen...`,
            null, null, m => {
                modal = m;
            });

        this.exportService.exportExcelWorkareas(Storage.getTown()['id']).then(() => {

            modal.hide();
            this.confirmModal.showModal('Exporteren naar excel',
                `We hebben het Excel-bestand naar uw e-mailadres ${Storage.getUser()['email']} verzonden.
                Heeft u het bericht over 15 minuten nog niet ontvangen? Controleer dan uw spam map.`,
                'Oke', null);

        }, error => {
            const err = error['error']['error']['errormessage'];
            this.confirmModal.showModal('Oeps...', `Er is iets fout gegaan op de server ${err}`, 'Oke', null).then(() => {
            });
        });

    }

    exportPdf() {

        let modal: any;

        this.confirmModal.showModal('Exporteren naar PDF',
            `We zijn het PDF-bestand naar uw e-mail aan het versturen...`,
            null, null, m => {
                modal = m;
            });

        this.exportService.exportPdfWorkareas(this.Storage.getTown()['id']).then(() => {
            modal.hide();
            this.confirmModal.showModal('Opdrachten PDF',
                `We hebben het PDF-bestand naar uw e-mailadres ${Storage.getUser()['email']} verzonden.
                Heeft u het bericht over 15 minuten nog niet ontvangen? Controleer dan uw spam map.`,
                'Oke', null);

        }, error => {
            const err = error['error']['error']['errormessage'];
            this.confirmModal.showModal('Oeps...', `Er is iets fout gegaan op de server ${err}`, 'Oke', null).then(() => {
            });
        });

    }

    ngOnInit() {
        if (Storage.getTown()) {
            if (Storage.getTown().towntype === 'straatwerk') {
                this.townTypeIsStraatwerk = true;
            }

            if (Utils.userHasRights('GEMEENTE')) {
                this.workareaService.getAvailableStatuses(Storage.getTown()['towntype']).then(statuses => {
                    this.settings = {};
                    this.settings['statusses'] = statuses;
                    let i = 0;
                    this.settings.statusses.forEach((status) => {
                        this.markers[i] = Settings.getMarker(1 + i, 1 + i, status.colorcode);
                        i++;
                    });
                });
            }
        }
        this.authService.getUserDetails().subscribe(() => {
            this.user = Storage.getUser();
        });
        this.http.get('assets/version.json').toPromise().then(versionInfo => {
            this.version = versionInfo;
        }, () => {

        });
    }

    logout() {
        this.authService.logout().subscribe();
        this.router.navigate(['login']);
    }

    mapSettingsChanged() {
        this.workareaService.resetLastTownId();
        Storage.setMapSettings(Settings._mapSettings);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    projectEmailNotifChanged(event: MatSlideToggleChange) {
        this.userService.changeUser(this.user);
        Storage.setUser(this.user);
    }

}

