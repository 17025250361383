import {WorkareaImage} from './workarea-image';
import {User} from './user.class';
import {Status} from './status.class';
import {Document} from './document';
import {TownProject} from './town-project';

export class Workarea {

    public id: number = null;
    public town_id: number;
    public user_id: number;
    public user: User;
    public status_id: number;
    public number: number;
    public street: string;
    public zipcode: string;
    public housenumber: string;
    public place: string;
    public road_number: string;
    public lat: number;
    public lng: number;
    public comments: string;
    public feedback: string;
    public project_id: number;
    public extra: any = {};
    public areas: any = [];

    public status: Status;
    public status_name: string;
    public status_colorcode: string;

    public image: string;
    public images: Array<WorkareaImage> = [];

    public created_at: Date;
    public updated_at: Date;
    public deleted_at: Date;

    public documents: Document[];

    public has_documents: boolean;
    public type?: string;
    public townproject_id?: number;
    public townProject?: TownProject;


    constructor() {
    }

}
