import {Component, OnInit} from '@angular/core';
import {MessageService} from '../shared/message.service';
import {Message} from '../shared/message';
import {Router} from '@angular/router';
import {Utils} from '../../utils.class';
import {ConfirmModalService} from '../../shared/confirm-modal.service';
import {Storage} from '../../storage.class';

@Component({
    selector: 'app-news-overview',
    templateUrl: './news-overview.component.html',
    styleUrls: ['./news-overview.component.scss']
})
export class NewsOverviewComponent implements OnInit {

    public Utils = Utils;
    public Storage = Storage;

    public editMode = false;
    public messages: Array<Message> = new Array<Message>();
    public loading = true;
    public showMore = false;
    public numberOfItemsEachTime = 30;

    inProgress = false;

    constructor(private messageService: MessageService, private router: Router,
                private confirmModal: ConfirmModalService) {
    }

    ngOnInit() {
        this.loadMessages();
    }

    private loadMessages() {
        this.loading = true;
        this.messageService.getList(this.messages.length, this.numberOfItemsEachTime).then(messages => {

            this.messages = this.messages.concat(messages);
            this.loading = false;
            this.showMore = messages.length === this.numberOfItemsEachTime;
            this.inProgress = false;

        }, () => {
            this.confirmModal.showModal('Fout bij ophalen berichten', 'Kon berichten niet ophalen', 'Oke', null);
        });
    }

    open(id) {
        if (this.editMode) {
            this.router.navigate([`news/edit/${id}`]);
        } else {
            this.router.navigate([`news/${id}`]);
        }
    }

    onPull() {
        this.inProgress = true;
        this.messages = [];
        this.loading = true;
        setTimeout(() => {
            this.loadMessages();
        }, 100);
    }

}
