import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {FileItem} from './file-item';
import {ApiService} from '../services/api/api.service';
import {ConfirmDialogService} from '../services/confirm-dialog-service/confirm-dialog.service';
import {FileService} from '../services/file.service';
import {Town} from '../town.class';

@Component({
    selector: 'app-document-upload',
    templateUrl: './document-upload.component.html',
    styleUrls: ['./document-upload.component.scss']
})
export class DocumentUploadComponent implements OnInit {

    @Input('storage')
    storage: string;

    @Input()
    fileTypes = ['application/pdf'];

    @Input()
    icon = '../../assets/images/icons/townprojects/add.svg';

    @Input()
    buttonIcon = false;

    @Input()
    town: Town;

    @Input()
    currentPath: string;

    @Output()
    uploaded = new EventEmitter<FileItem>();


    loaded = false;
    uploading = false;
    dragOver = false;
    currentDir: FileItem[];
    selectedFiles: FileItem[];

    constructor(private apiService: ApiService,
                private fileService: FileService,
                private confirmDialogService: ConfirmDialogService) {
    }

    ngOnInit(): void {
        this.readDir(this.currentPath);
    }

    @HostListener('dragover', ['$event'])
    public ondragover(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.dragOver = true;
    }

    @HostListener('dragleave', ['$event'])
    public ondragleave(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        if (evt.srcElement.classList.contains('dialog-container') || evt.srcElement.classList.contains('overlay')) {
            this.dragOver = false;
        }
    }

    @HostListener('drop', ['$event'])
    public ondrop(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.dragOver = false;
        const files = evt.dataTransfer.files;
        this.uploadFiles(files);
    }

    public uploadFile(event) {
        event.stopPropagation();
        event.preventDefault();
        const files = event.srcElement.files;
        this.uploadFiles(files);
    }

    public uploadFiles(files: File[]) {
        if (files.length > 0) {
            const uploadedFiles = [];
            for (let i = 0; i < files.length; i++) {
                this.uploading = true;
                if (!files[i].size || files[i].size < 110664300) { // ~100MB
                    if (this.fileTypes.indexOf(files[i].type) === -1) {
                        this.uploading = false;
                        this.confirmDialogService.confirm(
                            'Dit bestandstype is niet toegestaan!',
                            `${files[i].name} is geen toegestaan bestandtype`,
                            'Oké', null);
                        return;
                    }
                    this.apiService.makeFileRequest$<FileItem>(
                        'file/upload',
                        files[i],
                        {
                            path: this.currentPath,
                            current_town_id: this.town.id,
                            secure: this.storage
                        })
                        .subscribe(data => {
                            if (data['success']) {
                                this.uploaded.emit(data.data);
                                this.uploading = false;
                            } else {
                                this.uploading = false;
                                this.uploadErrorMessage(data['error']['upload'][0]);
                            }
                        }, error => {
                            this.uploading = false;
                            this.uploadErrorMessage(`Bestand: ${files[i].name}<br>${error['errormessage'] ? error['errormessage'] : 'Je kunt dit niet uploaden!'}`);
                        });
                } else {
                    if (files.length === 1) {
                        this.uploading = false;
                    }
                    this.confirmDialogService.confirm(
                        'Bestand te groot',
                        `${files[i].name} is te groot, de maximale grootte is 100MB.`,
                        'Oké', null);
                }
            }
        }
    }

    reload() {
        this.readDir(this.currentPath);
    }

    public readDir(dir = '') {
        this.loaded = false;
        this.fileService.read(dir).subscribe(dirContent => {
            this.currentPath = dir;
            this.currentDir = dirContent.data;
            this.loaded = true;
        });
    }

    private uploadErrorMessage(message) {
        this.confirmDialogService.confirm(
            'Er is iets foutgegaan bij het uploaden',
            `${message}`,
            'Oké', null);
    }
}
