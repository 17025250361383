import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TownProjectPhaseActivity} from '../../../town-project-phase-activity';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {TownProjectPhaseActivityService} from '../../../services/townproject/town-project-phase-activity.service';
import {ConfirmDialogService} from '../../../services/confirm-dialog-service/confirm-dialog.service';
import {MatSelectChange} from '@angular/material/select';
import {Storage} from '../../../storage.class';
import {map} from 'rxjs/operators';

@Component({
    selector: 'app-town-project-phase-activity-edit-dialog',
    templateUrl: './town-project-phase-activity-edit-dialog.component.html',
    styleUrls: ['./town-project-phase-activity-edit-dialog.component.scss']
})
export class TownProjectPhaseActivityEditDialogComponent implements OnInit {

    form: FormGroup;
    fc: {
        id: FormControl,
        name: FormControl,
        assignee: FormControl,
        deadline: FormControl,
        executed: FormControl,
        townprojectphase_id: FormControl,
        order: FormControl,
        user_id: FormControl
    };

    assignees: {
        user_id: number,
        name: string
    }[];
    assignee: {
        user_id: number,
        name: string
    };

    saving = false;

    constructor(private dialog: MatDialogRef<TownProjectPhaseActivityEditDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public phaseActivity: TownProjectPhaseActivity,
                private confirmDialog: ConfirmDialogService,
                private townProjectPhaseActivityService: TownProjectPhaseActivityService) {
    }


    ngOnInit(): void {
        this.fc = {
            id: new FormControl(this.phaseActivity.id),
            name: new FormControl(this.phaseActivity.name, Validators.required),
            assignee: new FormControl(this.phaseActivity.assignee),
            user_id: new FormControl(this.phaseActivity.user_id),
            deadline: new FormControl(this.phaseActivity.deadline),
            executed: new FormControl(this.phaseActivity.executed),
            townprojectphase_id: new FormControl(this.phaseActivity.townprojectphase_id),
            order: new FormControl(this.phaseActivity.order)
        };
        this.form = new FormGroup(this.fc);
        this.assignees = [
            {user_id: null, name: 'OG'},
            {user_id: null, name: 'ON'}
        ];
        this.townProjectPhaseActivityService.assignees(Storage.getTown()['id']).pipe(map(d => d.data)).subscribe(users => {
            this.assignees = this.assignees.concat(users.map(u => {
                return {user_id: u.id, name: u.name};
            }));
            this.assignee = this.assignees.find(a => a.name === this.phaseActivity.assignee && a.user_id === this.phaseActivity.user_id);
        });
    }

    assigneeChange(event: MatSelectChange) {
        this.fc.assignee.setValue(event.value.name);
        this.fc.user_id.setValue(event.value.user_id);
    }

    delete() {
        this.confirmDialog.confirm(
            'Verwijderen',
            `Weet je zeker dat je deze activiteit wilt verwijderen?`,
            'Verwijderen',
            'Behouden').then(() => {
            this.townProjectPhaseActivityService.delete(this.phaseActivity.id).subscribe(result => {
                Object.assign(this.phaseActivity, result.data);
                this.dialog.close(this.phaseActivity);
            });
        }, () => {

        });
    }

    save() {
        this.townProjectPhaseActivityService.save(this.form.value).subscribe(result => {
            Object.assign(this.phaseActivity, result.data);
            this.dialog.close(this.phaseActivity);
        });
    }

}
