<div class="container bg-white mt-2 item-edit">
    <div *ngIf="form">
        <form [formGroup]="form">
            <div (click)="fileManagerMain.chooseSource()"
                 class="main-image">
                <app-codalt-image [path]="form.value.id+'/'+form.controls['image'].value"
                                  [adapt]="form.controls['adapt_image'].value"
                                  [thumb]="article.image_thumb">
                </app-codalt-image>
                <file-manager #fileManagerMain
                              (callback)="uploadedMainImage($event)"
                              (loading)="uploadMainImage($event)"
                              [class.text-white]="isCordova && form.controls['image'].value"
                              [currentFile]="form.controls['image'].value"
                              [directUpload]="true"
                              [path]="form.value.id"
                              [remove]="true"
                              icon="{{isCordova ? 'fas fa-image' : null}}"
                              secure="article">
                </file-manager>
            </div>
            <div class="row">
                <mat-form-field class="col-auto flex-grow-1 mt-3">
                    <mat-label>Titel</mat-label>
                    <input formControlName="title" matInput type="text">
                    <mat-error>Titel is een verplicht veld</mat-error>
                </mat-form-field>
                <mat-checkbox class="col-auto mt-2 mt-lg-4" formControlName="alert">Belangrijk bericht</mat-checkbox>
                <mat-checkbox class="col-auto mt-2 mt-lg-4" formControlName="adapt_image">Afbeelding vullend</mat-checkbox>
            </div>
            <mat-form-field *ngIf="projects" class="w-100 mt-4">
                <mat-placeholder>Kies een project</mat-placeholder>
                <mat-select formControlName="townproject_id">
                    <mat-option *ngFor="let project of projects" [value]="project.id">
                        {{project.name}}
                    </mat-option>
                </mat-select>
                <mat-error>Kies een project</mat-error>
            </mat-form-field>
            <div class="w-100 col-date">
                <mat-form-field (click)="picker.open()" class="w-50">
                    <mat-label>Publicatiedatum</mat-label>
                    <input [matDatepicker]="picker" formControlName="publish_date" matInput>
                    <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-error>De publicatiedatum is verplicht</mat-error>
                </mat-form-field>
                <mat-form-field class="w-50 pl-2">
                    <mat-label>Publicatietijd</mat-label>
                    <input formControlName="publish_time" matInput placeholder="hh:mm" type="text">
                    <mat-error>De publicatietijd is verplicht</mat-error>
                </mat-form-field>
            </div>
            <div>
                <b>Beschrijving: </b>
                <simple-tiny [content]="form.get('content')"
                             elementId="content"
                             ngDefaultControl
                             propertyName="value"
                ></simple-tiny>
                <mat-error *ngIf="form.get('content')?.invalid">Content is een verplicht veld</mat-error>
            </div>
            <div class="pb-3">
                <b>Afbeeldingen</b>
                <div (cdkDropListDropped)="reorderImages($event)" cdkDropList class="images">
                    <div *ngFor="let image of article.images" cdkDrag class="image-edit">
                        <app-codalt-image [path]="article.id + '/' + image.path"
                                          [adapt]="image.adapt_image"
                                          [thumb]="image.thumb">
                        </app-codalt-image>
                        <button (click)="deleteImage(image)" color="primary" mat-mini-fab>
                            <mat-icon class="fas fa-trash"></mat-icon>
                        </button>
                        <mat-checkbox class="col-auto"
                                      [checked]="image.adapt_image"
                                      (change)="image.adapt_image = $event.checked; imageAdaptChanged()">
                            Afbeelding vullend
                        </mat-checkbox>
                        <div cdkDragHandle class="drag-handle">
                            <i class="fa fa-arrows-alt-v"></i>
                        </div>
                    </div>
                </div>
                <div class="mt-2 mb-2">
                    <file-manager
                        (callback)="uploadedFiles($event)"
                        (loading)="uploadFiles($event)"
                        [directUpload]="true"
                        [multiple]="true"
                        [path]="form.value.id"
                        secure="article">
                    </file-manager>
                </div>
                <div class="pb-3">
                    <b>PDF documenten</b>
                    <table (cdkDropListDropped)="reorderDocuments($event)" cdkDropList>
                        <tr *ngFor="let document of article.documents" cdkDrag>
                            <td cdkDragHandle class="curmove">
                                <i class="fas fa-file-pdf-o"></i>
                            </td>
                            <td cdkDragHandle class="curmove">
                                {{document.path}}
                            </td>
                            <td>
                                <button (click)="deleteDocument(document)" color="primary" mat-icon-button>
                                    <mat-icon class="fas fa-trash"></mat-icon>
                                </button>
                            </td>
                        </tr>
                    </table>
                    <div class="mt-2 mb-2">
                        <file-manager
                            (callback)="uploadedDocuments($event)"
                            [directUpload]="true"
                            [multiple]="true"
                            [path]="form.value.id"
                            secure="article">
                        </file-manager>
                    </div>
                </div>
            </div>
        </form>

    </div>
    <button (click)="save()" [disabled]="saving" class="save-button" color="primary" mat-fab>
        <mat-icon *ngIf="!saving" class="fas fa-save"></mat-icon>
        <mat-icon *ngIf="saving" class="fas fa-circle-notch fa-spin"></mat-icon>
    </button>
</div>
