import {EventEmitter, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ChatMessage} from './chat-message';
import {WebsocketService} from './websocket.service';
import {ApiService} from '../services/api/api.service';

@Injectable({
    providedIn: 'root'
})
export class ChatMessageService {

    public totalCount = 0;
    private items: ChatMessage[] = [];
    private itemChanged = new EventEmitter<number[]>();
    private currentChatId: number;
    private retryTimeoutPointer;

    constructor(private websocket: WebsocketService, private apiService: ApiService) {
        this.websocketInit();
        this.websocket.websocketOpen.subscribe(() => {
            if (this.currentChatId) {
                this.requestMessages(this.currentChatId);
            }
        });
    }

    public getList(chatId: number): Observable<ChatMessage[]> {
        return new Observable<ChatMessage[]>((observer) => {
            const itemChangedSubscription = this.itemChanged.subscribe(() => {
                observer.next(this.items);
            }, error => {
                observer.error();
            });

            this.items = [];
            this.currentChatId = chatId;
            this.requestMessages(chatId);

            return {
                unsubscribe(): void {
                    itemChangedSubscription.unsubscribe();
                }
            };
        });
    }

    public requestMessages(chatId: number, fromId: string | number = '', limit = 100) {
        this.websocket.doRequest('chatMessage', {
            chatId,
            limit,
            fromId
        }, 'get');
    }

    public newMessage(message: ChatMessage) {
        this.websocket.doRequest('chatMessage', message);
    }

    public lastRead(messageId, chatId) {
        return this.apiService.postCall$('chats/message/read', {messageId, chatId});
    }

    public deleteMessage(ids: number[]) {
        return this.apiService.deleteCall$<boolean>(`chats/message`, {ids});
    }

    private websocketInit(resetWebsocket = false) {
        this.websocket.getWebsocket<ChatMessageData>('chatMessage', resetWebsocket).subscribe((wsItems) => {
            if (wsItems?.success) {
                this.websocketHandling(wsItems.data);
            } else if (!!wsItems?.reinit) {
                this.retryTimeoutPointer = setTimeout(() => {
                    this.websocketInit(true);
                }, 500);
            }
        }, error => {
            this.itemChanged.error(error);
            if (this.retryTimeoutPointer) {
                clearTimeout(this.retryTimeoutPointer);
            }
            this.retryTimeoutPointer = setTimeout(() => {
                this.websocketInit();
            }, 500);
        }, () => {

        });
    }

    private websocketHandling(wsItems: ChatMessageData) {
        if (typeof wsItems !== 'undefined') {
            this.totalCount = wsItems.count;
        }
        if (this.items && wsItems && wsItems.messages) {
            wsItems.messages.filter(i => +i.chat_id === +this.currentChatId).forEach(item => {
                const currentItem = this.items.find(p => p.id === +item.id);
                if (currentItem) {
                    Object.assign(currentItem, item);
                } else {
                    this.items.push(item);
                }
            });
            this.items.sort((a, b) => {
                if (a.id < b.id) {
                    return -1;
                }
                if (a.id > b.id) {
                    return 1;
                }
                return 0;
            });
            this.itemChanged.emit(wsItems.messages.map(i => +i.id));
        }
    }
}

export class ChatMessageData {
    messages: ChatMessage[];
    count: number;
}
