<div class="container mb-5">
    <div class="row justify-content-sm-center">
        <div class="col-12 col-sm-10 col-md-9 col-lg-7 col-xl-6 pt-2">
            <h3>Welkom bij Dirk</h3>
            <h6 class="mb-3">De app van D. van der Steen B.V.</h6>
            <p>
                Via deze app kunt u onze werkzaamheden volgen en eventuele problemen melden.
            </p>
            <h6 class="mt-3">Heeft u al een account voor de Dirk-app?</h6>
            <mat-radio-group (change)="changeRegister()" [(ngModel)]="register">
                <div>
                    <mat-radio-button [value]="true">Ik heb nog geen account</mat-radio-button>
                </div>
                <div>
                    <mat-radio-button [value]="false">Ik heb al wel een account</mat-radio-button>
                </div>
            </mat-radio-group>
            <br>
            <ng-container *ngIf="register === true">
                <h6>Hoe bent u betrokken met onze organisatie?</h6>
                <mat-radio-group [(ngModel)]="usertype" (change)="townproject=null" class="mb-3">
                    <div>
                        <mat-radio-button value="consumer">Als bewoner in de omgeving van een project</mat-radio-button>
                    </div>
                    <div>
                        <mat-radio-button value="client">Als opdrachtgever</mat-radio-button>
                    </div>
                    <div>
                        <mat-radio-button value="employee">Als medewerker</mat-radio-button>
                    </div>
                    <br>
                </mat-radio-group>
            </ng-container>
            <ng-container *ngIf="usertype === 'consumer' && register === true">
                <h6 class="mb-2">In de omgeving van welk project woont u?</h6>
                <mat-radio-group [(ngModel)]="townproject" class="mb-3 projectsradio">
                    <div *ngFor="let project of townprojects" class="my-1 w-100">
                        <mat-radio-button [value]="project">
                            {{project.name}}<br>
                            <small>{{project.town_name}}</small>
                        </mat-radio-button>
                    </div>
                </mat-radio-group>
            </ng-container>
            <button *ngIf="townproject" routerLink="/{{Routenames.register}}/{{townproject.town_id}}/{{townproject.id}}" color="primary" mat-raised-button>Account aanmaken</button>


            <ng-container *ngIf="usertype === 'employee' && register === true">
                <h5 class="mb-1">Account aanmaken voor de Dirk-app</h5>
                <p>Als medewerker kun je geen account voor jezelf aanmaken.<br> Vraag een collega om een account voor je aan te maken.</p>
            </ng-container>

            <ng-container *ngIf="usertype === 'client' && register === true">
                <h5 class="mb-1">Account aanmaken voor de Dirk-app</h5>
                <p>
                    Als opdrachtgever is het niet mogelijk zelf te registreren.<br>
                    Vraag uw contactpersoon bij DvdSteen om een account aan te maken.
                </p>
            </ng-container>


            <form (ngSubmit)="login()" *ngIf="register === false" class="ng-pristine ng-valid">
                <h5 class="mb-1">Inloggen in de Dirk-app</h5>
                <div class="form-group">
                    <mat-form-field class="w-100">
                        <input [(ngModel)]="user.email" (change)="emailChange()" (keyup)="emailChange()" id="email" matInput name="email" placeholder="E-mailadres"
                               type="text">
                    </mat-form-field>
                    <mat-form-field class="w-100">
                        <input [(ngModel)]="user.password" id="password" matInput name="password"
                               placeholder="Wachtwoord"
                               type="password">
                    </mat-form-field>
                </div>

                <div class="mrg-top-20">
                    <div class="row">
                        <div class="col-sm-12">
                            <button color="primary" mat-raised-button>Inloggen</button>
                            <a color="primary" mat-button routerLink="/forgot-password">
                                Wachtwoord vergeten
                            </a>
                            <br>
                            <br>
                            <div *ngIf="message" class="message">
                                {{message}}
                            </div>
                            <br>
                        </div>
                    </div>
                </div>

            </form>
        </div>
    </div>
</div>
