import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-pdf-viewer-dialog',
    templateUrl: './pdf-viewer-dialog.component.html',
    styleUrls: ['./pdf-viewer-dialog.component.scss']
})
export class PdfViewerDialogComponent implements OnInit {

    public pdf: any;
    public name: string;

    constructor(public dialogRef: MatDialogRef<PdfViewerDialogComponent>,
                @Inject(MAT_DIALOG_DATA) {pdf, name}) {
        this.name = name;
        this.pdf = pdf;
    }

    ngOnInit() {
        document.getElementById('PDFviewer').setAttribute('src', this.pdf);
    }

    close() {
        this.dialogRef.close();
    }
}
