import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'chatTypePipe'
})
export class ChatTypePipe implements PipeTransform {

    transform(value: string): string {
        if (value === 'vraag') {
            return '❓';
        }
        if (value === 'klacht') {
            return '⚠';
        }
        if (value === 'compliment') {
            return '👍🏻';
        }
        if (value === 'info') {
            return 'ℹ';
        }
        return value;
    }

}
