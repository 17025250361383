import {Component, Inject, NgZone, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Chat} from '../chat';
import {ChatService} from '../chat.service';
import {GpsService} from '../../gps.service';
import {TownProject} from '../../town-project';
import {TownProjectService} from '../../services/townproject/town-project.service';
import {ChatMessageService} from '../chat-message.service';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {Utils} from '../../utils.class';
import {requiredConditional} from '../../validators/required-conditional.validator';
import {ChooseFileSourceComponent} from '../../file-manager/choose-file-source/choose-file-source.component';

declare var cordova;

@Component({
    selector: 'app-new-chat-dialog',
    templateUrl: './new-chat-dialog.component.html',
    styleUrls: ['./new-chat-dialog.component.scss']
})
export class NewChatDialogComponent implements OnInit {

    isCordova = typeof cordova !== 'undefined';

    form: FormGroup;
    fc;
    loading = true;

    types = ['vraag', 'klacht', 'compliment', 'info'];
    projects: TownProject[];

    constructor(public dialogRef: MatDialogRef<NewChatDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public chat: Chat,
                private gpsService: GpsService,
                private ngZone: NgZone,
                private matBottomSheet: MatBottomSheet,
                private projectService: TownProjectService,
                private chatMessageService: ChatMessageService,
                private chatService: ChatService) {
        if (!this.chat) {
            this.chat = new Chat();
        }
    }

    ngOnInit(): void {
        this.fc = {
            id: new FormControl(this.chat.id),
            name: new FormControl(this.chat.name),
            type: new FormControl(this.chat.type ?? 'vraag', Validators.required),
            lat: new FormControl(this.chat.lat),
            lng: new FormControl(this.chat.lng),
            townproject_id: new FormControl(this.chat.townproject_id, Validators.required)
        };
        if (!this.chat.id) {
            Object.assign(this.fc, {
                firstMessage: new FormControl('', Validators.required),
                image: new FormControl('', requiredConditional(this.fc.type, 'klacht'))
            });
        }
        this.form = new FormGroup(this.fc);
        this.gpsService.getCurrentPosition().then((geo) => {
            this.fc.lat.setValue(geo['latitude']);
            this.fc.lng.setValue(geo['longitude']);
        }, () => {
        });
        this.projectService.getProjects(true).subscribe(data => {
            this.projects = data.data;
            if (this.projects?.length === 1) {
                console.log(this.projects[0]);
                this.ngZone.run(() => {
                    this.fc.townproject_id.setValue(this.projects[0].id);
                    setTimeout(() => {
                        document.querySelector('textarea').focus();
                    }, 250);
                });

            }
        });
    }

    startChat() {
        if (!this.fc.name.value) {
            this.fc.name.setValue(this.fc.type.value);
        }
        if (this.form.valid) {

            this.chatService.saveChat({
                id: this.chat.id,
                type: this.fc.type.value,
                lat: this.fc.lat.value,
                lng: this.fc.lng.value,
                townproject_id: this.fc.townproject_id.value,
                name: this.fc.name.value
            }).subscribe(chat => {
                this.dialogRef.close(chat.data);
                if (this.fc.firstMessage.value) {
                    this.chatMessageService.newMessage({
                        message: this.fc.firstMessage.value,
                        chat_id: chat.data.id
                    });
                }
                if (this.fc.image.value) {
                    this.chatMessageService.newMessage({
                        message: this.fc.image.value,
                        chat_id: chat.data.id,
                        lat: this.fc.lat.value,
                        lng: this.fc.lng.value
                    });
                }
            });
        } else {
            Utils.triggerValidation(this.form);
        }
    }


    camera(event) {
        event.stopPropagation();
        event.preventDefault();
        const ref = this.matBottomSheet.open(ChooseFileSourceComponent, {
            data: {
                rotate: false,
                remove: false
            },
            panelClass: 'choose-file-dropupmenu'
        });
        const sheetDismissSubs = ref.afterDismissed().subscribe(type => {
            if (typeof cordova !== 'undefined' && type) {
                navigator['camera'].getPicture((data) => {
                    this.ngZone.run(() => {
                        this.fc.image.setValue("data:image/jpeg;base64," + data);
                    });
                }, (data) => {

                }, {
                    quality: 50,
                    targetWidth: 1600,
                    targetHeight: 1600,
                    destinationType: 0,
                    sourceType: type === 'camera' ? 1 : 0,
                    correctOrientation: true
                });
            }
            sheetDismissSubs.unsubscribe();
        });
    }

    uploadFile(event) {
        event.stopPropagation();
        event.preventDefault();
        const files = event.srcElement.files;
        for (let i = 0; i < files.length; i++) {
            const img = new Image();
            img.src = window.URL.createObjectURL(files[i]);
            img.onload = () => {
                const factor = 1600 / Math.max(img.naturalWidth, img.naturalHeight);
                const width = factor < 1 ? img.naturalWidth * factor : img.naturalWidth;
                const height = factor < 1 ? img.naturalHeight * factor : img.naturalHeight;
                const elem = document.createElement('canvas');
                elem.width = width;
                elem.height = height;
                const ctx = elem.getContext('2d');
                ctx.drawImage(img, 0, 0, width, height);
                this.fc.image.setValue(ctx.canvas.toDataURL('image/jpeg', 0.7));
            };
        }
    }
}
