<div class="shadow"></div>
<section [class.inprogress]="inProgress">
    <app-processbar *ngIf="inProgress"></app-processbar>

    <span class="pullto" *ngIf="startAfterRelease">
        <i class="fas fa-angle-double-up"></i>
        Laat los om te vernieuwen
        <i class="fas fa-angle-double-up"></i>
    </span>

    <span class="pullto" *ngIf="!startAfterRelease && !inProgress">
        <i class="fas fa-angle-double-down"></i> Vernieuwen
        <i class="fas fa-angle-double-down"></i>
    </span>
</section>
<div class="refreshable-content">
    <ng-content></ng-content>
</div>
<div [class.forcescrolling]="forcescrolling"></div>
