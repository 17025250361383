<div class="items h-100" [class.empty]="projects?.length === 0">
    <div *ngIf="projects?.length === 0 || !projects" class="not-found">
        <div class="text-center">
            Er staat nog niets ingepland voor dit project
        </div>
    </div>
    <div class="item-list" *ngIf="projects?.length > 0">
        <a *ngFor="let project of projects"
           class="item"
           routerLink="/{{'towns/'+project.town_id+'/projects/'+project.id+'/'}}{{Routenames.timeline}}">
            {{project.name}}
            <div class="icon"></div>
        </a>
        <div class="item-spacer"></div>
    </div>
</div>
