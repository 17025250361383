import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from '../services/auth/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Message} from '../message.class';
import {Subscription} from 'rxjs';

interface PasswordReset {
    password: string;
    repassword: string;
    token: string;
}


@Component({
    selector: 'app-restore-password',
    templateUrl: './restore-password.component.html',
    styleUrls: ['./restore-password.component.scss']
})
export class RestorePasswordComponent implements OnInit, OnDestroy {

    message: Message;
    buttonDisabled = false;
    isInvite = false;

    data: PasswordReset = {
        'password': '',
        'repassword': '',
        'token': ''
    };

    private subscriptions = new Subscription();

    constructor(private router: Router,
                private route: ActivatedRoute,
                private authService: AuthService) {
    }

    ngOnInit() {
        this.isInvite = this.checkIsInvite();

        this.subscriptions.add(this.route.params.subscribe(params => {
            this.data.token = params['token'];
            this.authService.verifyResetToken(this.data.token).then(() => {
            }, () => {
            });
        }));
    }

    onSubmit() {
        if (this.data.password !== this.data.repassword) {
            this.message = new Message('De wachtwoorden komen niet overeen!', true);
        } else if (this.data.password.length < 7) {
            this.message = new Message('Uw wachtwoord moet minimaal 7 karakters bevatten', true);
        } else {
            this.authService.restorePassword(this.data.token, this.data.password).subscribe(response => {
                this.message = new Message(response['message']);
                this.buttonDisabled = true;
                this.router.navigate(['/']);

            }, () => {
                this.message = new Message('Er ging iets fout, probeer het opnieuw.', true);
                this.buttonDisabled = false;

            });
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private checkIsInvite() {
        return (window.location.pathname.split('/')[1] === 'invite');
    }

}
