<div class="image-viewer-backdrop" *ngIf="showImage >= 0" (click)="showImage = -1">
    <div class="image-viewer">
        <pinch-zoom [autoHeight]="true" [minScale]="0.5" [limit-zoom]="5000" [draggable]="true" disableZoomControl="disable"
                    overflow="visible"
                    #pinchZoom>
            <img src="{{images[showImage][objectKey]}}" (click)="imageClick($event)" class="img" alt="image">
        </pinch-zoom>
        <div class="information">
            <h1>
                {{images[showImage].created_at | amLocale:'nl' | amDateFormat: 'dddd DD MMMM YYYY'}}
                <ng-container *ngIf="!hideTime">
                    &nbsp;{{images[showImage].created_at | amLocale:'nl' | amDateFormat: 'HH:mm'}}
                </ng-container>
                - {{images[showImage].status.name}}</h1>
            Foto gemaakt door {{images[showImage].user.name || images[showImage].user.email}} - werknummer {{workarea.number}}<br>
            {{workarea.street}} {{workarea.housenumber}}, {{workarea.zipcode}} {{workarea.place}} {{workarea.road_number ? '-' : ''}} {{workarea.road_number}}
        </div>
        <div class="next" (click)="next($event)">
            <i class="fas fa-arrow-right"></i>
        </div>
        <div class="prev" (click)="prev($event)">
            <i class="fas fa-arrow-left"></i>
        </div>
        <div class="close-viewer" (click)="showImage = -1">
            <i class="fas fa-close"></i>
        </div>
    </div>

</div>



