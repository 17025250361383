import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'projectType'
})
export class ProjecttypePipe implements PipeTransform {

    private typeValues = new Map<string, string>([
        ['maintenance', 'Onderhoud'],
        ['drillings', 'Asfaltboringen'],
        ['regular', 'Project']
    ]);

    transform(value: any, ...args: any[]): any {
        if (!this.typeValues.has(value)) {
            console.warn('Projecttype unkwown: ' + value);
        }
        return this.typeValues.get(value);
    }

}
