import {Component, OnInit} from '@angular/core';
import {TownService} from '../../services/town/town.service';
import {Contact} from '../shared/contact';
import {Storage} from '../../storage.class';
import {Utils} from '../../utils.class';
import {ConfirmModalService} from '../../shared/confirm-modal.service';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {Routenames} from 'app/route-names.enum';
import {SearchService} from '../../services/search/search.service';
import {TownProjectService} from '../../services/townproject/town-project.service';
import {TownProject} from '../../town-project';

@Component({
    selector: 'app-contact-overview',
    templateUrl: './contact-overview.component.html',
    styleUrls: ['./contact-overview.component.scss']
})
export class ContactOverviewComponent implements OnInit {

    public Utils = Utils;
    public Storage = Storage;

    contacts: Contact[];
    loading = true;
    townId: number;
    projectId: number;
    Routenames = Routenames;
    subscriptions = new Subscription();
    private allContacts: Contact[];
    projects: TownProject[];

    constructor(private townService: TownService,
                private confirmModal: ConfirmModalService,
                private searchService: SearchService,
                private projectService: TownProjectService,
                private route: ActivatedRoute) {
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    ngOnInit() {
        this.subscriptions.add(this.route.params.subscribe(params => {
            this.townId = +params['townId'];
            this.projectId = +params['projectId'];
        }));
        if (this.projectId) {
            this.townService.contacts(this.projectId).then(contacts => {
                this.loading = false;
                this.allContacts = this.contacts = contacts;
                this.filterAndSort();
            }, (error) => {
                this.confirmModal.showModal('Kon contactpersonen niet ophalen', error);
            });
        } else {
            this.projectService.getProjects(true, null, null, null, true).subscribe(data => {
                this.loading = false;
                this.projects = data.data;
            });
        }

        this.subscriptions.add(this.searchService.change.subscribe(searchValue => this.filterAndSort(searchValue)));
    }

    filterAndSort(searchValue?: string) {
        let contacts = this.allContacts;
        if (searchValue) {
            contacts = contacts.filter(c => {
                return c.description.toLowerCase().indexOf(searchValue) !== -1 ||
                    c.name.toLowerCase().indexOf(searchValue) !== -1 ||
                    c.email.toLowerCase().indexOf(searchValue) !== -1;
            });
        }
        this.contacts = contacts.sort(function (a, b) {
            const nameA = a['name'].toUpperCase(); // ignore upper and lowercase
            const nameB = b['name'].toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            // names must be equal
            return 0;
        });
    }

}
