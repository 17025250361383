import {Injectable} from '@angular/core';

@Injectable()
export class GpsService {

    constructor() {
    }

    public getCurrentPosition(): Promise<object> {
        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition((position) => {
                resolve(position.coords);
            }, () => {
                reject({'error': true});
            });
        });

    }

}
