<form #workareaForm="ngForm">
    <div class="row">
        <div class="col-12" [class.col-md-6]="workarea.extra.vogzang_type != 'Service'"
             [class.col-md-4]="workarea.extra.vogzang_type == 'Service'">
            <mat-form-field class="w-100">
                <mat-select name="type" (valueChange)="inputChanged($event)"
                            [(ngModel)]="workarea.extra.vogzang_type" #type="ngModel"
                            errorState="type.errors?.required && (type.dirty || workareaForm.submitted)"
                            required placeholder="Type">
                    <mat-option [value]="undefined" selected disabled>Type</mat-option>
                    <mat-option *ngFor="let type of fieldOptions['vogzang_types']"
                                [value]="type">{{type}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 col-md-4" *ngIf="workarea.extra.vogzang_type == 'Service'">
            <mat-form-field class="w-100">
                <mat-select name="service" placeholder="Service" (valueChange)="inputChanged($event)"
                            [(ngModel)]="workarea.extra.vogzang_service" required
                            #service="ngModel"
                            errorState="service.errors?.required && (service.dirty || workareaForm.submitted)">
                    <mat-option [value]="undefined" selected disabled>Service</mat-option>
                    <mat-option *ngFor="let service of vogzang_services" [value]="service">
                        {{service}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div [class.col-md-6]="workarea.extra.vogzang_type != 'Service'"
             [class.col-md-4]="workarea.extra.vogzang_type == 'Service'"
             *ngIf="Utils.userHasRights('GEMEENTE')"
             class="col-12">
            <mat-form-field class="w-100">
                <mat-select name="status" [(ngModel)]="workarea.status_id"
                            (change)="statusChange()"
                            placeholder="Satus">
                    <mat-option [ngValue]="undefined" disabled>Status</mat-option>
                    <mat-option *ngFor="let status of statuses" [value]="status.id">
                        {{status.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>


    <div class="row user-select-none" *ngIf="workarea.extra.vogzang_type == 'Service'">
        <div class="col-12">
            <div class="">
                <label>Wanneer wilt u gebruik maken van de service?</label>
                <div class="row">
                    <div class="col-12">
                        <div class="md-form float-left">
                            <input bsDatepicker required readonly
                                   [isDisabled]="workarea.id && !Utils.userHasRights('USER')"
                                   name="servicedate"
                                   id="servicedate"
                                   placement="right"
                                   placeholder="dd-mm-yyyy"
                                   [bsConfig]="bsConfig"
                                   (ngModelChange)="servicedate"
                                   [(ngModel)]="workarea.extra.servicedate"
                                   class="form-control datepicker"
                                   id="servicedate" required #servicedate="ngModel"
                                   [class.border-danger]="servicedate.errors?.required && (servicedate.dirty || workareaForm.submitted)">
                            <label for="servicedate"
                                   [class.active]="variableIsPresent(workarea.servicedate)"
                                   [class.text-danger]="servicedate.errors?.required && (servicedate.dirty || workareaForm.submitted)">Datum</label>
                        </div>
                        <timepicker class="float-left" required
                                    [disabled]="workarea.id && !Utils.userHasRights('USER')"
                                    [showMeridian]="false"
                                    [minuteStep]="5"
                                    [min]="getMinTime()"
                                    [max]="getMaxTime()"
                                    [ngModelOptions]="{standalone: true}"
                                    [(ngModel)]="workarea.extra.servicedate"></timepicker>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <mat-form-field class="w-100">
                                        <textarea [(ngModel)]="workarea.comments" name="comments"
                                                  matInput cdkTextareaAutosize cdkAutosizeMinRows="4"
                                                  placeholder="Opmerkingen"></textarea>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <mat-form-field class="w-100">
                                    <textarea [(ngModel)]="workarea.feedback" name="feedback"
                                              matInput cdkTextareaAutosize cdkAutosizeMinRows="4"
                                              [readonly]="!Utils.userHasRights('GEMEENTE')"
                                              placeholder="{{Utils.userHasRights('GEMEENTE') ? 'Terugkoppeling aan melder' : 'Terugkoppeling'}}"></textarea>
            </mat-form-field>
        </div>
    </div>

    <div class="row">
        <div class="col-12 col-md-6" *ngIf="Utils.userHasRights('GEMEENTE')">
            <mat-form-field class="w-100">
                <mat-select name="priority" [(ngModel)]="workarea.extra.priority"
                            placeholder="Prioriteit">
                    <mat-option *ngFor="let priority of priorities" [value]="priority">
                        {{priority}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <!--<div class="col-12 col-md-6">-->
        <!--<div class="form-check">-->
        <!--<label class="custom-control custom-checkbox">-->
        <!--<input type="checkbox" class="custom-control-input">-->
        <!--<span class="custom-control-indicator"></span>-->
        <!--<span class="custom-control-description">Betreft klinkervak</span>-->
        <!--</label>-->
        <!--</div>-->
        <!--</div>-->
        <div class="col-12 col-md-6" *ngIf="workarea.user">
            <mat-form-field class="w-100" *ngIf="workarea.user">
                                        <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="1"
                                                  placeholder="Aanvrager" [disabled]="true"
                                        >{{workarea.user.name}}
                                            , {{workarea.user.email}}{{workarea.user.phone ? '\nTel: ' + workarea.user.phone : ''}}</textarea>
            </mat-form-field>
        </div>
    </div>

    <!--<div class="row">-->
    <!--<div class="col-6">-->
    <!--</div>-->
    <!--<div class="col-6">-->
    <!--<div class="form-check">-->
    <!--<label class="custom-control custom-checkbox">-->
    <!--<input type="checkbox" class="custom-control-input">-->
    <!--<span class="custom-control-indicator"></span>-->
    <!--<span class="custom-control-description">Bewapeningsnet-->
    <!--gebruiken</span>-->
    <!--</label>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->

    <!--<div class="row">-->
    <!--<div class="col-6">-->
    <!--</div>-->
    <!--</div>-->

    <!--<div class="row">-->
    <!--<div class="col-6">-->
    <!--</div>-->
    <!--<div class="col-6">-->
    <!--<div class="form-group">-->
    <!--<select class="form-control">-->
    <!--<option value="" disabled selected>Afzetting</option>-->
    <!--</select>-->
    <!--<label>Afzetting</label>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->

    <!--<div class="row">-->
    <!--<div class="col-6">-->
    <!--<div class="row">-->
    <!--<div class="col-6">-->
    <!--<input type="text" class="form-control" placeholder="Lengte">-->
    <!--</div>-->
    <!--<div class="col-6">-->
    <!--<input type="text" class="form-control" placeholder="Breedte">-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div class="col-6">-->
    <!--<div class="form-group">-->
    <!--<select class="form-control distance-icon">-->
    <!--<option value="" disabled selected>-</option>-->
    <!--</select>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->

    <!--<div class="row">-->
    <!--<div class="col-6">-->
    <!--<div class="form-group">-->
    <!--<button class="btn btn-primary btn-block z-depth-0"> <i class="icon icon-add"></i> Vlak toevoegen</button>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div class="col-6">-->
    <!--<div class="form-group">-->
    <!--<select class="form-control distance-icon">-->
    <!--<option value="" disabled selected>-</option>-->
    <!--</select>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
</form>
