import {NativeDateAdapter} from '@angular/material/core';
import {formatDate} from '@angular/common';
import {Injectable} from '@angular/core';

@Injectable()
export class DateNLFormatAdapter extends NativeDateAdapter {

    format(date: Date, displayFormat: any): string {
        return formatDate(date, 'd-MM-yyy', 'nl');
    }

    parse(value: any): Date | null {
        if (typeof value === 'string') {
            const dateParts = value.split('-');
            if (dateParts.length > 2) {
                return new Date(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`);
            }
        }
        return super.parse(value);
    }

}
