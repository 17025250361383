import {Injectable} from '@angular/core';
import {ApiService} from '../../services/api/api.service';
import {Storage} from '../../storage.class';

@Injectable({
    providedIn: 'root'
})
export class FieldOptionsServiceService {

    private options = {};

    constructor(private apiService: ApiService) {
    }

    public getOptions(type?: string): Promise<object> {

        const town = Storage.getTown();
        if (!type) {
            type = '';
        }

        return new Promise((resolve, reject) => {
            if (typeof this.options[type || town.towntype] !== 'undefined') {
                resolve(this.options[type || town.towntype]);
            } else {
                this.apiService.getCall('workareas/options', {townType: type}).then((options: object) => {
                    this.options[type || town.towntype] = options;
                    resolve(this.options[type || town.towntype]);
                }, err => {
                    reject(err);
                });
            }
        });

    }
}
