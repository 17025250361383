<div class="modal-header">
    <h4 class="modal-title pull-left">Selecteer een project</h4>
    <button aria-label="Close" class="close pull-right" mat-dialog-close type="button">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <mat-selection-list [multiple]="false" class="w-100">
        <mat-list-option (click)="create(project)" *ngFor="let project of projects">
            {{project.name}}
        </mat-list-option>
    </mat-selection-list>
</div>
<div class="modal-footer justify-content-between">
    <button color="primary" mat-button mat-dialog-close type="button">
        Annuleren
    </button>
</div>
