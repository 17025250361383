import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {Settings} from '../settings.class';

@Pipe({
    name: 'marker'
})
export class MarkerPipe implements PipeTransform {
    constructor(private dom: DomSanitizer) {
    }

    transform(value, colorcode) {
        return this.dom.bypassSecurityTrustHtml(Settings.getMarker(9999, value, colorcode, true, false));
    }

}
