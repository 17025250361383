import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Settings} from 'app/settings.class';
import {Town} from '../town.class';
import {Storage} from '../storage.class';
import {Subscription} from 'rxjs';
import {TownService} from '../services/town/town.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TownProjectService} from '../services/townproject/town-project.service';
import {TownProject} from '../town-project';
import {MatDialog} from '@angular/material/dialog';
import {TownProjectEditDialogComponent} from '../town-project-edit-dialog/town-project-edit-dialog.component';
import {SearchService} from '../services/search/search.service';
import {CodaltComponent} from '../codalt.component';
import {Routenames} from '../route-names.enum';
import {saveAs} from 'file-saver';
import {ExportService} from '../services/export/export.service';
import {ConfirmDialogService} from '../services/confirm-dialog-service/confirm-dialog.service';

@Component({
    selector: 'app-town-project-overview',
    templateUrl: './town-project-overview.component.html',
    styleUrls: ['./town-project-overview.component.scss']
})
export class TownProjectOverviewComponent extends CodaltComponent implements OnInit, OnDestroy {

    @ViewChild('projectFilterSearchTemplate', {static: true}) projectFilterSearchTemplate;
    projectFilter = ['regular', 'maintenance'];

    town: Town;
    townId: number;
    projects: TownProject[];
    filteredProjects: TownProject[];
    Settings = Settings;
    subscriptions = new Subscription();
    projectsCounts;

    viewOnyDrilling: boolean;

    generatingExcel = false;

    constructor(private dialog: MatDialog,
                private townService: TownService,
                private townProjectService: TownProjectService,
                private router: Router,
                private search: SearchService,
                private searchService: SearchService,
                private exportService: ExportService,
                private confirmDialog: ConfirmDialogService,
                private route: ActivatedRoute) {
        super();
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.searchService.searchTemplate = this.projectFilterSearchTemplate;
        });
        this.subscriptions.add(this.route.parent.params.subscribe(params => {
            this.viewOnyDrilling = this.route.snapshot.url[0].path === Routenames.projectsDrilling;
            if (this.viewOnyDrilling) {
                this.projectFilter = ['drillings'];
            }
            this.townId = +params['townId'];
            this.townService.getSingle(this.townId).then(town => {
                this.town = town;
                Storage.setTown(town);
                this.loadProjects();
            });
        }));
        this.search.change.subscribe(searchTerm => {
            this.filteredProjects = this.projects.filter(p => p.name.toLowerCase().indexOf(searchTerm) !== -1);
        });
    }

    loadProjects() {
        const allProjectTypes = Settings.projectTypes.map(pt => pt.key);
        const projectType = this.projectFilter.filter(f => allProjectTypes.indexOf(f) !== -1);
        const archive = !!this.projectFilter.find(f => f === 'archive');
        this.townProjectService.getProjects(false, this.townId, projectType, archive).subscribe(projects => {
            this.projects = projects.data;
            this.filteredProjects = this.projects;
        });
        this.townProjectService.getProjectsWorkareaCounts().subscribe(counts => {
            this.projectsCounts = counts;
        });
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
        this.searchService.searchTemplate = null;
    }

    projectModal(project?: TownProject) {
        this.dialog.open(TownProjectEditDialogComponent, {
            panelClass: 'confirm-dialog',
            disableClose: true,
            data: {
                town: this.town,
                project,
                onlyDrilling: this.viewOnyDrilling
            }
        }).afterClosed().subscribe((project: TownProject) => {
            if (project) {
                this.router.navigate([
                    'towns',
                    project.town_id,
                    (this.viewOnyDrilling ? Routenames.projectsDrilling : Routenames.projects),
                    project.id]);
            }
        });
    }

    exportExcel() {
        this.generatingExcel = true;
        this.exportService.downloadExcelWorkareas().subscribe((document) => {
            saveAs(document, `Excel ${this.town.name}.xlsx`);
            this.generatingExcel = false;
        }, error => {
            this.generatingExcel = false;
            const err = error['error']['error']['errormessage'];
            this.confirmDialog.confirm('Oeps...', `Er is iets fout gegaan op de server ${err}`, 'Oke', null).then(() => {
            });
        });

    }
}
