import {User} from './user.class';
import {TownProjectPhase} from './town-project-phase';
import {Contact} from './contacts/shared/contact';

export class TownProject {
    id: number;
    town_id: number;
    townprojectphase_id: number;
    town_project_phase: TownProjectPhase;
    name: string;
    custom_numbers: boolean;
    consumers: boolean;
    consumers_site: boolean;
    type: string;
    budget: number;
    workarea_count: number;
    order: number;
    created_at: Date;
    manager: number;
    manager_user: User;
    town_manager: number;
    town_manager_user: User;
    town_project_phases: TownProjectPhase[];
    extra;
    counts: object;
    contact_count: number;
    contacts?: Contact[];
    archive: boolean;
    restrict: boolean;
    email: boolean;
    users: User[];
    description: string;
}
