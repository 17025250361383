import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TownProjectService} from '../../services/townproject/town-project.service';
import {TownProject} from '../../town-project';
import {ActivatedRoute, Router} from '@angular/router';
import {Storage} from '../../storage.class';
import {map} from 'rxjs/operators';

@Component({
    selector: 'app-workarea-project-choose-dialog',
    templateUrl: './workarea-project-choose-dialog.component.html',
    styleUrls: ['./workarea-project-choose-dialog.component.scss']
})
export class WorkareaProjectChooseDialogComponent implements OnInit {

    projects: TownProject[];

    constructor(public dialogRef: MatDialogRef<WorkareaProjectChooseDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public type: string,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private projectService: TownProjectService) {
    }

    ngOnInit(): void {

        if (this.activatedRoute.snapshot.queryParams['project']) {
            const route = ['towns', Storage.getTown()['id'], 'workareas', -1] as (number | string | object)[];
            this.router.navigate(route, {
                queryParamsHandling: "merge"
            });
            setTimeout(() => {
                this.dialogRef.close();
            });
        } else {
            this.projectService.getProjects(false, Storage.getTown()['id']).pipe(map(p => p.data)).subscribe(projects => {

                this.projects = projects;

                if (this.projects.length === 1) {
                    this.create(this.projects[0]);
                }
                if (this.projects.length < 1) {
                    this.router.navigate(['towns', Storage.getTown()['id'], 'workareas', -1], {
                        queryParamsHandling: "merge"
                    });
                    this.dialogRef.close();
                }
            });
        }
    }

    create(project = null) {
        if (project) {
            const route = ['towns', project.town_id, 'workareas', -1] as (number | string | object)[];

            route.push({project_id: project.id});

            this.router.navigate(route, {
                queryParamsHandling: "merge"
            });
        } else {
            const route = ['towns', Storage.getTown().id, 'workareas', -1] as (number | string | object)[];
            this.router.navigate(route, {
                queryParamsHandling: "merge"
            });
        }
        this.dialogRef.close();
    }

}
