export class AddressArea {
    id: number;
    name: string;
    strokeColor: string;
    exteriorRing: {
        longitude: number;
        latitude: number;
    }[];
    buildings: {
        identificatie: string;
        gebruiksdoelen: string[];
        locatie: {
            lat: number;
            lng: number;
        }
    }[];
    drawing?: boolean;
    loading?: boolean;
    updated_at?: Date;
}
