<div class="content-container">
    <div class="container-fluid">
        <div class="row justify-content-sm-center">
            <div class="col-12 col-sm-10 col-md-9 col-lg-7 col-xl-6 pt-2">

                <p class="mrg-btm-15" [hidden]="hideControls">
                    Bent u uw wachtwoord vergeten? Geen probleem!<br>
                    Voer hieronder uw e-mailadres in. We sturen u dan een link,
                    waarmee u een nieuw wachtwoord kunt instellen.
                </p>
                <p class="message" [ngClass]="{ 'error' : message.error }" *ngIf="message">
                    {{message.message}}
                    <br><br>
                    <button mat-raised-button color="primary" routerLink="/login" class="float-right">Terug naar
                        inloggen
                    </button>
                </p>
                <form #resetForm="ngForm" class="ng-pristine ng-valid" (ngSubmit)="onSubmit(resetForm)" *ngIf="!message">
                    <div class="form-group">
                        <mat-form-field class="w-100" [hidden]="hideControls">
                            <input type="email" [(ngModel)]="user.email" name="email" id="email" matInput
                                   placeholder="Uw email adres" [disabled]="buttonDisabled" #email="ngModel" required>
                        </mat-form-field>
                    </div>
                    <div class="mrg-top-20 text-right">
                        <button mat-raised-button color="primary" [disabled]="buttonDisabled" [hidden]="hideControls">
                            {{buttonText}}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
